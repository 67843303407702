require([
  'core/Knack',
  'builder/backbone/views/HelpView', // for some reason this is require here or else BuilderView won't load it in correctly
  'builder/backbone/views/BuilderView',
  'dashboard/backbone/routers/AppRouter',
  'jquery'
], function(knack, HelpView, BuilderView, AppRouter, $) {

  // exposing jquery for 3rd party libs
  window.$ = $;

  // Application
  window.Knack = new knack();
  Knack.init({
    scene_id: 'key',
    mode: 'dashboard',
    router: new AppRouter()
  });

  // Builder setup
  window.Builder = new BuilderView({
    'el': 'body'
  });
});
