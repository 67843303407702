define([
  'jquery', 'moment', 'backbone', 'underscore',
  'core/backbone/views/BaseView',
  'builder/help/content',
  'builder/help/map',
  'builder/templates/help.html',
  'builder/templates/help-tour.html',
  'builder/templates/help-tour-welcome.html',
  'builder/templates/help-ticket.html',
  'builder/templates/help-bug.html',
  'builder/templates/help-assistance.html',
  'builder/templates/help-emergency.html',
  'builder/ui/kn-search-input',
  'core/lib/cookie'
], function($, moment, Backbone, _, BaseView, NewContent ,map, template_help_page, template_tour, template_welcome, template_ticket, template_bug, template_assistance, template_emergency, SearchInput, cookie) {
  return BaseView.extend({

    events: {
    },

    initialize: function() {
      /*
      $('#intercom-container > .intercom-app-container > .intercom-app > .intercom-launcher-frame').hide();

      if ($('#knack-dashboard-login').length < 1) {
        this.render();
      };

      if (typeof Intercom !== 'undefined') {
        Intercom('onShow', function() {
          log('ONSHOW!');
          $('#kn-help-widget').hide();
          var to = setTimeout(function() {
            $('#intercom-container > .intercom-app-container > .intercom-app > .intercom-launcher-frame').show();
            log($('#intercom-container > .intercom-app-container > .intercom-app > .intercom-launcher-frame').length);
          }, 500);
        });

        Intercom('onHide', function() {
          $('#kn-help-widget').show();
          log('ONHIDE!');
          $('#intercom-container > .intercom-app-container > .intercom-app > .intercom-launcher-frame').hide();
        });
      }
      */

      this.render();
    },

    render: function() {
      log('render :: HelpView.js');

      $('#help-link-search').off().on('click', $.proxy(this.renderHelpPage, this));

      // This view gets rendered/included in our CRM for some reason and we won't have a Knack.account so it was causing
      // Nothing to get rendered when accessing Knack.account.product_plan below
      if (!Knack.account) {

        return;
      }

      var plan = Knack.account.product_plan;

      if (!plan) {

        return;
      }

      var account_level = plan.level;

      // check for account level-specific links
      if (plan.name !== 'Trial') {

        $('#help-link-assistance').remove();
      }

      // remove for corporate or below
      if (account_level < 4) {

        $('#help-link-emergency').remove();
      }

      // link events
      $('#help-link-ticket a').off().on('click', $.proxy(this.handleClickSupportTicket, this));
      $('#help-link-bug a').off().on('click', $.proxy(this.handleClickBugTicket, this));
      $('#help-link-emergency a').off().on('click', $.proxy(this.handleClickEmergencyTicket, this));
    },

    renderWidget: function() {
      // handle help page
      $('#help-link-search').off().on('click', $.proxy(this.renderHelpPage, this));
    },

    renderHelpPage: function(event) {

      event.preventDefault();

      var _this = this;

      log('renderHelpPage :: HelpView.js');

      // use Knack overlay
      Knack.renderOverlayTemplate('template_help_page', template_help_page);

      // hide quick help
      $('#quick-help').remove();

      // close event
      $(document).off('knack-overlay-close').on('knack-overlay-close', function() {
        Knack.closeOverlay();
        $('#kn-help-widget').show();
        Knack.hideSpinner(); // just in case
      });

      this.$master_search_result = $('#search-results > li:first-child').detach();

      // quick help
      this.renderQuickHelp();

      // search events
      var searchHandler = function(q) {
        log('searchHandler: ');
        log(q);
        _this.submitSearch(q);
      };
      var cancelHandler = function(q) {
        $('#quick-help').show();
        $('#search-results, #search-links').hide();
      };

      $('#help-search-input').knSearch({
        searchHandler: searchHandler,
        cancelHandler: cancelHandler
      });

      // focus the search input
      $('#help-search-input').focus();
    },

    handleClickNav: function(event) {
      log('handleClickNav :: HelpView.js');
      event.preventDefault();

      var which = $(event.currentTarget).prop('href').split('#')[1];

      $('#help-content .active, #help-nav .active').removeClass('active');
      $('#help-content #help-' + which).addClass('active');
      $(event.currentTarget).addClass('active');

      $('#help-content').scrollTop(0);
    },

    submitSearch: function(q) {
      var _this = this;

      var model = new Backbone.Model();
      model.url = Knack.api + '/help/search?q=' + q;

      Knack.showSpinner();
      $('#search-cancel').hide();
      $('#search-spinner').show();

      model.fetch({
        success: function(model, response) {
          log('SUCCESS!'); log(response.items);

          if (response.q != $('#help-search-input').val()) {
            return;
          }

          Knack.hideSpinner();
          $('#search-cancel').show();
          $('#search-spinner').hide();

          $('#quick-help').hide();

          if (response.items && response.items.length) {
            var showItems = function(items) {

              $('#search-results').empty();

              _.each(items, function(item) {

                var $item = _this.$master_search_result.clone();
                $item.find('img').remove();
                $item.find('h3').html(item.htmlTitle.replace(' – Knack', ''));
                $item.find('p').html(item.htmlSnippet.replace('<br>', ''));
                $item.find('a').prop('href', item.link);

                if (item.pagemap && item.pagemap.cse_thumbnail && item.pagemap.cse_thumbnail[0]) {
                  //$item.prepend( '<img src="' + item.pagemap.cse_thumbnail[0].src + '" style="max-height: 100px; float: right; margin-left:30px;" />')
                }

                $('#search-results').append($item);
              });
            };

            $('#search-results').empty();
            $('#search-results, #search-links').show();
            $('#search-empty').hide();

            var docs = _.filter(response.items, function(item) {
              return (item.link.indexOf('articles') > -1);
            });
            var posts = _.filter(response.items, function(item) {
              return (item.link.indexOf('discussions') > -1);
            });

            // show docs
            if (docs.length) {
              showItems(docs);
            }

            // if less than 5 then show posts as well
            if (docs.length && posts.length && response.items.length < 5) {
              showItems(posts);
            }

            // if just posts then show those
            if (!docs.length && posts.length) {
              showItems(posts);
            }

            // if posts and more than 5 total then split
            if (docs.length && posts.length && response.items.length > 4) {
              $('#search-posts-link').show();
              $('#search-docs-link span').text(docs.length);
              $('#search-posts-link span').text(posts.length);

              $('#search-docs-link').off().on('click', function(event) {
                event.preventDefault();
                $('#search-links .active').removeClass('active');
                $(event.currentTarget).addClass('active');
                showItems(docs);
              });
              $('#search-posts-link').off().on('click', function(event) {
                event.preventDefault();
                $('#search-links .active').removeClass('active');
                $(event.currentTarget).addClass('active');
                showItems(posts);
              });
            } else {
              $('#search-posts-link').hide();
              $('#search-docs-link span').text(response.items.length);
            }

            $('#search-links .active').removeClass('active');
            $('#search-docs-link').addClass('active');

          } else {

            $('#search-results, #search-links').hide();
            $('#search-empty').show();
          }
        }
      });
    },

    handleClickSupportTicket: function(event) {
      event.preventDefault();
      var _this = this;
      log('support ticket!');
      Knack.renderModalTemplate('template_ticket', template_ticket, {
        top: true
      });
      $('#submit-ticket').on('click', function(e) {
        e.preventDefault()

        Knack.showSpinner()

        const helpData = {
          helpSubject: $('select[name="repeat"] option:selected').text(),
          helpQuestion: $('.kn-modal .wrapper textarea').val(),
          devLink: _this.getDevLink()
        }

        const getHelp = new Backbone.Model()

        getHelp.url = `${Knack.api_dev}/applications/${Knack.application_id}/help/get-help`

        getHelp.save(helpData, {
          success: function () {

            Knack.hideSpinner()

            $('.kn-modal .wrapper').html('<p>Thank you for contacting us. We will review your ticket and get back to you as quickly as possible.</p><p>You can check the status of your ticket at the <strong><a target="_blank" href="https://support.knack.com">Knack Helpdesk</a></strong>.</p>')
          },
          error: function () {

            Knack.hideSpinner()

            $('#submit-ticket').removeClass(`disabled`)
          }
        })
      });

      $('.bug-ticket').on('click', function(e) {
        e.preventDefault();
        Knack.closeModal();
        _this.handleClickBugTicket(e);
      });
    },

    handleClickBugTicket: function(event) {
      event.preventDefault();
      var _this = this;
      log('bug ticket!!!!');
      Knack.renderModalTemplate('template_bug', template_bug, {
        class: 'medium'
      });

      $('input[name="type"]').on('change', function(e) {
        e.preventDefault();
        $('.bug-login').toggle();
      });

      $('#submit-bug').on('click', function(e) {
        e.preventDefault()

        Knack.showSpinner()

        const bugData = {
          bugDescription: $(`textarea[name="bug_description"]`).val(),
          bugLocation: $(`input[name="type"]:checked`).val(),
          bugUrl: $(`input[name="bug_url"]`).val(),
          canManipulateData: ($(`input[name="manipulate_data"]:checked`).val() === `yes`),
          devLink: _this.getDevLink()
        }

        const reportBug = new Backbone.Model()

        reportBug.url = `${Knack.api_dev}/applications/${Knack.application_id}/help/report-bug`

        reportBug.save(bugData, {
          success: function () {

            Knack.hideSpinner()

            $('.kn-modal .wrapper').html('<p>Thank you for contacting us. We will review your ticket and get back to you as quickly as possible.</p><p>You can check the status of your ticket at the <strong><a target="_blank" href="https://support.knack.com">Knack Helpdesk</a></strong>.</p>')
          },
          error: function () {

            Knack.hideSpinner()

            $('#submit-bug').removeClass(`disabled`)
          }
        })
      });
    },

    getLogRocketSession: function(callback) {
      if (!window.LogRocket) {

        return callback();
      }

      return LogRocket.getSessionURL(callback);
    },

    handleClickEmergencyTicket: function(event) {

      event.preventDefault()

      const _this = this
      const devLink = _this.getDevLink()

      Knack.renderModalTemplate(`template_emergency`, template_emergency, {
        class: `medium`
      })

      $(`#submit-emergency`).on(`click`, function(e) {

        e.preventDefault()

        Knack.showSpinner()

        if (Knack.isHIPAA()) {

          return _this.submitEmergencySupportTicket(devLink)
        }

        return _this.getLogRocketSession(function (session_url) {

          _this.submitEmergencySupportTicket(devLink, session_url)
        })
      })
    },

    submitEmergencySupportTicket: function(devLink, session_url) {

      const builder_link = window.location.href
      const message_text = $(`textarea[name=details]`).val()
      const live_url = $(`input[name=live-url]`).val()
      const issue_noticed = $(`input[name=issue-noticed]`).val()
      const changes_prior = $(`input[name=changes-prior]`).val()
      const test_record_permission = $(`input[name=test-record-permission]`).val() ? `Yes` : `No`

      const emergencyData = {
        builderLink: builder_link,
        devLink: devLink,
        emergencyUrl: live_url,
        emergencyWhen: issue_noticed,
        emergencyChanges: changes_prior,
        emergencyIssue: message_text,
        logRocketSessionUrl: session_url,
        testRecordPermission: test_record_permission,
      }

      const reportEmergency = new Backbone.Model()

      reportEmergency.url = `${Knack.api_dev}/applications/${Knack.application_id}/help/report-emergency`

      reportEmergency.save(emergencyData, {
        success: function () {

          Knack.hideSpinner()

          $('.kn-modal .wrapper').html('<p>Thank you for contacting us. We will review your ticket and get back to you as quickly as possible.</p><p>You can check the status of your ticket at the <strong><a target="_blank" href="https://support.knack.com">Knack Helpdesk</a></strong>.</p>')
        },
        error: function () {

          Knack.hideSpinner()

          $('#submit-emergency').removeClass(`disabled`)
        }
      })
    },

    getDevLink: function() {

      if (Knack.app.get) {

        return `http://builder.knackcrm.com:3000/${Knack.account.slug}/${Knack.app.get('slug')}#${window.location.hash.substr(1)}`
      }

      return `http://builder.knackcrm.com:3000/${Knack.account.slug}/`
    },

    renderQuickHelp: function() {

      var $master = $('#quick-help > ul > li:first-child').detach();
      $('#quick-help > ul').empty();

      // where are we?
      this.loc = [];
      this.url = Knack.getSceneHash();

      // interface or database?

      log('renderQuickHelp :: HelpView.js ! url: ' + this.url);

      // dashboard
      if (!this.url) {
        this.loc.push('dashboard');
        this.mapDashboard();

      } else {

        if (this.url.indexOf('pages') > -1) {
          this.loc.push('interface');
        } else {
          this.loc.push('database');
        }

        // get granular
        switch (this.loc[0]) {
          case 'database':
            this.mapHelpDatabase();
            break;

          case 'interface':
            this.mapHelpInterface();
            break;
        }
      }

      log(this.loc);
      var articles = [];

      log('map:'); log(map);

      // iterate back through locations incase the current location doesn't map to anything
      while ((!articles || articles.length == 0) && this.loc.length) {
        var final_loc = this.loc.join('.');
        articles = map[final_loc];
        this.loc.pop();
      }
      log('final_loc: ' + final_loc);

      _.each(articles, function(article) {

        var $article = $master.clone();
        var $article_header = $article.find('h3');
        var $article_body = $article.find('p');
        var $article_link = $article.find('a');

        $article_header.html(article.title);
        $article_body.html(article.copy);

        if (article.link) {
          $article_header.append(' <span class="icon-external-link"></span>');
          $article_link.prop('href', article.link);
        } else {
          $article.removeClass('help-item-highlight');
          $article_link.on('click', function(e) {
            e.preventDefault();
          });
        }

        $('#quick-help ul').append($article);
      });

    },

    mapDashboard: function(loc) {

      var hash = location.href.split('#')[1] || '';

      switch (hash) {
        case 'account':
          this.loc.push('account-settings');
          break;

        case 'user':
          this.loc.push('user-settings');
          break;

        case 'billing':
          this.loc.push('billing');
          break;

        case 'delete':
          this.loc.push('delete-account');
          break;
      }
    },

    mapHelpDatabase: function(loc) {

      var _this = this;

      log('mapHelpDatabase!');
      // user role object?
      if (Knack.router.object_view && Knack.router.object_view.model.attributes.user) {
        this.loc.push('roles');
      }
      if (this.url.indexOf('fields') > -1) {
        this.loc.push('fields');
      }
      // field edit?
      if (this.url.indexOf('fields/field_') > -1) {
        var field = Knack.router.active_field.model;
        log('field:'); log(field);
        var field_type = field.get('type');

        // remove roles mapping if editing fields..
        if (this.loc.indexOf('roles') > -1) {
          this.loc.splice(this.loc.indexOf('roles'), 1);
        }

        this.loc.push(field_type);
      } else if (this.url.indexOf('/records') > -1) {
        if (this.loc.indexOf('roles') > -1) {
          this.loc.splice(this.loc.indexOf('roles'), 1);
        }
        this.loc.push('records');
      } else if (this.url.indexOf('/tasks') > -1) {
        if (this.loc.indexOf('roles') > -1) {
          this.loc.splice(this.loc.indexOf('roles'), 1);
        }
        this.loc.push('tasks');
      } else if (this.url.indexOf('#settings/app') > -1) {
        this.loc.push('settings');
      } else if (this.url.indexOf('#settings/users') > -1) {
        this.loc.push('user-settings');
      } else if (this.url.indexOf('#settings/paymentprocessors') > -1) {
        this.loc.push('ecommerce');
      }
    },

    mapHelpInterface: function(loc) {
      var _this = this;
      // view edit?
      if (this.url.indexOf('views') > -1) {

        //this.loc.push('views');

        var view = Knack.router.active_view;
        var view_type = view.model.get('type');

        log('view:'); log(view.model.get('type'));
        var tab = view.getActiveTab();

        function getLinksTab(tab) {
          if ($('.tab-right a.active').length) {
            _this.loc.push('links');
          } else {
            _this.loc.push(tab);
          }
        }

        log('mapInterface, view: ' + view.model.get('type') + '; tab: ' + tab);

        this.loc.push(view_type);

        switch (view_type) {

          case 'calendar':

            var tabs = {
              'calendar-settings': 'settings',
              'calendar-data-source': 'source',
              'details-layout': 'details',
              'form-inputs': 'form'
            };

            this.loc.push(tabs[tab] || tab);

            if (tabs[tab] == 'details') {
            // check for detail input edits..

              getLinksTab(tabs[tab]);

            } else if (tabs[tab] == 'form') {

            // get input edit..
            // check for specials tab..

            }

            break;

          case 'details':
            var tabs = {
              'details-layout': 'details',
              'display-rules': 'rules',
              'details-options': 'options'
            };

            this.loc.push(tabs[tab] || tab);

            if (tabs[tab] == 'details') {
              // get input edit..
              // check for special tab
            }

            break;

          case 'form':

            // tab
            var tabs = {
              'form-inputs': 'inputs',
              'email-options': 'emails',
              'form-rules': 'rules'
            };
            this.loc.push(tabs[tab] || tab);

            // specifics
            if (tab == 'form-inputs') {

              // check specific inputs
              // if someone has a conditional field in a form..
              // how can we convey to them why they cant add it to the form?

              var objectFields = view.current_object.fields.models;

              var conditionals = objectFields.filter(function(field) {
                return field.attributes.conditional;
              });

              // if I do this, I'd need to duplicate the form entries again
              // also doesn't help if he ALSO has an equation in the object and want to add entries for that
              if (conditionals) {
                //this.loc.push('has-conditionals')
              }

              // editing in a modal
              if ($('#edit_input:visible').length) {

                var input = view.getEditInput();

                this.loc.push('edit');
                if (input.type) {
                  this.loc.push(input.type);
                }
              }

            } else if (tab == 'form-rules') {
              var rule = $('#form-rules-menu .active').prop('href').split('#')[1];
              var rules = {
                'submit-rules-editor': 'submit',
                'field-rules-editor': 'display',
                'record-rules-editor': 'record'
              };
              this.loc.push(rules[rule]);
            }

            break;

          case 'list':
            var tabs = {
              'details-layout': 'fields',
              'list-data-source': 'source',
              'list-options': 'options'
            };
            this.loc.push(tabs[tab] || tab);

            if (tabs[tab] == 'fields') {
              if ($('.tab-right a.active').length) {
                this.loc.push('links');
              } else {
                this.loc.push(tabs[tab]);
              }
            }

            if ($('#field_edit').length) {
              this.loc.pop();
              this.loc.push('edit');
              // get field type from edit
            }
            break;

          case 'map':
            var tabs = {
              'map-data-source': 'source',
              'map-options': 'options',
              'details-layout': 'details'
            };

            this.loc.push(tabs[tab]);

            if (tabs[tab] == 'details') {
              getLinksTab(tabs[tab]);

              // check for input edits
            }

            break;

          case 'search':

            var tabs = {
              'data-source': 'source',
              'search-fields': 'fields',
              'details-layout': 'list-results',
              'table-columns': 'table-results',
              'search-settings': 'options'
            };

            this.loc.push(tabs[tab] || tab);

            if (tabs[tab] == 'table-results' || tabs[tab] == 'list-results') {
            }

            //watch for search result layout change...

            break;

          case 'table':

            var tabs = {
              'table-data-source': 'source',
              'table-columns': 'columns',
              'table-filters': 'filters',
              'table-options': 'options'
            };
            this.loc.push(tabs[tab] || tab);

            if (tabs[tab] == 'columns') {

              if ($('#edit-column').length) {
                this.loc.pop();

                var column = view.getEditField();
                log('table column!!!!!'); log(column);
                this.loc.push('edit');
                if (column.type) {
                  this.loc.push(column.type);
                }
              }
            }

            break;
        }
      }
    },

    renderWelcome: function() {
      Knack.renderModalTemplate('template_tour', template_tour, {
        class: 'ninetwenty'
      });

      // set the preview session for first time users
      $.cookie('kn_page_preview', true);
    },
  });
});
