var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kn-migration-dashboard_container" },
    [
      _c("DashboardTopNav"),
      _vm._v(" "),
      _vm.shouldRenderContent
        ? _c("div", { staticClass: "kn-migration-dashboard_content" }, [
            _vm.applications.length > 0
              ? _c(
                  "div",
                  { staticClass: "kn-migration-dashboard_content-left" },
                  [
                    _c("DashboardIntro", { attrs: { timezone: _vm.timezone } }),
                    _vm._v(" "),
                    _c("DashboardToolbar", {
                      attrs: {
                        applications: _vm.applications,
                        "selected-application-ids": _vm.selectedApplicationIds,
                      },
                      on: {
                        "update:check": _vm.updateCheckAll,
                        "update:filter": _vm.updateToolbarFilter,
                      },
                    }),
                    _vm._v(" "),
                    _c("ApplicationList", {
                      attrs: {
                        applications: _vm.applications,
                        "selected-application-ids": _vm.selectedApplicationIds,
                        filter: _vm.filter,
                        now: _vm.now,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "kn-migration-dashboard_empty-list" },
                  [
                    _c("p", [
                      _vm._v(
                        "There aren't any applications to display at the moment."
                      ),
                    ]),
                  ]
                ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "kn-migration-dashboard_content-right fancy-scrollbar",
              },
              [_c("DashboardHelpSection")],
              1
            ),
          ])
        : _vm.shouldRenderError
        ? _c("div", { staticClass: "kn-migration-dashboard_error" }, [
            _c("p", [_vm._v(_vm._s(_vm.error))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("LoadingSpinner", { attrs: { "is-loading": _vm.isLoading } }),
      _vm._v(" "),
      _c("MigrationDashboardModal", {
        attrs: {
          "applications-by-id": _vm.applicationsById,
          "selected-application-ids": _vm.selectedApplicationIds,
          timezone: _vm.timezone,
          now: _vm.now,
        },
        on: {
          cancel: _vm.clearSelectedMigrationsIds,
          submit: _vm.submitAppeal,
        },
      }),
      _vm._v(" "),
      _c("SuccessModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }