<template>
  <Modal
    :title="title"
    :is-modal-active="isModalActive"
    :save-label="saveLabel"
    :save-disabled="saveDisabled"
    @save="submit"
    @cancel="clickCancel"
    @click:background="closeRescheduleModal"
  >
    <template
      v-if="isModalActive"
      slot="content"
    >
      <Banner>
        <template slot="content">
          <span v-html="rescheduleWindow" />
        </template>
      </Banner>
      <h2 class="kn-migration-modal_content-heading">
        Selected Apps
      </h2>
      <ol class="kn-migration-modal_app-list fancy-scrollbar">
        <li
          v-for="id in selectedApplicationIds"
          :key="id"
        >
          <span>{{ getAppName(id) }}</span>
        </li>
      </ol>
      <h2 class="kn-migration-modal_content-heading">
        Reschedule Date
      </h2>
      <div class="kn-migration-modal_date-time-container">
        <DatePicker
          :min-date="minDate.format(dateFormat)"
          :date="dateInputValue"
          :max-date="sqlMigrationsAppealEndDate"
          @update:date="updateDate"
        />
        <TimePicker
          :min-date="minTime"
          :date="timeInputValue"
          :hour-increment="1"
          :minute-increment="20"
          @update:time="updateTime"
        />
        <span>{{ timezone }}</span>
      </div>
      <span class="kn-migration-modal_error">{{ saveError }}</span>
    </template>
  </Modal>
</template>

<script>
import moment from 'moment-timezone';
import { getBrowserTimezoneAbbr } from 'dashboard/utility/timezone';
import EventBus from 'dashboard/components/eventBus';
import Modal from 'dashboard/components/Modal.vue';
import DatePicker from 'dashboard/components/DatePicker.vue';
import TimePicker from 'dashboard/components/TimePicker.vue';
import Banner from 'dashboard/components/Banner.vue';

export default {
  components: {
    Modal,
    DatePicker,
    TimePicker,
    Banner,
  },

  props: {
    applicationsById: {
      type: Map,
      required: true,
    },
    selectedApplicationIds: {
      type: Array,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    now: {
      type: Object,
      required: true,
    }
  },

  emits: ['cancel', 'submit'],

  data() {
    return {
      title: 'Reschedule',
      saveLabel: 'Submit',
      isModalActive: false,
      date: undefined,
      sqlMigrationsAppealEndDate: '09/23/2022',
      dateFormat: 'M/D/Y',
      saveError: undefined,
      timezoneAbbr: getBrowserTimezoneAbbr(),
      dateInputValue: undefined,
      timeInputValue: undefined,
    };
  },

  computed: {
    minDate() {
      return this.now.startOf('hour').add(2, 'hours');
    },
    minTime() {
      let minTime = '';

      if (this.now.isSame(this.date, 'day')) {
        minTime = this.minDate.format('h:mmA');
      }

      return minTime;
    },
    saveDisabled() {
      return !this.dateInputValue || !this.timeInputValue;
    },
    rescheduleWindow() {
      const minDate = this.minDate;
      const maxDate = moment(this.sqlMigrationsAppealEndDate, this.dateFormat);
      const date = `<b style='font-weight: 400'>${minDate.format('MMMM Do')}</b>`;
      const time = `<b style='font-weight: 400'>${minDate.format('h:mma')}</b>`;
      const endDate = `<b style='font-weight: 400'>${maxDate.format('MMMM Do')}</b>`;
      const endTime = `<b style='font-weight: 400'>11:40pm ${this.timezoneAbbr}</b>`;
      return `Upgrades can be rescheduled to occur in 20 minute intervals between:<br />${date} at ${time} to ${endDate} at ${endTime}`;
    },
  },

  created() {
    EventBus.$on('renderRescheduleModal', this.renderRescheduleModal);
    EventBus.$on('closeRescheduleModal', this.closeRescheduleModal);
    EventBus.$on('renderSaveError', this.handleSaveError);
  },

  unmounted() {
    EventBus.$off('renderRescheduleModal', this.renderRescheduleModal);
    EventBus.$off('closeRescheduleModal', this.closeRescheduleModal);
    EventBus.$off('renderSaveError', this.handleSaveError);
  },

  methods: {
    renderRescheduleModal() {
      this.resetDate();

      this.dateInputValue = this.date.format('M/D/Y');
      this.timeInputValue = this.date.format('h:mmA');
      this.isModalActive = true;
    },
    closeRescheduleModal() {
      this.clearSaveError();
      this.isModalActive = false;
    },
    submit() {
      this.clearSaveError();
      this.$emit('submit', this.date.toISOString());
    },
    clickCancel() {
      this.$emit('cancel');

      this.resetDate();
      this.clearSaveError();

      this.closeRescheduleModal();
    },
    getAppName(id) {
      const app = this.applicationsById.get(id);

      return app.app_name;
    },
    resetDate() {
      this.date = this.minDate;
    },
    updateDate({ selectedDates }) {
      const updatedDate = moment(selectedDates[0]);

      // Maintain associated time
      updatedDate
        .set('hour', this.date.get('hour'))
        .set('minute', this.date.get('minute'));

      this.date = updatedDate;
      this.dateInputValue = selectedDates.length > 0 ? updatedDate.format('M/D/Y') : '';
      this.clearSaveError();
    },
    updateTime({ selectedDates }) {
      const updatedDate = moment(selectedDates[0]);

      // Check if user has entered a time via the input and the time is not a 20 min interval
      if (updatedDate.minutes() % 20 !== 0) {
        const remainder = 20 - (updatedDate.minutes() % 20);

        // Round up to the nearest 10 min mark
        updatedDate.add(remainder, 'minutes');
      }

      // Maintain associated date
      updatedDate
        .set('year', this.date.get('year'))
        .set('month', this.date.get('month'))
        .set('date', this.date.get('date'));

      this.date = updatedDate;
      this.timeInputValue = selectedDates.length > 0 ? updatedDate.format('h:mmA') : '';
      this.clearSaveError();
    },
    handleSaveError(error) {
      this.saveError = error || 'An error has occured. Please try again.';
    },
    clearSaveError() {
      this.saveError = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../layout.scss";

.kn-migration-modal_content-heading {
  font-size: 1em;
  font-weight: 600;
  line-height: 2rem;
  margin: 0px;
}

.kn-migration-modal_app-list {
  overflow: auto;

  max-height: 25vh;
  list-style: auto;

  li {
    margin-left: 1.5rem;
  }

  @media screen and (min-height: 680px) {
    max-height: 45vh;
  }

  @media screen and (min-height: 960px) {
    max-height: 60vh;
  }
}

.kn-migration-modal_date-time-container {
  @include h-stack("xsmall");

  margin-top: 8px;
}

.kn-migration-modal_error {
  color: #dd2740;
}
</style>
