var Poll = (function() {

  // dependencies, privates, and inits

  // constructor
  var Poll = function() {

    this.pollers = [];
    this.interval = null;

    // start polling
    //this.poll();

  };

  Poll.prototype = {

    constructor: Poll,

    poll: function() {
      _.each(this.pollers, function(poller) {
        poller.poll();
      });
    },

    startPolling: function() {
      var _this = this;
      this.interval = setInterval(function(){ _this.poll(); }, 5000);
    },

    stopPolling: function() {
      if (!this.pollers.length) {
        clearInterval(this.interval);
      }
    },

    addPoller: function(poller) {
      this.pollers.push(poller);
      if (this.pollers.length == 1) {
        this.startPolling();
      }
    },

    removePoller: function(id) {
      this.pollers = _.reject(this.polleres, function(poller) { return poller.id == id });
      this.stopPolling();
    }
  };


  return Poll;
}());

module.exports = Poll;
