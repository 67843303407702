define([
  'jquery',
  'underscore',
  'backbone',
  'core/backbone/models/BaseModel',
  'core/backbone/collections/Versions'
], function($, _, Backbone, BaseModel, Versions) {
  return BaseModel.extend({
    /**
     * Overrides the url function to add the secure id onto the url.
     *
     * @returns {string}
     */
    url: function () {
      const base = _.result(this, 'urlRoot')
        || _.result(this.collection, 'url')
        || Backbone.urlError();

      if (this.isNew()) {
        return base;
      }

      const id = this.getSecureId();
      return base.replace(/([^\/])$/, '$1/') + encodeURIComponent(id);
    },

    getId: function () {
      return this.id;
    },

    getSecureId: function (pageSlug = '_self') {
      const secureIds = this.get('secureIds');

      return (secureIds) ? secureIds[pageSlug] : this.getId();
    },

    parse: function(result) {

      // add summaries for report
      if (result && result.record) {
        return result.record;
      }
      return result;

    },

    fetchVersions: function(options) {
      options || (options = {});
      this.versions = new Versions();
      this.versions.url = this.url() + '/history?page=' + options.page;
      this.versions.fetch(options);
    }
  });
});
