define([
  'jquery',
  'dashboard/ui/widgets/menus/base-menu'
], function(jQuery, BaseMenu) {

// jquery plugin
  (function($) {
    var TabMenu = $.extend(Object.create(BaseMenu), {

  // init() ---------------
      init: function(options, elem) {

    // set vars
        this.options = $.extend(true, {
        }, this.options, options);
        this.$elem = $(elem);

    // eventify
        this.$elem.find('a').off().on('click', $.proxy(this.handleClickSelectLink, this));

    // activate first
        this.activateLink();

        log('tab menu!!!!');
      },

  // handleClickSelectLink() ---------------
      handleClickSelectLink: function(event) {

        event.preventDefault();

        var id = $(event.currentTarget).attr('href');

    // show active
        var $content = (this.options.parent) ? $(this.options.parent).find(id) : $(id);
        $content.parent().children().hide();
        $content.show();

        log('handleClickSelectLink!!!!!!!!!');

        this.setActiveLink(event);
      },

  // setActiveLink() ---------------
      setActiveLink: function(event) {

        log('SET ACTIVE LINK!!!!!!');

    // change styles
        this.$elem.find('.active').removeClass('active');
        $(event.currentTarget).closest('li').addClass('active');

    // check for callback
        if (this.options.click_handler) {
          this.options.click_handler(event, this.click_data);
        }
      }
    });

    $.fn.extend({
      tabMenu: function(options) {
        return this.each(function() {

      // create a new instance of our object, init, and create data bridge
          var myTabMenu = Object.create(TabMenu);
          myTabMenu.init(options, this);
          $(this).data('tabMenu', myTabMenu);

          return this;
        });
      }
    });
  })(jQuery);

});
