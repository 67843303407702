define(['jquery', 'core/lib/jquery-ui'], function($, jQueryUI) {

  // jquery plugin

  // Proxy this once to save computation
  var uiPosition = $.ui.position;

  // popover Class
  var popover = {

    spacing: {
      my: 'center bottom',
      at: 'center top',
      offset: '0 -10',
      collision: 'flop flop'
    },

    $elem: null,
    $trigger: null,
    $popover: null,

    timeout: null,
    $win: $(window),

    flop: null,

    init: function (options, elem, event) {

      // set vars
      this.options = $.extend({}, this.options, options);
      var $elem = this.$elem = this.$trigger = $(elem);

      this.$elem.addClass('active');

      //log("POPOVER CHECKING MODAL: ");log( $(event.target).closest('.kn-modal-bgX') ); log( $(event.target).closest('.kn-modal-bg') );

      // create kn-popover if it doesn't exist
      //if (! $('#kn-popover').length ) $('.kn-content').append( '<div id="kn-popover" />');

      if (options.z_index) {
        $('#kn-popover').zIndex(options.z_index);
      } else if (event && $(event.target).closest('.kn-modal-bg').length) {
        //log('MOVING kn-popover: ' + $('#kn-popover') );
        $('#kn-popover').zIndex( $(event.target).closest('.kn-modal-bg').zIndex() + 1 );
      } else {
        $('#kn-popover').zIndex(9999);
      }

      this.$popover = $('#kn-popover');

      if (this.options.content) {
        this.$popover.html('');
        this.$popover.append(this.options.content);
      }
      this.$popover.prepend('<span role="presentation" class="before"/>').append('<span role="presentation" class="after"/>').hide();

      /**
       * Custom collision handling for popovers (access via "flop" keyword)
       * Identical to "flip", but adds class to element being flipped to let
       * you know when it has been changed from default position.
       * Functions get bound to this.$popover in the constructor.
       * Used as a monkey patch below.
       */
      this.flop = {
        left: function (position, data) {
          var cPosition = data.collisionPosition,
            $popover = $(this),
            c = 'flopped-x',
            out;
            cPosition = $elem.offset();

          // Run the original first -- it modifies position and data by reference.
          // Store return value anyway, since we want to make sure if they do decide to return something in future the API isn't broken
          out = uiPosition.flip.left(position, data);

          (cPosition.left < position.left) ? $popover.addClass(c) : $popover.removeClass(c);

          return out;
        },
        top: function (position, data) {
          var cPosition = data.collisionPosition,
            $popover = $(this),
            c = 'flopped-y',
            out;

            cPosition = $elem.offset();

          // Run the original first -- it modifies position and data by reference.
          // Store return value anyway, since we want to make sure if they do decide to return something in future the API isn't broken
          out = uiPosition.flip.top(position, data);

          (cPosition.top < position.top) ? $popover.addClass(c) : $popover.removeClass(c);

          return out;
        }
      };

      // bind & show
      this.bindEvents();
      this.showPopover();
    },

    bindEvents: function () {

      this.$popover.find('.button.cancel').click(function (event) {
        event.preventDefault();
      });

      var _this = this;

      $('body').click(function (event) {

        // don't hide if clicked in popover, date picker, or connection picker delete
        var hide_popover = ($(event.target).closest('#kn-popover').length == 0 && $(event.target).closest('.ui-datepicker').length == 0 && $(event.target).closest('.ui-timepicker-list').length == 0 && $(event.target).closest('.ui-datepicker-header').length == 0 && !$(event.target).hasClass('search-choice-close') && $(event.target).closest('.mceListBoxMenu').length == 0) && $(event.target).closest('.kn-search_form').length == 0;

        // don't hide if popover is below a modal click
        if (_this.$elem.closest('.kn-modal').length == 0 && $(event.target).closest('.kn-modal').length == 1) hide_popover = false;

        // force hide if clicked cancel in popover
        if ( $(event.target).closest('#kn-popover').length && $(event.target).hasClass('cancel') || $(event.target).hasClass('close')  ) {
          hide_popover = true;
        }

        if (hide_popover) {

          _this.$popover.fadeOut('fast', function() {
            $('#kn-popover').html('');
          });
          _this.$elem.removeClass('active');
          $(this).unbind(event);
        }
      });

      this.$win.resize($.proxy(this.pinToTargetDebounced, this));
    },

    showPopover: function (e) {

      if (this.options.fade_in) {
        this.$popover.fadeIn('fast');
      } else {
        this.$popover.show();//fadeIn('fast');
      }
      this.pinToTarget();

      // move left/right?
      var offset = this.$popover.offset();
      var after = this.$popover.find('.after').offset();
      // get the position based on kn-content which may be narrower than body
      var knoffset = offset.left - $(".kn-content").offset().left;

      // move left
      if (offset.left + this.$popover.outerWidth() > $('.kn-content').width()) {

        var diff = (knoffset + this.$popover.outerWidth()) - $('.kn-content').width() + 16;

        offset.left -= diff;
        this.$popover.offset(offset);

        //after.left += diff;
        this.$popover.find('.after').offset(after);
      }

      // move right
      if (knoffset < 0) {
        var diff = 0 - knoffset + 10;

        offset.left += diff;
        this.$popover.offset(offset);

        //after.left -= diff;
        this.$popover.find('.after').offset(after);
      }

      if (this.options.modal) {
        this.$popover.addClass('kn-popover-modal');
      }


    },

    hidePopover: function (e) {//
      this.$elem.removeClass('active');
      this.$popover.removeClass();
      var _this = this;
      this.$popover.fadeOut('fast', function() {

        _this.$popover.html('');

        // move back to top level
        $('.kn-content').append($('#kn-popover').detach() );
      });
      e.preventDefault();
      e.stopPropagation();
    },

    // Calculate and position against trigger
    pinToTarget: function () {
      var $popover = this.$popover;
      var options = this.options;
      var defaultPosOpts = {
        of: this.$trigger
      };
      var posOpts = $.extend(defaultPosOpts, this.spacing);

      // Monkey-patch in our custom collision handling
      var flop = {
        // Bind our custom collision handling to the popover element
        left: $.proxy(this.flop.left, this.$popover),
        top: $.proxy(this.flop.top, this.$popover)
      };

      uiPosition.flop = flop;
      $popover.position(posOpts);
      uiPosition.flop = undefined;


        //log( 'top: ' + $popover.offset().top + '; window scrolltop: ' + $(window).scrollTop() + ' popover height: ' + $popover.height() + '; window: ' + $(window).height())

      if (this.options.resize_el) {
        if ($popover.offset().top < $(window).scrollTop() ) {

          var diff = 0 - $popover.offset().top;
          var height = $popover.find(this.options.resize_el).height() - diff;

          $popover.offset({left: $popover.offset().left, top: 4});

        } else {
          var height = $(window).height() - 100;
        }

        var css = {
            'overflow':'auto'
          , 'max-height': height + 'px'
        }
        $popover.find(this.options.resize_el).addClass('fancy-scrollbar');
        $popover.find(this.options.resize_el).css(css)

      }

      if ($popover.offset().top < $(window).scrollTop() ) {

        var diff = 0 - $popover.offset().top;
        var height = $popover.find(this.options.resize_el).height() - diff;

        $popover.find(this.options.resize_el).addClass('fancy-scrollbar');
        $popover.offset({left: $popover.offset().left, top: 4});
        var css = {
            'overflow':'auto'
          , 'max-height': height + 'px'
        }
        $popover.find(this.options.resize_el).css(css)
      }

      else if (this.options.resize_el && ($popover.offset().top + $popover.height() - $(window).scrollTop()> $(window).height() )) {


        var diff = $popover.outerHeight() - $popover.find(this.options.resize_el).outerHeight();
        var height = $popover.offset().top + $popover.height() - $(window).scrollTop()- $(window).height() + 30;

        //log('resizing!, diff: ' + diff + ' height: ' + height);

        $popover.find(this.options.resize_el).addClass('fancy-scrollbar');
        //$popover.offset({left: $popover.offset().left, top: 4});
        $popover.find(this.options.resize_el).css({
            'overflow':'auto'
          , 'max-height': height + 'px'
        })
      }
    },

    // Debounced to prevent hitting lots of times while resizing happens.
    // Will fire a maximum of 10x per second. Useful for binding to the window resize event.
    pinToTargetDebounced: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout($.proxy(this.pinToTarget, this), 100);
    }
  };

  $.fn.extend({
    popover: function(options, event) {
      return this.each(function() {
        // create object, init, and add bridge
        var myPopover = Object.create(popover);
        myPopover.init(options, this, event);
        $(this).data('popover', myPopover);
      });
    }
  });

});
