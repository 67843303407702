var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldRenderHeader
    ? _c(
        "header",
        { ref: "headerElement", class: ["knHeader", ..._vm.headerClasses] },
        [
          _c("div", { staticClass: "kn-container" }, [
            _c("div", { staticClass: "knHeader__content" }, [
              _vm.shouldRenderTitleLogoArea
                ? _c(
                    "a",
                    {
                      staticClass: "knHeader__title-logo-wrapper",
                      attrs: { href: `#${_vm.homeSlug}`, title: _vm.title },
                      on: { click: _vm.onTitleLogoClick },
                    },
                    [
                      _vm.showLogo
                        ? _c(
                            "div",
                            {
                              class: [
                                "knHeader__logo",
                                `knHeader__logo--${_vm.headerDesign.logo.type}`,
                              ],
                            },
                            [
                              _vm.headerDesign.logo.type === "icon"
                                ? _c("i", {
                                    class: `fa fa-${_vm.getSanitizedIcon(
                                      _vm.logoIcon
                                    )}`,
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerDesign.logo.type === "custom" &&
                              _vm.logoImageUrl
                                ? _c("img", {
                                    staticClass: "knHeader__logo-image",
                                    attrs: {
                                      src: _vm.logoImageUrl,
                                      alt: "Application Logo",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showTitle
                        ? _c("h1", { staticClass: "knHeader__title" }, [
                            _vm._v(
                              "\n          " + _vm._s(_vm.title) + "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPageMenu
                ? _c(
                    "div",
                    {
                      class: [
                        "knHeader__menu",
                        `knHeader__menu--${_vm.pageMenuFormat}`,
                      ],
                    },
                    [
                      _c("nav", { staticClass: "knHeader__menu-nav" }, [
                        _c(
                          "ul",
                          { staticClass: "knHeader__menu-list" },
                          _vm._l(_vm.entryPages, function (page) {
                            return _c(
                              "li",
                              {
                                key: page.key,
                                class: [
                                  "knHeader__menu-list-item",
                                  {
                                    "knHeader__menu-list-item--dropdown":
                                      page.type === "menu",
                                  },
                                ],
                              },
                              [
                                page.type === "menu"
                                  ? [
                                      _c("DropdownMenu", {
                                        attrs: {
                                          "entry-page": page,
                                          "dropdown-pages": page.dropdown_pages,
                                          "dropdown-toggle-classes":
                                            _vm.pageMenuLinkClasses,
                                          "get-active-link-class":
                                            _vm.getActiveLinkClass,
                                        },
                                      }),
                                    ]
                                  : _c(
                                      "a",
                                      {
                                        class: [
                                          "knHeader__menu-link",
                                          _vm.pageMenuLinkClasses,
                                          _vm.getActiveLinkClass(page.slug),
                                        ],
                                        attrs: { href: `#${page.slug}` },
                                      },
                                      [
                                        page.icon &&
                                        page.icon.icon &&
                                        page.icon.align === "left"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "knHeader__menu-link-icon knHeader__menu-link-icon--left",
                                              },
                                              [
                                                _c("i", {
                                                  class: `fa fa-${_vm.getSanitizedIcon(
                                                    page.icon.icon
                                                  )}`,
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n\n                " +
                                            _vm._s(page.name) +
                                            "\n\n                "
                                        ),
                                        page.icon &&
                                        page.icon.icon &&
                                        page.icon.align === "right"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "knHeader__menu-link-icon knHeader__menu-link-icon--right",
                                              },
                                              [
                                                _c("i", {
                                                  class: `fa fa-${_vm.getSanitizedIcon(
                                                    page.icon.icon
                                                  )}`,
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPageMenu
                ? _c("div", { staticClass: "knHeader__mobile-controls" }, [
                    _c(
                      "button",
                      {
                        staticClass: "knHeader__mobile-nav-toggle",
                        on: { click: _vm.toggleMobileNav },
                      },
                      [
                        _vm.isMobileNavOpen
                          ? _c("i", { staticClass: "fa fa-times" })
                          : _c("i", { staticClass: "fa fa-bars" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("MobileNav", {
            attrs: {
              "is-open": _vm.isMobileNavOpen,
              "close-mobile-nav": _vm.closeMobileNav,
              "get-active-link-class": _vm.getActiveLinkClass,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }