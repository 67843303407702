define([
  'jquery', 'backbone',
  'core/backbone/views/BaseView',
  'core/templates/supportlogin.html',
  'core/utility/utility-forms',
  'core/lib/cookie'
], function($, Backbone, BaseView, login_template, UtilityForms, cookie) {
  return BaseView.extend({

    events: {
      'submit form.login_form': 'handleSubmitLogin'
    },

    remember_login: false, // if checked on login this will set a cookie

    initialize: function() {

    },

    render: function() {
      $(this.el).html(Knack.render('login-view', login_template, this.model.view));
    }
  });
});
