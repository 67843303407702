<template>
  <div class="kn-migration_time-picker-container">
    <VueFlatPicker
      :value="date"
      :config="config"
    />
  </div>
</template>

<script>
import VueFlatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    VueFlatPicker,
  },

  props: {
    minDate: {
      type: String,
      default: undefined,
    },
    date: {
      type: String,
      required: true,
    },
    hourIncrement: {
      type: Number,
      default: 1,
    },
    minuteIncrement: {
      type: Number,
      default: 5,
    },
    timeFormat: {
      type: String,
      default: 'h:iK',
    },
  },

  emits: ['update:time'],

  computed: {
    config() {
      return {
        allowInput: true,
        noCalendar: true,
        enableTime: true,
        dateFormat: this.timeFormat,
        onClose: this.onClose,
        hourIncrement: this.hourIncrement,
        minuteIncrement: this.minuteIncrement,
        minDate: this.minDate,
      };
    },
  },

  methods: {
    onClose(selectedDates, dateStr, instance) {
      this.$emit('update:time', { selectedDates, dateStr, instance });
    },
  },
};
</script>

<style lang="scss">
.kn-migration_time-picker-container {
  .flatpickr-input:not(.flatpickr-mobile) {
    max-width: 75px;
  }
}
</style>
