var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kn-card_container" }, [
    _c("div", { staticClass: "kn-card_left-item" }, [_vm._t("left-item")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "kn-card_content" }, [
      _c("div", { staticClass: "kn-card_title" }, [_vm._t("title")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "kn-card_meta" }, [_vm._t("meta")], 2),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "kn-card_right-item" }, [_vm._t("right-item")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }