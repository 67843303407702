define([
  'core/lib/backbone',
  'core/backbone/models/Distribution'
], function(Backbone, Distribution) {
  return Backbone.Collection.extend({
    model: Distribution,

    initialize: function() {
      this.url = Knack.api_dev + '/distributions';
    }
  });
});
