var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "knHeader__mobile-nav-transition" } },
    [
      _vm.isOpen
        ? _c(
            "div",
            { ref: "mobileNavElement", staticClass: "knHeader__mobile-nav" },
            [
              _c("div", { staticClass: "kn-container" }, [
                _vm.currentScene.authenticated && _vm.user && _vm.user.id
                  ? _c("div", { staticClass: "knHeader__mobile-user-info" }, [
                      _vm.user.name
                        ? _c(
                            "h3",
                            { staticClass: "knHeader__mobile-user-name" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    `${_vm.user.name.first} ${_vm.user.name.last}`
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "knHeader__mobile-user-page-list" },
                        [
                          _vm._l(_vm.userAccountPages, function (userPage) {
                            return _c(
                              "li",
                              {
                                key: userPage.key,
                                staticClass:
                                  "knHeader__mobile-user-page-list-item",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: [
                                      "knHeader__menu-link knHeader__mobile-nav-link knHeader__mobile-user-page-link",
                                      _vm.getActiveLinkClass(userPage.slug),
                                    ],
                                    attrs: { href: `#${userPage.slug}` },
                                    on: { click: _vm.closeMobileNav },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(userPage.name) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass:
                                "knHeader__mobile-user-page-list-item",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "knHeader__menu-link knHeader__mobile-nav-link knHeader__mobile-user-page-link",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onLogoutClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v("\n              Log Out "),
                                  _c("i", { staticClass: "fa fa-sign-out" }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "knHeader__mobile-nav-list" },
                  _vm._l(_vm.entryPages, function (page) {
                    return _c(
                      "li",
                      {
                        key: page.key,
                        staticClass: "knHeader__mobile-nav-list-item",
                      },
                      [
                        page.type === "menu"
                          ? [
                              _c("DropdownMenu", {
                                attrs: {
                                  "entry-page": page,
                                  "is-mobile-dropdown": true,
                                  "is-dropdown-open-by-default": true,
                                  "dropdown-pages": page.dropdown_pages,
                                  "dropdown-toggle-classes": [
                                    "knHeader__mobile-nav-link",
                                  ],
                                  "get-active-link-class":
                                    _vm.getActiveLinkClass,
                                  "on-dropdown-link-click": _vm.closeMobileNav,
                                },
                              }),
                            ]
                          : _c(
                              "a",
                              {
                                class: [
                                  "knHeader__menu-link knHeader__mobile-nav-link",
                                  _vm.getActiveLinkClass(page.slug),
                                ],
                                attrs: { href: `#${page.slug}` },
                                on: { click: _vm.closeMobileNav },
                              },
                              [
                                page.icon &&
                                page.icon.icon &&
                                page.icon.align === "left"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "knHeader__menu-link-icon knHeader__menu-link-icon--left",
                                      },
                                      [
                                        _c("i", {
                                          class: `fa fa-${_vm.getSanitizedIcon(
                                            page.icon.icon
                                          )}`,
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n\n            " +
                                    _vm._s(page.name) +
                                    "\n\n            "
                                ),
                                page.icon &&
                                page.icon.icon &&
                                page.icon.align === "right"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "knHeader__menu-link-icon knHeader__menu-link-icon--right",
                                      },
                                      [
                                        _c("i", {
                                          class: `fa fa-${_vm.getSanitizedIcon(
                                            page.icon.icon
                                          )}`,
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }