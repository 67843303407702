// eslint-disable-next-line no-undef
define([], () => ({
  /**
   * Converts an object or value into a valid string that can be used as a Vue prop inside an
   * HTML template.
   *
   * Since complex data structures can't be passed as props to Vue components inside HTML
   * templates, they need to be converted into a JSON string.
   *
   * @param {any} value - The object or value to convert into a string.
   * @returns {string} - A string representing the given value, or an empty string.
  */
  convertToStringProp(value) {
    // replace any double-quotes with single-quotes to avoid errors in the HTML
    return value ? JSON.stringify(value).replace(/"/g, "'") : '';
  },

  /**
   * Converts a previously stringified object or value (used as a Vue prop) back into its
   * original shape or value.
   *
   * @param {string} prop - The string to convert into an object or value.
   * @returns {any} - An object or value corresponding to the given string.
  */
  convertFromStringProp(prop) {
    // make sure the string can be parsed
    try {
      // replace single-quotes with double-quotes before parsing
      return JSON.parse(prop.replace(/'/g, '"'));
    } catch (e) {
      return undefined;
    }
  },

  sanitizeIcon(icon) {
    return icon.replace(/^fa-/, '');
  },

  isElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  },
}));
