define([
  'backbone'
], function(Backbone) {

  return Backbone.Model.extend({
    view: null,

    initialize: function() {
      this.set({
        'authenticated': true
      });
    }
  });
});
