const state = {
  message: `You are accessing this app on a Knack CRM URL.`
}

const mutations = {
  setMessage: (state, message) => {

    state.message = message
  }
}

const actions = {
  login ({ state, commit, dispatch }) {

    if (Knack.isHIPAA() || Knack.isGovCloud()) {

      return dispatch(`unavailable`)
    }

    if (!Knack.isSupportAccessible()) {

      return dispatch(`disabled`)
    }

    const email = Knack.session.user.values.email.email

    if (Knack.user.getProfileKeys().length) {

      return commit(`setMessage`, `You are accessing this app as user <b>${email}</b> with user role(s): <b>${Knack.getUserRoleNames()}</b>`)
    }

    return commit(`setMessage`, `You are accessing this app as user <b>${email}.</b>`)
  },
  logout ({ state, commit, dispatch }) {

    if (Knack.isHIPAA() || Knack.isGovCloud()) {

      return dispatch(`unavailable`)
    }

    if (!Knack.isSupportAccessible()) {

      return dispatch(`disabled`)
    }

    commit(`setMessage`, `You are accessing this app on a Knack CRM URL.`)
  },
  default ({ state, commit }) {

    commit(`setMessage`, `You are accessing this app on a Knack CRM URL.`)
  },
  disabled ({ state, commit }) {

    commit(`setMessage`, `Support access for this app has been disabled. Please contact the account owner to enable support access.`)
  },
  unavailable ({ state, commit }) {

    commit(`setMessage`, `Support access for this app is not available. Please request shared builder access and permission to create a test user as needed.`)
  },
  mounted ({ state, commit, dispatch }) {

    if (Knack.isHIPAA() || Knack.isGovCloud()) {

      return dispatch(`unavailable`)
    }

    if (!Knack.isSupportAccessible()) {

      return dispatch(`disabled`)
    }

    if (Knack.session && Knack.session.user) {

      return dispatch(`login`)
    }
  }
}

const getters = {
  message: state => state.message
}

module.exports = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
