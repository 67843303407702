define([
  'jquery'
], function(jQuery) {

  return (function($) {

    // Private methods

    /**
     * Redirect to the parent page of the currently active page. If the page is rendered in a modal then the modal will be closed and any views on the parent page will refresh.
     *
     * @api private
     */

    var _getPageLink = function(page) {

      // convert backbone models to vars
      if (page.toJSON) {
        page = page.toJSON();
      }

      if (page.icon && page.icon.icon) {
        var link_icon = '<i class="fa ' + page.icon.icon + '"></i>';
      }

      var page_link = '<a href="' + Knack.hash_token + Knack.generateAppLink(page.slug, {
        ignore_base: true
      }) + '" data-kn-slug="' + Knack.hash_token + page.slug +'"><span>'

      // left icon
      if (link_icon && page.icon.align == 'left') {
        page_link += link_icon + '&nbsp;&nbsp;';
      }

      // link text
      page_link += page.name;

      // right icon
      if (link_icon && page.icon.align == 'right') {
        page_link += '&nbsp;&nbsp;' + link_icon;
      }

      page_link += '</span></a>';

      return page_link;
    };

    // Constructor

    var UI = function() {

    };

    // Public methods

    UI.prototype = {

      getPageLink: function(page) {
        return _getPageLink(page);
      }
    };

    UI.prototype.constructor = UI;
    return UI;

  })(jQuery);

});
