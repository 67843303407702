<template>
  <li class="kn-migration-list-item">
    <Card>
      <template slot="left-item">
        <div class="kn-migration-list-item_left-item">
          <input
            type="checkbox"
            class="kn-migration-list-item_checkbox"
            :checked="checked"
            :disabled="!isReschedulable"
            @change="check(app.app_id, $event.target.checked)"
          >
          <span
            class="kn-migration-list-item_status-emoji"
            :class="iconClass"
          />
        </div>
      </template>
      <template slot="title">
        <span :title="app.app_name">{{ app.app_name }}</span>
      </template>
      <template slot="meta">
        <div
          class="kn-migration-list-item_meta"
          v-html="friendlyStatus"
        />
      </template>
      <template slot="right-item">
        <button
          v-if="isReschedulable"
          title="Reschedule this upgrade"
          class="kn-migration-list-item_button"
          :disabled="selectedApplicationIds.length > 0"
          @click="click(app.app_id)"
        >
          Reschedule <span class="kn-migration-list-item_button-calendar" />
        </button>
      </template>
    </Card>
  </li>
</template>

<script>
import moment from 'moment-timezone';
import { getBrowserTimezoneAbbr } from 'dashboard/utility/timezone';
import EventBus from 'dashboard/components/eventBus';
import Card from 'dashboard/components/Card.vue';

export default {
  components: {
    Card,
  },

  props: {
    app: {
      type: Object,
      required: true,
    },
    selectedApplicationIds: {
      type: Array,
      required: true,
    },
    now: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      // Fallback for displaying pending resolution status and corresponding icon if exceeded
      MAX_MIGRATION_HOURS: 5,
    }
  },

  computed: {
    checked() {
      return this.selectedApplicationIds.includes(this.app.app_id);
    },
    isReschedulable() {
      return ['scheduled', 'pendingSchedule'].includes(this.app.migration_status);
    },
    date() {
      return moment(this.app.migration_date).local();
    },
    nowLocal() {
      return this.now.local();
    },
    isFallbackMaxTimeThresholdExceeded() {
      if (['completed', 'pendingResolution', 'pending'].includes(this.app.migration_status)) {
        return false;
      }

      if (this.nowLocal.isAfter(this.date)) {
        // Calculate and compare time difference as hours between now and the scheduled migration date
       return parseInt(moment.duration(this.nowLocal.diff(this.date)).asHours()) > this.MAX_MIGRATION_HOURS;
      }
    },
    friendlyStatus() {
      // Possible migration status include: pending (deprecated), pendingResolution, active,
      // pendingSchedule, scheduled, completed, ineligible.
      // `scheduled` and `pendingSchedule` are statuses that permit updates.
      // if priority is -2, that means it has intentionally been set by Knack to prevent app from going through.
      const status = this.app.migration_status;
      let friendlyStatus = 'Upgrade status unavailable';

      const date = `<b style='font-weight: 400'>${this.date.format('MMMM Do')}</b>`;
      const time = `<b style='font-weight: 400'>${this.date.format('h:mma')} ${getBrowserTimezoneAbbr()}</b>`;

      const isPendingInvestigation = this.app.migration_priority === '-2' || status === 'pendingResolution' || status === 'pending';

      if (isPendingInvestigation || this.isFallbackMaxTimeThresholdExceeded) {
        friendlyStatus = 'Upgrade is pending investigation';
      } else if (status === 'scheduled') {
        friendlyStatus = `Upgrade scheduled for ${date} at ${time}`;
      } else if (status === 'active') {
        friendlyStatus = `Upgrade began on ${date} at ${time} and is in progress`;
      } else if (status === 'ineligible') {
        friendlyStatus = 'Upgrade will be scheduled at a later date';
      } else if (status === 'pendingSchedule') {
        friendlyStatus = 'Not yet scheduled';
      } else if (status === 'completed') {
        if (!this.app.migration_date) {
          friendlyStatus = 'Already upgraded';
        } else {
          friendlyStatus = `Upgrade completed on ${date} at ${time}`;
        }
      }

      return friendlyStatus;
    },
    iconClass() {
      if (this.isFallbackMaxTimeThresholdExceeded) {
        return 'pending';
      }

      return this.app.migration_status;
    },
  },

  methods: {
    check(id, checked) {
      EventBus.$emit('check', id, checked);
    },
    click(id) {
      this.check(id, true);
      EventBus.$emit('renderRescheduleModal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../layout.scss";

.kn-migration-list-item_left-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kn-migration-list-item_checkbox {
  margin-right: 0px !important;
}

.kn-migration-list-item_button {
  background-color: rgb(213, 218, 223);
  border-radius: 0.1875rem;
  border-width: 0px;
  padding: 8px 16px;
  min-width: 140px;
  margin-left: 32px;

  line-height: 16px;
  color: rgb(145, 38, 127);
  font-size: 16px;
  font-weight: 400;

  &:hover:not([disabled]) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  }
  &:disabled {
    opacity: 0.7;
  }
}

.kn-migration-list-item_button-calendar {
  margin-left: 4px;
  font-size: 13px;

  &:before {
    content: "\1F5D3"; // 🗓
  }
}

.kn-migration-list-item_status-emoji {
  margin-top: 14px;

  &.active:before {
    content: "\2728"; // ✨
  }
  &.pendingSchedule:before,
  &.ineligible:before {
    content: "\231B"; // ⌛
  }
  &.scheduled:before {
    content: "\1F4C5"; // 📅
  }
  &.pendingResolution:before,
  &.pending:before {
    content: "\26A0" "\FE0F"; // yellow filled :warning-sign: ⚠️
  }
  &.completed:before {
    content: "\1F680"; // 🚀
  }
}
</style>
