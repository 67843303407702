<template>
  <div>
    <button
      :class="['knHeader__menu-dropdown-toggle knHeader__menu-link', dropdownToggleClasses, getActiveLinkClass(entryPage.slug)]"
      ref="dropdownToggle"
      @click="toggleDropdownMenu"
    >
      <span
        v-if="entryPage.icon && entryPage.icon.icon"
        class="knHeader__menu-link-icon knHeader__menu-link-icon--left"
      >
        <i :class="`fa fa-${getSanitizedIcon(entryPage.icon.icon)}`"/>
      </span>
      {{ entryPage.name }}
      <span class="knHeader__menu-link-icon knHeader__menu-link-icon--right">
        <i class="fa fa-angle-down"/>
      </span>
    </button>
    <ul
      :class="['knHeader__menu-dropdown-list', {'knHeader__menu-dropdown-list--open': isDropdownMenuOpen}]"
      ref="dropdownList"
    >
      <template v-for="dropdownPage in dropdownPages">
        <!-- Only render dropdownPage if it belongs to the current entryPage's menu -->
        <li
          :key="dropdownPage.key"
          class="knHeader__menu-dropdown-list-item"
        >
          <a
            :href="`#${dropdownPage.slug}`"
            :class="[
              'knHeader__menu-link knHeader__menu-dropdown-link',
              {'knHeader__mobile-nav-link': isMobileDropdown},
              getActiveLinkClass(dropdownPage.slug)
            ]"
            @click="onLinkClick"
          >
            <span
              v-if="dropdownPage.icon && dropdownPage.icon.icon && dropdownPage.icon.align === 'left'"
              class="knHeader__menu-link-icon knHeader__menu-link-icon--left"
            >
              <i :class="`fa fa-${getSanitizedIcon(dropdownPage.icon.icon)}`"/>
            </span>

            {{ dropdownPage.name }}

            <span
              v-if="dropdownPage.icon && dropdownPage.icon.icon && dropdownPage.icon.align === 'right'"
              class="knHeader__menu-link-icon knHeader__menu-link-icon--right"
            >
              <i :class="`fa fa-${getSanitizedIcon(dropdownPage.icon.icon)}`"/>
            </span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { sanitizeIcon, isElementInViewport } from 'core/ui/helpers/vue-helper';

export default {
  props: {
    entryPage: {
      type: Object,
      required: true,
    },
    dropdownPages: {
      type: Array,
      required: true,
    },
    getActiveLinkClass: {
      type: Function,
      required: true,
    },
    dropdownToggleClasses: {
      type: Array,
      default: () => [],
    },
    isDropdownOpenByDefault: {
      type: Boolean,
      default: false,
    },
    isMobileDropdown: {
      type: Boolean,
      default: false,
    },
    onDropdownLinkClick: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      isDropdownMenuOpen: this.isDropdownOpenByDefault,
    };
  },
  updated() {
    if (!this.isMobileDropdown) {
      this.adjustDropdownPosition();
    }
  },
  mounted() {
    if (!this.isMobileDropdown) {
      document.addEventListener('click', this.onClickOutside);
    }
  },
  beforeDestroy() {
    if (!this.isMobileDropdown) {
      document.removeEventListener('click', this.onClickOutside);
    }
  },
  methods: {
    toggleDropdownMenu() {
      this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
    },
    onLinkClick() {
      this.isDropdownMenuOpen = false;
      if (this.onDropdownLinkClick) {
        this.onDropdownLinkClick();
      }
    },
    getSanitizedIcon(icon) {
      return sanitizeIcon(icon);
    },
    onClickOutside(event) {
      // We close the dropdown menu when a click occurs outside of itself
      const { dropdownList, dropdownToggle } = this.$refs;
      if (!dropdownList || dropdownList.contains(event.target) || dropdownToggle.contains(event.target)) {
        return;
      }
      this.isDropdownMenuOpen = false;
    },
    adjustDropdownPosition() {
      const { dropdownList } = this.$refs;
      const directionClass = 'knHeader__menu-dropdown-list--right';

      if (!dropdownList) {
        return;
      }

      // On desktop, in some screen sizes we need to position edge of the dropdown menu relative
      // to the top-right side of the menu link to avoid the element going out of the viewport
      if (!isElementInViewport(dropdownList)) {
        dropdownList.classList.add(directionClass);
      } else {
        dropdownList.classList.remove(directionClass);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.knHeader__menu-link-icon {
  display: inline-flex;
}
</style>
