<template>
  <Toolbar>
    <template slot="left-item">
      <div class="kn-migration-dashboard-toolbar_input-container">
        <div>
          <input
            id="checkAll"
            type="checkbox"
            :disabled="!isCheckable"
            :checked="checked"
            @change="$emit('update:check', $event.target.checked)"
          >
          <label for="checkAll">{{ selectedAppCountString }}</label>
        </div>

        <button
          v-if="isSelectedApplicationIdsPresent"
          title="Reschedule selected upgrades"
          class="kn-migration-dashboard-toolbar_button"
          @click="click"
        >
          Reschedule <span class="kn-migration-dashboard-toolbar_button-calendar" />
        </button>
      </div>
    </template>
    <template slot="right-item">
      <div class="kn-migration-dashboard-toolbar_filter-container">
        <label for="filter">Filter by:</label>
        <select
          id="filter"
          v-model="filter"
          name="filter"
          class="kn-migration-dashboard-toolbar_filter"
          @change="$emit('update:filter', $event.target.value)"
        >
          <option
            v-for="option in filterOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </template>
  </Toolbar>
</template>

<script>
import EventBus from 'dashboard/components/eventBus';
import Toolbar from 'dashboard/components/Toolbar.vue';

export default {
  components: {
    Toolbar,
  },

  props: {
    applications: {
      type: Array,
      required: true,
    },
    selectedApplicationIds: {
      type: Array,
      required: true,
    },
  },

  emits: ['update:check', 'update:filter'],

  data() {
    return {
      filterOptions: [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'scheduled', label: 'Scheduled' },
        { value: 'completed', label: 'Complete' },
      ],
      filter: 'all',
    };
  },

  computed: {
    isSelectedApplicationIdsPresent() {
      return this.selectedApplicationIds.length > 0;
    },
    selectedAppCountString() {
      const selectedAppsCount = this.selectedApplicationIds.length;
      const appNoun = `App${selectedAppsCount === 1 ? '' : 's'}`;

      return `${selectedAppsCount} ${appNoun} Selected`;
    },
    isCheckable() {
      return this.applications.filter((application) => application.migration_status === 'scheduled').length > 0;
    },
    checked() {
      return (
        this.selectedApplicationIds.length > 1 && this.selectedApplicationIds.length === this.applications
          .filter((application) => ['scheduled', 'pendingSchedule'].includes(application.migration_status))
          .map((application) => application.app_id).length
      );
    },
    enabled() {
      return ['all', 'scheduled'].includes(this.filter);
    },
  },

  methods: {
    click() {
      EventBus.$emit('renderRescheduleModal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../layout.scss";

.kn-migration-dashboard-toolbar_button {
  background-color: #850c5c;
  border: 0px;
  border-radius: 0.25rem;

  line-height: 16px;
  padding: 8px 16px;

  color: #fff;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
  }
}

.kn-migration-dashboard-toolbar_button-calendar {
  margin-left: 4px;
  font-size: 13px;

  &:before {
  content: "\1F5D3"; // 🗓
  }
}

.kn-migration-dashboard-toolbar_input-container {
  @include h-stack("small");

  align-items: flex-start;
}

.kn-migration-dashboard-toolbar_filter-container {
  @include h-stack("xsmall");
}

.kn-migration-dashboard-toolbar_filter {
  min-width: 96px;

  cursor: pointer;
}
</style>
