 define([
   'jquery', 'backbone'
 ], function($, Backbone) {
   return Backbone.Model.extend({
     idAttribute: 'key',

     parse: function(response) {
       return response.distribution || response;
     }
   });
 });
