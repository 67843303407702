define([
  'jquery',
  'core/lib/backbone',
  'dashboard/backbone/views/DashboardView',
  'dashboard/backbone/views/RegisterView'
], function($, Backbone, DashboardView, RegisterView) {
  return Backbone.Router.extend({
    routes: {
      '': 'viewDashboard',
      'apps': 'viewApps',
      'apps/shared': 'viewAppsShared',
      'migrations': 'viewMigrations',
      'apps/add': 'viewAppAdd',
      'apps/v3': 'viewAppsV3',
      'apps/v3/add': 'viewAppAddV3',
      'apps/v3/copy': 'copyAppToV3',
      'account': 'viewAccount',
      'billing': 'viewBilling',
      'billing/invoices': 'viewInvoices',
      'billing/payment': 'viewPayment',
      'billing/plan': 'viewPlan',
      'billing/settings': 'viewBillingSettings',
      'update-billing-address': `viewBillingAddress`,
      // 'security': 'viewSecuritySettings',
      'user': 'viewUser',
      'delete': 'viewDelete',
      'cancel': 'viewDelete',
      'knack-password/forgot': 'viewForgetPass',
      'knack-password/reset/:token': 'resetPassword',
      'welcome': 'viewRegister',
      'register': 'viewRegister',
      'register2': 'viewRegisterStep2',
      'template/:app_id': 'viewRegisterTemplate'
    },

    dashboard_view: null,

    checkAuth: function() {

      log('Router.checkAuth:');
      log(Knack.user);

      if (!Knack.user || !Knack.user.id || Knack.user.isNew()) {
        Builder.renderLoginScene();

        return false;
      }
      return true;

    },

    viewRegisterTemplate: function(app_id) {

      log('viewRegisterTemplate()');

      // does the user exist?
      if (this.checkAuth()) {

        log('need to route to account!');
        if (!this.dashboard_view) {
          this.dashboard_view = new DashboardView({
            'el': '#content-wrapper'
          });
        }
        this.dashboard_view.renderAddApp(app_id);

      } else {

        if (!this.register_view) {
          this.register_view = new RegisterView();
        }
        this.register_view.render(app_id);
      }
    },

    viewRegister: function() {
      if (!this.register_view) {
        this.register_view = new RegisterView();
      }
      this.register_view.render();
    },

    viewRegisterStep2: function() {
      if (!this.register_view) {
        this.register_view = new RegisterView();
      }
      this.register_view.renderStep2();
    },

    viewDashboard: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewDashboard!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }

      // non accounts have shared apps but not a personal account
      if (Knack.account.non_account) {
        this.dashboard_view.renderSharedApps();
      } else {
        this.dashboard_view.renderYourApps();
      }
    },

    viewApps: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewAppsShared');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderYourApps();
    },

    viewAppsShared: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewAppsShared');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderSharedApps();
    },

    viewMigrations: function() {
      if (!this.checkAuth()) {
        return false;
      }

      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#dashboard-wrapper'
        });
      }

      this.dashboard_view.renderMigrationApps();
    },

    viewAppsV3: function() {

      if (!this.checkAuth()) {

        return false
      }

      if (!this.dashboard_view) {

        this.dashboard_view = new DashboardView({
          el: `#content-wrapper`
        })
      }

      this.dashboard_view.renderV3Apps()
    },

    viewAppAdd: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewAppAdd');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderAddApp();
    },

    viewAppAddV3: function() {

      if (!this.checkAuth()) {

        return false
      }

      if (!this.dashboard_view) {

        this.dashboard_view = new DashboardView({
          el: `#content-wrapper`
        })
      }

      this.dashboard_view.renderAddApp(null, true)
    },

    copyAppToV3: function() {

      if (!this.checkAuth()) {

        return false
      }

      if (!this.dashboard_view) {

        this.dashboard_view = new DashboardView({
          el: `#content-wrapper`
        })
      }

      this.dashboard_view.renderCopyAppToV3()
    },

    viewAccount: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('VIEW Account!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderAccount();
    },

    viewBilling: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('VIEW Billing!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderBilling();
    },

    viewInvoices: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewInvoices!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderInvoices();
    },

    viewPayment: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewPayment!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderPayment();
    },

    viewPlan: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewPlan!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderPlan(Knack.user.getAccountStatus());
    },

    viewBillingSettings: function() {
      log('viewBillingSettings!!!!');
      if (!this.checkAuth()) {
        return false;
      }
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderBillingSettings();
    },

    viewUser: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewUser()!!!!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderUser();
    },

    viewSecuritySettings: function() {

      if (!this.checkAuth()) {

        return false
      }

      if (!this.dashboard_view) {

        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }

      this.dashboard_view.renderSecuritySettings();
    },

    viewDelete: function() {
      if (!this.checkAuth()) {
        return false;
      }
      log('viewDelete()');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderDelete();
    },

    viewForgetPass: function() {
      //if (!this.checkAuth()) return false;
      log('viewForgetPass!');
      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }
      this.dashboard_view.renderForgotPassword();
    },

    resetPassword: function(token) {

      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }

      this.dashboard_view.renderResetPassword(token);
    },

    viewBillingAddress: function () {

      if (!this.dashboard_view) {
        this.dashboard_view = new DashboardView({
          'el': '#content-wrapper'
        });
      }

      this.dashboard_view.renderBillingAddress()
    }
  });
});
