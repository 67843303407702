define([
  'jquery', 'backbone',
  'core/templates/asset-view.html'
], function($, Backbone, asset_view_template) {
  return Backbone.View.extend({

    initialize: function(info) {
      var is_builder = Knack.mode === 'builder';
      this.scene_key = is_builder ? 'builder' : 'scene_' + info.scene_key;
      this.view_key = is_builder ? 'builder' : 'view_' + info.view_key;
      this.field_key = is_builder ? 'builder' : 'field_' + info.field_key;
      this.asset_id = info.asset_id;
      this.previewable_file_extensions = ['txt', 'html', 'png', 'pdf', 'jpg', 'jpeg', 'gif', 'bmp', 'mp3'];
      this.new_token_url = Knack.api + '/scene/' + this.scene_key + '/view/' + this.view_key + '/field/' + this.field_key + '/assets/' + this.asset_id + '/token/new';
      this.base_download_url = Knack.api + '/asset/' + this.asset_id + '/download/';
      this.close_modal_hash_regex = /^\S+[#](\S+)kn-asset\S+$/;
      this.setElement($('#kn-overlay'));
    },

    render: function() {

      var _this = this;

      Knack.showSpinner();

      $('#asset_viewer').remove();

      var model = new Backbone.Model();

      model.url = Knack.api + '/scene/' + this.scene_key + '/view/' + this.view_key + '/field/' + this.field_key + '/asset/' + _this.asset_id;

      model.fetch({
        success: function(response_model, response) {

          if (!_.isUndefined(response.success) && !response.success) {

            _this.renderViewer({
              filename: '',
              file_size: ''
            });

            $('.kn-asset-content').text('File not available.');
            $('.kn-file-download').off();
            $('#kn-asset-header').hide();
            $('#kn-asset-footer').hide();

            return;
          }

          var asset = response.asset;

          asset.file_extension = _this.getFileExtension(asset.filename);
          asset.file_size = Knack.formatFileSize(asset.size);

          _this.renderViewer(asset);

          if (_.contains(_this.previewable_file_extensions, _this.getFileExtension(asset.filename))) {
            $('.kn-asset-content').text('Loading...');
            _this.previewAsset(asset);
          }
        }
      });
    },

    renderViewer: function(asset) {

      this.$el.html(Knack.render('asset_view_template', asset_view_template, asset));

      var $viewer = $('#asset-viewer');

      $('.kn-file-download').off().on('click', $.proxy(this.handleDownload, this));

      $('.kn-asset-close').click($.proxy(this.closeAssetViewer, this));

      Knack.hideSpinner();

      this.$el.fadeIn('fast');

      this.$el.appendTo('body');

      $viewer.show();
    },


    isIOS() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    },
    previewAsset: function(asset) {
      var _this = this;

      var hash = '';

      if (location.href.indexOf('kn-asset/') > -1 && location.href.indexOf('#') > -1) { // re-use current url since they're already viewing the asset directly

        hash = `#${location.href.split('#')[1]}`
      } else if (Knack.mode === 'builder') { // all things builder where they aren't directly on the kn-asset url already

        hash = '#kn-asset/' + this.asset_id + '/' + asset.filename;
      } else if (Knack.url_base) { // Example: table view, preview image asset, right click open in new tab

        hash = Knack.scene_hash + 'kn-asset/' + this.scene_key.split('_')[1] + '-' + this.view_key.split('_')[1] + '-' + this.field_key.split('_')[1] + '-' + this.asset_id + '/' + asset.filename;
      }

      _this.getNewDownloadTokenKey(function(token_key) {

        var preview_url = `${_this.base_download_url}${token_key}?preview=true${hash}`
        var $preview = $('.kn-asset-content');

        // Currently, the "loading preview..." text is only useful for plain text assets because we have no way to know that img/iframe/embeds have completed loading
        // See handleDownload function comments for more details
        switch (asset.file_extension) {
          case 'txt':

            var model = new Backbone.Model();

            model.url = preview_url;

            model.fetch({
              dataType: 'text',
              success: function(response_model, response) {
                if (!response) {
                  $preview.text('Unable to preview file at this time. Please try again later.');
                  return;
                }
                $preview.css({
                  'background-color': '#FFFFFF',
                  'text-align': 'left',
                  'padding': '20px',
                  'max-height': '75%',
                  'max-width': '80%',
                  'overflow-x': 'auto',
                  'white-space': 'pre-wrap'
                });
                $preview.empty();
                $preview.text(response);
              }
            });
            break;
          case 'html':
          // frameborder="##" if we want to outline the iframe
            $preview.empty();
            $preview.css({
              'height': '75%',
              'width': '80%'
            });

            $preview.append('<iframe style="background: #FFFFFF;padding: 20px;" src="' + preview_url + '" id="asset-preview-frame" height="100%" width="100%" ></iframe>');
            break;
        // Currently using fall-through but we should eventually switch to a multi-dimensional array that categorizes preview types and switch off it (e.g., "images", "text", etc.)
          case 'jpg':
          case 'jpeg':
          case 'gif':
          case 'bmp':
          case 'png':
            $preview.empty();
            $preview.append('<img src="' + preview_url + '" style="max-height: 100%; max-width: 100%">');
            break;
          case 'pdf':
            $preview.empty();
            $preview.css({
              'height': '75%',
              'width': '80%'
            });

            if(_this.isIOS()) {
              $preview.text('In order to preview this PDF, click on the button below to open it in a new tab.').css({
                'color': '#2C3E50',
                'font-size': '18px',
                'font-family': 'Arial, sans-serif',
                'margin-bottom': '30px',
                'text-align': 'center',
                'padding': '20px',
                'background-color': '#ECF0F1',
                'border-radius': '10px',
                'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)'
            });
              $preview.append('<button style="padding: 15px 30px; font-size: 18px; border: none; border-radius: 5px; background-color: #007BFF; color: white; cursor: pointer; text-align: center; display: block; margin: auto; position: relative; top: 50%; transform: translateY(-50%);"><a style="text-decoration: none; color: white;" href="' + preview_url + '" target="_blank">Open PDF in new tab</a></button>');
            } else {
            $preview.append('<embed src="' + preview_url + '" width="100%" height="100%" type="application/pdf" title="' + asset.filename + '">');
            }
            break;
          case 'mp3':
            $preview.empty();
            $preview.append('<audio id="audio_' + asset.filename + '" controls><source src="' + preview_url + '" type="audio/mp3"></audio>');
            break;
          default:
            $preview.text('Unable to preview file at this time. Please try again later.');
            break;
        }
      });
    },

    getFileExtension: function(file_name) {
      return file_name.slice((file_name.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();
    },

    closeAssetViewer: function(e) {

      log('closeAssetViewer()');

      this.$el.hide();
      this.$el.empty();

      if (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }

      if (Knack.mode === 'builder') {

        if ($('#menu-content a.active').length) {
          return Knack.router.navigate($('#menu-content a.active').prop('href').split('#')[1], true);
        }

        return Knack.router.navigate($('#database-link a').prop('href').split('#')[1], true);
      }

      if (Knack.mode !== 'renderer') {
        return;
      }

      var new_hash = this.close_modal_hash_regex.exec(location.href);

      if (!new_hash || new_hash.length < 2) {
        return;
      }

      log('navigating to ' + new_hash[1]);

      Knack.router.navigate(new_hash[1], true);
    },

    handleDownload: function(e) {

      e.preventDefault();

      var _this = this;

      _this.getNewDownloadTokenKey(function(token_key) {

        $('#iFrameDownload').remove();

        var $iframe = $('<iframe id="iFrameDownload">');

        $iframe.hide();
        $iframe.prop('src', _this.base_download_url + token_key);
        $iframe.appendTo('body');
      });
      // This was an attempt to prevent the download link from being clicked multiple times in a row. It could also be used to show "downloading..." or something in the UI
      // Right now, if I rapidly click on a 50MB download link 4 times, the server will serve the file 4 times. Not great.

      // After a decent chunk of research, IE, Safari, FF seem to fire iframe events but only for certain content types when they are attachments
      // However, load, or any iframe event, does not fire in chrome when the src triggers a file download...
      // One description of a solution from SO is as follows:
      /*    the original JS request to create an iframe loads a src that would normally have loaded the pdf However,
            the src now loads a page with yet another iframe inside of it, which now contains the url of the pdf. in the html
             response I trigger the onload and also a catchall setTimeout funciton which calls my response on window.parent.window.handlerfunction
             which my onload on the top iframe would have been. The result is a PDF download and a trigger on the top level parent of the handler
             function that works across the browsers since it no longer relies on detecting an actual iframe
             load but rather relies on supported parent/child window relationships.

        I have not spent the time to go down that path as it seems extremely hackish and would only work with things such as PDFs I think
      */
      // Another possible alternative is to use a plugin such as https://github.com/johnculviner/jquery.fileDownload
      // Another possible alternative is to take what the aforementioned plugin is doing which is using a cookie. haven't thought through this yet.
      // console.log('the frame', frames['iFrameDownload'])
      // var x = document.getElementById("iFrameDownload")
      // console.log('the frame window', (x.contentWindow || x.contentDocument))
      // $iframe.on('load', function(e) {
      //   $('.kn-file-download').off().one('click', $.proxy(_this.handleDownload, _this))
      // });
    },

    getNewDownloadTokenKey: function(callback) {

      var model = new Backbone.Model();

      model.url = this.new_token_url;

      model.fetch({
        success: function(response_model, response) {
          callback(response.token_key);
        }
      });
    }
  });
});
