define([
  'jquery',
  'backbone',
  'core/backbone/views/BaseView',
  'core/templates/maintenance.html',
], function($, Backbone, BaseView, maintenanceTemplate) {
  return BaseView.extend({
    initialize: function() {},

    render: function() {
      $(this.el).html(Knack.render('maintenance-template', maintenanceTemplate, this.model.view));
    },
  });
});
