define([
  'jquery', 'backbone',
  'core/backbone/collections/Views',
  'core/backbone/models/BaseModel'
], function($, Backbone, Views, BaseModel) {
  return BaseModel.extend({
    views: null,
    dirty: null,

    initialize: function() {
      this.idAttribute = Knack.scene_id;
      this.views = new Views(this.attributes.views);
      this.views.url = Knack.api_dev + '/scenes/' + this.get('key') + '/views';
      this.views.each(function(view) {
        view.attributes.scene = {
          'key': this.get('key')
        };
      }, this);
      this.id = this.attributes[Knack.scene_id];

      if (Knack.initialized) {
        this.setProperties();
      }
    },

    parse: function(response) {
      var scene = response.scene || response;
      this.id = scene[Knack.scene_id];
      this.views.url = Knack.api_dev + '/scenes/' + scene.key + '/views';
      return scene;
    },

    toJSON: function() {
      if (this.dirty) {
        var json = _.clone(this.dirty);
        this.dirty = null;
      } else {
        var json = _.clone(this.attributes);
      }
      json.views = this.views.toJSON();
      return json;
    },

    isUserPage: function() {
      return (this.attributes.type && this.attributes.type === 'user');
    },

    isEntryScene: function() {
      if (this.attributes.parent) {
        return false;
      }

      return true;
    },

    save: function(attrs, options) {
      if (attrs) {
        this.dirty = attrs;
      }
      Backbone.Model.prototype.save.call(this, attrs, options);
    },

    // used to set authentication and such.  WILL ONLY WORK AFTER ALL SCENES HAVE INSTANTIATED
    setProperties: function() {
      this.set({
        authenticated: false
      }, {
        silent: true
      });

      // if this scene is part of the app's collection of scenes, set auth properties by checking parents for a login scene
      if (this.collection) {
        var par = this;
        while (par != null) {
          var parent = par;
          if (parent.get('type') == 'authentication' || parent.get('authenticated') == true) {

            // get the login view for allowed profiles
            if (parent.get('type') == 'authentication') {
              parent.views.each(function(view) {
                if (view.get('type') == 'login') {
                  var allowed_profiles = (view.get('limit_profile_access')) ? view.get('allowed_profiles') : null;
                  parent.set({
                    authentication_profiles: allowed_profiles
                  }, {
                    silent: true
                  });
                }
              });
            }

            this.set({
              authenticated: true,
              authentication_profiles: parent.get('authentication_profiles')
            }, {
              silent: true
            });

            break;
          }
          par = this.collection.getBySlug(parent.get('parent'));
        }

      }

      // this could be a user page, so assign the properties from there as well
      if (this.get('type') && this.get('type') === 'user') {

        var allowed_profiles = (this.get('limit_profile_access')) ? this.get('allowed_profiles') : null;
        this.set({
          authenticated: true,
          authentication_profiles: allowed_profiles
        }, {
          silent: true
        });
      }
    },

    updateViewSort: function(new_order) {
      var keys = new_order.toString();
      this.views.comparator = function(model) {
        return keys.indexOf(model.get('key'));
      };
      this.views.sort();
      delete this.views.comparator;
    },

    // which views (tables/details/forms) is this page capable of displaying?
    getAvailableViewOptions: function() {

      var views = {
        form_create: {
          name: 'form',
          type: 'plural',
          action: 'insert',
          objects: []
        },
        form_update: {
          name: 'form',
          type: 'single',
          action: 'update',
          objects: []
        },
        table: {
          name: 'table',
          type: 'plural',
          objects: []
        },
        list: {
          name: 'list',
          type: 'plural',
          objects: []
        },
        search: {
          name: 'search',
          type: 'plural',
          objects: []
        },
        map: {
          name: 'map',
          type: 'plural',
          field: 'address',
          objects: []
        },
        calendar: {
          name: 'calendar',
          type: 'plural',
          field: 'date_time',
          objects: []
        },
        report: {
          name: 'report',
          type: 'plural',
          objects: []
        },
        details: {
          name: 'details',
          type: 'single',
          objects: []
        },
        checkout: {
          name: 'checkout',
          type: 'single',
          objects: []
        }
      };

      var addViewOption = function(object_key, type, source) {

        if (!source) {
          source = {
            object: object_key
          };
        }
        var object = Knack.objects.get(object_key);

        _.each(views, function(data, view) {

          if (data.type == type) {

            var add = true;

            // if map confirm address
            if (view == 'map') {
              var has_address = object.fields.any(function(field) {
                return field.get('type') == Knack.config.ADDRESS;
              });

              var plan = Knack.account.product_plan;

              var address_plan = (plan.level > 1 || plan.id == 'trial' || Knack.getProductionMode() == 'development');

              if (!has_address || !address_plan) {
                add = false;
              }
            }

            // if calendar confirm date
            if (view == 'calendar') {
              var has_date = object.fields.any(function(field) {
                return field.get('type') == Knack.config.DATE_TIME;
              });
              add = has_date;
            }

            if (add) {
              data.objects.push({
                key: object_key,
                source: source
              });
            }
          }
        });
      };

      var scene = this.attributes;

      log('scene object?:'); log(scene);

      // no object? 'records in your database'
      if (!scene.object) {
        Knack.objects.each(function(obj) {
          addViewOption(obj.get('key'), 'plural');
        });
      }

      // object so these are 'single' edit views!
      if (scene.object) {

        var scene_object = Knack.objects.get(scene.object);

        // add single options for this object
        addViewOption(scene.object, 'single');

        // check objects connected to this object
        _.each(scene_object.get('conns'), function(conn) {

          var conn_obj = Knack.objects.get(conn.object);

          var link_name = (conn.relationship_type == 'local') ? conn.name : (conn.has == 'many') ? conn_obj.get('inflections').plural : conn_obj.get('inflections').singular;
          if (conn.name != link_name && conn.name != conn_obj.get('inflections').singular && conn.name != conn_obj.get('inflections').plural && scene_object.get('inflections').plural.indexOf(conn.name) == -1) {
            link_name += ' (' + conn.name + ')';
          }

          // add this object
          var type = (conn.has == 'one') ? 'single' : 'plural';
          var source = {
            object: conn.object,
            connection_key: conn.key,
            relationship_type: conn.relationship_type
          };
          addViewOption(conn_obj.get('key'), type, source);

          _.each(conn_obj.get('conns'), function(sub_conn) {

            if (sub_conn.has == 'many') {

              var source = {
                object: sub_conn.object,
                connection_key: sub_conn.key,
                relationship_type: sub_conn.relationship_type,
                parent_source: {
                  connection: conn.key,
                  object: conn_obj.get('key')
                }
              };
              var type = (sub_conn.has == 'one') ? 'single' : 'plural';
              addViewOption(sub_conn.object, type, source);

            }
          });
        });
      }

      log('> >> > >> GET USER AUTH???');
      log(scene);

      // user objects!
      if (Knack.app.get('users').enabled && scene.authenticated && !scene.object) {

        Knack.objects.each(function(obj) {

          var has_profile_access = (obj.attributes.profile_key && scene.authentication_profiles && scene.authentication_profiles.length && scene.authentication_profiles.indexOf(obj.attributes.profile_key) > -1);

          if (obj.attributes.profile_key == 'all_users' || has_profile_access) {

            // user/profile object
            var source = {
              object: obj.get('key'),
              authenticated_user: true
            };
            addViewOption(obj.get('key'), 'single', source);

            // CONNECTED to user
            var object_counts = {
            };
            _.each(obj.get('conns'), function(conn) {
              object_counts[conn.object] || (object_counts[conn.object] = 0);
              object_counts[conn.object] ++;
            });

            _.each(obj.get('conns'), function(conn) {
              var conn_obj = Knack.objects.get(conn.object);
              var obj_name = (conn.has == 'many') ? conn_obj.get('inflections').plural : conn_obj.get('inflections').singular;
              link_name = obj_name;
              if (object_counts[conn.object] > 1) {
                link_name += ' (' + conn.name + ')';
              }

              var source = {
                object: conn_obj.get('key'),
                authenticated_user: true,
                connection_key: conn.key,
                relationship_type: conn.relationship_type
              };
              var type = (conn.has == 'one') ? 'single' : 'plural';
              addViewOption(conn_obj.get('key'), type, source);

              // FURTHER connected
              if (conn.has == 'one' || conn.belongs_to == 'many' || 1==1) {
                var sub_counts = {
                };
                _.each(conn_obj.get('conns'), function(sub_conn) {
                  sub_counts[sub_conn.object] || (sub_counts[sub_conn.object] = 0);
                  sub_counts[sub_conn.object] ++;
                });

                _.each(conn_obj.get('conns'), function(sub_conn) {
                  if (sub_conn.has=='many' || sub_conn.has=='one') {

                    var source = {
                      authenticated_user: true,
                      object: sub_conn.object,
                      connection_key: sub_conn.key,
                      relationship_type: sub_conn.relationship_type,
                      parent_source: {
                        connection: conn.key,
                        object: conn_obj.get('key')
                      }
                    };
                    var type = (sub_conn.has == 'one') ? 'single' : 'plural';
                    addViewOption(sub_conn.object, type, source);
                  }
                });
              }
            });

          }

        });
      }

      return views;
    }
  });
});
