define([
  'core/lib/backbone',
  'core/backbone/models/Object'
], function(Backbone, Object) {
  return Backbone.Collection.extend({
    model: Object,

    initialize: function() {
      this.url = Knack.api_dev + '/objects';
    },

    getByProfile: function(key) {
      return this.find(function(obj) {
        return obj.attributes.profile_key == key;
      });
    },

    getField: function(key) {

      var field;
      var obj = this.find(function(obj) {

        var find = obj.fields.find(function(field) {
          return field.get('key') == key;
        });

        if (find) {
          field = find;
        }

        return (find);
      });

      return field;
    },

    updateSort: function(new_order) {
      var keys = ',' + new_order.toString() + ',';
      this.comparator = function(model) {
        return keys.indexOf(',' + model.get('key') + ',');
      };
      this.sort();
      delete this.comparator;
    }
  });
});
