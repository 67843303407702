var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "kn-migration-list-item" },
    [
      _c(
        "Card",
        [
          _c("template", { slot: "left-item" }, [
            _c("div", { staticClass: "kn-migration-list-item_left-item" }, [
              _c("input", {
                staticClass: "kn-migration-list-item_checkbox",
                attrs: { type: "checkbox", disabled: !_vm.isReschedulable },
                domProps: { checked: _vm.checked },
                on: {
                  change: function ($event) {
                    return _vm.check(_vm.app.app_id, $event.target.checked)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "kn-migration-list-item_status-emoji",
                class: _vm.iconClass,
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "title" }, [
            _c("span", { attrs: { title: _vm.app.app_name } }, [
              _vm._v(_vm._s(_vm.app.app_name)),
            ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "meta" }, [
            _c("div", {
              staticClass: "kn-migration-list-item_meta",
              domProps: { innerHTML: _vm._s(_vm.friendlyStatus) },
            }),
          ]),
          _vm._v(" "),
          _c("template", { slot: "right-item" }, [
            _vm.isReschedulable
              ? _c(
                  "button",
                  {
                    staticClass: "kn-migration-list-item_button",
                    attrs: {
                      title: "Reschedule this upgrade",
                      disabled: _vm.selectedApplicationIds.length > 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.click(_vm.app.app_id)
                      },
                    },
                  },
                  [
                    _vm._v("\n        Reschedule "),
                    _c("span", {
                      staticClass: "kn-migration-list-item_button-calendar",
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }