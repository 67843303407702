define([
  'jquery', 'backbone', 'underscore',
  'core/backbone/models/Form',
  'core/backbone/views/FormView',
  'core/templates/account.html',
  'core/templates/v2/account-v2.html'
], function($, Backbone, _, Form, FormView, account_template, account_template_v2) {
  return Backbone.View.extend({

    form_view: null,

    render: function() {
      if (Knack.getStyleEngine() === 'v2') {
        account_template = account_template_v2;
      }

      $(this.el).append(Knack.render('account-view', account_template, this.model.toJSON()));

      // get fields
      var user_object = Knack.objects.find(function(object) {
        return object.get('profile_key') == 'all_users';
      });
      var name_field = user_object.fields.find(function(field) {
        return (field.get('key') == 'name' || (field.get('user') && field.get('type') == 'name'));
      }).toJSON();
      var email_field = user_object.fields.find(function(field) {
        return (field.get('key') == 'email' || (field.get('user') && field.get('type') == 'email'));
      }).toJSON();
      var pass_field = user_object.fields.find(function(field) {
        return (field.get('key') == 'password' || (field.get('user') && field.get('type') == 'password'));
      }).toJSON();

      // account form
      var values = Knack.user.get('values');
      values.id = Knack.user.id;
      var view_model = new Form(values);
      view_model.type = 'user';

      view_model.setView({
        title: '',
        description: '',
        confirmation: {
          type: 'show_text',
          confirmation_text: Knack.trans('account_confirm')
        },
        inputs: [
          {
            type: 'name',
            id: name_field.key,
            field: name_field,
            label: Knack.trans('Name')
          },
          {
            type: 'email',
            id: email_field.key,
            field: email_field,
            label: Knack.trans('Email Address')
          }
        ]
      });

      this.form_view = new FormView({
        'model': view_model,
        'el': '.account-settings'
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      this.form_view.render();
      this.form_view.model.on('sync', this.handleUserUpdated, this);

      // password form

      // sso account?
      log('SSO ACCOUNT!');
      log(Knack.user);

      var inputs = [];

      var sso_ids = Knack.user.get('sso_ids');
      if (!Knack.user.get('empty_pass')) {
        inputs.push({
          type: 'password',
          ignore_confirmation: true,
          id: 'old_password',
          field: {
            'key': 'old_password'
          },
          label: Knack.trans('Current Password')
        });
      }

      inputs.push({
        type: 'password',
        id: 'password',
        field: {
          'key': 'password'
        },
        label: Knack.trans('New Password')
      });

      var values = Knack.user.get('values');
      values.id = Knack.user.id;
      var view_model = new Form(values);
      view_model.type = 'user';

      view_model.setView({
        title: '',
        description: '',
        confirmation: {
          type: 'show_text',
          confirmation_text: Knack.trans('pass_confirm')
        },
        inputs: inputs
      });

      this.form_view = new FormView({
        'model': view_model,
        'el': '.account-password'
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      this.form_view.render();

      return this;
    },

    renderResetPassword: function(options) {

      options = options || {
      };

      var vals = this.model.get('values') || {};

      vals.id = this.model.get('_id');

      var view_model = new Form(vals);

      view_model.url = this.model.url;

      var description = '';

      if (Knack.query_string && Knack.query_string.indexOf('reason=expiration') > -1) {

        var security = Knack.app.get('settings').password_options;

        description = security.password_require_expiration_message;
      }

      view_model.setView({
        title: Knack.trans('Reset Password'),
        description: description,
        confirmation: {
          type: 'show_text',
          confirmation_text: Knack.trans('pass_reset_confirm')
        },
        inputs: [
          {
            type: 'password',
            id: 'password',
            field: {
              'key': 'password'
            },
            label: Knack.trans('New Password')
          }
        ],
        ignore_reload: true,
        object: this.model.get('object_key')
      });

      if (options.url) {
        view_model.url = options.url;
      }

      this.form_view = new FormView({
        'model': view_model,
        'el': this.el
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      this.form_view.render();
      //this.form_view.model.on('sync', this.handleUserUpdated, this);
    },

    renderForgotPassword: function() {

      var view_model = new Form();

      view_model.setView({
        title: Knack.trans('forgot_pass'),
        description: Knack.trans('pass_reset_copy'),
        confirmation: {
          type: 'show_text',
          confirmation_text: Knack.trans('pass_reset_confirm')
        },
        inputs: [
          {
            type: 'email',
            id: 'email',
            field: {
              'key': 'email'
            },
            label: Knack.trans('Email Address'),
            required: true
          }
        ]
      });

      this.form_view = new FormView({
        'model': view_model,
        'el': this.el,
        submit_handler: $.proxy(this.handleSubmitForgotPass, this),
        ignore_reload: true
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      this.form_view.render();
      $('.kn-input-label').append('<span class="kn-required"> *</span>');
    },

    handleSubmitForgotPass: function(values, connection, crumbtrail) {

      const url_parts = window.location.href.split(`/knack-password`)

      values.url = url_parts[0]

      const _this = this
      const model = new Backbone.Model()

      model.url = `${Knack.api_dev}/users/password`

      model.save(values, {
        success: function() {

          _this.$el.html(`<p>${Knack.trans('pass_general_response')}</p>`)
        },
        error: function(response_model, result) {

          // handle rate limiting error response
          if (result.responseText === `Rate limit exceeded`) {

            var error_html = `<p><strong>${Knack.trans('pass_reset_exceeded')}</strong></p>`

            $.utility_forms.renderMessage($(`#knack-reset-pass`), error_html, `error`)

            $(`.kn-message.error`).css(`margin-top`, `18px`)
            $(`#email`).addClass(`input-error`)
            $(`:input, :input:submit`).prop(`disabled`, false)

            $(`#knack-reset-pass`).find(`.is-loading`).removeClass(`is-loading`)
            Knack.hideSpinner()
            $(`.kn-spinner`).hide()

            return
          }

          // handle default nondescript response
          _this.$el.html(`<p>${Knack.trans('pass_general_response')}</p>`)
        }
      });
    },

    handleUserUpdated: function(event, result, errors) {

      if (result) {

        // update users account info
        Knack.user.attributes.values.name.first = result.name.first;
        Knack.user.attributes.values.name.last = result.name.last;

        // update user name?
        $('.kn-current_user .first').html(result.name.first);
        $('.kn-current_user .last').html(result.name.last);

      }
    }
  });
});
