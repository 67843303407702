define([
  'jquery',
  'core/ui/tether'
], function(jQuery, Tether) {

(function($) {

  var knDropdown = {

    init: function(options, el) {

      var _this = this;

      // hide all other lists
      $('.kn-dropdown-list').hide();

      this.options = $.extend({}, options, this.options);
      this.$el = $(el);
      this.$list = this.options.list || this.$el.nextAll('.kn-dropdown-list:first');
      this.$links = this.$list.find('a');
      this.hover_timeout;

      //bind events
      this.$el.off().on(this.options.trigger || 'click', $.proxy(this.handleMenuClick, this));
      this.$links.off().on('click', $.proxy(this.handleLinkClick, this));

      // hover option
      var hover_duration = options.hover_duration || 800;
      if (options.hover) {
        this.$el.hover(function(event) {
          clearTimeout(_this.hover_timeout);
          if (!_this.$list.is(':visible')) {
            _this.handleMenuClick(event);
          }
        }, function(event) {
          if (_this.$list.is(':visible')) {
            _this.hover_timeout = setTimeout(function() {
              _this.handleMenuClick(event);
            }, hover_duration);
          }
        });
        this.$list.on('mouseenter', function(event) {
          clearTimeout(_this.hover_timeout);
        });

        this.$list.on('mouseleave', function(event) {
          if (_this.$list.is(':visible')) { // needed in case a click on a link in the dropdown causes the list to hide
            _this.hover_timeout = setTimeout(function() {
              _this.handleMenuClick(event);
            }, hover_duration);
          }
        });
      }

      //initiate Tether
      this.tetherMenu();
    },

    handleMenuClick: function(event) {

      var _this = this;

      event.preventDefault();
      event.stopPropagation();

      // close all other dropdowns in case of hover delays
      if (!this.$list.is(':visible')) {
        _this.$el.siblings('.kn-dropdown-trigger').each(function() {
          $(this).data('knDropdown').closeMenu();
        });
      }

      if (this.$list.is(':visible')) {

        this.closeMenu();
        $('body').off('click', this.handleClickCloseMenu);
      } else {

        // kill all other hover timeouts
        $('.kn-dropdown-trigger').each(function() {

          if ($(this).data('knDropdown').hover_timeout) {

            clearTimeout($(this).data('knDropdown').hover_timeout);
          }
        });

        // hide all other dropdowns
        $('.kn-dropdown-list').hide();
        this.$list.show();
        $('body').on('click', $.proxy(this.handleClickCloseMenu, this));
      }

      // refresh Tether position. Otherwise it won't position properly until the page is scrolled
      this.tether.position();
    },

    handleLinkClick: function(event) {

      this.$list.toggle();
      clearTimeout(this.hover_timeout);

      if (this.options.linkHandler) {
        event.preventDefault();
        this.options.linkHandler(event);
      }
    },

    handleClickCloseMenu: function(event) {
      if (this.$el.has(event.target).length === 0 && !this.$el.is(event.target)) {
        this.closeMenu();
      }
    },

    closeMenu: function() {
      this.$list.hide();
      $('body').off('click', this.handleClickCloseMenu);
      clearTimeout(this.hover_timeout);
    },

    tetherMenu: function() {

      // assemble constraints
      var constraints = [];

      // constrainToParent binds the target to the parent element
      if (this.options.constrainToParent) {
        constraints.push({
          to: 'scrollParent',
          attachment: 'together none',
          pin: ['top', 'bottom']
        });
      } else {
        constraints.push({
          to: 'scrollParent'
        });
      }

      // constrainToWindow binds the target to browser window
      // so if you scroll the entire page, target always remain visible
      if (this.options.constrainToWindow) {
        constraints.push({
          to: 'window',
          attachment: 'together'
        });
      } else {

      // this adds 'out of bounds' classes to target when not visible
        constraints.push({
          to: 'window'
        });
      }

      if (!this.$list.length) {

        log('no list to set tether on, return');
        return;
      }

      this.tether = new Tether({
        element: this.$list,
        target: this.$el,
        attachment: this.options.attachment || 'top left',
        targetAttachment: this.options.targetAttachment || 'bottom right',
        offset: this.options.offset || '0 0',
        targetOffset: this.options.targetOffset || '0 0',
        constraints: constraints
      });
    }
  };

  $.fn.extend({
    knDropdown: function(options) {
      return this.each(function() {
        var myDropdown = Object.create(knDropdown);
        myDropdown.init(options, this);
        $(this).data('knDropdown', myDropdown);
      });
    }
  });
}(jQuery));
});
