import { render, staticRenderFns } from "./ApplicationList.vue?vue&type=template&id=52043e74&scoped=true&"
import script from "./ApplicationList.vue?vue&type=script&lang=js&"
export * from "./ApplicationList.vue?vue&type=script&lang=js&"
import style0 from "./ApplicationList.vue?vue&type=style&index=0&id=52043e74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52043e74",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/knack/Client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52043e74')) {
      api.createRecord('52043e74', component.options)
    } else {
      api.reload('52043e74', component.options)
    }
    module.hot.accept("./ApplicationList.vue?vue&type=template&id=52043e74&scoped=true&", function () {
      api.rerender('52043e74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/MigrationApps/ApplicationList.vue"
export default component.exports