define(['jquery'], function(jQuery) {

// jquery plugin
  (function($) {

	// object creation
    if (typeof Object.create !== 'function') {
	  Object.create = function(o) {
	    var F = function() {};
	    F.prototype = o;
	    return new F();
	  };
    }

    var method;
    var noop = function() {};
    var methods = [
      'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
      'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
      'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
      'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {
    });

    // stub undefined methods.
    if (!console.log) {
      console.log = noop;
    }

	// window log
	//   usage: log('inside coolFunc',this,arguments);
	//   paulirish.com/2009/log-a-lightweight-wrapper-for-consolelog/
    window.log = function() {
	  if (typeof window.console != 'undefined' && console.log && window.knack_production_mode && (window.knack_production_mode == 'development' || window.knack_production_mode == 'staging' || window.knack_production_mode === 'test')) {
	    console.log(arguments[0]);
	  }
    };

  })(jQuery);

});

