define([
  'jquery',
  'core/lib/fileupload'
], function($) {

  // jquery plugin
  var assetPicker = {

    $elem: null,
    options: {
    },
    type: 'image',
    file: null, // current file that is queued
    delay: false, // don't immediately upload the asset

    init: function(options, elem) {

      this.$elem = $(elem);
      this.options = options || {
      };
      this.type = options.type || this.type;

      if (options.delay_upload) {
        this.delay = options.delay_upload;
      }

      this.$elem.find('.kn-flash-upload').remove();
      this.$elem.find('.kn-file-upload').show();
      this.renderModernUpload();
    },

    renderModernUpload: function() {

      var _this = this;

      this.$file_upload = this.$elem.find('.kn-file-upload input');

      this.$file_upload.fileupload({
        dropZone: this.$file_upload,
        pasteZone: null,
        dataType: 'json',
        replaceFileInput: false,
        formData: {
          sso: this.options.sso,
          origin: JSON.stringify(this.options.origin)
        },
        headers: {
          'x-knack-application-id': Knack.application_id,
          'x-knack-rest-api-key': Knack.mode
        },
        xhrFields: {
          withCredentials: !Knack.isOnNonKnackDomain() || !Knack.user || !Knack.user.attributes.token
        },
        add: function(e, data) {

          const generateFileDataQueryString = (fileData) => {

            let queryString = ``

            Object.keys(fileData).forEach((key) => {

              queryString += `&${key}=${encodeURIComponent(fileData[key])}`
            })

            return queryString
          }

          data.context = 'Uploading';
          _this.file = data.files[0];

          const field_key = _this.$elem.find(':input[type=hidden]:first').attr('name')

          // rules-helper .checkRule() requires 'filename' not 'name'
          const { name: filename, size, type } = _this.file
          const fileData = { filename, size, type }
          const fileDataQueryString = generateFileDataQueryString(fileData)

          if (_this.options.upload_in_progress_handler) {

            const passedValidation = _this.options.upload_in_progress_handler(fileData)
            const assetType = _this.options.getAssetType()
            if (!passedValidation) {

              // remove the file from the "choose file" field so that the user can upload another file
              const fileUploadEl = _this.$elem.find('.kn-file-upload input')
              fileUploadEl.replaceWith(fileUploadEl.val('').clone(true))

              return
            }

            data.url = `${Knack.api}/assets/${assetType}/upload/stream?fieldKey=${field_key}${fileDataQueryString}`
          }

          if (!_this.isValidFileType()) {
            _this.$elem.find('.kn-file-upload').popover({
              content: '<p style="margin: 12px;">Please select a valid ' + _this.options.valid_file_type + ' file.</p>'
            });
            log('File type was invalid, aborting add.');
            log('Selected file:');
            log(_this.file);
            log('Valid file type:');
            log(_this.options.valid_file_type);
            return;
          }

          if (_this.options.file_selected_handler) {
            _this.options.file_selected_handler();
          }

          if (!_this.delay) {
            data.submit();
            _this.$elem.find('.kn-spinner').show();
          } else {
            _this.$elem.find('.kn-asset-result').text(_this.file.name);

            _this.modern_upload = data;

            if (_this.options.queue_handler) {

              _this.options.queue_handler(_this.file);
            }
          }
        },
        done: function(e, data) {

          _this.$elem.find('.kn-spinner').hide();

          _this.file = data.files[0];

          const result = (Array.isArray(data.result)) ? data.result[0] : data.result;

          _this.handleUploadComplete(result);

          if (_this.options.success_handler) {
            _this.options.success_handler(result);
          }
        },
        error: function(response, status_code) {

          _this.$elem.find('.kn-spinner').hide();
          _this.$elem.find('.kn-file-upload').replaceWith(_this.$elem.find('.kn-file-upload').val('').clone(true));

          if (!_this.options.hide_popover_error) {

            _this.$elem.find('.kn-file-upload').popover({
              content: '<p style="margin: 12px;">Unable to upload, please try again.</p>'
            });
          }

          if (_this.options.error_handler) {
            _this.options.error_handler(response, status_code);
          }
        }
      });
    },

    handleUploadComplete: function(uploaded_file) {

      // set values
      var img_val = [uploaded_file.id];

      if (uploaded_file.id) {
        this.$elem.find('input[type=hidden]').val([uploaded_file.id]);
      }

      // update current
      this.$elem.find('.kn-asset-result').empty();
      this.$elem.find('.kn-asset-current').text(uploaded_file.filename);

      var orig = this.$elem.find('.kn-asset-current').css('background-color');

      this.$elem.find('.kn-asset-current').stop().css('background-color', '#ffcc99').animate({
        backgroundColor: orig
      }, 1200);

      // hide spinner
      this.$elem.find('.kn-spinner').hide();
    },

    startUpload: function(vars) {
      if (vars) {
        this.modern_upload.formData = vars; // e.g. {id: 123}
      }

      this.modern_upload.jqXHR = this.$file_upload.fileupload('send', this.modern_upload);
    },

    isValidFileType: function() {
      return !this.options.valid_file_type || (this.file.type && this.file.type.indexOf(this.options.valid_file_type)) === 0;
    }
  };

  $.fn.extend({
    assetPicker: function(options) {
      return this.each(function() {
        var myAssetPicker = Object.create(assetPicker);
        myAssetPicker.init(options, this);
        $(this).data('assetPicker', myAssetPicker);
        return this;
      });
    }
  });

  return null;
});
