 define(['jquery', 'underscore'], function($, _) {

  $.utility_forms = {

    callbacks: {
      success: this.successCallback,
      error: this.errorCallback
    },

  // renderMessage() ---------------
    renderMessage: function($form, message, type) {

      type || (type = 'error');

      if (Knack.getStyleEngine() === 'v2') {
        var $msg = $(
        '<div class="kn-message">' +
          '<a class="close delete"></a>' +
          '<span class="kn-message-body"></span>' +
        '</div>');
        $msg.addClass('is-' + type); // is-success, is-error, etc.
        $msg.find('.kn-message-body').html(message); // Add message body
      } else {
        var $msg = '<div class="kn-message ' + type + '"><a href="#" class="close">x</a>';
        $msg += message;
        $msg += '</div>';
      }

      $form.find('.kn-message.error, .kn-message.is-error').remove();

      $form.prepend($msg);
      $form.find('.kn-message a.close').click(function(event) {
        event.preventDefault();
        $(this).closest('.kn-message').remove();
      });
    },

    parseErrors: function(result) {
      var e = result.responseText;
      e = $.parseJSON(e.substring(e.indexOf('({')+1, e.length-2));
      return e.errors;
    },

  // errorCallback() ---------------
    errorCallback: function(model, result, $form) {
      if ((result.status == 400 || result.status == 401) || ((result.status == 500 || result.status == 502) && result.errors)) {
        if (result.errors) {
          e = {
            errors: result.errors
          };
        } else {
          var e = result.responseText;
          if (e && e.indexOf('({') !== -1) {
            e = $.parseJSON(e.substring(e.indexOf('({')+1, e.length-2));
          }
        }

      // add any base messages to top
        if (e && e.errors) {
          var error_msg = '';

          if (_.isArray(e.errors)) {
            _.each(e.errors, function(err) {

            // add message
              error_msg += '<p><strong>' + err.message + '</strong></p>';

            // highlight the input?
              if (err.field) {
                $form.find('#kn-input-' + err.field +' :input').addClass('input-error').focus(function() {
                  $(this).closest('.kn-input').find(':input').removeClass('input-error');
                });
              }
            });

          } else {
            error_msg += '<p><strong>' + e.errors + '</strong></p>';
          }
          this.renderMessage($form, error_msg, 'error');
        }

      }

    // add any field messages directly to inputs

    // hide indicator and activate form
      $('#kn-loading-spinner').hide();
      $form.find('.is-loading').removeClass('is-loading'); // v2 loading button
      $form.find('.kn-submit .kn-spinner').hide();
      $form.find(':input').attr('disabled', false);
    },

  // successCallback() ---------------
    successCallback: function(model, resp) {
      log('success callback!!!');
      log(model);
      log(resp);
    }
  };

});
