var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kn-migration-dashboard-help-container" }, [
      _c("section", [
        _c("h2", [_vm._v("Learn More")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      We’ve created a page with more details about the performance\n      environment, including what to expect, any product changes, and known\n      issues.\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              rel: "noopener",
              target: "_blank",
              href: "https://www.knack.com/performance-upgrade",
            },
          },
          [_vm._v("\n      Learn More\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h2", [_vm._v("FAQ")]),
        _vm._v(" "),
        _c("h3", [
          _vm._v("Do the scheduled dates indicate the start time or end time?"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      The scheduled date lists when the upgrade will begin. You will receive an\n      email as soon as the upgrade is complete.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("How long will each upgrade take?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      We’re expecting each upgrade to take up to 20 minutes. Some may not take\n      that long.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("Will my app be available during the upgrade?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      No. Both the builder and your live app will show a scheduled maintenance\n      message instead. Any API requests will receive 503 error messages that\n      indicate the service is temporarily unavailable.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("Do I need to be available for the upgrade?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "No, your app will upgrade automatically at the scheduled time."
          ),
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            "What if I want to reschedule on a date that is not available?"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Our goal is to upgrade every application as quickly as possible, so only\n      limited days are available to upgrade. We expect all upgrades to be\n      completed by August 15th.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("What can I expect from my app after the upgrade?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Along with improved performance, you can expect some minor product\n      changes, lots of fixed bugs, and a few known issues. We’re tracking all\n      of this at our\n        "
          ),
          _c(
            "a",
            {
              attrs: {
                rel: "noopener",
                target: "_blank",
                href: "https://www.knack.com/performance-upgrade#issues",
              },
            },
            [_vm._v("\n          Performance Upgrade page\n        ")]
          ),
          _vm._v(".\n    "),
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            "Will this upgrade change any of my API keys or any other app information?"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "No, all of your application information will stay exactly the same."
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("What happens if my upgrade fails?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Our engineers will investigate and we’ll reschedule it a day later to\n      try again. Your app will be immediately available to use in the\n      meantime.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v("Can I migrate back if I run into issues after the upgrade?"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      No, the old environment will be permanently deprecated. Please reach out\n      to support with any issues so we can resolve them ASAP.\n    "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }