define([
  'jquery',
  'core/lib/lazyload'
], function($, LazyLoad) {

  return {

    modeOptions: {
      DASHBOARD: `dashboard`,
      LIVE_APP: `liveApp`
    },

    renderCaptcha: async function ({ increment = true, mode }) {

      let sitekey = ``

      if (mode === this.modeOptions.DASHBOARD) {

        sitekey = `6LeuBqsUAAAAALwWF9x_fuoaaJU15QV_3P5Xx2mw`
      } else if (mode === this.modeOptions.LIVE_APP) {

        sitekey = `6LctkNkZAAAAADMmIHTrijWmJVXmYztuoTgvG5aE`
      }

      if (!window.grecaptcha) {

        await new Promise(resolve => {

          LazyLoad.js([`https://www.google.com/recaptcha/api.js?render=explicit`], () => resolve())
        })
      }

      this.renderedCaptcha = !!$('#billing-payment-recaptcha').html();

      let attempts = $.cookie(`billingAttempts`) ? parseInt($.cookie(`billingAttempts`), 10) : 0

      if (attempts < 3) {

        if (increment) {

          attempts++

          $.cookie(`billingAttempts`, attempts, {
            expires: 1
          })
        }

        return
      }

      this.captchaResponse = false

      $(`#billing-payment-submit`).attr(`disabled`, `disabled`).addClass(`disabled`)

      if (this.renderedCaptcha === true) {

        window.grecaptcha.reset()
      } else {

        // We need to wrap this in setTimeout() because of a race condition (?) from LazyLoad.
        // We encounter this if this render method is called more than once on a page, in the case
        // there are multiple checkouts for example. By adding in a timeout it allows window.grecaptcha
        // to be fully loaded on the page before the rendering starts.
        setTimeout(() => {

          window.grecaptcha.render(`billing-payment-recaptcha`, {
            sitekey,
            callback: (response) => {

              $.ajax({
                type: `POST`,
                url: `${Knack.api_url}/captcha`,
                data: {
                  response,
                  mode
                },
                success: (response) => {

                  if (response.success) {

                    this.captchaResponse = true

                    $(`#billing-payment-submit`).removeAttr(`disabled`).removeClass(`disabled`)
                  }
                }
              })
            }
          })
        }, 500)
      }
    }
  }
})
