define(['jquery'], function($) {

// right now is named with the _ underscore because otherwise it was throwing errors for any files using it that were before it alphabetically
// This is used as a base class for other data popover editors

  return {

  // properties
    options: {
      include_add_link: false,
      click_handler: null, // used for parent objects to have a handler for clicks
      default_link: null,  // used to trigger the click event for a link after build
      toggle: null
    },
    $elem: null,
    $master: null,
    click_data: {
    }, // sent along with click handler

    toggle: true, // if toggle, then activate link

  // init() ---------------
    init: function(options, elem) {

    // set vars
      this.options = $.extend(true, {
      }, this.options, options);
      this.$elem = $(elem);
      this.setMaster();

      if (this.options.toggle != null) {
        this.toggle = this.options.toggle;
      }

    // build Dom
      this.render();

    // eventify
      this.$elem.find('a').live('click', $.proxy(this.handleClickSelectLink, this));

    // activate link
      if (this.toggle) {
        this.activateLink(this.options.default_link);
      }
    },

  // setMaster() ---------------
    setMaster: function() {
      this.$master = this.$elem.find('li:first').detach();
    },

  // render() ---------------
    render: function() {

    // select objects
      var objects = this.getLinkSource();

    // add links
      if (objects.length) {
        for (var i in objects) {
          this.addLink(objects[i]);
        }
      }
    },

  // getLinkSource() ---------------
    getLinkSource: function() {

    // OVERWRITE
      if (this.options.get_source) {
        return this.options.get_source();
      }

      return null;
    },

  // addLink() ---------------
    addLink: function(obj) {
      var $li = this.$master.clone();
      this.setLinkProperties($li.find('a'), obj);
      this.$elem.append($li);
    },

  // updateLink() ---------------
    updateLink: function(obj) {
      var $link = this.$elem.find('a[href=#' + obj.key + ']');
      this.setLinkProperties($link, obj);
    },

  // setLinkProperties() ---------------
    setLinkProperties: function($link, obj) {
      $link.data('object', obj);
      $link.find('span').html(obj.name);
      $link.attr('href', '#' + obj.key);
    },

  // removeLink() ---------------
    removeLink: function(obj_key) {
      this.$elem.find('a[href=#' + obj_key + ']').remove();
    },

  // clearLinks() ---------------
    clearLinks: function() {
      this.$elem.children().remove();
    },

  // activateLink() ---------------
    activateLink: function(obj_key) {
      if (this.$elem.children().length) {
        if (obj_key) {
          this.$elem.find('a[href=#' +obj_key + ']').click();
        } else {
          if (this.$elem.find('li.active').length) {
            this.$elem.find('li.active a').click();

          } else {
            this.$elem.find('a:first').click();
          }
        }
      }
    },

  // handleClickSelectLink() ---------------
    handleClickSelectLink: function(event) {
      event.preventDefault();
      this.setActiveLink(event);
    },

  // setActiveLink() ---------------
    setActiveLink: function(event) {

      log('base.setActiveLink!!!!');

    // change styles
      this.$elem.find('.active').removeClass('active');
      $(event.currentTarget).closest('li').addClass('active');

    // check for callback
      if (this.options.click_handler) {
        this.options.click_handler(event, this.click_data);
      }
    }
  };

});
