define([
  'jquery'
], function($) {
    var CookieUtil = {
      checkCookie: function(options) {
        var cookie = $.cookie(options.cookie_key);
        if (!cookie) {
          return options.no_cookie();
        }

        cookie = $.parseJSON(cookie);
        if (!cookie.user) {
          return options.no_cookie();
        }

        var model = Knack.user;
        model.set('id', cookie.user.id);
        model.save(cookie.user, {
          success: options.success,
          error: function() {
            $.cookie(options.cookie_key, null);
            $.removeCookie(options.cookie_key);
            options.failure();
          },
          wait: true,
          silent: true
        });
      },
      destroyCookie: function(options) {
        $.cookie(options.key, null);
        $.removeCookie(options.key);
      }
    };
    return CookieUtil;
});
