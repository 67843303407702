/* eslint-disable no-undef */
define(['lodash'], (_) => ({
  getEntryPages() {
    let isPageMenuAuthBased;

    if (Knack.app.get('design_version') === 'v3') {
      if (Knack.app.get('design').regions.header.isLegacy) {
        isPageMenuAuthBased = _.get(Knack.app.get('design'), ['regions', 'header', 'legacySettings', 'menu', 'user_auth_based'], false);
      } else {
        isPageMenuAuthBased = _.get(Knack.app.get('design'), ['regions', 'header', 'menu', 'userAuthBased'], false);
      }
    } else {
      isPageMenuAuthBased = _.get(Knack.app.get('layout'), 'app_menu_auth', false);
    }

    const entryPages = [];

    Knack.scenes.each((scene) => {
      const isApplicationScopedUsers = Knack.app.get('users').scope && Knack.app.get('users').scope === 'application';

      if (isApplicationScopedUsers && !Knack.user.id) {
        return;
      }

      const page = scene.toJSON();
      if (page.ignore_entry_scene_menu) {
        return;
      }

      // if page is a dropdown menu and has pages in it
      if (page.type === 'menu' && page.menu_pages && page.menu_pages.length) {
        // Add new 'dropdown_pages' property to the page object to store
        // potential dropdown pages associated with an entry page
        page.dropdown_pages = [];

        page.menu_pages.forEach((pageKey) => {
          let menuPage = Knack.scenes.getByKey(pageKey);

          if (menuPage) {
            menuPage = menuPage.toJSON();
            let showChild = true;
            const isLogin = !isApplicationScopedUsers && menuPage.authenticated;

            if (isLogin && isPageMenuAuthBased) {
              if (!Knack.user.id || Knack.user.isNew()) {
                if (menuPage.parent !== Knack.home_slug && menuPage.slug !== Knack.home_slug) {
                  showChild = false;
                }
              } else {
                const pageRoleKeys = menuPage.authentication_profiles;
                if (pageRoleKeys && pageRoleKeys.length && !Knack.User.hasPermissions(pageRoleKeys)) {
                  showChild = false;
                }
              }
            }

            if (showChild) {
              if (isLogin) {
                const parentIcon = menuPage.icon;
                menuPage = Knack.scenes.find((s) => s.get('parent') === menuPage.slug);

                if (menuPage) {
                  menuPage = menuPage.toJSON();
                  menuPage.icon = parentIcon;
                  page.dropdown_pages.push(menuPage);
                }
              } else {
                page.dropdown_pages.push(menuPage);
              }
            }
          }
        });

        // Only add the page to the list of entryPages if the dropdown menu has visible pages
        if (page.dropdown_pages.length) {
          entryPages.push(page);
        }
      }

      // Get the page's parent scene, if there is one
      const parentSceneObject = Knack.scenes.getBySlug(page.parent);

      // Check if page is orphan.
      // Orphan pages have a parent slug but the parent page doesn't actually exist,
      // so we don't want to consider them as entry pages nor show them on the page menu.
      if (page.parent && !parentSceneObject) {
        return;
      }

      const notParentOrApplicationScopedLogins = !parentSceneObject && !isApplicationScopedUsers;
      const isParentTopLevelLogin = parentSceneObject && parentSceneObject.get('type') === 'authentication' && !parentSceneObject.get('parent');
      const applicationScopedLoginsAndParentIsTopLevelLogin = isApplicationScopedUsers && isParentTopLevelLogin;
      const noParentOrSingleLoginAndAuthParent = notParentOrApplicationScopedLogins || applicationScopedLoginsAndParentIsTopLevelLogin;
      const isStartPage = noParentOrSingleLoginAndAuthParent && page.type !== 'user' && page.type !== 'menu' && !page.menu;
      const isLoginPage = !parentSceneObject && page.type === 'authentication' && !page.menu && !isApplicationScopedUsers;

      // if page is a regular entry page or login
      if (isStartPage || isLoginPage) {
        if (isLoginPage) {
          let proceed = true;

          if (isPageMenuAuthBased) {
            const hasInitialHashScene = Knack.hash_scenes && Knack.hash_scenes.length;
            if ((!Knack.user.id || Knack.user.isNew()) && (hasInitialHashScene && Knack.hash_scenes[0].slug !== page.slug)) {
              proceed = false;
            } else {
              const pageRoleKeys = page.authentication_profiles;
              if (pageRoleKeys && pageRoleKeys.length && !Knack.User.hasPermissions(pageRoleKeys)) {
                proceed = false;
              }
            }
          }

          if (proceed) {
            const parentIcon = page.icon;
            Knack.scenes.each((child) => {
              if (child.get('parent') === page.slug) {
                const childPage = child.toJSON();

                if (parentIcon) {
                  childPage.icon = parentIcon;
                }

                if (!_.find(entryPages,
                  (entryPage) => entryPage.slug === childPage.slug)) {
                  entryPages.push(childPage);
                }
              }
            });
          }
        } else if (!_.find(entryPages,
          (entryPage) => entryPage.slug === page.slug)) {
          entryPages.push(page);
        }
      }
    });

    return entryPages;
  },

  getUserAccountPages() {
    const userAccountPages = [];
    const profileKeys = Knack.user.getProfileKeys();
    Knack.scenes.each((page) => {
      if (page.isUserPage()) {
        const authProfiles = page.get('authentication_profiles');
        if (authProfiles && authProfiles.length) {
          if (_.intersection(profileKeys, authProfiles).length === 0) {
            return;
          }
        }
        userAccountPages.push(page.attributes);
      }
    });
    return userAccountPages;
  },
}));
