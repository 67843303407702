define([
  'jquery',
  'underscore',
  'backbone',
  'builder/lib/socket-io',
  'core/lib/cookie',
  'core/lib/toastr.min',
  'core/backbone/views/BaseView',
  'core/backbone/views/LoginView',
  'core/backbone/views/SupportLoginView',
  'core/backbone/models/Account',
  'core/backbone/views/AccountView',
  'dashboard/templates/knack-layout-login.html',
  'dashboard/templates/knack-layout-dashboard.html',
  'builder/templates/knack-layout-builder.html',
  'builder/templates/help.html',
  'builder/templates/help-tour-data.html',
  'builder/templates/help-tour-interface.html',
  'builder/templates/refer-friend.html',
  'builder/ui/tooltip',
  'builder/ui/clipboard',
  'core/lib/notify',
  'builder/ui/kn-dropdown',
  'core/utility/utility-cookies'
], function($, _, Backbone, io, cookie, Toastr, BaseView, LoginView, SupportLoginView, Account, AccountView, template_login, template_dashboard, template_builder, template_help, template_help_data, template_help_interface, template_refer_friend, ToolTip, Clipboard, Notify, DropDown, CookieUtil) {
  return BaseView.extend({

    messages: [],
    clicks: [],

    uid: 1,

    getUID: function() {
      return this.uid++;
    },

    initialize: function() {
      // Chrome on Windows has Lato font rendering issues where parts of certain characters are not displayed. Stroke over all the characters to prevent
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.userAgent.toLowerCase().indexOf('windows') > -1) {
        $('body').css('-webkit-text-stroke', '0.2px');
      }

      $.expr[':'].visibleInput = function(a) {
        return $(a).closest('.view-input').css('display') != 'none' && $(a).closest('.view-input').css('visibility') == 'visible';
      };

      // bind load
      Knack.off(null, null, this);
      Knack.on('load', $.proxy(this.initializeLoad, this));
      Knack.on('load', $.proxy(this.initializeUsers, this));
      Knack.on('load', $.proxy(this.render, this));

      // resize event!
      $(window).resize(function() {
        if (this.resizeTO) {
          clearTimeout(this.resizeTO);
        }
        this.resizeTO = setTimeout(function() {
          $(this).trigger('knackResize');
        }, 100);
      });

      // sockets
      this.initSockets();

      // handle errors
      $(document).bind('knack-error', $.proxy(this.renderErrorMessage, this));

      $('.kn-help-hint').live('click', $.proxy(this.handleClickHelpHint, this));

      $('a').live('click', $.proxy(this.storeClick, this));
    },

    initSockets: function() {

      switch (Knack.mode) {

        case 'builder':

          return this.initBuilderSocket();

        case 'crm':

          return this.initCrmSocket();
        default:

          return;
      }
    },

    initCrmSocket: function() {

      log('initCrmSocket');

      Knack.socket = io('wss://knschema.knack.com:443', {
        query: {
          user: window.support_user.email.replace('@knackhq.com', '').replace('@knack.com', '')
        },
        'forceNew': false,
        'reconnection delay': 1000
      });
    },

    initBuilderSocket: function() {

      log('initBuilderSocket');

      // The Underscore folks are silly and refuse to add an isNil operator like lodash
      // https://github.com/jashkenas/underscore/issues/2280
      // https://lodash.com/docs/4.17.4#isNil
      if (_.isUndefined(Knack.application_id) || _.isNull(Knack.application_id)) {

        return;
      }

      Knack.socket = io(Knack.socket_url + '?appid=' + Knack.application_id + '&type=browser', {
        'forceNew': true,
        'reconnection delay': 1000
      });

      var jobs = ['csv:import', 'batch:update', 'batch:delete'];

      _.each(jobs, function(job) {

        Knack.socket.on(job + ':progress', function(data) {

          Knack.dispatcher.trigger(job + ':progress', data);

          _.each(data.jobs, function(j, object_key) {

            if (window.location.hash.split('/')[1] === object_key) {
              Knack.dispatcher.trigger(j.name + ':progress:' + object_key, j);
            }
          });
        });

        Knack.socket.on(job + ':complete', function(data) {

          log('builder > complete event >  ' + job);
          log(data);

          var object = Knack.objects.get(data.object_key);

          var title = '';
          var msg = '';

          switch (job) {

            case 'batch:update':
              title = 'Batch Complete';
              msg = 'The ' + object.get('name') + ' update has completed. A total of ' + data.processed_count + ' records were updated.';
              break;

            case 'batch:delete':
              title = 'Delete Complete';
              msg = data.saved_count + ' ' + object.get('name') + ' records have been deleted.';
              break;

            default:
              title = 'Import Complete';
              msg = 'The ' + object.get('name') + ' import has completed. A total of ' + data.processed_count + ' records were imported.';
              break;
          }

          // push message
          if (job !== 'batch:delete' || data.saved_count > 5) {
            Builder.postMessage({
              title: title,
              object: {
                key: object.id
              },
              message: msg
            });
          }

          // set status, handle changes
          object.set({
            status: 'current'
          });

          if (data.changes) {
            Knack.handleChanges(data.changes);
          }

          log('dispatching ' + job + ':complete:' + data.object_key);

          if (!data.name) {
            data.name = job;
          }
          Knack.dispatcher.trigger(job + ':complete:' + data.object_key, data);
        });

        Knack.socket.on(job + ':canceled', function(data) {

          log('builder > canceled event >  ' + job);
          log(data);

          var object = Knack.objects.get(data.object_key);

          var title = '';
          var msg = '';

          switch (job) {
            case 'batch:update':
              title = 'Batch Canceled';
              msg = 'The ' + object.get('name') + ' update has been canceled. A total of ' + data.processed_count + ' records were updated.';
              break;

            case 'batch:delete':
              title = 'Delete Canceled';
              msg = 'The ' + object.get('name') + ' records delete has been canceled. A total of ' + data.processed_count + ' records were deleted.';
              break;

            default:
              title = 'Import Canceled';
              msg = 'The ' + object.get('name') + ' import has been canceled. A total of ' + data.processed_count + ' records were imported.';
              break;
          }

          // push message
          Builder.postMessage({
            title: title,
            object: {
              key: object.id
            },
            message: msg
          }, data);

          // set status, this should also fetch records
          object.set({
            status: 'current'
          });

          data.name = job;
          Knack.dispatcher.trigger(job + ':canceled', data);
          Knack.dispatcher.trigger(job + ':canceled:' + data.object_key, data);
        });

        Knack.socket.on(job + ':failed', function(data) {

          log('builder > failed event >  ' + job);
          log(data);

          var object = Knack.objects.get(data.object_key);

          var title = '';
          var msg = '';

          switch (job) {

            case 'batch:update':
              title = 'Batch Failed';
              msg = 'The ' + object.get('name') + ' update has failed due to technical issues. A total of ' + data.processed_count + ' records were updated.';
              break;

            case 'batch:delete':
              title = 'Delete Failed';
              msg = 'The ' + object.get('name') + ' records delete has failed due to technical issues. A total of ' + data.processed_count + ' records were deleted.';
              break;

            default:
              title = 'Import Failed';
              msg = 'The ' + object.get('name') + ' import has failed due to technical issues. A total of ' + data.processed_count + ' records were imported.';
              break;
          }

          if (data.client_error_message) {
            msg += ' <br /><br />' + data.client_error_message;
          }

          // push message
          Builder.postMessage({
            title: title,
            object: {
              key: object.id
            },
            message: msg
          }, data);

          // set status, this should also fetch records
          object.set({
            status: 'current'
          });

          Knack.dispatcher.trigger(job + ':failed', data);
          Knack.dispatcher.trigger(job + ':failed:' + data.object_key, data);
        });
      });
    },

    render: function() {

      log('Builder.render()');

      var _this = this;

      if (Knack.getProductionMode() == `production` && Knack.mode == `dashboard` && ($.cookie(`banner_message_clicked`) === `false` || $.cookie(`banner_message_clicked`) === null)) {

        // this.renderBannerMessaging()
      }

      // check for user
      this.checkAuthentication();

      // render dashboard
      if (Knack.mode == 'dashboard') {

        if (!$('#global-user-settings').data('knDropdown')) {
          $('#global-user-settings').knDropdown({
            attachment: 'top right',
            targetAttachment: 'bottom left',
            offset: '-10 -50px',
            hover: true
          });
        }
      }

      // render builder
      if (Knack.mode == 'builder') {

        // check app is in good standing
        log('before');
        if (!this.checkAppStatus()) {
          return false;
        }

        // Update title and load
        this.renderAppName();

        // app title dropdown
        $('#app-title-settings').knDropdown({
          targetAttachment: 'bottom middle',
          attachment: 'top middle',
          offset: '0px 0',
          hover: true
        });

        // check for beta
        this.renderBetaInfo();

        // check for billing
        this.renderBillingInfo();

        if (!$('#global-app-settings').data('knDropdown')) {
          $('#global-app-settings').knDropdown({
            attachment: 'top right',
            targetAttachment: 'bottom left',
            offset: '-10 -90px',
            hover: true
          });
        }
        if (!$('#global-user-settings').data('knDropdown')) {
          $('#global-user-settings').knDropdown({
            attachment: 'top right',
            targetAttachment: 'bottom left',
            offset: '-10 -50px',
            hover: true
          });
        }

        if (!$('#view-pages').data('knDropdown')) {
          $('#view-pages').knDropdown({
            attachment: 'top right',
            targetAttachment: 'bottom left',
            offset: '-10 -192px',
            hover: true
          });
        }

        // hide links for shared builders
        if (Knack.account.non_account) {
          $('#user-settings-dropdown a[href*="#account"]').closest('li').remove();
          $('#user-settings-dropdown a[href*="#billing"]').closest('li').remove();
        }

        // view menu
        log('renderViewAppScenes...');
        this.renderViewAppScenes();
        log(Knack.scenes);
        Knack.scenes.on('add remove change', function() {
          log('Knack.scenes change! check to update view page menu............');
          _this.renderViewAppScenes();
        });

      }

      /*
      // help
      $('#trigger-help-menu').off().hover(
        function(event) {
          var max_height = $(window).height() - 60;
          $('#view-app-help').css({top:'34px', opacity: 1, 'max-height': max_height + 'px'});
        },
        function(event) {
          $('#view-app-help').css({top:'34px', opacity: '.0', 'max-height': '0px'});
        }

      );

      // help
      $('#trigger-settings-menu').off().hover(
        function(event) {
          var max_height = $(window).height() - 60;
          $('#view-app-settings').css({top:'34px', opacity: 1, 'max-height': max_height + 'px'});
        },
        function(event) {
          $('#view-app-settings').css({top:'34px', opacity: '.0', 'max-height': '0px'});
        }

      );
      */

      var HelpView = require('builder/backbone/views/HelpView');

      this.help_view = new HelpView();
      this.help_view.renderWidget();

      // Tooltips
      ToolTip.init();

      // handle refer-a-friend click
      $('#refer-a-friend').off().on('click', $.proxy(this.handleClickReferFriend, this));

      // handle logout
      $('#log-out').off().on('click', $.proxy(this.handleClickLogout, this));
    },

    renderViewAppScenes: function() {

      log('renderViewAppScenes!');

      var max_height = $(window).height() - 140;
      $('#view-pages-dropdown ul').css('max-height', max_height + 'px');

      // get entry scenes
      var scenes = Knack.scenes.getEntryScenes();

      var url = Knack.protocol + Knack.app.get('account').slug + '.' + Knack.domain + '/' + Knack.app.get('slug') + '#';

      $('#view-pages-dropdown ul').children().remove();
      _.each(scenes, function(scene) {
        $('#view-pages-dropdown ul').append(`
          <li class="clearfix">
            <a href="${url}${scene.get('slug')}" target="_blank" rel="noopener">
              <span class="icon icon-page-fill"></span>
              ${scene.get('name')}
            </a>
          </li>`)
      });

    },

    renderAppName: function() {

      $('#app-title h1 a').text(Knack.app.get('name'));

      //var font_size = (Knack.app.get('name').length  < 22) ? '19px' : '18px';
      //$('#app-title h1 a').css({'font-size':font_size});

      $('#app-title-text').text(Knack.app.get('name'));
      $('#app-view-link').attr('href', 'http://' + Knack.app.get('account').slug + '.' + Knack.domain + '/' + Knack.app.get('slug')).attr('target', '_blank');
    },

    // used for title/description forms for views
    renderCopy: function($copy, vals) {

      $copy.find('.view-copy > div').children().hide();

      if (vals.title) {
        $copy.find('.view-name .edit').show();
        $copy.find('h3').html(vals.title);
      } else {
        $copy.find('.view-name a.add').show();
      }

      if (vals.description) {
        $copy.find('.view-description .edit').show();
        $copy.find('.view-description p').html(vals.description);
      } else {
        $copy.find('.view-description a.add').show();
      }

      $copy.find('.view-copy a.edit, .view-copy a.add').click(function(event) {
        event.preventDefault();
        $(this).hide();
        $(this).parent().find('.input').show();

        var val = $(this).parent().find('.edit').children().html();
        $(this).parent().find('.value').val(val);
      });

      $copy.find('.btn').click(function(event) {
        event.preventDefault();

        var val = $(this).closest('.input').find('.value').val();

        $(this).closest('.input').hide();

        if (val) {
          $(this).parent().parent().find('.edit').children().html(val);
          $(this).parent().parent().find('.edit').show();
        } else {
          $(this).parent().parent().find('.add').show();
        }

      });

      $copy.find('.icon').click(function(event) {

        event.preventDefault();
        event.stopImmediatePropagation();

        $(this).closest('.input').hide();
        var val = $(this).parent().parent().find('.edit').children().html();

        if (val) {
          $(this).parent().parent().find('.edit').children().html(val);
          $(this).parent().parent().find('.edit').show();
        } else {
          $(this).parent().parent().find('.add').show();
        }

      });

    },

    initializeLoad: function() {
      if (Knack.mode == 'builder') {

        // bind on object statuses
        Knack.objects.off(null, null, this);
        Knack.objects.on('change:status', function(model) {
          if (model.get('status') == 'current') {
            if (model.previousAttributes().status == 'convert-field') {
              this.postMessage({
                type: 'conversion',
                object: {
                  key: model.id
                },
                message: 'The ' + model.get('name') + ' conversion has completed!'
              }, {
                object_key: model.get('key')
              });
            }
          }
        }, this);

      }
    },

    initializeUsers: function() {

      // bind user
      if (Knack.user) {
        Knack.user.off(null, null, this);
        Knack.user.on('destroy', this.handleLoggedOut, this);
        Knack.user.on('login', this.handleLoggedIn, this);
      }
    },

    storeClick: function(event) {
      if ($(event.currentTarget).attr('href') && $(event.currentTarget).attr('href').indexOf('http') == -1) {

        var date = new Date();

        this.clicks.unshift({
          href: $(event.currentTarget).attr('href'),
          'class': $(event.currentTarget).attr('class'),
          'id': $(event.currentTarget).attr('id'),
          'date': date.toLocaleString()
        });
        if (this.clicks.length > 10) {
          this.clicks.pop();
        }
      }
    },

    handleClickHelpHint: function(event) {
      event.preventDefault();

      var which = $(event.currentTarget).attr('href').substr(1);

      if (!which) {
        return;
      }

      content = {
        add_object: '<p>An <strong>object</strong> defines the records your app will manage.  Think of it like a spreadsheet or a database table.</p><p>For example, if you want to track contact names and emails, add an object called <em>Contacts</em>.  <a href="http://support.knackhq.com/knowledge_base/topics/working-with-objects-and-fields" target="_blank">more</a></p>',
        equation_date: '<p>The <strong>date type</strong> is used to convert any number values in the equation before being calculated.</p>',
        equation_date_total: '<p>The <strong>result type</strong> is used to format the equation result.  If set to Number, the Date Type (hour, week, etc.) will be used.</p>',
        add_child_scene: '<p>A <strong>menu</strong> will be added to this scene that links to the new child scene.</p>'
      };

      $(event.currentTarget).popover({
        content: '<h1>' + $(event.currentTarget).attr('title') + '</h1><div class="kn-help-tip" style="padding:12px; width: 300px;">' + content[which] + '</div>'
      });
    },

    renderErrorMessage: function(event, response) {
      event || (event = {
      });
      response || (response = {
      });

      // Test for error legitimacy
      if (response.data && response.data.responseText) {

        if (response.data.responseText === 'Rate limit exceeded') {
          return;
        } else if (response.data.responseText.indexOf('({') > -1) {
          var e = $.parseJSON(response.data.responseText.substring(response.data.responseText.indexOf('({')+1, response.data.responseText.length-2));
          // natural form errors, so ignore so these can be handled by form
          if (e && e.errors) {
            return;
          }
        }
      }

      // close spinner, hide popups
      $('#kn-loading-spinner').hide();
      $('#modal').trigger('reveal:close');
      if ($('body').length) {
        $('body').click();
      }

      if (response.data && response.data.responseText && response.data.responseText.indexOf('pending') > -1) {

        var html = '<h1>Your account is no longer active!</h1>'
                + '<div><div style="padding: 25px">'
                + '<p style="margin-bottom: 12px;">Uh oh, it looks like your account is no longer active.</p>'
                + '<p style="margin-bottom: 12px;">Please <a href="/#billing" class="underline">update your billing information</a> and subscribe to a <a target="_blank" class="underline" href="http://knackhq.com/pricing">plan</a> so you can continue.</p>'
                + '</div></div>';

      } else {
        var html = '<h1>Technical Issue</h1>'
                + '<div><div style="padding: 25px">'
                + '<p style="margin-bottom: 12px;">Oops, it looks like there was a problem with your last request.</p>'
                + '<h2 style="margin-bottom: 12px;" class="fuschia">Please refresh your browser and try again.</h2>'
                + '<p style="margin-bottom: 12px;">If the problem persists, please <a href="mailto:support@knackhq.com" class="underline">contact support immediately</a> so we can investigate further.</p>'
                + '<a href="#" class="underline" onclick="location.reload(); return false;">Refresh</a></p></div></div>';

      }

      // show new popup
      Knack.renderModal(html, {
        class: 'medium'
      });
    },

    renderBannerMessaging: function() {

      // display global banner message
      Toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: 'toast-top-full-width',
        preventDuplicates: true,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '0',
        extendedTimeOut: '0',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        tapToDismiss: false,
        containerId: 'toast-container-banner-message',
        iconClasses: {
          info: 'toast-info banner-message'
        }
      }

      const bannerMessage = `<a href="https://www.knack.com/knackcon/chicago2019" target="_blank"><img src="https://www.knack.com/images/knackcon/chicago2019/knackcon-chi-banner.svg"></a><div class="kn-text-container"><div class="kn-text-row"><strong>October 2–4<sup>th</sup></strong></div><div class="kn-text-row"><a href="https://www.knack.com/knackcon/chicago2019" target="_blank">Register now</a> <span class="kn-text-details"></span></div></div>`

      // display toast with message
      Toastr['info'](bannerMessage)

      // set initial cookie to track this banner message
      $.cookie(`banner_message_clicked`, false, { expires: 14 })

      var $bannerMessageToastContainer = $('#toast-container-banner-message')

      // manually push content beneath the toast, it would otherwise float over the navigation
      $bannerMessageToastContainer.prependTo(`body`).css({ position: 'relative' })

      // when toast is clicked set cookie to store that it has been seen
      $bannerMessageToastContainer.find(`.toast-close-button`).on(`click`, function(event) {

        $.cookie(`banner_message_clicked`, true, { expires: 14 })
      })
    },

    handleClickLogout: function(event) {
      event.preventDefault();

      $('#kn-loading-spinner').show();

      CookieUtil.destroyCookie({
        key: 'knack-builder'
      });

      Knack.user.destroy({
        wait: true
      });
    },

    handleClickReferFriend: function(event) {

      event.preventDefault();
      Knack.showSpinner();

      $.ajax({
        type: 'GET',
        url: Knack.api_url + '/v1/affiliates/' + Knack.account.id + '/referfriend',
        dataType: 'JSON',
        success: function(response) {

          Knack.hideSpinner();

          if (_.isEmpty(response)) {

            return;
          }

          var options = {
          };

          Knack.renderOverlayTemplate('template_refer_friend', template_refer_friend, options, response);

          // add copy to clipboard
          new Clipboard('#referral-url-copy').on('success', function(event) {

            new ToolTip({
              target: document.getElementById('referral-url-copy'),
              position: 'top center',
              content: 'Copied!',
              openOn: 'click'
            }).open();
          });
        },
        error: function(response) {

          log('error! response was:');
          log(response);

          Knack.hideSpinner();
        }
      });
    },

    handleLoggedIn: function(event) {
      if ((Knack.mode == 'builder' || Knack.mode == 'dashboard') && !Knack.modal_login) {  // modal login is when they are active but the session has expired
        Knack.registerLogin();
        var t=setTimeout(function() {

          if (window.location.href.indexOf('redirect=') != -1) {

            var href = window.location.href.split('redirect=');
            var href = href[0] + '#' + href[1];
            window.location.href = href;

          } else {
            window.location.reload();
          }
        }, 500);
      }
    },

    handleLoggedOut: function(event) {
      $('#kn-loading-spinner').hide();
      window.location.href = '/';
    },

    renderBetaInfo: function() {

      if (!Knack.user.getAccountStatus() || Knack.user.getAccountStatus() !== 'beta' || !Knack.user.getBetaDeadline()) {

        return;
      }

      var deadline = new Date(Knack.user.getBetaDeadline());
      var today = new Date();
      var time_left = (deadline.getTime() - today.getTime())/(24*60*60*1000);
      var days_left = Math.floor(time_left);
      if (today.getHours()/24 + (time_left - days_left) > 1) {
        days_left++;
      }

      if (days_left < 6) {

        if (days_left < 0) {
          left = 'Your free trial has expired!';
        } else {
          var left = '';
          if (days_left == 0) {
            left = 'Less than a day is';
          } else if (days_left == 1) {
            left = 'Only one day ';
          } else {
            left = 'Only ' + days_left + ' days ';
          }
          left += ' left in your free trial!';
        }

        showNotification({
          type: 'information',
          message: left + ' &nbsp;<a href="/' + Knack.app.get('account').slug + '/#billing">Select a plan</a> to keep using your app.',
          showAfter: 0
        });

        if ($('.kn-notify-top').is(':visible')) {

          $('#knack-content').addClass('kn-notify-top-visible')
        }
      }
    },

    renderBillingInfo: function() {

      var billing = Knack.user.get('billing');
      if (!Knack.user.getBillingID() || !billing || billing.status !== 'delinquent' || !billing.notice_count || billing.notice_count <= 2) {

        return;
      }

      showNotification({
        type: 'information',
        message: 'We had trouble running your credit card for your latest payment.&nbsp;Please <a href="/' + Knack.app.get('account').slug + '/#billing">update your card info</a> to keep using your app.',
        showAfter: 2
      });
    },

    checkAppStatus: function() {

      var status;
      if (Knack.app && Knack.app.get) {
        status = Knack.app.get('status');
      }

      if (Knack.getProductionMode() == 'production' && status) {
        if (status == 'cancelled') {

          $('.kn-content').html('<div style="padding: 40px;"><p class="alert-message block-message error" style="padding-bottom: 12px; ">This app has been cancelled.</p></div>');

          return false;

        } else if (status == 'frozen') {

          $('.kn-content').html('<div style="padding: 40px;"><p class="alert-message block-message error" style="padding-bottom: 12px; ">This app has been frozen.<br /><br />You must <a href="/#billing" style="text-decoration:underline;">unfreeze this account</a> to activate the app.</p></div>');

          return false;

        } else if (status == 'pending') {
          var pending_reason = Knack.app.get('pending_reason');
          if (pending_reason == 'no payment') {

            $('.kn-content').html('<div style="padding: 40px;"><p class="alert-message block-message error" style="padding-bottom: 12px; ">This app has been suspended because the free beta trial has expired.<br /><br />You must <a href="/#billing" style="text-decoration:underline;">select a plan</a> to activate the app.</p></div>');

          } else if (pending_reason == 'bad card') {

            $('.kn-content').html(`<div style="padding: 40px;"><p class="alert-message block-message error" style="padding-bottom: 12px; ">There is a problem with this app. Please contact the app's admin for additional information.</p></div>`);
          }

          return false;
        }
        return true;

      }
      return true;

    },

    renderLoginScene: function(msg) {
      // check for cookie!
      CookieUtil.checkCookie({
        cookie_key: 'knack-builder',
        success: function() {
          window.location.reload(true);
        },
        failure: function() {
          window.location.reload(true);
        },
        no_cookie: function() {
          // hide left col
          $('#leftcol').hide();

          // attach wrapper
          var view = {
            title: '',
            description: '',
            registration_type: 'closed'
          };

          var view_model = Knack.user;

          if (!view_model && Knack.getProductionMode() === 'development') {
            var view_view = new SupportLoginView({
              model: {
              },
              el: '#knack-login'
            });
            view_view.render();
            return;
          }

          view_model.setView(view);
          var view_view = new LoginView({
            model: view_model,
            el: '#login-form'
          });
          view_view.render();

          // hide loader
          $('#kn-loading-spinner').hide();
          $('#content-wrapper') .show();

          // show first input
          $('#email').focus();
        }
      });
    },

    renderForgotPassword: function(msg) {

      // hide left col
      $('#leftcol').hide();

      $('#knack-login').html('<div class="kn-view kn-form" id="knack-reset-pass" />');
      var view_model = new Account();
      var view_view = new AccountView({
        model: view_model,
        el: '#knack-reset-pass'
      });
      view_view.renderForgotPassword();

      $('#knack-reset-pass h2').remove();
      $('#knack-reset-pass').append('<p style="margin-top: 15px; margin-bottom: 0px;"><a href="/">back</a></p>');

    },

    renderResetPassword: function(token) {

      $('#kn-loading-spinner').show();

      var model = new Backbone.Model();

      model.urlRoot = Knack.api + '/password/reset/confirm/' + token;

      model.fetch({
        success: function(success_model, result) {

          $('#kn-loading-spinner').hide();
          $('#knack-login').html('<div class="kn-view kn-form" id="knack-reset-pass" />');

          var view_model = new Account(result.user);
          var view_view = new AccountView({
            model: view_model,
            el: '#knack-reset-pass'
          });

          view_view.renderResetPassword({
            url: Knack.api + '/password/reset/' + token
          });

          $('#knack-reset-pass').append('<p style="margin-top: 15px; margin-bottom: 0px;"><a href="/">back</a></p>');
        },
        error: function(response_model, result) {

          var error_html = '';

          if (result.responseText === 'Rate limit exceeded') {

            error_html = '<p><strong>' + Knack.trans('pass_reset_exceeded') + '</strong></p>';

          } else {

            var e = $.parseJSON(result.responseText.substring(result.responseText.indexOf('({')+1, result.responseText.length-2));

            _.each(e.errors, function(error) {
              error_html += '<p><strong>' + error.message + '</strong></p>';
            });
          }

          Knack.hideSpinner();

          $('#login-form').empty();

          $.utility_forms.renderMessage($('#login-form'), error_html, 'error');

          $('.kn-message.error').css('margin-top', '18px');
          $('.close').hide();
        }
      });
    },

    checkAuthentication: function() {

      // check for login
      if (Knack.mode == 'dashboard') {
        if (Knack.user && Knack.user.id && !Knack.user.isNew()) {
          $('#dashboard-wrapper').html(Knack.render('template_dashboard', template_dashboard));
          $('#user-welcome a').html(Knack.user.get('first_name'));

        } else {
          $('#dashboard-wrapper').html(Knack.render('template_login', template_login));
        }
      } else if (Knack.mode == 'builder') {

        if (Knack.user && Knack.user.id) {
          $('#builder-wrapper').html(Knack.render('template_dashboard', template_builder, {}));
          $('#user-welcome a').html(Knack.user.get('first_name'));

          var url = 'http://' + Knack.app.get('account').slug + '.' + Knack.domain + '/' + Knack.app.get('slug') + '#';
          $('#view-app-link').attr('href', url);

        } else {
          $('#builder-wrapper').html(Knack.render('template_login', template_login));
        }
      }

    },

    error_callback: function() {
    },

    // used when saving models from a modal to close or alert of error
    modal_callbacks: {
      success: function(model, response) {

        // close modal/popover
        Knack.hideSpinner();
        Knack.closeModal();
        $(body).click();

        // enable any button
        $('.save.disabled').removeClass('disabled');

        // handle changes
        if (response.changes) {
          Knack.handleChanges(response.changes);
        }
      },
      error: this.error_callback,
      wait: true
    },
    overlay_callbacks: {
      success: function(model, response) {

        // close modal/popover
        Knack.hideSpinner();
        Knack.closeOverlay({
          navigate: false
        });

        // handle changes
        if (response.changes) {
          Knack.handleChanges(response.changes);
        }
      },
      error: this.error_callback,
      wait: true
    },

    addscene_callbacks: {
      success: function(model, response) {

        if (response.new_scenes) {
          _.each(response.new_scenes, function(new_scene) {
            Knack.scenes.add(new_scene);
          });

          Knack.router.refreshScene();
        }

      },
      error: this.error_callback
    },

    clearMessages: function(msg) {
      $('#messages ul').html('<li id="messages-empty"><p><em>No messages</em></p></li>');
      $('#messages').css({
        top: '0',
        opacity: '.0',
        'max-height': '34px'
      });
    },

    postMessage: function(msg, job_data) {

      log('postMessage!'); log(msg);

      var _this = this;

      // remove empty
      $('#messages-empty').remove();

      var title;
      switch (msg.type) {

        case 'import':
          title = 'Import Complete';
          break;

        case 'conversion':
          title = 'Field Conversion Complete';
          break;

        case 'batch':
          title = 'Batch Complete';
          break;

        default:
          title = msg.title;
          break;
      }

      var date = new Date();
      var parts = date.toTimeString().split(':');
      msg.date = parts[0] + ':' + parts[1];

      this.messages.push(msg);

      Knack.dispatcher.trigger('knack.message', msg, job_data);
      Knack.dispatcher.trigger('knack.message:' + ((job_data && typeof job_data === 'object') ? job_data.object_key : job_data), msg, job_data);

    },

    handleClickViewApp: function(event) {

      event.preventDefault();

      // check if there's an interface.
      if (!Knack.objects.length) {

        this.renderEmptyObjects();

      } else if (Knack.scenes.getEmpty()) {
        this.renderEmptyInterface();

      } else {
        window.open($(event.currentTarget).attr('href'), 'View App');
      }

    },

    handleClickPublishApp: function(event) {

      // check if there's an interface.
      if (!Knack.objects.length) {

        event.preventDefault();
        this.renderEmptyObjects();

      } else if (Knack.scenes.getEmpty()) {

        event.preventDefault();
        this.renderEmptyInterface();

      }
    },

    handleClickInterface: function(event) {
      if (!Knack.objects.length) {

        event.preventDefault();
        this.renderEmptyObjects();

      }
    },

    renderEmptyObjects: function() {

      var html = '<h1>Not so fast<span class="close-modal icon-cancel"></span></h1><div class="wrapper"><p>Before you can add any pages you need to <strong>define your database</strong>.</p>'
                + '<p class="bottom-margin-double">Then come back here to view and update the records in your database with forms, tables, and more.</p>'
                + '<a href="#data" class="btn continue"><em>Got it!</em</a></div>';

      Knack.renderModal(html, {
        class: 'medium'
      });

    },

    renderEmptyInterface: function() {

      var html = '<h1>Not so fast<span class="close-modal icon-cancel"></span></h1><div class="wrapper"><p>You need to add a <strong>page</strong> first so you have something to view.</p><p class="bottom-margin-double">You can add tables and calendars to view your <strong>' + Knack.objects.at(0).get('inflections').singular + '</strong> records or forms to add a new <strong>' + Knack.objects.at(0).get('inflections').singular + '</strong>.</p><a href="#pages" class="btn continue">Add a pge</a><</div>';

      Knack.renderModal(html, {
        class: 'medium'
      });
    },

    // used to get a hash of values from a standard form, like edit object
    getFormValues: function(selector, input_selector) {
      input_selector || (input_selector = ':input');
      var obj = {
      };
      $(selector).find(input_selector).each(function() {

        if ($(this).attr('name')) {
          var val = $(this).val();

          // convert booleans
          if (($(this).is('select') || $(this).is(':checkbox')) && (val == 'true' || val == 'false')) {
            val = (val == 'true') ? true : false;
          } else if ($(this).is(':checkbox')) {
            val = $(this).prop('checked');
          } else if ($(this).is(':radio')) {
            val = $(selector).find(':input[name=' + $(this).attr('name') + ']:checked').val();
            if (val == 'true' || val == 'false') {
              val = (val == 'true') ? true : false;
            }
          }
          obj[$(this).attr('name')] = val;
        }
      });
      return obj;
    },

    // used to populate a standard form area
    setFormValues: function(selector, vals, input_selector) {
      if (!vals) {
        return;
      }
      input_selector || (input_selector = ':input');

      $(selector).find(input_selector).each(function() {

        if (typeof vals[$(this).attr('name')] != 'undefined') {
          var val = vals[$(this).attr('name')];

          // convert booleans
          if (($(this).is('select') || $(this).is(':radio')) && (val === true || val === false)) {
            val = (val === true) ? 'true' : 'false';
          }

          if ($(this).is(':checkbox') && (val === true || val === false)) {
            //val = (val === true) ? 'on' : 'off';
            if (val) {
              $(this).prop('checked', true);
            } else {
              $(this).prop('checked', false);
            }
          } else if ($(this).is(':radio')) {

            $(selector).find(':input[name="' + $(this).attr('name') + '"][value="' + val + '"]').attr('checked', 'checked');

          } else {
            // set
            $(this).val(val);

          }

        }
      });
    },

    handleTriggerLoadForm: function(event) {
      //$(event.currentTarget).after('<img class="kn-spinner" src="https://cdn1.cloud-database.co/builder/images/spinner.gif">');
      $(event.currentTarget).siblings('.cancel, .back').hide();
      $(event.currentTarget).siblings('.kn-spinner').show();
      $(event.currentTarget).addClass('disabled');
    },

    handleClickDisabled: function(event) {
      event.stopImmediatePropagation();
      return false;
    },

    handleClickHelp: function(event) {
      event.preventDefault();

      Knack.renderModalTemplate('template_help', template_help, {
        class: 'small'
      }, {
      });

      $('.help-chat').click(function(event) {
        event.preventDefault();
        if (Knack.user && Knack.user.get('email')) {
          SnapABug.setUserEmail(Knack.user.get('email'));
        }
        SnapABug.startLink();
      });
    },

    renderHelpTour: function(which) {

      // track
      /*
      if (Knack.getProductionMode() == 'production' && mixpanel) {
        var track = Knack.mixpanel_track;
        track.help_type = which;
        mixpanel.track('help', track);
      }*/

      // render
      if (which == 'data') {
        var html = Knack.render('template_help_data', template_help_data);
      } else if (which == 'interface') {
        var html = Knack.render('template_help_interface', template_help_interface);
      }

      $.fancybox(
        html,
        {
          'autoDimensions': true,
          'width': 'auto',
          'height': 'auto',
          'transitionIn': 'none',
          'transitionOut': 'none'
        }
      );

      $('#help-tour li a').addClass('tour-off').find('.content').hide();
      $('#help-tour li:first a').removeClass('tour-off').addClass('active').find('.content').show();

      $('#help-tour li a').hover(function(event) {
        $('#help-tour li a').addClass('tour-off').removeClass('active').find('.content').hide();
        $(this).removeClass('tour-off').addClass('active').find('.content').show();

        var pos = Number($(this).attr('href').substr(1));

        background = pos * 393 - 393;

        $('#help-tour .tour-image').css({
          'background-position': '0px -' + background + 'px'
        });
      });
      $('#help-tour li a').click(function(event) {
        event.preventDefault();
      });

      $('.tour-trigger-close').click(function() {
        $.fancybox.close();
        return false;
      });
    },

    getDetailLinks: function(object_key, scene_parent) {

      var links = [];

      var entry_scenes = Knack.scenes.getEntryScenes();
      var order = String(_.map(entry_scenes, function(scene) {
        return scene.get('name');
      }));

      //_.each(entry_scenes, function(scene) {

      Knack.scenes.each(function(scene) {

        if (scene.get('object') == object_key) {

            // let's include this scene!
          var scenes = [scene.get('name')];

          var parent = scene.get('parent');

          while (parent) {
            var parent = Knack.scenes.getBySlug(parent);
            if (parent) {
              if (parent.get('type') == 'authentication') {
                parent = false;
              } else {
                scenes.unshift(parent.get('name'));
                parent = parent.get('parent');
              }
            } else {
              parent = false;
            }
          }

          var label = '';
          var l = scenes.length,
            count = 0;
          _.each(scenes, function(scene) {
            label += scene;
            if (++count < l) {
              label += ' > ';
            }
          });

            //if (!scene_parent || (scene.get('parent') != scene_parent))
          links.push({
            label: label,
            scene: scene.get('slug'),
            key: scene.get('key')
          });

        }
      }, this);

      // sort
      if (links) {
        links = _.sortBy(links, function(link) {
          var split = link.label.split(' > ');
          return order.indexOf(split[0]);
        });
      }

      return links;

    }
  });
});
