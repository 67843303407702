define(['jquery'], function(jQuery) {

// jquery plugin
(function($) {

// inlineDropDown Class
var Accordion = {

  // init() ---------------
  init:function(options, elem) {

    var _this = this;

    // set options
    this.options = $.extend({}, this.options, options);

    // set vars
    this.$elem = $(elem);

    if ( this.$elem.hasClass('open') ) this.$elem.find('.accordion-subsection').show();

    log(' > > > Showing accordion opens: ' + this.$elem.find('.open .accordion-subsection').length);

    // click
    this.$elem.find('.accordion-expand, h3 > a').off().on('click', function(event) {

      _this.$elem.find('.accordion-subsection').slideToggle('fast');

      if (_this.$elem.hasClass('open')) {
        _this.$elem.find('.accordion-expand').text('edit');
        _this.$elem.removeClass('open');
      } else {
        _this.$elem.find('.accordion-expand').text('close');
        _this.$elem.addClass('open');
      }

    });

  }
};

// jquery plugin
$.fn.extend({
  accordion: function(options) {
    return this.each(function() {

      // create object, init, and add bridge
      var myAccordion = Object.create(Accordion);
      myAccordion.init(options, this);
      $(this).data('accordion', myAccordion);

      return this;
    });
  }
});
})(jQuery);

});