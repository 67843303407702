define([
  'jquery'
], function(jQuery) {

  return (function($) {

    // Private methods

    /**
     * Redirect to the parent page of the currently active page. If the page is rendered in a modal then the modal will be closed and any views on the parent page will refresh.
     *
     * @api private
     */

    var _redirectToParentPage = function() {

      log('redirectToParentPage()!');

      // check for modal
      var current_scene = Knack.scenes.getBySlug(Knack.router.current_scene);
      var is_modal = current_scene.get('modal');

      // modal: simply close
      if (is_modal && Knack.modals && Knack.modals.length) {

        Knack.closeModal();

        var to = setTimeout(function() {

          Knack.showSpinner();
          Knack.router.scene_view.renderViews();

        }, 500);

      // non-modal, navigate back
      } else {

        // get link
        var link = Knack.getPreviousScene().link;

        // navigate!
        var to = setTimeout(function() {
          window.location = link;
        }, 500);
      }
    };

    /**
     * Redirects to a child page using a record ID of the active page. This assumes the child page is a details page that knows about the same object source.
     *
     * @param {String} scene - the slug of the scene to redirec to
     * @param {String} [record_id] - the slug of the scene to redirec to
     * @api private
     */

    var _redirectToChildPage = function(scene_slug, record_id) {

      // set link
      var url_parts = window.location.href.split(Knack.hash_token);
      var link = url_parts[0];

      link += Knack.getSceneHash();

      // get scene
      if (link[link.length-1] != '/') {
        link += '/';
      }
      link += scene;

      // add id if parent
      if (record_id) {
        link += '/' + record_id;
      }

      var to = setTimeout(function() {
        window.location = link;
      }, 500);
    };

    /**
     * Redirects to an existing page using the slug of the page.
     *
     * @param {String} scene_slug - The slug of the scene to redirect to.
     * @param {String} scene_object
     * @param {String} [record_id]
     * @returns {boolean} - True if the redirect will happen, false otherwise.
     * @private
     */
    const _redirectToExistingPage = (scene_slug, scene_object, record_id) => {

      var url_parts = window.location.href.split(Knack.hash_token);

      // set link
      var link = url_parts[0] + Knack.hash_token;

      const sceneModel = Knack.scenes.getBySlug(scene_slug)

      if (!sceneModel) {
        return false;
      }

      // get scene
      var scene = sceneModel.toJSON();

      if (scene.parent) {
        var parent = Knack.scenes.getBySlug(scene.parent).toJSON();
        if (parent.parent) {
          var parent_scene = Knack.scenes.getBySlug(parent.parent).toJSON();
          if (!parent_scene.type || parent_scene.type != 'authentication') {
            link += parent.parent + '/';
          }
        }
        if (!parent.type || parent.type != 'authentication') {
          link += scene.parent + '/';
        }
      }

      link += scene_slug;

      // add id if parent
      if (scene.object && scene.object == scene_object && record_id) {
        link += '/' + record_id;
      }

      setTimeout(() => {
        window.location = link;
      }, 1000);

      return true;
    };

    // Constructor

    var Navigation = function() {
      log('Knack.Navigation constructor()');
    };

    // Public methods

    Navigation.prototype = {

      redirectToParentPage: function() {
        return _redirectToParentPage();
      },

      redirectToChildPage: function(scene_slug, record_id) {
        return _redirectToChildPage(scene_slug, record_id);
      },

      redirectToExistingPage: function(scene_slug, scene_object, record_id) {
        return _redirectToExistingPage(scene_slug, scene_object, record_id);
      },

      redirectToURL: function(url) {
        var to = setTimeout(function() {
          window.location = url;
        }, 500);
      }
    };

    Navigation.prototype.constructor = Navigation;
    return Navigation;

  })(jQuery);

});
