define([
  'jquery', 'backbone', 'underscore'
], function($, Backbone, _) {
  return Backbone.Model.extend({

    idAttribute: 'key',
    dirty: null, // just save these attributes

    initialize: function() {
      this.attributes.label = this.setLabel(this.attributes.type);

      if (this.attributes.type == 'search') {
        if (this.attributes.groups.initial) {

          this.attributes.groups = [{
            columns: [{
              fields: this.attributes.groups.initial
            }]
          }];
        }
      }
    },

    parse: function(response) {
      return response.view;
    },

    save: function(attrs, options) {
      if (attrs) {
        this.dirty = attrs;
      }
      Backbone.Model.prototype.save.call(this, attrs, options);
    },

    toJSON: function() {
      if (this.dirty) {
        var json = _.clone(this.dirty);
        this.dirty = null;
      } else {
        var json = _.clone(this.attributes);
      }
      return json;
    },

    setLabel: function(str) {
      if (!str) {
        return '';
      }
      switch (str) {
        case 'rich_text':
          return 'Rich Text';
          break;
        default:
          return str.charAt(0).toUpperCase() + str.substr(1);
          break;
      }
    },

    setChildScenes: function() {

      var _this = this;

      _this.attributes.child_scenes = [];
      _this.attributes.child_views = [];
      _this.attributes.child_child_views =[];

      const pushActionLinkChildScenes = (action_rules) => {

        for (let action_rule of action_rules) {

          _.each(action_rule.submit_rules, function(rule) {
            if (rule.action == 'child_page' && rule.scene) {
              this.attributes.child_scenes.push(rule.scene);
            }
          }, this);
        }
      };

      // add any child scenes
      switch (this.get('type')) {

        case 'form':
          _.each(this.get('groups'), function(group) {
            _.each(group.columns, function(col) {
              _.each(col.inputs, function(input) {
                if (input.view) {
                  this.attributes.child_views.push(input.view);
                }

              }, this);
            }, this);
          }, this);

          if (this.get('rules')) {
            _.each(this.get('rules').submits, function(rule) {
              if (rule.action == 'child_page' && rule.scene) {
                this.attributes.child_scenes.push(rule.scene);
              }
            }, this);
          }
          break;

        case 'menu':
          _.each(this.get('links'), function(link) {
            if (link && link.type && link.type == 'scene') {
              this.attributes.child_scenes.push(link.scene);
            }
          }, this);
          break;

        case 'table':
          _.each(this.get('columns'), function(column) {
            if (column.type == 'link') {
              this.attributes.child_scenes.push(column.scene);
            } else if (column && column.conn_link) {
              var scene = Knack.scenes.getByKey(column.conn_link);
              if (scene) {
                this.attributes.child_scenes.push(scene.get('slug'));
              }
            } else if (column && column.type === 'action_link') {

              if (!column.action_rules) {

                return;
              }

              pushActionLinkChildScenes(column.action_rules);
            }
          }, this);
          break;

        case 'search':
          _.each(this.get('results').columns, function(column) {

            if (column.type == 'link') {
              this.attributes.child_scenes.push(column.scene);
            }

            if (column && column.type === 'action_link') {

              if (!column.action_rules) {

                return;
              }

              pushActionLinkChildScenes(column.action_rules);
            }

            _.each(column.groups, function(group) {
              _.each(group.columns, function(group_col) {
                _.each(group_col, function(field) {
                  if (field.type == 'scene_link') {
                    this.attributes.child_scenes.push(field.scene);
                  } else if (field && field.conn_link) {
                    var scene = Knack.scenes.getByKey(field.conn_link);
                    if (scene) {
                      this.attributes.child_scenes.push(scene.get('slug'));
                    }
                  }
                }, this);
              }, this);
            }, this);
          }, this);
          break;

        case 'list':
        case 'details':
          _.each(this.get('columns'), function(column) {
            _.each(column.groups, function(group) {
              _.each(group.columns, function(group_col) {
                _.each(group_col, function(field) {
                  if (field && field.type == 'scene_link') {
                    this.attributes.child_scenes.push(field.scene);
                  } else if (field && field.conn_link) {
                    var scene = Knack.scenes.getByKey(field.conn_link);
                    if (scene) {
                      this.attributes.child_scenes.push(scene.get('slug'));
                    }
                  } else if (field && field.type === 'action_link') {

                    if (!field.action_rules) {

                      return;
                    }

                    pushActionLinkChildScenes(field.action_rules);
                  }
                }, this);
              }, this);
            }, this);
          }, this);
          break;

        case 'calendar':
        case 'map':
          _.each(this.get('details').columns, function(column) {
            _.each(column.groups, function(group) {
              _.each(group.columns, function(group_col) {
                _.each(group_col, function(field) {
                  if (field.type == 'scene_link') {
                    this.attributes.child_scenes.push(field.scene);
                  } else if (field && field.conn_link) {
                    var scene = Knack.scenes.getByKey(field.conn_link);
                    if (scene) {
                      this.attributes.child_scenes.push(scene.get('slug'));
                    }
                  } else if (field && field.type === 'action_link') {

                    if (!field.action_rules) {

                      return;
                    }

                    pushActionLinkChildScenes(field.action_rules);
                  }
                }, this);
              }, this);
            }, this);
          }, this);

          if (this.get('type') == 'calendar') {
            _.each(this.get('form').groups, function(group) {
              _.each(group.columns, function(col) {
                _.each(col.inputs, function(input) {

                  if (input.view) {
                    this.attributes.child_views.push(input.view);
                  }

                }, this);
              }, this);
            }, this);
          }
          break;

        case 'report':
          _.each(this.get('rows'), function(row) {
            _.each(row.reports, function(report) {
              if (report.child_page) {
                this.attributes.child_scenes.push(report.child_page.scene);
              }
            }, this);
          }, this);
          break;

        case 'login':
          var login_scene = Knack.scenes.getByKey(_this.get('scene').key);
          var scene_slug = login_scene.get('slug');

          Knack.scenes.each(function(scene) {
            if (scene.get('parent') == scene_slug) {
              _this.attributes.child_scenes.push(scene.get('slug'));
            }
          });

          var registration_forms = _this.get('registration_forms');

          _.each(registration_forms, function(registration_form) {

            _this.attributes.child_views.push(registration_form);

            var registration_view = _.find(login_scene.get('views'), function(view) {
              return view.key === registration_form;
            });

            if (registration_view) {

              _.each(registration_view.groups, function(group) {
                _.each(group.columns, function(col) {

                  if (col && col.inputs) {
                    _.each(col.inputs, function(input) {
                      if (input.view) {
                        _this.attributes.child_child_views.push({
                          parent: registration_form,
                          child: input.view
                        });
                      }
                    });
                  }
                });
              });

              if (registration_view.rules && registration_view.rules.submits && registration_view.rules.submits.length > 0) {

                _.each(registration_view.rules.submits, function(rule) {
                  if (rule.action === 'child_page' && rule.scene && _this.attributes.child_scenes.indexOf(rule.scene) === -1) {
                    _this.attributes.child_scenes.push(rule.scene);
                  }
                });
              }
            }
          });
          break;
      }
    }
  });
});
