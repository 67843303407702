define([
  'jquery', 'backbone', 'underscore',
  'core/backbone/collections/Fields',
  'core/backbone/collections/Tasks',
  'core/lib/poller'
], function($, Backbone, _, Fields, Tasks, Poller) {
  return Backbone.Model.extend({
    idAttribute: 'key',
    fields: null,
    tasks: null,
    conns: null,
    dirty: null, // just save these attributes

    initialize: function() {

      this.setFields(this.attributes.fields, this.attributes.key);

      this.tasks = new Tasks(this.attributes.tasks);
      this.tasks.url = Knack.api_dev + '/objects/' + this.attributes.key + '/tasks';

      // combine connections for convenience
      this.setConnections(this.attributes.connections);

      var _this = this;

      Knack.dispatcher.on('csv.import.complete:' + this.attributes.key, function(data) {
        _this.set({
          status: 'current'
        });
      });

    },

    getIdentifier: function() {
      if (this.get('identifier')) {
        return this.get('identifier');
      }
      return this.fields.at(0).get('key');

    },

    setConnections: function(connections) {
      var conns = Array();
      if (connections) {
        _.each(connections.outbound, function(conn) {
          conn.relationship_type = 'local';
          conns.push(conn);
        }, this);
        _.each(connections.inbound, function(conn) {
          conns.push({
            belongs_to: conn.has,
            field: conn.field,
            has: conn.belongs_to,
            key: conn.key,
            name: conn.name,
            object: conn.object,
            relationship_type: 'foreign'
          });
        }, this);
      }
      this.set({
        conns: conns
      });

      return conns;
    },

    setFields: function(fields, key) {

      if (this.fields) {
        this.fields.reset(fields, {
          silent: true
        });
      } else {
        this.fields = new Fields(fields);
      }
      this.fields.url = Knack.api_dev + '/objects/' + key + '/fields';

      this.fields.each(function(field) {
        field.set({
          'object_key': key
        }, {
          silent: true
        });
      }, this);
    },

    save: function(attrs, options) {
      if (attrs) {
        this.dirty = attrs;
      }
      Backbone.Model.prototype.save.call(this, attrs, options);
    },

    parse: function(response) {
      var obj = response.object || response;
      if (obj.fields) {
        this.setFields(obj.fields, obj.key);
      }

      if (obj.status && obj.status != 'current') {
        this.addPoller();
      }

      obj.conns = this.setConnections(obj.connections);

      this.fields.url = Knack.api_dev + '/objects/' + obj.key + '/fields';
      return obj;
    },

    addPoller: function() {
      var poller = new Poller();
      var object = this;
      poller.id = this.get('key');
      poller.url = Knack.api_dev + '/objects/' + this.get('key');
      poller.poll = function() {
        var _poller = this;
        try {
          this.fetch(
            {
              success: function(model, response) {
                if (!response.object || response.object.status == 'current') {
                  Knack.poll.removePoller(_poller.id);
                  object.set({
                    status: 'current'
                  });
                }
              }
            });
        } catch (err) {
          Knack.poll.removePoller(this.id);
        }
      };
      Knack.poll.addPoller(poller);
    },

    toJSON: function() {
      if (this.dirty) {
        var json = _.clone(this.dirty);
        this.dirty = null;
      } else {
        var json = _.clone(this.attributes);
      }
      json.fields = this.fields.toJSON();
      return json;
    },

    updateFieldSort: function(new_order) {
      var keys = ',' + new_order.toString() + ',';
      this.fields.comparator = function(model) {
        return keys.indexOf(',' + model.get('key') + ',');
      };
      this.fields.sort();

      delete this.fields.comparator;
    },

    updateTaskSort: function(new_order) {
      var keys = ',' + new_order.toString() + ',';
      this.tasks.comparator = function(model) {
        return keys.indexOf(',' + model.get('key') + ',');
      };
      this.tasks.sort();
      delete this.tasks.comparator;
    },

    updateTasks: function() {
      this.tasks = new Tasks(this.get('tasks'));
      this.tasks.url = Knack.api_dev + '/objects/' + this.attributes.key + '/tasks';
    }
  });
});
