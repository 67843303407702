<template>
  <div class="kn-migration-dashboard-list_container">
    <ul
      v-if="filteredApplications.length > 0"
      class="kn-migration-dashboard-list fancy-scrollbar"
    >
      <ListItem
        v-for="app in filteredApplications"
        :key="app.app_id"
        :app="app"
        :selected-application-ids="selectedApplicationIds"
        :now="now"
      />
    </ul>
    <div v-else>
      <p>Your filter returned no results.</p>
    </div>
  </div>
</template>

<script>
import ListItem from 'dashboard/components/MigrationApps/ApplicationListItem.vue';

export default {
  components: {
    ListItem,
  },

  props: {
    applications: {
      type: Array,
      required: true,
    },
    selectedApplicationIds: {
      type: Array,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
    now: {
      type: Object,
      required: true,
    },
  },

  computed: {
    filteredApplications() {
      if (this.filter === 'all') {
        return this.applications;
      }
      return this.applications.filter(
        (application) => application.migration_status === this.filter,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$top-nav-height: 48px;
$dashboard-instructions-section-height: 104px;
$dashboard-toolbar-margin-top: 24px;
$height-offset: $top-nav-height + $dashboard-instructions-section-height + $dashboard-toolbar-margin-top;

.kn-migration-dashboard-list_container {
  height: calc(100% - #{$height-offset});
  margin-top: 0px;
}

.kn-migration-dashboard-list {
  height: 100%;
  padding-right: 8px;

  > * {
    margin-bottom: 16px;
  }
}
</style>
