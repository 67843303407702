define([
  'jquery',
  'underscore',
  'core/lib/backbone',
  'core/backbone/views/BaseView',
  'dashboard/templates/builders.html',
  'dashboard/templates/builder-edit.html',
  'dashboard/templates/builder-delete.html',
  'builder/ui/tooltip'
], function($, _, Backbone, BaseView, template_builders, template_buildersEdit, template_builderRemove, ToolTip) {

  return BaseView.extend({

    app: {
    },
    builders: [],

    initialize: function(options) {

      log('options!'); log(options);
      if (options && options.app) {

        this.app = options.app;
      }
    },

    render: function() {

      log('BUILDERSVIEW.render()!');

      //this.$el.html( Knack.render('template_builders', template_builders) );
      Knack.renderModalTemplate('template_builders', template_builders, {
        class: 'sevensixty'
      });

      this.setElement('#app-share');

      log('app name length: ' + this.$('.app-name').length);

      this.$builder_master = this.$('#builders-list > li').detach();

      // app name
      this.$('.app-name').text(this.app.name);

      // eventify
      $('#add-builder-link').off().on('click', $.proxy(this.handleClickAddBuilder, this));
      this.$builder_master.find('.delete-builder-link').on('click', $.proxy(this.handleClickRemoveBuilder, this));

      // get vars for checking builder permissions
      var plan = Knack.account.product_plan;

      var builder_count = Knack.account.counts.builders;

      log('plan: ' + plan.builders + '; builder_count: ' + builder_count);

      // empty
      if (plan.builders === 1 && Knack.getProductionMode() !== 'development') {

        $('#builders-list').html('<div class="alert-message">'
          + '<p>Your plan currently only allows for <strong>1 builder</strong> to access this account.</p>'
          + '<p>You can share this app with other builders by <a href="/#billing" class="underline">upgrading to a higher plan</a>.</p>'
          + '</div>');

        $('#add-builder-link').remove();
      }

      return this;
    },

    fetch: function() {

      var plan = Knack.account.product_plan;

      if (plan.builders === 1) {
        return;
      }

      log('fetch()!');

      var _this = this;

      Knack.showSpinner();

      $('#builders-list').html('<p>loading builders...</p>');
      $('#add-builder-link').hide();

      var model = new Backbone.Model();

      model.url = Knack.api + '/applications/' + this.app.id + '/builders';

      log('saving URL to app_id: ' + this.app.id);

      model.fetch({
        success: function(event, result) {

          Knack.hideSpinner();

          log('success!'); log(arguments);

          _this.builders = result.builders;

          _this.populate();
        },
        error: function() {

        }
      });
    },

    populate: function() {

      log('populate()!');

      $('#add-builder-link').show();
      $('#builders-list').empty();

      if (this.builders && this.builders.length) {

        _.each(this.builders, function(builder) {

          this.addBuilder(builder);
        }, this);
      }
    },

    addBuilder: function(builder) {

      builder = builder || {
      };

      var $builder = this.$builder_master.clone(true);

      this.setProperties($builder, builder);

      log('addBuilder, adding builder to list:');
      log(builder);
      //log($('#builders-list'));

      $('#builders-list').append($builder);
    },

    setProperties: function($builder, builder) {

      $builder.find('.builder-name').html(builder.first_name + ' ' + builder.last_name);
      $builder.find('.builder-email').html('<a href="mailto:' + builder.email + '">' + builder.email + '</a>');

      $builder.data('builder', builder);

      $builder.find('.tooltip').each(function() {

        log('>>>> >>>> >>> TOOL TIP!');

        new ToolTip({
          target: this
        });
      });
    },

    handleClickAddBuilder: function(event) {

      event.preventDefault();
      var _this = this;

      log('add builder, app:');
      log(this.app);

      // render modal
      Knack.renderModalTemplate('template_buildersEdit', template_buildersEdit, {
        class: 'medium'
      }, {
        user: {
        }
      });

      // save
      $('#submit-save-builder').off().on('click', $.proxy(function(event) {

        event.preventDefault();

        log('saving!');

        var vals = {
          first_name: $('#builder-properties input[name=first_name]').val(),
          last_name: $('#builder-properties input[name=last_name]').val(),
          email: $('#builder-properties input[name=email]').val()
        };

        Knack.showSpinner();

        var model = new Backbone.Model(vals);
        model.url = Knack.api + '/applications/' + this.app.id + '/builders';

        log('saving URL to app_id: ' + this.app.id);

        model.save({
        }, {
          success: function(event, result) {
            log('success! arguments:');
            log(arguments);

            if (result.builderAlreadyExistsForApplication) {

              $('#builder-properties').html('<p>This builder has already been granted rights to edit this app.<p><a href="#" class="medium btn btn-outline cancel">Close</a>')

              Knack.hideSpinner()

              return
            }

            _this.addBuilder(result.builder);

            Knack.account.counts.builders = result.builder_count;

            $('#builder-properties').html('<p>Success! This builder has been granted rights to edit this app.<p><a href="#" class="medium btn btn-outline cancel">Close</a>');

            Knack.hideSpinner();
          },
          error: function(result, xdm) {

            log(`BuildersView.handleClickAddBuilder error`)
            log(`arguments:`)
            log(arguments)

            const responseJson = $.parseJSON(xdm.responseText.substring(xdm.responseText.indexOf('({') + 1, xdm.responseText.length - 2));

            log(`responseJson:`, responseJson)

            const error = responseJson.errors[0]

            if (!error) {

              Knack.hideSpinner()

              return
            }

            let messageToDisplay = ``

            log(`error:`, error)

            switch (error.message) {

              case `shared builder limit reached`:

                $('#add-builder-link').before('<div class="alert-message">'
                  + '<p>You\'ve reached the limit of <strong>' + Knack.account.product_plan.builders + ' builders</strong> included in your plan.</p>'
                  + '<p>You can add a new builder by <a href="/#billing" class="underline">upgrading to a higher plan</a> or removing access for an existing builder.</p>'
                  + '</div>');

                $('#add-builder-link').hide();

                Knack.closeModal()

                break
              case `invalid email`:

                messageToDisplay = `<p><strong>${error.translatedMessage}</strong></p>`

                $.utility_forms.renderMessage($(`#builder-properties .form-modal`), messageToDisplay, 'error');

                $(`#submit-save-builder`).removeClass(`disabled`)

                break

              case `first_name required`:

                messageToDisplay = `<p><strong>First Name ${error.translatedMessage}</strong></p>`

                $.utility_forms.renderMessage($(`#builder-properties .form-modal`), messageToDisplay, 'error');

                $(`#submit-save-builder`).removeClass(`disabled`)

                break
              case `last_name required`:

                messageToDisplay = `<p><strong>Last Name ${error.translatedMessage}</strong></p>`

                $.utility_forms.renderMessage($(`#builder-properties .form-modal`), messageToDisplay, 'error');

                $(`#submit-save-builder`).removeClass(`disabled`)

                break
              case `email is account owner`:

                messageToDisplay = `<p><strong>This application is owned by that email address.</strong></p>`

                $.utility_forms.renderMessage($(`#builder-properties .form-modal`), messageToDisplay, 'error');

                $(`#submit-save-builder`).removeClass(`disabled`)

                break
            }

            Knack.hideSpinner()

            return false;
          }
        });
      }, this));
    },

    handleClickRemoveBuilder: function(event) {

      var _this = this;

      var $builder = $(event.currentTarget).closest('li');
      var builder = $builder.data('builder');

      log('REMOVING!');

      // show confirmation
      Knack.renderModalTemplate('template_builderRemove', template_builderRemove, {
        user: {
        }
      }, 'medium');

      $('#submit-remove-builder').click(function(event) {

        event.preventDefault();
        Knack.showSpinner();

        var model = new Backbone.Model(builder);
        model.url = Knack.api + '/applications/' + _this.app.id + '/builders/' + builder.id;

        log('destroy URL: ' + builder.id);

        model.destroy({
          success: function(event, result) {
            log('success!');
            Knack.hideSpinner();

            $builder.remove();

            // decrement local count
            Knack.account.counts.builders = result.builder_count;

            $('#builder-delete').html('<p>This builder can no longer edit this app.<p><a href="#" class="medium btn btn-outline cancel">Close</a>');
          },
          error: function() {
            log('error!');
          }
        });
      });
    }
  });
});
