<template>
  <header
    :class="['knHeader', ...headerClasses]"
    ref="headerElement"
    v-if="shouldRenderHeader"
  >
    <div class="kn-container">
      <div class="knHeader__content">
        <!-- Logo/title -->
        <a
          v-if="shouldRenderTitleLogoArea"
          class="knHeader__title-logo-wrapper"
          :href="`#${homeSlug}`"
          :title="title"
          @click="onTitleLogoClick"
        >
          <div
            v-if="showLogo"
            :class="['knHeader__logo', `knHeader__logo--${headerDesign.logo.type}`]"
          >
            <i
              v-if="headerDesign.logo.type === 'icon'"
              :class="`fa fa-${getSanitizedIcon(logoIcon)}`"
            />
            <img
              v-if="headerDesign.logo.type === 'custom' && logoImageUrl"
              :src="logoImageUrl"
              alt="Application Logo"
              class="knHeader__logo-image"
            >
          </div>
          <h1
            v-if="showTitle"
            class="knHeader__title"
          >
            {{ title }}
          </h1>
        </a>

        <!-- Page menu -->
        <div
          v-if="showPageMenu"
          :class="['knHeader__menu', `knHeader__menu--${pageMenuFormat}`]"
        >
          <nav class="knHeader__menu-nav">
            <ul class="knHeader__menu-list">
              <li
                v-for="page in entryPages"
                :key="page.key"
                :class="[
                  'knHeader__menu-list-item',
                  {'knHeader__menu-list-item--dropdown': page.type === 'menu'},
                ]"
              >
                <!-- If page has dropdown menu, render dropdown page links -->
                <template v-if="page.type === 'menu'">
                  <DropdownMenu
                    :entry-page="page"
                    :dropdown-pages="page.dropdown_pages"
                    :dropdown-toggle-classes="pageMenuLinkClasses"
                    :get-active-link-class="getActiveLinkClass"
                  />
                </template>

                <!-- Otherwise, render regular page menu link -->
                <a
                  v-else
                  :href="`#${page.slug}`"
                  :class="['knHeader__menu-link', pageMenuLinkClasses, getActiveLinkClass(page.slug)]"
                >
                  <span
                    v-if="page.icon && page.icon.icon && page.icon.align === 'left'"
                    class="knHeader__menu-link-icon knHeader__menu-link-icon--left"
                  >
                    <i :class="`fa fa-${getSanitizedIcon(page.icon.icon)}`"/>
                  </span>

                  {{ page.name }}

                  <span
                    v-if="page.icon && page.icon.icon && page.icon.align === 'right'"
                    class="knHeader__menu-link-icon knHeader__menu-link-icon--right"
                  >
                    <i :class="`fa fa-${getSanitizedIcon(page.icon.icon)}`"/>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <!-- Mobile nav toggle -->
        <div
          v-if="showPageMenu"
          class="knHeader__mobile-controls"
        >
          <button
            class="knHeader__mobile-nav-toggle"
            @click="toggleMobileNav"
          >
            <i v-if="isMobileNavOpen" class="fa fa-times"/>
            <i v-else class="fa fa-bars"/>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile nav -->
    <MobileNav
      :is-open="isMobileNavOpen"
      :close-mobile-nav="closeMobileNav"
      :get-active-link-class="getActiveLinkClass"
    />
  </header>
</template>

<script>
import { sanitizeIcon } from 'core/ui/helpers/vue-helper';
import DropdownMenu from './pieces/DropdownMenu.vue';
import MobileNav from './pieces/MobileNav.vue';

export default {
  components: { DropdownMenu, MobileNav },
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  computed: {
    headerClasses() {
      const classes = [];

      if (this.headerDesign.options.sticky) {
       classes.push('knHeader--sticky');
      }
      if (this.headerDesign.options.raised) {
        classes.push('knHeader--raised');
      }
      if (this.headerDesign.options.bottomBorder.active) {
        classes.push('knHeader--bottom-border');
      }
      if (this.isMobileNavOpen) {
        classes.push('knHeader--mobile-nav-open');
      }
      if (!this.showPageMenu) {
        classes.push('knHeader--no-page-menu');
      }

      // Embedded app specific classes
      if (this.isEmbedded && this.embedHeaderDesign === 'menu') {
        // Necessary to render an embedded app's header with a transparent background
        classes.push('knHeader--no-background');
      }

      return classes;
    },

    // Render specific computed values
    shouldRenderHeader() {
      // Only display header if we have something to render
      return this.showPageMenu || this.shouldRenderTitleLogoArea;
    },
    shouldRenderTitleLogoArea() {
      // If this app is not embedded, only display the title/logo area if there is a title or
      // a logo to display
      if (!this.isEmbedded) {
        return this.showTitle || this.showLogo;
      }

      // If this app is embedded, determine if the title/logo area should be displayed based on the
      // embed 'Header & Page Menu' design setting in the builder for this particular embed
      if (this.isEmbedded && this.embedHeaderDesign === 'fullHeader') {
        return this.showTitle || this.showLogo;
      }

      return false;
    },

    // Title
    title() {
      if (this.headerDesign.title.isCustom) {
        return this.headerDesign.title.text;
      }
      return Knack.app.get('name');
    },
    showTitle() {
      return this.headerDesign.title.show;
    },

    // Logo
    logoIcon() {
      return this.headerDesign.logo.icon && this.headerDesign.logo.icon.icon;
    },
    logoImageUrl() {
      return this.headerDesign.logo.image && this.headerDesign.logo.image.url;
    },
    showLogo() {
      return this.headerDesign.logo.show;
    },

    // Page Menu
    showPageMenu() {
      // Don't render the page menu if no top scene exists
      if (!this.topScene) {
        return false;
      }

      // Evaluates the "Display the Page Menu when viewing this page" option set in the builder (Page > Settings).
      // We need to use the top scene here, as it controls the page menu settings for any children scenes.
      // If the current scene is already the top-most scene, then current scene and top scene will be equivalent.
      const displayMenuForThisPage = (typeof this.topScene.page_menu_display === 'undefined')
        || this.topScene.page_menu_display;

      // We need more than one entry scene to show the page menu, unless it's a dropdown menu
      const hasEnoughEntryPages = (this.entryPages && (this.entryPages.length > 1))
        || (this.entryPages && this.entryPages.length === 1 && this.entryPages[0].type === 'menu');

      return hasEnoughEntryPages && displayMenuForThisPage && this.headerDesign.menu.show;
    },
    pageMenuFormat() {
      return this.headerDesign.menu.format;
    },
    pageMenuLinkClasses() {
      const classes = [];

      if (this.headerDesign.menu.format === 'buttons') {
        classes.push('knHeader__menu-link--button');
      } else if (this.headerDesign.menu.format === 'tabs') {
        classes.push('knHeader__menu-link--tab');
      } else {
        classes.push('knHeader__menu-link--text');
      }

      return classes;
    },

    // Live App specific computed values
    headerDesign() {
      const appDesign = Knack.app.get('design');
      return appDesign.regions.header;
    },
    homeSlug() {
      return this.$store.getters.homeSlug;
    },
    currentScene() {
      return this.$store.getters.currentScene;
    },
    topScene() {
      return this.$store.getters.topScene;
    },
    entryPages() {
      return this.$store.getters.entryPages;
    },
    hashScenes() {
      return this.$store.getters.hashScenes;
    },

    // Embedded app specific computed values
    isEmbedded() {
      return this.$store.getters.isEmbedded;
    },
    embedHeaderDesign() {
      return this.$store.getters.distributionHeaderDesign; // 'menu' | 'menuWithBackgroundColor' | 'fullHeader'
    },
  },
  methods: {
    onTitleLogoClick() {
      this.isMobileNavOpen = false;
    },
    openMobileNav() {
      this.isMobileNavOpen = true;

      // Prevent scrolling on body while mobile nav is open
      const bodyElement = document.getElementById('knack-body');
      if (bodyElement) {
        bodyElement.style.overflow = 'hidden';
      }
    },
    closeMobileNav() {
      this.isMobileNavOpen = false;

      // Set overflow on body element back to inherit
      const bodyElement = document.getElementById('knack-body');
      if (bodyElement) {
        bodyElement.style.overflow = 'inherit';
      }
    },
    toggleMobileNav() {
      if (this.isMobileNavOpen) {
        this.closeMobileNav();
      } else {
        this.openMobileNav();
      }
    },

    // Live App specific methods
    getActiveLinkClass(pageSlug) {
      if (pageSlug === this.hashScenes[0].slug && !this.currentScene.modal) {
        return 'knHeader__menu-link--active';
      }
      return '';
    },
    getSanitizedIcon(icon) {
      return sanitizeIcon(icon);
    },
  },
};
</script>
