 define([
   'jquery', 'backbone', 'underscore'
 ], function($, Backbone, _) {
   return Backbone.Model.extend({
     idAttribute: 'key',
     dirty: null, // just save these attributes

     initialize: function() {
       Knack.fields[this.id] = this;
     },

     parse: function(response) {
       var task = response.task || response;
       return task;
     },

     save: function(attrs, options) {
       options || (options = {
       });
       if (attrs) {
         this.dirty = attrs;
       }

       Backbone.Model.prototype.save.call(this, attrs, options);
     },

     toJSON: function() {
       if (this.dirty) {
         var json = _.clone(this.dirty);
         this.dirty = null;
         return json;
       }
       return _.clone(this.attributes);

     }
   });
 });
