define([
  'core/lib/backbone',
  'core/backbone/models/Scene'
], function(Backbone, Scene) {
  return Backbone.Collection.extend({
    model: Scene,

    initialize: function() {
      this.url = Knack.api_dev + '/scenes';

      //this.setProperties();
    },

    setProperties: function() {
      if (Knack.app.get('users').enabled) {
        // set authenticated attributes and entry scenes
        this.each(function(scene) {
          scene.setProperties();
        });
      }
    },

    getBySlug: function(slug) {
      return this.find(function(scene) {
        return scene.attributes.slug == slug;
      });
    },

    getByKey: function(key) {
      return this.find(function(scene) {
        return scene.attributes.key == key;
      });
    },

    getEntryScenes: function(options) {

      var entry_scenes = [];

      options || (options = {
      });

      // first get any login scenes
      this.each(function(scene) {

        var parent_scene = false;

        if (options.login === false && scene.get('parent')) {

          parent_scene = Knack.scenes.getBySlug(scene.get('parent'));
        }

        if (options.login === false || !scene.get('parent')) {

          if (options.login !== false && (scene.get('type') == 'authentication' || scene.get('type') == 'menu')) {

            // options.login = true will include each login
            if (options.login && scene.get('type') == 'authentication') {

              entry_scenes.push(scene);
            } else if (options.menu && scene.get('type') == 'menu') {

              entry_scenes.push(scene);
            } else {

              this.each(function(child) {

                if (child.get('parent') == scene.get('slug')) {

                  if (child.get('views') && child.get('views').length) {

                    var is_ecom_child = false;

                    _.each(child.get('views'), function(child_view) {

                      if (!child_view) {

                        return;
                      }

                      if (child_view.type === 'checkout') {

                        is_ecom_child = true;
                      }
                    });

                    if (is_ecom_child) {

                      return;
                    }
                  }

                  entry_scenes.push(child);
                }
              }, this);
            }
          } else if (options.login === false && parent_scene && parent_scene.get('type') === 'authentication') {

            entry_scenes.push(scene);
          } else if (options.login === false && !parent_scene && (Knack.app.get('users').scope !== 'application' || (Knack.app.get('users').scope === 'application' && scene.get('type') !== 'authentication'))) {

            entry_scenes.push(scene);
          } else if (options.login !== false && scene.get('type') !== 'authentication') {

            entry_scenes.push(scene);
          }
        }
      }, this);

      return entry_scenes;

    },

    // checks if there's anything to show in the interface
    getEmpty: function() {

      if (this.models.length > 1) {
        return false;
      }

      if (this.at(0).views.length) {
        return false;
      }

      return true;
    },

    findViewBySceneKey: function(viewKey, sceneKey) {

      const currentScene = this.getByKey(sceneKey)

      if (!currentScene) {

        return
      }

      const view = currentScene.views.get(viewKey)

      if (!view) {

        const parentSceneKey = this.getBySlug(currentScene.get(`parent`)).get(`key`)

        return this.findViewBySceneKey(viewKey, parentSceneKey)
      }

      return view
    },

    updateSort: function(new_order) {
      var keys = new_order.toString();
      this.comparator = function(model) {
        return keys.indexOf(model.get('key'));
      };
      this.sort();
      delete this.comparator;

      this.trigger('change');
    }
  });
});
