var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        "is-modal-active": _vm.isModalActive,
        "save-label": _vm.saveLabel,
        "display-cancel-button": _vm.displayCancelButton,
      },
      on: {
        save: _vm.closeSuccessModal,
        cancel: _vm.closeSuccessModal,
        "click:background": _vm.closeSuccessModal,
      },
    },
    [
      _c("template", { slot: "content" }, [
        _c("p", [
          _vm._v("Upgrade successfully rescheduled to occur on "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.date) } }),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }