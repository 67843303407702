define([
  'jquery',
  'backbone',
  'underscore',
  'core/store',
  'core/lib/inactivity_timeout',
], function($, Backbone, _, $store, InactivityTimeout) {

  return Backbone.Model.extend({
    view: null,

    initialize: function() {
      this.urlRoot = Knack.api_dev;

      // shared builder with no account
      if (Knack.account && Knack.account.non_account) {
        this.urlRoot += '/shared';
      }
      this.urlRoot += '/session/';
      if (Knack.mode == 'builder') {
        this.urlRoot += 'builder/';
      }
    },

    parse: function(result) {
      if (result.using_2fa) {

        return result
      }

      if (result.password_reset_url) {

        return window.location.href = result.password_reset_url;
      }

      if (Knack.mode == 'renderer' || Knack.mode == 'builder') {

        return result.session.user;
      }

      return result.account.session.user;
    },

    setView: function(view) {
      this.view = view;
    },

    getProfileKeys: function() {
      var profile_keys = [];
      _.each(this.get('profile_keys'), function(profile) {
        if (profile) {
          if (profile.object) {
            var key = Knack.objects.get(profile.object).get('profile_key');
          } else {
            var key = profile;
          }
          profile_keys.push(key);
        }
      });
      return profile_keys;
    },

    hasFeature: function(feature) {
      // NOTE: The plans-helper.hasPlanType() function has been inlined here to remove the dependency
      // on plans-helper. That dependency exposes many customer names in the minified javascript, but
      // it was only used for this one function call.
      // return plans_helper.hasPlanType(Knack.account.billing.plans, 'feature', feature);
      return Knack.account.billing.plans.some((plan) => plan.type === 'feature' && plan.id.includes(feature));
    },

    getAccountStatus: function() {
      return Knack.account.status || 'beta';
    },

    getBilling: function() {

      return Knack.account.billing || {
        status: 'current'
      };
    },

    getBillingID: function() {

      return Knack.account.billing.customer_id;
    },

    getBetaDeadline: function() {
      return Knack.account.beta_deadline;
    },

    fetch: function(attributes, options) {

      const model = this

      const originalUrlRoot = model.urlRoot

      if (!model.urlRoot.includes(`/token/`)) {

        model.urlRoot += `token/`
      }

      attributes = {}

      //  We want to wrap the passed in success function with our own
      const passedInSuccess = options.success
      options.success = (_model, resp, xhr) => {

        model.urlRoot = originalUrlRoot
        passedInSuccess && passedInSuccess(_model, resp, xhr)

        if (resp.using_2fa) {

          //TODO: in the future, send back token instead of email & password
          return _model.trigger(`login_2fa`, {'email': _model.attributes.email,
                                             'password': _model.attributes.password})
        }

        _model.set(`id`, _model.attributes._id)

        // trigger logged in?
        if (_model.id) {

          _model.trigger(`login`, _model, resp)

          new InactivityTimeout(Knack.app.attributes)

          // Update Support Access Tool banner
          if (Knack.mode === `renderer`) {

            $store.dispatch(`supportAccessTool/login`)
          }
        }
      };

      Backbone.Model.prototype.fetch.call(this, options)
    },

    save: function(attrs, options) {

      var _this = this;
      var original_url_root = this.urlRoot;
      if (Knack.isOnNonKnackDomain() && attrs.token && !this.urlRoot.includes(`/token/`)) {

        this.urlRoot += `token/`
      } else if (attrs.utility_key) {

        this.urlRoot += 'cookie/';
      }

      var success = options.success;
      var model = this;
      options.success = function(model, resp, xhr) {

        _this.urlRoot = original_url_root;
        if (success) {
          success(model, resp, xhr);
        }

        if (resp.using_2fa) {

          //TODO: in the future, send back token instead of email & password
          return model.trigger('login_2fa', {'email': model.attributes.email,
                                             'password': model.attributes.password})
        }

        model.id = model.attributes.id

        // trigger logged in?
        if (model.attributes.id) {
          model.trigger('login', model, resp);
          new InactivityTimeout(Knack.app.attributes);

          // Update Support Access Tool banner
          if (Knack.mode === `renderer`) {

            $store.dispatch(`supportAccessTool/login`)
          }
        }
      };

      Backbone.Model.prototype.save.call(this, attrs, options);
    }
  });
});
