var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        ref: "dropdownToggle",
        class: [
          "knHeader__menu-dropdown-toggle knHeader__menu-link",
          _vm.dropdownToggleClasses,
          _vm.getActiveLinkClass(_vm.entryPage.slug),
        ],
        on: { click: _vm.toggleDropdownMenu },
      },
      [
        _vm.entryPage.icon && _vm.entryPage.icon.icon
          ? _c(
              "span",
              {
                staticClass:
                  "knHeader__menu-link-icon knHeader__menu-link-icon--left",
              },
              [
                _c("i", {
                  class: `fa fa-${_vm.getSanitizedIcon(
                    _vm.entryPage.icon.icon
                  )}`,
                }),
              ]
            )
          : _vm._e(),
        _vm._v("\n    " + _vm._s(_vm.entryPage.name) + "\n    "),
        _vm._m(0),
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        ref: "dropdownList",
        class: [
          "knHeader__menu-dropdown-list",
          { "knHeader__menu-dropdown-list--open": _vm.isDropdownMenuOpen },
        ],
      },
      [
        _vm._l(_vm.dropdownPages, function (dropdownPage) {
          return [
            _c(
              "li",
              {
                key: dropdownPage.key,
                staticClass: "knHeader__menu-dropdown-list-item",
              },
              [
                _c(
                  "a",
                  {
                    class: [
                      "knHeader__menu-link knHeader__menu-dropdown-link",
                      { "knHeader__mobile-nav-link": _vm.isMobileDropdown },
                      _vm.getActiveLinkClass(dropdownPage.slug),
                    ],
                    attrs: { href: `#${dropdownPage.slug}` },
                    on: { click: _vm.onLinkClick },
                  },
                  [
                    dropdownPage.icon &&
                    dropdownPage.icon.icon &&
                    dropdownPage.icon.align === "left"
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "knHeader__menu-link-icon knHeader__menu-link-icon--left",
                          },
                          [
                            _c("i", {
                              class: `fa fa-${_vm.getSanitizedIcon(
                                dropdownPage.icon.icon
                              )}`,
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n\n          " +
                        _vm._s(dropdownPage.name) +
                        "\n\n          "
                    ),
                    dropdownPage.icon &&
                    dropdownPage.icon.icon &&
                    dropdownPage.icon.align === "right"
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "knHeader__menu-link-icon knHeader__menu-link-icon--right",
                          },
                          [
                            _c("i", {
                              class: `fa fa-${_vm.getSanitizedIcon(
                                dropdownPage.icon.icon
                              )}`,
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "knHeader__menu-link-icon knHeader__menu-link-icon--right",
      },
      [_c("i", { staticClass: "fa fa-angle-down" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }