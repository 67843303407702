define(['jquery',  'underscore'], function(jQuery, _) {

(function($){

	var knSearch = {
		options: {},

		init: function(options,el) {
			this.$el = $(el);
			this.$cancel = this.$el.parent().find('.icon-cancel');
			this.options = $.extend({}, options, this.options);

			// bind events
			this.$el.on('blur', $.proxy(this.handleInputBlur, this));
			this.$el.on('focus', $.proxy(this.handleInputFocus, this));
			this.$el.on('keyup', $.proxy(this.handleInputKeyUp, this));
			this.$cancel.on('click', $.proxy(this.handleCancelClick, this));
		},

		handleInputBlur: function(event) {
			event.preventDefault();

			if (this.$el.val().length === 0) {
				this.$cancel.click();
				Knack.hideSpinner();
				return;
			}
		},

		handleInputFocus: function(event) {
			//event.preventDefault();

			log('handleInputFocus()');

			if (this.$el.val() == 'Search...') {
				this.$el.val('');
				this.$cancel.hide();
			}
		},

		handleInputKeyUp: function(event) {
			event.preventDefault();

			var q = this.$el.val();

			if (q.indexOf('Search...') === 0) {
				q = q.replace('Search...', '');
				this.$el.val(q);
			}

			if (q.length === 0 && this.options && this.options.cancelHandler) {
				this.options.cancelHandler();
			}

			if (q.length > 0) {
				this.$cancel.show();
			}

			if (q.length > 2) {
				log('search handler!');
				if (this.options && this.options.searchHandler) {
					_.debounce(this.options.searchHandler(q), 300);
				}
			}
		},

		handleCancelClick: function(event) {
			event.preventDefault();

			this.$el.val('Search...');
			this.$cancel.hide();
			if(this.options && this.options.cancelHandler) {
				this.options.cancelHandler();
			}
		}
	};

	$.fn.extend({
		knSearch: function(options) {
			return this.each(function() {

				var searchInput = Object.create(knSearch);

				searchInput.init(options, this);

				$(this).data('knSearch', searchInput);

				return this;
			});
		}
	});
}(jQuery));
});
