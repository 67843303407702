define([
  'jquery', 'core/templates/asset-view.html'

], function(jQuery, asset_view_template) {

// jquery plugin
(function($) {

  // popover Class
  var imageGallery = {

    init: function (options, elem, event) {

      var _this = this;

      // set vars
      this.options = $.extend({}, this.options, options);
      var $trigger = $(elem);
      this.$el = $('#kn-overlay');

      this.image_url = $trigger.find('img').attr('data-kn-img-gallery');

      log('image length: ' + $trigger.find('img').length + '; url: ' + this.image_url);

      // multiple images
      this.has_multiple = false;

      // check for the family!
      var $images = $trigger.closest('.kn-view').find('.kn-img-gallery');

      log('total images: ' + $images.length);

      if ($images.length && $images.length > 1) {

        this.images = [];
        this.has_multiple = true;

        var i = 0;

        $images.each(function() {
          var url = $(this).find('img').attr('data-kn-img-gallery');
          _this.images.push(url);

          log('comparin url: ' + _this.image_url + ' with ' + url);
          if (_this.image_url == url) {
            _this.index = i;
          }
          i++;
        });

        log('index! ' + _this.index);
      }

      this.render();
    },

    render: function () {

      var asset = {
        filename: this.image_url,
        file_size: 0
      }

      this.$el.html(Knack.render('asset_view_template', asset_view_template, asset));

      var $preview = $('.kn-asset-content');

      if (Knack.getStyleEngine() === 'v1') {
        $preview.css({
          'text-align': 'center',
          'padding': '0px',
          'width' : '100%',
          'height': '95%',
          'max-height': '95%',
          'overflow-x': 'auto',
          'white-space': 'pre-wrap'
        });
      }

      $preview.html('<img class="kn-asset-img" src="' + this.image_url + '" style="max-height: 100%; max-width: 90%">');

      if (this.has_multiple) {

        $preview.prepend('<a class="kn-asset-prev" href="#"><i class="fa fa-chevron-left"></i></a>');
        $preview.append('<a class="kn-asset-next" href="#"><i class="fa fa-chevron-right"></i></a>');

        $('.kn-asset-prev').on('click', $.proxy(this.handleClickPrev, this) );
        $('.kn-asset-next').on('click', $.proxy(this.handleClickNext, this) );
      }

      this.$el.find('.kn-asset').show();
      this.$el.appendTo('body');
      this.$el.fadeIn('fast');

      $('#kn-asset-header, #kn-asset-footer').remove();

      $('.kn-asset-close').off().on('click', $.proxy(this.closeAssetViewer, this));
    },

    handleClickPrev: function(event) {

      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }

      log('this.index: ' + this.index);

      this.index --;
      if (this.index < 0) this.index = this.images.length - 1;

      log('prev, index: ' + this.index);
      log(this.images);

      this.image_url = this.images[this.index];
      $('.kn-asset-content img').prop('src', this.image_url);
    },

    handleClickNext: function(event) {

      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }

      log('this.index: ' + this.index);

      this.index ++;
      if (this.index > this.images.length - 1) this.index = 0

      log('prev, index: ' + this.index);
      log(this.images);

      this.image_url = this.images[this.index];
      $('.kn-asset-content img').prop('src', this.image_url);
    },

    closeAssetViewer: function(event) {

      log('closeAssetViewer()');

      this.$el.hide();
      this.$el.empty();

      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    },
  };

  $.fn.extend({
    ImageGallery: function(options, event) {
      return this.each(function() {
        // create object, init, and add bridge
        var image_gallery = Object.create(imageGallery);
        image_gallery.init(options, this, event);
        $(this).data('ImageGallery', image_gallery);
      });
    }
  });
})(jQuery);

});
