define([
  'jquery',
  'core/ui/drop'
], function($, Drop) {

  var knPopover = {

    init: function(options, el) {

      var _this = this;

      this.options = $.extend({}, options, this.options);
      this.$el = $(el);

      this.id = this.$el.prop('id');

      var content = this.options.content;

      if (this.options.warning) {
        content = '<h1>Warning<span class="close-popover icon-cancel"></span></h1>'
                + '<div class="wrapper medium">'
                + '<p>' + this.options.warning + '</p>'
                + '</div>';
      }
      if (this.options.notice) {
        content = '<div class="wrapper small">'
                + this.options.notice
                + '</div>';
      }
      if (this.options.validate) {
        content = '<div class="wrapper small warning red-bg">'
                + this.options.validate
                + '</div>';
      }

      var position = this.options.position || 'top center'; // left middle

      this.drop = new Drop({
        target: this.$el[0],
        position: position,
        content: content,
        classes: 'kn-popover',
        remove: true,
        openOn: 'click',
        tetherOptions: {
            constraints: [{
                to: 'window',
                pin: true,
                attachment: 'together'
            }]
        },
        beforeClose: (event) => {

          // Clean classes before closing drop
          const popoverClasses = ['kn-popover', 'drop-target', 'drop-enabled', 'drop-element-attached-bottom', 'drop-element-attached-center', 'drop-target-attached-top', 'drop-target-attached-center', 'drop-target-attached-middle', 'drop-target-attached-left', 'drop-target-attached-right', 'drop-abutted-top', 'drop-abutted', 'drop-element-attached-left', 'drop-element-attached-middle'];
          _this.$el[0].classList.remove(...popoverClasses);

          // Don't compete with calendar repeat modal
          if ($('#kn-calendar-repeat').length > 0) {

            return false
          }

          if (!event) {

            return;
          }

          return !$(event.target).parents('.prevent-close').length
        }
      });

      this.drop.on('close', function(event) {

        var to = setTimeout(function() {
          _this.drop.destroy();
        }, 100)
      });

      // close handler
      $(this.drop.content).find('.close-popover').off().on('click', function(event) {
        _this.drop.close();
      });

      // any buttons handler
      $(this.drop.content).find('.btn').off().on('click', function(event) {

        if (_this.options.click_handler) {
          _this.options.click_handler(event);
        }

        // sometime we don't want to close e.g. we need the DOM to get form values
        if (! $(event.target).hasClass('prevent-close')) {
          _this.drop.close();
        }

      });
    },

    open: function(event) {
      var _this = this;
      log('opening drop: ' + _this.drop);
      _this.drop.open();
    },

    close: function() {
      _this.drop.close();
    },

    position: function() {
      log('position!');
      this.drop.position();
    },

    returnElement: function() {
      return $(this.drop.drop);
    }
  };

  $.fn.extend({
    knPopover: function(options) {
      return this.each(function() {
        /*if ($(this).data('knPopover') ) {
          log('already exists, just open')
        } else {*/
          var myPopover = Object.create(knPopover);
          myPopover.init(options, this);
          $(this).data('knPopover', myPopover);
        //}
        myPopover.open();
      })
    }
  });

  return null;

});
