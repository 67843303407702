var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kn-migration-dashboard-toolbar" },
    [_vm._t("left-item"), _vm._v(" "), _vm._t("right-item")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }