<template>
  <section class="kn-migration-dashboard_intro-container">
    <p>
      The following is the upgrade schedule for each of your applications. Click “Reschedule” to
      change the upgrade time for any app, or use the checkboxes to reschedule multiple apps at once.
    </p>
    <Banner>
      <template slot="content">
        <span>All times are listed as <strong>{{ timezone }}</strong></span>
      </template>
    </Banner>
  </section>
</template>

<script>
import Banner from 'dashboard/components/Banner.vue';

export default {
  components: {
    Banner,
  },

  props: {
    timezone: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../layout.scss";

.kn-migration-dashboard_intro-container {
  @include v-stack("xsmall");

  p {
    margin: 0px;
  }
}
</style>
