<template>
  <div v-if="isModalActive">
    <div
      class="kn-migration-modal-bg"
      @click="clickBackground"
    />
    <div class="kn-migration-modal">
      <div class="kn-migration-modal-header">
        <h1>{{ title }}</h1>
        <span
          class="kn-migration-modal-close icon-cancel"
          @click="clickCancel"
        />
      </div>
      <div class="kn-migration-modal-body">
        <slot name="content" />
      </div>
      <div class="kn-migration-modal-footer">
        <div class="kn-migration-modal-button-container">
          <button
            class="kn-migration-modal-save"
            :disabled="saveDisabled"
            @click="clickSave"
          >
            {{ saveLabel }}
          </button>
          <button
            v-if="displayCancelButton"
            class="kn-migration-modal-cancel"
            @click="clickCancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isModalActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    saveLabel: {
      type: String,
      default: 'Save',
    },
    displayCancelButton: {
      type: Boolean,
      default: true,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['cancel', 'save', 'click:background'],

  methods: {
    clickBackground() {
      this.$emit('click:background');
    },
    clickSave() {
      this.$emit('save');
    },
    clickCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./layout.scss";

$modal-header-height: 48px;

.kn-migration-modal-bg {
  display: block;
  left: 0;
  position: fixed;
  overflow-y: auto;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.kn-migration-modal {
  position: fixed;
  left: 50%;
  max-height: calc(100% - #{$modal-header-height});
  transform: translateX(-50%);
  top: 0;

  overflow: auto;
  width: 600px;

  background-color: #fff;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  z-index: 200;
}

.kn-migration-modal-header {
  display: flex;
  justify-content: space-between;

  background-color: #e5e6ea;

  height: $modal-header-height;
  padding: 0px 1.5rem;

  h1 {
    margin: 0;

    font-size: 1.2em;
    line-height: 2.4em;
  }
}

.kn-migration-modal-close {
  align-self: center;

  height: 32px;
  width: 32px;

  cursor: pointer;

  color: #afb0b7;
  font-size: 32px;
  font-weight: 400;

  &:hover {
    color: #76777d;
  }
}

.kn-migration-modal-body {
  @include v-stack("xsmall");

  padding: 1.5rem;
}

.kn-migration-modal-footer {
  display: flex;
  justify-content: center;

  padding: 0px 1.5rem 1.5rem;
}

.kn-migration-modal-button-container {
  @include h-stack("xsmall");
}

.kn-migration-modal-save {
  background-color: #2ab27b;
  border: 0px;
  border-radius: 0.25rem;

  line-height: 16px;
  padding: 8px 16px;

  color: #fff;
  font-weight: 400;
  font-size: 16px;

  &:hover:not([disabled]) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
  }
  &:disabled {
    opacity: 0.7;
  }
}

.kn-migration-modal-cancel {
  background-color: #fbfbfa;
  border: 1px solid #c7cacd;
  border-radius: 0.25rem;

  line-height: 16px;
  padding: 8px 16px;

  font-weight: 400;
  font-size: 16px;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  }
}
</style>
