define([
  'jquery'
], function(jQuery) {

  return (function($) {

    // Private methods

    /**
     * Redirect to the parent page of the currently active page. If the page is rendered in a modal then the modal will be closed and any views on the parent page will refresh.
     *
     * @api private
     */

    var _hasPermissions = function(role_keys) {

      var profile_keys = Knack.user.getProfileKeys();

      return (_.intersection(profile_keys, role_keys).length > 0);

    };

    // Constructor

    var User = function() {
      log('Knack.User constructor()');
    };

    // Public methods

    User.prototype = {

      hasPermissions: function(role_keys) {
        return _hasPermissions(role_keys);
      }
    };

    User.prototype.constructor = User;
    return User;

  })(jQuery);

});
