var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kn-migration_date-picker-container" },
    [_c("VueFlatPicker", { attrs: { value: _vm.date, config: _vm.config } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }