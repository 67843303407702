<template>
  <Modal
    :title="title"
    :is-modal-active="isModalActive"
    :save-label="saveLabel"
    :display-cancel-button="displayCancelButton"
    @save="closeSuccessModal"
    @cancel="closeSuccessModal"
    @click:background="closeSuccessModal"
  >
    <template slot="content">
      <p>Upgrade successfully rescheduled to occur on <span v-html="date" /></p>
    </template>
  </Modal>
</template>

<script>
import moment from 'moment-timezone';
import { getBrowserTimezoneAbbr } from 'dashboard/utility/timezone';
import EventBus from 'dashboard/components/eventBus';
import Modal from 'dashboard/components/Modal.vue';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      title: 'Reschedule',
      saveLabel: 'Close',
      isModalActive: false,
      displayCancelButton: false,
      date: undefined,
    };
  },

  created() {
    EventBus.$on('renderSuccessModal', this.renderSuccessModal);
  },

  unmounted() {
    EventBus.$off('renderSuccessModal', this.renderSuccessModal);
  },

  methods: {
    renderSuccessModal(date) {
      const dateMoment = moment(date).local();
      this.date = `<b style='font-weight: 400'>${dateMoment.format('MMMM Do')}</b>
        at <b style='font-weight: 400'>${dateMoment.format('h:mma')} ${getBrowserTimezoneAbbr()}</b>`;

      this.isModalActive = true;
    },
    closeSuccessModal() {
      this.isModalActive = false;
    },
  },
};
</script>
