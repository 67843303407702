var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "kn-migration-dashboard_intro-container" },
    [
      _c("p", [
        _vm._v(
          "\n    The following is the upgrade schedule for each of your applications. Click “Reschedule” to\n    change the upgrade time for any app, or use the checkboxes to reschedule multiple apps at once.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "Banner",
        [
          _c("template", { slot: "content" }, [
            _c("span", [
              _vm._v("All times are listed as "),
              _c("strong", [_vm._v(_vm._s(_vm.timezone))]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }