<template>
  <div
    v-if="isLoading"
    class="kn-migration-loading-spinner-bg"
  >
    <div id="kn-loading-spinner" />
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.kn-migration-loading-spinner-bg {
  display: block;
  left: 0;
  position: fixed;
  overflow-y: auto;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
}
</style>
