define(function() {
  var database_fields = [
    {
      title: 'Working with fields',
      copy: 'A comprehensive guide on working with fields.',
      link: 'http://helpdesk.knackhq.com/solution/categories/5000070877/folders/5000083614/articles/5000442081-working-with-fields'
    },

    {
      title: 'All about field types',
      copy: 'Find out how the different field types work',
      link: 'http://helpdesk.knackhq.com/solution/folders/5000083616'
    },

    {
      title: 'Connect your objects together',
      copy: 'Add a connection to link the records from your two objects together',
      link: 'http://helpdesk.knackhq.com/solution/articles/5000443665-working-with-connections',
      when: 'no-connections'
    },

    {
      title: 'Why doesn\'t my new field show up in my app?',
      copy: 'New fields are not automatically added to your interface. You’ll need to edit the views you want to add it to.'
    },

    {
      title: 'About the Object Display Field',
      copy: 'The Object\'s <strong>Display Field</strong> is used when you need a single field to display each record (like in a connection look-up). It should be unique, like a name, ID, or email field.'
    }
  ];
  var display_rules = [
    {
      title: 'When does a Display Rule trigger?',
      copy: 'A Display Rule will trigger whenever it detects a change in your field input criteria.'
    },

    {
      title: 'What is the difference between <i>Show</i> and <i>Show / Hide</i>?',
      copy: '<i>Show / Hide</i> will show a field when the conditions are met, and hide the field when they are not. A <i>Show</i> rule will <strong>just</strong> show a field when the condition is met, but will not hide it when the conditions are not true.'
    }
  ];

  var data_source = [
    {
      title: 'What is the Data Source?',
      copy: 'The data source indicates which object the view is displaying and allows you to add rules to filter the records from that object.  Click <i>"Add rules to filter records"</i> if you only want to display records that match your rules.',
      link: 'http://helpdesk.knackhq.com/solution/articles/5000443618-data-source-which-records-will-the-view-display'
    },
    {
      title: 'What is the difference between match "any" and "all"',
      copy: '"Any" means that any of the rules or groups can match for a record to be included. "All" means that every rule and group must match for a record to be included.'
    },
    {
      title: 'Can I sort records by multiple fields?',
      copy: 'Yes, click on the green "+" icon next to the first sort rule to additional sort rules.'
    },

    {
      title: 'How does the <strong>Limit</strong> option work?',
      copy: 'The <i>Limit</i> option allows you to only display a specific number of records. This is useful when you only want to show the latest x amount of records, for example.'
    }
  ];

  var field_default = {
    title: 'What is the field <strong>default</strong>?',
    copy: 'Any value you enter here will be used as the value for a new record if no other value is given. It will also populate any forms used to add this record.  So instead of the form input being blank, it will contain the default value. The input <strong>does not</strong> need to be in the form to acquire the default value.'
  };

  var field_unique = {
    title: 'How does the <strong>Must be unique</strong> option work?',
    copy: 'This option helps you avoid duplicate record entries by enforcing a single unique value in this field amongst all of the object\'s records.'
  };

  var field_required = {
    title: 'How does the <strong>Required</strong> option work?',
    copy: 'When toggled to <i>Yes</i>, users will be required to enter a value in this field when adding or editing the record in the Interface.'
  };

  var new_field =
    {
      title: 'Why is my new field not showing up in my Interface?',
      copy: 'New fields are not automatically added to your interface. You’ll need to edit each view you want to add it to.'
    };

  var view_header =
    {
      title: 'How do I rename my view\'s header?',
      copy: 'Simply click on the current header to edit it! You can also add your own description right below it.'
    };
  var details_edit = [
    {
      title: 'About the <strong>Label Format</strong>',
      copy: 'The Label Format allows you to individually control a field\'s label format. If using the <i>Group Format</i>, your field will inherit the Label Format used in their <i>Field Group</i>'
    },

    {
      title: 'What is a <strong>Label</strong>?',
      copy: 'The <strong>Label</strong> is displayed next to your field\'s value. You can rename your field for this specific view if you need to.'
    },

    {
      title: 'Using more than 1 <strong>Style</strong> at once',
      copy: 'You can add more than one Style to your field, for example you can make a field both bold and italic.'
    }
  ];
  var details_format = [
    view_header,
    {
      title: 'How can I edit my Fields?',
      copy: 'Hover over your field and click on the pencil icon to access the <i>Style</i> options, <i>Label</i> options, or to rename the label.'
    },

    {
      title: 'How can I hide empty fields?',
      copy: 'You can hide empty fields from your <strong>Options</strong> tab.'
    },

    {
      title: 'How does the <i>Layout</i> option work?',
      copy: 'The <i>Layout</i> option will divide your interface into multiple columns. Each Column can hold additional <strong>Groups</strong>.'
    },

    {
      title: 'What are <strong>Field Groups</strong>?',
      copy: 'Fields are placed into groups which can have separate label and column formats and can enable more flexible layouts. Your view defaults to a single group. Click the Add Group button to additional groups.'
    }
  ];

  var form_inputs = [
    {
      title: 'Can I display my inputs in more than 1 column?',
      copy: 'Yes. Each <strong>group</strong> of inputs can display in 1 or 2 columns. Hover over the group and change the "Layout" option at the top of the group.'
    },

    {
      title: 'How do I edit my input labels?',
      copy: 'Input labels will use the name of the field by default. If you want to use a custom label simply hover over the input and click the pencil icon to edit the label.'
    },

    {
      title: 'Can I add instructions to the input?',
      copy: 'Yes, hover over the input and click the pencil icon to edit the input and add your instructions.'
    },

    {
      title: 'Can I add read-only fields to my form?',
      copy: 'No. But you can add a details view to this page to display any read-only fields.'
    },

    {
      title: 'What is a <i>Form Group</i>?',
      copy: 'Fields are organized into <strong>groups.</strong> Each group can be displayed with a 1 or 2-column layout. Click the "Add Group" button to add multiple groups. The active group will have a gray background. Any fields you add as new inputs will be added to this active group. Click on any other group to make it active. You can change the order for any input by dragging and dropping it into a new location. If you have more than one group you can click on the move icon above a group and drag to change the order.'
    }
  ];

  var keyword_search = [
    {
      title: 'What is a Keyword Search?',
      copy: 'They keyword search is a single search input that will search through <strong>all</strong> fields in your record\'s object. It can be enabled in the <strong>Options</strong> tab.'
    }
  ];

  var connection_edit = [
    {
      title: 'How does the <strong>Value Separator</strong> work?',
      copy: 'Each value in your connection field can be separated by a Comma or in a New Line.'
    },

    {
      title: 'How does the <strong>Value Link</strong> work?',
      copy: 'Each value in your connection field can be linked to another page that is displaying details about the connected record. <strong>Value Link</strong> allows you to select the page to link each value to.',
      link: 'http://helpdesk.knackhq.com/solution/articles/5000443652#link'
    }
  ];

  var inline_edit = [
    {
      title: 'What is inline editing?',
      copy: 'Inline editing allows your users to click on a field in the table and edit its value without needing to load a new page or leave the table.'
    }
  ];

  var link_edit = [
    {
      title: 'What is the <strong>Link Type</strong>?',
      copy: 'The Link Type determines the text for your link. The default is <i>text</i> of your choice. You can also a <i>field</i> and the record value for that field will be the link. <br><strong>Note:</strong> This also works with Images!'
    },

    {
      title: 'What is the <strong>Link Text</strong>?',
      copy: 'The Link Text allows you to set your own text for this clickable link.'
    }
  ];

  var list_layout = [
    {
      title: 'What is the List Layout?',
      copy: 'List Layout allows you to show up to 3 records in a single row. This is useful when wanting to compare records side by side, or displaying a gallery of images.'
    },

    {
      title: 'Hiding Empty Fields',
      copy: 'The <strong>Hide Empty Fields</strong> option will hide any field that is completely blank.'
    }
  ];

  var number_formats = [
    {
      title: 'Can I use my own custom number formatting?',
      copy: 'Every  numerical field allows you to choose a <strong>Custom</strong> Format. When using the custom format, can choose to add custom formatting before and/or after the number.'
    },

    {
      title: 'What does <i>Default</i> Rounding mean?',
      copy: '<strong>Default Rounding</strong> will round the last decimal place up if it\'s higher than or equal to 5, otherwise it will be rounded down.'
    }
  ];

  var search_results = [
    {
      title: 'Can I display my search results in a different view type?',
      copy: 'Try using the <strong>Display results in a List</strong> setting in your <a href="http://helpdesk.knackhq.com/solution/articles/5000443518#search">Search Options</a>. <strong><br>Note:</strong> Inline editing is <strong>not</strong> available in other layouts.'
    },

    {
      title: 'Can I show results as soon as my Search view loads?',
      copy: 'Yes, you can enable the <i>Show Results to Start</i> setting in your <a href="http://helpdesk.knackhq.com/solution/articles/5000443518#options">Search Options</a>.'
    }
  ];

  var table_columns = [
    view_header,

    {
      title: 'How do I rename a table column?',
      copy: 'Hover over the column and click on the <i>pencil</i> icon to edit the <strong>Column Header</strong>.'
    },

    {
      title: 'How can I <strong>Group</strong> common records?',
      copy: 'You can group records by a common value in a specific tab;e column. You can indicate which column(s) to group with by checking the "Grouping" option when editing your column.'
    },

    {
      title: 'How do I add <strong>links</strong> to my view?',
      copy: 'Click on the <strong>LINKS</strong> above your fields list in the left column. You can add links to view, edit, or delete records.'
    },

    {
      title: 'Want to hide a column when it has no values?',
      copy: 'You can enable the <strong>Hide Empty Columns</strong> setting in your <strong>Options</strong> tab.'
    }
  ];

  var table_columns_edit = [
    {
      title: 'About the Column Header',
      copy: 'The Column Header allows you to rename the header for that field. This only affects the current view!'
    },

    {
      title: 'How does the <strong>Grouping</strong> feature work?',
      copy: 'The <strong>Grouping</strong> feature will group all records in this view by the field selected. Each group will be identified with a header containing the field value.'
    }
  ];

  var view_export = [
    {
      title: 'Can I export records from the Interface?',
      copy: 'You can enable an "export" link to your view from the <strong>Options</strong> tab. Users will be able to choose CSV, Text, and JSON format.'
    }
  ];

  var view_filter = [
    {
      title: 'What Filtering options are available?',
      copy: '<ul><li><strong>Data Source </strong>- The source indicates which object the table is displaying and allows you to add rules to filter the records from that object.  Click "Add rules to filter records" if you only want to display records that match your rules.</li><li><strong>Enable Users to Filter Records </strong>- Let your users add filters through the interface.</li><li><strong>Filter Menu </strong>- Create a pre-set menu that your users can click through and show different specific sets of records in the same view.</li>'
    },

    {
      title: 'Can I add filter presets to initially filter records?',
      copy: 'Yes, if you <strong>Enable users to filter records</strong>, you\'ll enable an additional <i>Show all records</i> setting that can be set to <strong>No</strong>.'
    }
  ];

  var view_links = [
    {
      title: 'About Delete Links',
      copy: 'Using a delete link will completely delete a record from a database and cannot be restored. Be careful where you add them!'
    },

    {
      title: 'I have already built a details/edit page for my records, do I need to recreate it again?',
      copy: 'Absolutely not! Try using the <strong>link to an existing page</strong> option instead. You can choose any existing page that belongs to the same object as this view. You can also <strong>copy</strong> a view if you want to make slight modifications to it.',
      link: 'http://helpdesk.knackhq.com/solution/articles/5000442746#copy'
    },

    {
      title: 'How do I make my links appear as a modal popup instead?',
      copy: 'If you want your child page to appear in a modal popup instead, you need to edit that Page\'s <strong>Page Options</strong>',
      link: 'http://helpdesk.knackhq.com/solution/articles/5000442746#modal'
    }
  ];

  var view_pagination = [
    {
      title: 'Why is the <strong>show all records</strong> option not available in my Pagination settings?',
      copy: 'Loading potentially thousands of records can cause severe browser delays so this options is disabled to safeguard this from happening intentionally. Your users can still choose to show all records if you enable the additional option: <i> Let the user set how many records to view per page</i>.'
    }
  ];

  return {
    'dashboard': [
      {
        title: 'What is Knack?',
        copy: 'Learn about apps, databases and getting started with the Knack builder.',
        link: 'https://knack.freshdesk.com/support/solutions/articles/5000125798-what-is-knack-'
      },

      {
        title: 'Introducing the Builder',
        copy: 'Learn the ins and outs to Knack\'s most powerful tool.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000125834'
      },

      {
        title: 'Set up your Database',
        copy: 'Define the data you want to store in your database',
        link: 'https://knack.freshdesk.com/support/solutions/articles/5000125839-step-1-define-your-database'
      },

      {
        title: 'Build your Interface',
        copy: 'Create forms, tables, maps, calendars, login pages and more',
        link: 'https://knack.freshdesk.com/support/solutions/articles/5000125852-step-2-build-your-interface'
      },

      {
        title: 'Can I connect one app with another app?',
        copy: 'No. Each app is completely independent from one another. If you find yourself needing to connect two apps together, you\'ll want to consider merging both apps into one.'
      },

      {
        title: 'Can there be more than 1 <strong>Builder</strong> for my app?',
        copy: 'You can add a <strong>Shared Builder</strong> to each app individually. Shared Builders won\'t be able to delete your app or add new apps, and won\'t have access to your account details like billing. They <strong>will</strong> be able to fully edit the app you share with them, including adding, editing, and deleting elements from both the database and interface. Be careful who you\'re sharing with!'
      },

      {
        title: 'How do I change my Account name / URL?',
        copy: 'Your account name and URL can be changed at any time from the <strong>Account Settings</strong> tab in the left panel. Remember that when changing your URL, any user-saved bookmarks will no longer work.'
      },

      {
        title: 'How do I access my payment receipts?',
        copy: 'All of your payment receipts can be downloaded in PDF format from the <strong>Billing</strong> tab in the left panel.'
      },

      {
        title: 'How can I hand over ownership of this account to someone else?',
        copy: 'In order to fully hand over ownership of an account, you must update the Account name in the <strong>Account Settings</strong>, the Email address / password in the <strong>User Settings</strong>, and the payment method in the <strong>Billing</strong> tab.'
      },

      {
        title: 'How is my <strong>storage</strong> calculated?',
        copy: 'Storage accounts for any <strong>Files</strong> or <strong>Images</strong> stored in your app. Deleting a record with a file will not fully remove the file from your storage, we store them in case a backup is required.<br>You can completely remove files from your app by <strong>clicking</strong> on its storage size in the Apps list.'
      },

      {
        title: 'My record counts are incorrect, how do I fix them?',
        copy: 'First, remember that a user that has multiple roles will count as multiple records. Therefore a user with 2 roles will have <strong>2 separate records</strong>. If you still suspect your counts are incorrect, you can force a sync by clicking on the count displayed in the Apps list.'
      },

      {
        title: 'How can I backup my app?',
        copy: 'You can <strong>copy</strong> your app to store separate versions of your app and records. This allows you to continue development on your original app without affecting your working app.<br> To copy an app, click on the <strong>settings</strong> wheel icon for a specific app to access the <strong>Copy</strong> menu.'
      },

      {
        title: 'I just deleted my app! Can you restore it?',
        copy: 'Knack automatically backs up your application on a daily basis, however they\'re not readily available to our users and requires manual labor in order to restore from a previous session. Backup restores are included in our Corporate plans and above. If you\'re on a lower plan and are in need of a restore, we can quote you an hourly rate of $100 / hour to restore the app / records for you. Depending on the complexity of the restore, it generally takes 1-2 hours to restore from a recent backup.'
      }
    ],

    'dashboard.account-settings': [
      {
        title: 'What happens if I update the URL of my account?',
        copy: 'Updating the URL will create a new web address for your apps. Current users will need to be informed of the change in order to access your apps.'
      },

      {
        title: 'I need specific information included in my receipts from Knack.',
        copy: 'In the Billing Info box, you can customize the receipt text.'
      }
    ],

    'dashboard.user-settings': [
      {
        title: 'Manage the email and password you use to access Knack.',
        copy: 'This email and password will be used to login to the Knack builder.'
      },

      {
        title: 'About In-app email alerts',
        copy: 'The email for your Knack account will be the address at which you will receive in-app email notifications. If you use a <a href="https://knack.freshdesk.com/support/solutions/articles/5000443471-forms#emails">custom email</a> alert option you will be provided an option to set an alternate email address within the alert.'
      }
    ],

    'dashboard.billing': [
      {
        title: 'If I upgrade to a higher plan, do I have access to the new features immediately?',
        copy: 'Yes, higher-plan features are unlocked as soon as you upgrade. Support tiers, however, require a 3-month plan history.'
      },

      {
        title: 'What happens if I upgrade / downgrade in the middle of my billing cycle?',
        copy: 'Your account will be prorated the price difference for the remaining days.'
      }
    ],

    'dashboard.delete-account': [
      {
        title: 'If I delete my account will I lose everything?',
        copy: 'Yes, deleting will remove your apps and your account from Knack completely. Consider freezing your plan if you want to retain your app for future use.'
      }
    ],

    'database': [
      {
        title: 'Working with Databii',
        copy: 'A comprehensive guide on working with fields.',
        link: 'http://helpdesk.knackhq.com/solution/categories/5000070877/folders/5000083614/articles/5000442081-working-with-fields'
      },

      {
        title: 'How do I access my App Settings?',
        copy: 'You can access the <strong>App Settings</strong> by clicking on the purple menu icon on the top left of your Builder page.'
      }
    ],

    'database.ecommerce': [
      {
        title: 'Managing Payment Processors',
        copy: 'Detailed guide on how Payment Processors work in Knack',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000659253'
      },

      {
        title: 'How do I accept payments from my interface?',
        copy: 'Payments are processed through <strong>Checkout Views</strong>. Click here for a step-by-step guide on how to add your own Checkout Views.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000658908'
      },

      {
        title: 'How do I enable recurring payments?',
        copy: 'Recurring payments are currently not available. Your users will need to re-submit a <strong>Checkout Form</strong> in order to submit a new subsequent payment.'
      }
    ],

    'database.roles': [].concat([
      {
        title: 'Managing User Records',
        copy: 'User records have additional features you can use to manage your app users, such as <strong>Update Status</strong>, <strong>Reset Password</strong>, and <strong>Email Account Info</strong>',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443934'
      },
      {
        title: 'Users with multiple roles',
        copy: 'Each role record for a specific user shares the Name,Email, and Password fields. All other fields are unique to that specific role and are not shared. This also means that a user with multiple roles counts as multiple records in the database.'
      },
      {
        title: 'How do I enable User Registrations?',
        copy: 'In order to enable registration for a specific <strong>role</strong>, you need to add a <a href="http://helpdesk.knackhq.com/solution/articles/5000443954#logins">Login Page</a> from your <strong>Interface Builder.</strong>'
      },
      {
        title: 'How do I enable Single Sign On logins?',
        copy: 'You can customize an existing <a href="http://helpdesk.knackhq.com/solution/articles/5000443954#logins">Login Page</a> to enable Single Sign On with <a href="http://helpdesk.knackhq.com/solution/articles/5000443954#sso">Facebook, Google, or Twitter</a>.'
      },
      {
        title: 'How does Single Sign On registration work?',
        copy: 'If you have Registrations enabled for your Login Page & requires anything more than the user’s name and email, the user will be redirected to the registration page to complete the additional required inputs.'
      }
    ]
      ,database_fields),

    'database.fields': database_fields,

    'database.fields.address': [].concat(field_required, [
      {
        title: 'U.S. & International Formats',
        copy: 'The international format will replace the "State" input with "Province/Region", and "Zip Code" with "Postal Code".'
      },

      {
        title: 'Need to use coordinates instead?',
        copy: 'You can change this field\'s input to <strong>Latitude/Longitude</strong>. You do not need to change this option if you want to simply import the coordinates but display the full address.'
      }
    ]),

    'database.fields.auto_increment': [
      {
        title: 'How do I reset the Auto Increment field?',
        copy: 'In order to reset the Auto Increment field, you need to use the <strong>Clear all records</strong> option in the <i>Records</i> tab. <br><strong>Note:</strong> This action is non-reversible!'
      },

      {
        title: 'Can I customize my Auto Increment number?',
        copy: 'Yes, you can use equations to customize your auto increment to start from any number, or use text formula fields to append a string before or after the number.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000522058'
      }
    ],

    'database.fields.boolean': [].concat(
      {
        title: 'About Boolean Labels',
        copy: 'A Yes/No field can also be identified with <strong>Off/On</strong> or <strong>True/False</strong> labels.'
      },
      field_default,
      {
        title: 'Can I require a user to select a checkbox?',
        copy: 'Yes, you can require a "yes" value for this field. An unchecked checkbox equates to a "No".'
      }
    ),

    'database.fields.connection': [
      {
        title: 'How do I control the sort order of the connection look-up?',
        copy: 'Connection fields use the Object\'s <strong>Default Sort Order</strong> to list the available options. You can change the Default Sort Order in the right <strong>Settings</strong> column of the object being connected to.'
      },

      {
        title: 'How do I control the field being displayed in my connection look-up?',
        copy: 'Connection fields use the Object\'s <strong>Display Field</strong> to identify the record in the dropdown. You can change the Display Field in the right <strong>Settings</strong> panel of the object being connected to.'
      },

      {
        title: 'How do I display dynamic dropdowns in my Interface?',
        copy: 'You can add multiple <strong>connection</strong> inputs to a form so that changing the record of the <strong>parent</strong> will update the records available in the child. Click here for a step-by-step tutorial.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443104'
      },

      {
        title: 'How can I display records connected to this field?',
        copy: 'You can build Interface <strong>child pages</strong> to display connected records. Click here for a step-by-step tutorial',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443680'
      },

      {
        title: 'Can I show records connected to the logged-in User?',
        copy: 'Yes, once you establish a connection to a User, you can create Interface pages so they can work with their own records only. Click here for a step-by-step tutorial',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443054'
      },

      {
        title: 'Can I combine connected fields in your views',
        copy: 'Yes, when working with parent-child connections, they will have access to their connected fields when building <strong>Interface Views</strong>. Click here for a step-by-step tutorial.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443708'
      }
    ],

    'database.fields.currency': [].concat([
      {
        title: 'How do I use more than 2 decimal places in my Currency field.',
        copy: 'Currency Fields do not have access to Number Formatting options. If you want more control over the currency display, use a <strong>Number field</strong> instead.'
      }
    ],
      field_unique,
      field_default
    ),

    'database.fields.date_time': [].concat(field_required, [
      {
        title: 'About the Date Format',
        copy: 'You can change your date format <strong>dd/mm/yyyy</strong> or <strong>M,D,yyyy</strong>. You can also choose to completely <i>ignore date</i> if you only want to track Time.'
      },

      {
        title: 'About the Time Format',
        copy: 'Time is ignored by default. You can choose 12-hour(HH:MM am) or 24-hour(HH:MM military) format.'
      },

      {
        title: 'How do the Calendar Options work?',
        copy: 'The Calendar Options enable an additional set of options to the date/time field when used in a form. These options allow you to set separate From-To dates as well as repeat events in a <strong>Calendar view</strong>.'
      },

      {
        title: 'Are new records created when using the advanced <strong>Repeat</strong> options?',
        copy: 'No, this could potentially cause an infinite amount of records! The advanced repeat options are intended for <strong>display purposes</strong> in a Calendar view only.'
      },

      {
        title: 'The date/time added to my record was incorrect.',
        copy: 'Double check your Timezone / Daylight Saving settings in the <strong>App Settings</strong>'
      }
    ],
      field_default
    ),

    'database.fields.email': [].concat(field_required, field_unique, [
      {
        title: 'How do I change the way my Email address is displayed in my Interface?',
        copy: 'You can control the <i>Email Text Format</i> for this field to either use the same text you define, or allow users to set their own identifying email label through the interface.'
      },

      {
        title: 'How do I send an Email to my Users?',
        copy: 'Emails can be triggered through <strong>Form Rules</strong> or with <strong>Scheduled Tasks</strong>. Follow this guide to learn more about emailing your users.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000528235'
      },

      {
        title: 'Integrating Knack and Mailchimp',
        copy: 'An extensive guide to connecting Knack with a third-party email campaign manager.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000550412'
      }
    ]),

    'database.fields.equation': [
      {
        title: 'My equation is not updating',
        copy: 'Equations run in order, so double check if your equation is using the value of another equation. If it is, make sure this equation is ordered AFTER that one. This way the other equation will be updated before this equation uses it.'
      },

      {
        title: 'How do I use a field from another Object in my equation?',
        copy: 'In order for the Object to be eligible, this object must be the child of the connection. Click on the purple Object name when editing your equation to access all other eligible Objects.'
      }
    ],

    'database.fields.formula': [].concat(number_formats, [
      {
        title: 'How do I exclude specific records from my Formula?',
        copy: 'Click on the <i>Filter which records the sum uses</i> link to access field filters for that Object. This is useful when you want to make sure the calculation only incudes a specific group of records.'
      }
    ]),

    'database.fields.image': [].concat(field_required, [
      {
        title: 'How can I import images?',
        copy: 'You can set this field\'s <strong>Image Source</strong> to <i>load images from an external url</i> instead. Your import file will need to include a public URL where the image is hosted, which will then be uploaded to your Knack storage.'
      },

      {
        title: 'How do I keep image sizes consistent for my interface?',
        copy: 'Your Image field has a <strong>Thumbnail</strong> setting you can enable, this allows you to size all images equally to keep a consistent look across your interface. You can add multiple thumbnails to a single image field.'
      },

      {
        title: 'I added thumbnails to my image but my interface still shows the full-size image.',
        copy: 'An image thumbnail becomes a new mappable field in your interface. It will be identified by the Image field name and the dimensions of the thumbnail. You must add this new field to your interface pages wherever you want the thumbnail images to be referenced instead.'
      }
    ]),

    'database.fields.link': [].concat(field_required, field_unique, [
      {
        title: 'Using Unique Link Texts',
        copy: 'If you want to add our own link text when adding a new record, you can set the <i>Link Text Format</i> to <strong>Use unique text for each link.</strong>'
      },

      {
        title: 'Using the same link text for all links',
        copy: 'If you want to identify all download links with the same text, try changing the <i>Link Text Format</i> to <strong>Use the same text for all links.</strong>'
      },

      {
        title: 'Want to force all links to open in a new window?',
        copy: 'Toggle the <i>Open link in new window</i> option to <strong>Yes</strong>'
      }
    ], field_default),

    'database.fields.multiple_choice': [].concat(field_required, field_unique, [
      {
        title: 'How do I set a <strong>default</strong> option?',
        copy: 'You can select a default option by selecting the radio button next to your desired option.'
      },

      {
        title: 'Can I add more than 1 option at a time?',
        copy: 'You can paste an entire list of options using the <strong>quick edit</strong> setting in this field.'
      },

      {
        title: 'Multiple Choice Layouts',
        copy: 'You are not just limited a single dropdown selection! You can change your input to a Radio Button, or use Check Boxes if you need to select multiple options.'
      },

      {
        title: 'How can I sort my options?',
        copy: 'There are no sorting options available for Multiple Choice field. The only time Knack automatically re-sorts the options is when importing new records. If you want to have more control over your options, try using a <strong>Connection field</strong> instead.'
      }
    ]),

    'database.fields.name': [].concat(field_required, [
      {
        title: 'About the Name Format',
        copy: 'The Name Format is for display purposes only - it does not affect the form inputs or the way the name is sorted.'
      },

      {
        title: 'How can I add more name <i>titles</i>?',
        copy: 'Custom titles can not be added to the built-in titles. For custom titles, try using your own <i>Multiple Choice</i> choice field and combine it with the <i>Name</i> field using a <strong>Text Formula</strong>.'
      }
    ]),

    'database.fields.number': number_formats,

    'database.fields.paragraph_text': [].concat(field_required, field_unique, field_default, [
      {
        title: 'How can I add formatting to my paragraph text?',
        copy: 'Paragraph Text fields do not have any formatting options. Try using a <strong>Rich Text</strong> field instead.'
      }
    ]),

    'database.fields.phone': [].concat(field_required, field_unique, [
      {
        title: 'Need to use a Phone Format not listed?',
        copy: 'You can set this field\'s <i>Format</i> to <strong>Any</strong>'
      },

      {
        title: 'How does the <strong>Extensions</strong> format work?',
        copy: 'If enabled, the phone input field will contain an additional set of inputs that will allow an Extension number of up to 5 digits.'
      }
    ], field_default),

    'database.fields.rich_text': [].concat(field_required, field_unique, [
      {
        title: 'About the Rich Text field',
        copy: 'Rich Text fields are used when you need to add more rich formatting and HTML to text.  When edited, a rich text field will have a WYSIWYG interface for formatting the text with styles like bold, bullets, and more.'
      },

      {
        title: 'Can Rich Text fields access Knack fields?',
        copy: 'No. Rich Text fields are completely separate from Knack fields/records.'
      },

      {
        title: 'Add a Logo to your Pages',
        copy: 'Learn how to use Rich Text fields to display a logo in any of your pages',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000448339'
      }
    ], field_default),

    'database.fields.short_text': [].concat(field_required, field_unique, field_default, [
      {
        title: 'Do Short Text fields have a character limit?',
        copy: 'Yes, they are limited to 255 characters.'
      }
    ]),

    'database.fields.signature': [].concat(field_required, field_unique, field_default),

    'database.fields.timer': [].concat(field_required, [
      {
        title: 'Date & Time Formats',
        copy: 'Dates are disabled by default in Timer fields, but you can enable dates through the <i>Date Format</i> option.'
      },

      {
        title: 'How do I control the time intervals in the timer?',
        copy: 'You can change the <i>Minutes Format</i> to limit selections by <strong>5/10/15/30 minutes</strong>'
      },

      {
        title: 'How do I display my Timer results in another format?',
        copy: 'The <i>Total Format</i> option for this field allows you to control whether calculations should be made in <strong>seconds,minutes,hours,days, or weeks.</strong>'
      }
    ]),

    'database.records': [
      {
        title: 'Working with Records',
        copy: 'Learn everything you need to know about Records in Knack.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000442202'
      },

      {
        title: 'Can I import multiple records?',
        copy: 'Records can be imported with a CSV spreadsheet. Click here for a step-by-step tutorial.<br><strong>Note:</strong> Record imports are only available in the Builder.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000442202'
      },

      {
        title: 'How can I update multiple records at once?',
        copy: 'You can use the table checkboxes, or access the <strong>Batch Update</strong> setting in the right panel. Click here for a step-by-step tutorial.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443120'
      },

      {
        title: 'How to Export records from the Knack Builder and App Interface',
        copy: 'An extensive guide to exporting records in your Knack App.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443134'
      },

      {
        title: 'How do I access the <strong>Record History</strong>?',
        copy: 'Click on the <i>pencil</i> icon next to a record in the database to access the <strong>History</strong> tab. The Record History will display all changes made to a record, who made the update, and the IP address used when the record was submitted. <br><strong>Note:</strong> The Record History is <i>not</i> available through the Interface. It is meant is a database utility tool for builders only.'
      },

      {
        title: 'How to track when records were last updated',
        copy: 'Use a date/time field to keep track of the last time a record was edited.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000448192'
      },

      {
        title: 'How to track which user last updated a record',
        copy: 'Use <strong>Record Rules</strong> in your Forms to track who last updated a record. Click here for a step-by-step tutorial',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000448212'
      },

      {
        title: 'How to approve new records before displaying them in your Interface',
        copy: 'Add <strong>data source filters</strong> to your views to only display approved records. Click here for a step-by-step tutorial.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000448165'
      }
    ],

    'database.settings': [
      {
        title: 'Can I change my app name / link at any time?',
        copy: 'Yes, there is no limit to when or how many times you can change this. If you are changing the link, any bookmarks saved will no longer work.'
      },

      {
        title: 'How do I upload my own custom app logo?',
        copy: 'You can upload your own app logo in the <strong>Layout & Colors</strong> settings.'
      },

      {
        title: 'Where is my App Email Address used exactly?',
        copy: 'The App Email address is used in any app-generated emails, such as the "forgot password" link.'
      },

      {
        title: 'How does the Timezone / Daylight Saving settings affect my app?',
        copy: 'These settings are used whenever a Date/Time field or Equation in used in your app.'
      },

      {
        title: 'Can I translate my app into mulitple languages at once?',
        copy: 'You can only translate 1 app to a single language at a time. You can <strong>Copy</strong> an app and set it to another language if you\'d like.'
      },

      {
        title: 'What is IP Blocking?',
        copy: 'IP Blocking allows you to restrict who has access to your app. You can target an entire range of IP addresses to allow an entire work network to access your app.'
      }
    ],

    'database.tasks': [
      {
        title: 'Working with Scheduled Tasks',
        copy: 'An extensive guide to getting the most out of your Scheduled Tasks',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000528377'
      },

      {
        title: 'Need to run a task immediately?',
        copy: 'Try the manual <strong>Run</strong> option in your task'
      },

      {
        title: 'What happens when I <strong>Pause</strong> my task?',
        copy: 'A paused task will <strong>not</strong> be re-scheduled. The task will never run unless you run it manually or unpause it.'
      }
    ],

    'database.user-settings': [
      {
        title: 'I want to restrict different roles to different pages - which <strong>Login Setting</strong> should I use?',
        copy: 'In order to have control over which Role has access to a specific page, use the <strong>Choose which pages you want to secure with a login</strong> setting. <strong><a href="http://helpdesk.knackhq.com/solution/articles/5000443892-activate-user-and-login-features" target="_blank">Click here to learn more.</a></strong>'
      }
    ],

    'interface': [
      {
        title: 'Building your Interface',
        copy: 'Getting started with building your interface.',
        link: 'http://helpdesk.knackhq.com/support/solutions/articles/5000442373-about-your-interface'
      },

      {
        title: 'Copy and Reuse your pages',
        copy: 'You may not need to re-create entire pages all over again. If you have similar pages, try copying or re-using them instead.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000442746#copy'
      },

      {
        title: 'Modal Popups',
        copy: 'Did you know you can turn <i>any</i> page into a modal popup? Try enabling it in the Page Options!',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000442746#modal'
      },

      {
        title: 'Printing and PDFs',
        copy: 'If you need to print, try adding a Print Link to your page. For generating custom PDFs, you can integrate your app with <a href="http://www.knack.com/webmerge">Webmerge</a>.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000442746#print'
      },

      {
        title: 'Need to copy or move an individual view?',
        copy: 'Try dragging the view to your new desired page! Note: If your view has a <strong>gold key</strong> icon over it, your destination page must the same <strong>user role</strong> enabled.'
      },
      new_field
    ],

    'interface.views': [
      {
        title: 'Working with Views',
        copy: 'A comprehensive guide on working with views.',
        link: 'http://helpdesk.knackhq.com/support/solutions/articles/5000443455-about-views'
      },

      {
        title: 'Need to Copy or Move a view?',
        copy: 'You can click and drag a view into any eligible page. You can also move or copy any view from the <i>View Menu</i> (the top right when you have clicked on a view to edit it).'
      }
    ],

    'interface.calendar.source': data_source,

    'interface.calendar.settings': [
      {
        title: 'How can I set my own custom event colors?',
        copy: 'You can customize your calendar event colors based on record values from the <strong>Calendar Settings</strong>',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443531#settings'
      },

      {
        title: 'How can I exclude <strong>weekends</strong> or start my calendar on <strong>Monday</strong> instead?',
        copy: 'You can customize your <strong>Starting Range</strong> and <strong>Week Start</strong> directly from your <strong>Calendar Settings</strong>',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443531#settings'
      },

      {
        title: 'Can I add RSS Feeds & iCal Integrations?',
        copy: 'Yes, you can enable an RSS feed or generate an .ics download link directly from your <strong>Calendar Settings</strong>'
      },

      {
        title: 'How can I integrate my calendar into Google Calendars?',
        copy: 'Google requires a URL in order to subscribe to a calendar. Once you enable the "iCal download link", you can right click on the download link and <i>copy the link address</i> so you can paste it into Google Calendars.<br><strong>Note:</strong> This only works with <i>public</i> calendars, it will not work if the Calendar page requires a login.'
      },

      {
        title: 'How can I add a link to view more details?',
        copy: 'You have access to <strong>Special</strong> links when you edit your <i>Event Details</i> in the Calendar view.'
      },

      {
        title: 'Advanced Calendar Features',
        copy: 'Calendars have some unique features that allow you to <i>drag</i> an event to a different time, or click on an open slot to add a new event. You can enable these features in your <strong>Calendar Settings</strong>',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443531#settings'
      }
    ],

    'interface.calendar.details': details_format,

    'interface.calendar.form': [].concat([
      {
        title: 'Why is my <i>Add Event Form</i> not showing up?',
        copy: 'In order to access the <i>Add Event Form</i>, you need to enable the <strong>"Click on the calendar to add events"</strong> Event Option from your <strong>Calendar Settings</strong>'
      }],
      form_inputs
    ),

    'interface.calendar.details.links': view_links,

    'interface.details.details': details_format,

    'interface.details.rules': display_rules,

    'interface.details.options': [
      {
        title: 'Hiding Empty Fields',
        copy: 'The <strong>Hide Empty Fields</strong> option will hide any field that is completely blank.'
      }
    ],

    'interface.form.inputs': [].concat(
      form_inputs, [
        {
          title: 'Hide / Show Fields',
          copy: 'Want to show / hide fields based on user input? Try <a href="http://helpdesk.knackhq.com/solution/articles/5000443471#display-rules">Display Rules</a>'
        },

        {
          title: 'Redirect Users or Automatically reload forms',
          copy: 'Control what happens after a form submits with <a href="http://helpdesk.knackhq.com/solution/articles/5000443471#submit-rules">Submit Rules</a>'
        },

        {
          title: 'Update / Insert Records',
          copy: 'Use <a href="http://helpdesk.knackhq.com/solution/articles/5000443471#record-rules">Record Rules</a> to add more workflow to your forms.'
        },

        {
          title: 'Send Emails',
          copy: 'Use <a href="http://helpdesk.knackhq.com/solution/articles/5000443471#emails">Email Rules</a> to send custom notifications based on form inputs.'
        },

        {
          title: 'Customize Form Buttons / Links',
          copy: 'Change your <strong>submit button</strong> and <strong>reload form text</strong> in the <a href="http://helpdesk.knackhq.com/solution/articles/5000443471#options">Form Options</a>'
        }
      ]),

    'interface.form.inputs.edit.connection': [
      {
        title: 'Can I show Parent-Child Dropdowns',
        copy: 'Yes, click for a a step-by-step guide to displaying dynamic dropdowns in your form',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443104'
      },

      {
        title: 'Can new records be added without leaving the form?',
        copy: 'Yes, connection fields have <a href="http://helpdesk.knackhq.com/solution/articles/5000443471#input-options">additional options</a> you can enable to allow users to add new records.'
      },

      {
        title: 'How can I display multiple fields in my connection dropdown?',
        copy: 'Since connections use the object\'s Display Field, you can use a <strong>Text Formula</strong> to identify your record with multiple fields. Click here for a step-by-step tutorial.',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000528192'
      }
    ],

    'interface.form.rules.display': display_rules,

    'interface.form.rules.submit': [
      {
        title: 'When does a Submit Rule trigger?',
        copy: 'Submit rules trigger <strong>after</strong> the record is submitted to your Database, so your rule will use values used after the form was submitted.'
      },

      {
        title: 'What do all of these different Submit Rules do?',
        copy: 'Submit Rules allow you to control what happens after the user submits a record. You can modify a message or redirect them to a new page altogether:<ul><li><strong>Show a confirmation message</strong>: after the submission the form will fade out and the confirmation text will appear. &nbsp;There are 2 additional options here: <em>Show a link to reload a form </em>and <em>Automatically reload the form</em>.</li><li><strong>Redirect to an existing page</strong>: after the submission the user will be redirected to the selected page</li><li><strong>Redirect to the parent page: </strong> after the form is submitted the user will be redirected to a parent page. Perfect if you\'re adding a child record and want to quickly go back to a parent list showing all those record.</li><li><strong>Redirect to another website\'s URL</strong>: &nbsp;after the submission the user will be redirected to the entered URL.</li><li><strong>Redirect to a new child page:</strong> create a <em><strong>new</strong></em> child page to direct the user to. You can add any views you wish to this page and it will automatically work with the form\'s record. This means you insert a new record and immediately show other views for that record.</li></ul>'
      },

      {
        title: 'Can I reload the form automatically after submitting?',
        copy: 'Yes, when using a <i>confirmation message</i> submit rule, you can enable the "automatically reload the form" option.'
      },

      {
        title: 'What is a <i>new child page</i>?',
        copy: 'A <i>new child page</i> is a page becomes <strong>attached</strong> to the original form. This is useful when you need access to a specific page only after submitting the form.'
      }
    ],

    'interface.form.rules.record': [
      {
        title: 'When does a Record Rule trigger?',
        copy: 'Record rules trigger <strong>before</strong> the record is submitted to your Database, so your rule will use any <i>form values</i> present when submitting the record.'
      },

      {
        title: 'How do I connect this record to the <strong>logged-in User</strong>?',
        copy: 'Make sure your page has the proper <a href="http://helpdesk.knackhq.com/solution/articles/5000442746#logins">user login permissions</a> enabled.'
      },

      {
        title: 'Can I use Equation values with my form rules?',
        copy: 'Remember that if Equations that reference other equations, conditionals, or connected fields, the record needs to be submitted <strong>first.</strong> A record rule will not capture the final Equation value.'
      }

    ],

    'interface.form.emails': [
      {
        title: 'Can I use an email field for the "From" email address?',
        copy: 'No. For security purposes you can only use an email address that you have control of. In most cases you can use an email field for the "Reply-To" option so replies are routed to an email address stored in the record.'
      },

      {
        title: 'What does the basic email notification do?',
        copy: 'It sends a basic email listing every field value that was submitted in the form.'
      },

      {
        title: 'Can I send file attachments with these emails?',
        copy: 'No. You can include file fields, but they will display as links the recipient will need to click on in order to view or download the file.'
      }
    ],

    'interface.list.fields': details_format.concat(view_export, [
      {
        title: 'Want to display more than 1 record per row?',
        copy: 'Change your List Layout in the <a href="http://helpdesk.knackhq.com/solution/articles/5000443505#options"> List Options</a>'
      }
    ]),

    'interface.list.edit': details_edit,

    'interface.list.fields.edit.connection': connection_edit,

    'interface.list.fields.edit.link': link_edit,

    'interface.list.fields.links': [].concat(view_links,details_format),

    'interface.list.source': data_source,

    'interface.list.options': [].concat(list_layout, view_pagination, keyword_search, view_export,view_filter, [

    ]),

    'interface.map.options': [].concat([
      {
        title: 'What is the <strong>Title Field</strong>?',
        copy: 'The <strong>Title Field</strong> is the field the map will use to display for an address point. Choose a title, name, or other field that can easily identify the whole record.'
      },

      {
        title: 'How can I display custom pin colors?',
        copy: 'You can set pin colors based on record values in your <strong>Map Settings</strong>',
        link: 'http://helpdesk.knackhq.com/solution/articles/5000443553#settings'
      },

      {
        title: 'How can I use the User\'s <i>current location</i> as a starting point?',
        copy: 'Every map has a <i>Starting Point</i> option in the <strong>Map Settings</strong>. Due to security purposes, the user must grant the browser access to their current location.'
      },

      {
        title: 'Can I make my map larger or smaller?',
        copy: 'Yes, you can customize your <i>Map Size</i> and <i>List Width</i> from the <strong>Map Settings</strong> tab.'
      }],
      view_pagination,
      view_filter
    ),

    'interface.map.source': data_source,

    'interface.map.details': details_format,

    'interface.map.details.links': [].concat(view_links, details_format),

    'interface.search.source': data_source,

    'interface.search.fields': [].concat(view_header, keyword_search, [{
      title: 'Managing your Search Fields',
      link: 'http://helpdesk.knackhq.com/solution/articles/5000443518#search',
      copy: 'Discover all of the tools available for your <i>Search Fields</i>'
    },

    {
      title: 'Can I disable the advanced search filters for my <i>search fields</i>?',
      copy: 'Yes, hover over the field and click the pencil icon to view additional options. You can turn off advanced filters and even control whether you want ‘any’ match (‘contains’ filter), or an ‘exact’ match (‘is’ filter).'
    },

    {
      title: 'Can I make my search fields required?',
      copy: 'Yes, you can edit each specific search field to access a "Required" setting you can enable.'
    }

    ]),

    'interface.search.table-results': [].concat(search_results, table_columns),

    'interface.search.table-results.links': [].concat(view_links, search_results),

    'interface.search.list-results': [].concat(search_results, details_format),

    'interface.search.options': [].concat(view_export, view_filter, view_pagination),

    'interface.table.columns': [].concat(table_columns),

    'interface.table.columns.edit': table_columns_edit,

    'interface.table.columns.edit.connection': [].concat(connection_edit,table_columns_edit),

    'interface.table.columns.edit.link': link_edit,

    'interface.table.columns.links': view_links,

    'interface.table.source': data_source,

    'interface.table.filters': [].concat(view_filter, keyword_search),

    'interface.table.options': [].concat(inline_edit, keyword_search, view_pagination, view_export, [
      {
        title: 'What are <strong>Row Summaries</strong>?',
        copy: 'Row summaries are used to run calculations like sum and averages on all the rows in the column and display the results as a summary at the bottom of the table. Any <strong>label</strong> you add will be placed in a left column if available. You can use the green "add" buttons to add multiple summaries. If your fields are <strong>grouped</strong>, a summary will be given per grouping as well.'
      }
    ])
  };

});
