
define([
  'jquery',
  '@knack/sanitization-helper'
], function(jQuery, sanitization_helper) {

(function($) {

  var slugInput = {

    init: function(options,el) {
      var _this = this;
      this.$el = $(el);
      this.options = $.extend({ offset:7 }, options, this.options);

      if (this.options.type === 'app') { // render app slug overlay
        this.$overlay = $('<div id="url-overlay" class="app-url-overlay" data-before="https://' + Knack.account.slug + '.knack.com/"></div>');
      } else { // render account slug overlay
        this.$overlay = $('<div id="url-overlay" data-before="https://" data-after=".knack.com"></div>');
      }

      this.$overlay.insertBefore(this.$el);

      if (this.options.type === 'app') {
        $('#url-overlay').empty(); // clear html for offset
        this.$el.css('padding-left', $('#url-overlay').width() + this.options.offset);
      }

      // Disallows non-hyphen special characters, double hyphens, and leading hyphens during input
      this.$el.off('input change').on('input change', function() {
        var start = this.selectionStart;
        var val = _this.$el.val();
        var length = val.length;

        val = sanitization_helper.removeLeadingAndDoubleHyphens(val);
        $(this).val(val);

        var range = start - (length - val.length);

        _this.$overlay.html(_this.$el.val());

        this.setSelectionRange(range, range);
      }).change().focus();

      // Removes trailing hyphens when input loses focus
      this.$el.off('focusout').on('focusout', function() {
        $(this).val(sanitization_helper.removeTrailingHyphens(this.value));
        _this.$overlay.html(_this.$el.val());
      });

    }

  };

  $.fn.extend({
    slugInput: function(options) {
      return this.each(function() {
        var myInput = Object.create(slugInput);
        myInput.init(options, this);
        $(this).data('slugInput', myInput);
      })
    }
  })
}(jQuery));
});
