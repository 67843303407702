<template>
  <transition name="knHeader__mobile-nav-transition">
    <div
      v-if="isOpen"
      class="knHeader__mobile-nav"
      ref="mobileNavElement"
    >
      <div class="kn-container">
        <!-- User area -->
        <div
          v-if="currentScene.authenticated && user && user.id"
          class="knHeader__mobile-user-info"
        >
          <h3
            v-if="user.name"
            class="knHeader__mobile-user-name"
          >
            {{ `${user.name.first} ${user.name.last}` }}
          </h3>
          <ul class="knHeader__mobile-user-page-list">
            <li
              v-for="userPage in userAccountPages"
              :key="userPage.key"
              class="knHeader__mobile-user-page-list-item"
            >
              <a
                :href="`#${userPage.slug}`"
                :class="[
                  'knHeader__menu-link knHeader__mobile-nav-link knHeader__mobile-user-page-link',
                  getActiveLinkClass(userPage.slug)
                ]"
                @click="closeMobileNav"
              >
                {{ userPage.name }}
              </a>
            </li>
            <li class="knHeader__mobile-user-page-list-item">
              <a
                class="knHeader__menu-link knHeader__mobile-nav-link knHeader__mobile-user-page-link"
                @click.prevent="onLogoutClick"
              >
                Log Out <i class="fa fa-sign-out"/>
              </a>
            </li>
          </ul>
        </div>

        <!-- Mobile nav list  -->
        <ul class="knHeader__mobile-nav-list">
          <li
            v-for="page in entryPages"
            :key="page.key"
            class="knHeader__mobile-nav-list-item"
          >
            <!-- If page has dropdown menu, render dropdown page links -->
            <template v-if="page.type === 'menu'">
              <DropdownMenu
                :entry-page="page"
                :is-mobile-dropdown="true"
                :is-dropdown-open-by-default="true"
                :dropdown-pages="page.dropdown_pages"
                :dropdown-toggle-classes="['knHeader__mobile-nav-link']"
                :get-active-link-class="getActiveLinkClass"
                :on-dropdown-link-click="closeMobileNav"
              />
            </template>

            <!-- Otherwise, render regular page menu link -->
            <a
              v-else
              :href="`#${page.slug}`"
              :class="['knHeader__menu-link knHeader__mobile-nav-link', getActiveLinkClass(page.slug)]"
              @click="closeMobileNav"
            >
              <span
                v-if="page.icon && page.icon.icon && page.icon.align === 'left'"
                class="knHeader__menu-link-icon knHeader__menu-link-icon--left"
              >
                <i :class="`fa fa-${getSanitizedIcon(page.icon.icon)}`"/>
              </span>

              {{ page.name }}

              <span
                v-if="page.icon && page.icon.icon && page.icon.align === 'right'"
                class="knHeader__menu-link-icon knHeader__menu-link-icon--right"
              >
                <i :class="`fa fa-${getSanitizedIcon(page.icon.icon)}`"/>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { sanitizeIcon } from 'core/ui/helpers/vue-helper';
import DropdownMenu from './DropdownMenu.vue';

export default {
  components: { DropdownMenu },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    closeMobileNav: {
      type: Function,
      required: true,
    },
    getActiveLinkClass: {
      type: Function,
      required: true,
    },
  },
  computed: {
    currentScene() {
      return this.$store.getters.currentScene;
    },
    entryPages() {
      return this.$store.getters.entryPages;
    },
    user() {
      return this.$store.getters.user;
    },
    userAccountPages() {
      return this.$store.getters.userAccountPages;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  updated() {
    if (this.isOpen) {
      this.updateMobileNavHeight();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    getSanitizedIcon(icon) {
      return sanitizeIcon(icon);
    },
    onLogoutClick() {
      this.closeMobileNav();
      Knack.handleClickLogout();
    },
    // Close mobile nav if viewport is not mobile-size anymore (>= 768px)
    onWindowResize(event) {
      if (event) {
        const viewportWidth = event.currentTarget.innerWidth;
        if (viewportWidth >= 768) {
          this.closeMobileNav();
        }
      }
    },
    // Adjust mobile nav height
    updateMobileNavHeight() {
      const { headerElement } = this.$parent.$refs;
      const { mobileNavElement } = this.$refs;

      const headerHeight = headerElement && headerElement.offsetHeight;

      if (mobileNavElement && headerHeight) {
        // Since the mobile nav uses a 100vh height, we need to subtract the height
        // of the header bar from it so the computed height is correct
        mobileNavElement.style.height = `calc(100vh - ${headerHeight}px)`;
      }
    },
  },
}
</script>
