<template>
  <div class="kn-migration-dashboard_container">
    <DashboardTopNav />
    <div
      v-if="shouldRenderContent"
      class="kn-migration-dashboard_content"
    >
      <div
        v-if="applications.length > 0"
        class="kn-migration-dashboard_content-left"
      >
        <DashboardIntro :timezone="timezone" />
        <DashboardToolbar
          :applications="applications"
          :selected-application-ids="selectedApplicationIds"
          @update:check="updateCheckAll"
          @update:filter="updateToolbarFilter"
        />
        <ApplicationList
          :applications="applications"
          :selected-application-ids="selectedApplicationIds"
          :filter="filter"
          :now="now"
        />
      </div>
      <div
        v-else
        class="kn-migration-dashboard_empty-list"
      >
        <p>There aren't any applications to display at the moment.</p>
      </div>
      <div class="kn-migration-dashboard_content-right fancy-scrollbar">
        <DashboardHelpSection />
      </div>
    </div>

    <div
      v-else-if="shouldRenderError"
      class="kn-migration-dashboard_error"
    >
      <p>{{ error }}</p>
    </div>

    <LoadingSpinner :is-loading="isLoading" />

    <MigrationDashboardModal
      :applications-by-id="applicationsById"
      :selected-application-ids="selectedApplicationIds"
      :timezone="timezone"
      :now="now"
      @cancel="clearSelectedMigrationsIds"
      @submit="submitAppeal"
    />

    <SuccessModal />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { getBrowserTimezone } from 'dashboard/utility/timezone';
import EventBus from 'dashboard/components/eventBus';
import DashboardTopNav from 'dashboard/components/MigrationApps/DashboardTopNav.vue';
import DashboardIntro from 'dashboard/components/MigrationApps/DashboardIntro.vue';
import DashboardToolbar from 'dashboard/components/MigrationApps/DashboardToolbar.vue';
import ApplicationList from 'dashboard/components/MigrationApps/ApplicationList.vue';
import DashboardHelpSection from 'dashboard/components/MigrationApps/DashboardHelpSection.vue';
import MigrationDashboardModal from 'dashboard/components/MigrationApps/MigrationDashboardModal.vue';
import SuccessModal from 'dashboard/components/MigrationApps/SuccessModal.vue';
import LoadingSpinner from 'dashboard/components/LoadingSpinner.vue';

export default {
  components: {
    DashboardTopNav,
    DashboardIntro,
    DashboardToolbar,
    ApplicationList,
    DashboardHelpSection,
    MigrationDashboardModal,
    SuccessModal,
    LoadingSpinner,
  },

  props: {
    accountId: {
      type: String,
      default: '',
    },
    apiUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      success: false,
      applications: [],
      selectedApplicationIds: [],
      filter: 'all',
      timezone: getBrowserTimezone(),
      error: 'An error occurred. Please try again or contact Knack Support.',
      now: moment(),
    };
  },

  computed: {
    shouldRenderContent() {
      return !this.loading && this.success;
    },
    shouldRenderError() {
      return !this.isLoading && !this.success;
    },
    applicationsById() {
      return this.applications.reduce(
        (acc, app) => acc.set(app.app_id, app),
        new Map(),
      );
    },
  },

  created() {
    if (this.accountId) {
      this.fetchAccountMigrationApplications();
    } else {
      // User has been authenticated and is a shared builder without an account id
      this.error = 'Your account does not have any applications.'
    }


    EventBus.$on('check', this.check);
  },

  unmounted() {
    EventBus.$off('check', this.check);
  },

  methods: {
    fetchAccountMigrationApplications() {
      this.isLoading = true;

      const url = `${this.apiUrl}/v1/accounts/${this.accountId}/migrations/apps`;

      const requestOptions = {
        credentials: 'include',
      };

      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }

          this.applications = data.apps;
          this.isLoading = false;
          this.success = true;
        })
        .catch((error) => {
          this.isLoading = false;
          this.success = false;
          console.error('There was an error!', error);
        });
    },
    submitAppeal(rescheduleDatetime) {
      this.isLoading = true;

      const url = `${this.apiUrl}/v1/accounts/${this.accountId}/migrations`;

      let migrationIds = [];
      let applicationIds = [];

      this.selectedApplicationIds.forEach((id) => {
        const app = this.applicationsById.get(id);

        if (app.migration_id) {
          migrationIds.push(app.migration_id);
        } else {
          applicationIds.push(id);
        }
      })

      const params = {
        date: rescheduleDatetime,
        migration_ids: migrationIds,
        application_ids: applicationIds,
      };

      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
        credentials: 'include',
      };

      fetch(url, requestOptions)
        .then(async (response) => {
          const isJson = response.headers
            .get('content-type')
            .includes('application/json');
          const data = isJson && (await response.json());

          // check for error response
          if (!data.success || !response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          this.clearSelectedMigrationsIds();

          EventBus.$emit('closeRescheduleModal');
          EventBus.$emit('renderSuccessModal', params.date);

          this.isLoading = false;
        })
        .then(this.fetchAccountMigrationApplications)
        .catch((error) => {
          this.isLoading = false;
          EventBus.$emit('renderSaveError');
          console.error('There was an error!', error);
        });
    },
    check(id, checked) {
      if (checked) {
        this.selectedApplicationIds.push(id);
      } else {
        this.selectedApplicationIds.splice(
          this.selectedApplicationIds.indexOf(id),
          1,
        );
      }
    },
    updateCheckAll(checked) {
      if (checked) {
        this.selectedApplicationIds = this.applications
          .filter((application) => ['scheduled', 'pendingSchedule'].includes(application.migration_status))
          .map((application) => application.app_id);
      } else {
        this.clearSelectedMigrationsIds();
      }
    },
    clearSelectedMigrationsIds() {
      this.selectedApplicationIds = [];
    },
    updateToolbarFilter(value) {
      this.filter = value;

      const resetSelectedApplicationsList = this.selectedApplicationIds.length && !['all', 'scheduled'].includes(value);

      if (resetSelectedApplicationsList) {
        this.clearSelectedMigrationsIds();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../layout.scss";

$max-content-width: 1080px;
$top-nav-height: 48px;

.kn-migration-dashboard_container {
  height: 100%;
}

.kn-migration-dashboard_content,
.kn-migration-dashboard_error {
  @include h-stack("large");

  height: calc(100% - #{$top-nav-height});
  margin-left: auto;
  margin-right: auto;
  max-width: $max-content-width;
  overflow: auto;
  padding: 20px 30px;
}

.kn-migration-dashboard_content-left,
.kn-migration-dashboard_empty-list {
  @include v-stack("medium");

  min-width: 480px;
  width: 67%;
}

.kn-migration-dashboard_content-right {
  min-width: 236px;
  width: 33%;
}
</style>
