define([
  'jquery', 'backbone',
  'core/backbone/views/BaseView',
  'core/templates/login.html',
  'core/templates/embed-login.html',
  'core/templates/v2/login-v2.html',
  'core/templates/v2/embed-login-v2.html',
  'core/templates/login-2fa.html',
  'core/utility/utility-forms',
  'core/lib/cookie'
], function($, Backbone, BaseView, login_template, embed_login_template, login_template_v2, embed_login_template_v2, login_2fa, UtilityForms, cookie) {
  return BaseView.extend({

    events: {
      'submit form.login_form': 'handleSubmitLogin',
      'click #openEmbeddedLogin': 'handleEmbeddedLogin'
    },

    remember_login: false, // if checked on login this will set a cookie

    initialize: function() {

      Knack.user.on('login', this.handleLoginSubmitted, this);
      Knack.user.on('login_2fa', this.handle2faLogin, this);
    },

    handleEmbeddedLogin: function() {

      const appOrigin = `${Knack.protocol}${Knack.account.slug}.${Knack.domain}`
      const appLocation = `${appOrigin}/${Knack.app.attributes.slug}#${Knack.hash_parts[0]}`

      // Open another window to allow user to authenticate without us having to
      // do tricks to write a third-party cookie
      const windowHeight = 600
      const windowWidth = 800

      const windowLeftOffset = (window.screen.width / 2) - (windowWidth / 2)
      const windowTopOffset = (window.screen.height / 2) - (windowHeight / 2)

      // `location=no` is hopeful. Typically browsers don't allow for it,
      // due to phishing concerns. The most we can do is leave it in and
      // the browsers can decide how to handle it.
      const windowOptions = `toolbar=no,menubar=no,scrollbars=no,location=no,status=no,width=${windowWidth},height=${windowHeight},top=${windowTopOffset},left=${windowLeftOffset}`
      const loginWindow = window.open(appLocation, `_blank`, windowOptions)

      window.addEventListener(`message`, ({data, origin}) => {

        // This isn't a concern security-wise considering we are also using
        // cookies to verify the authentication, but never bad to have more
        // security.
        if (origin !== appOrigin) {

          return
        }

        if (data === `login-success`) {

          loginWindow.close()
          window.location.reload()
        }
      })

    },

    render: function() {

      if (Knack.isOnNonKnackDomain() &&
        Knack.is_embedded &&
        _.isEmpty($.cookie(`third-party-blocked`)) &&
        Knack.third_party_cookies_blocked === undefined) {

        // Purposefully wrapping in arrow func to maintain `this`
        // for `checkAuthentication
        return Knack.checkIfThirdPartyCookiesAreBlocked(() => this.render())
      }

      const usesPopupLogin = Knack.app && Knack.app.get &&
        Knack.app.get(`settings`).embed_login_method === `cookies`

      // Check if it is non-knack domain, which will exclude private clusters
      // Check if we are allowed to use pop-up login, this is just limiting
      //  who can use this method for now while we prove it works
      // Check if it is embedded, which will exclude custom domains which use
      //  tokens by default
      // Check if third-party cookies are blocked, which will allow browsers
      //  that can handle inline logins to use inline logins rather than this
      //  new method.
      if (Knack.isOnNonKnackDomain() && usesPopupLogin && Knack.is_embedded
        && Knack.third_party_cookies_blocked) {

        $(this.el).html(`
          <button class="kn-button is-primary" id="openEmbeddedLogin">Login</button>
        `)

        this.triggerRendered()
        this.triggerReady()

        return this
      }

      const loginTemplates = {
        [`v1.regular`]: login_template,
        [`v1.embed`]: embed_login_template,
        [`v2.regular`]: login_template_v2,
        [`v2.embed`]: embed_login_template_v2
      }

      // There is a small chance that a user not logging in via pop-up
      //  could get the embed login template
      //
      // This can happen if the app has their embed login setting set to `popups`
      // then someone opens a link that they are given that does
      // not use the ref setting `noopener` (which doesn't set `window.opener`)
      //
      // We can't access variables on `window.opener` and sending messages
      //  between windows on pop-up in the style of a handshake should be possible
      //  but is a bit more work.
      //
      // Nothing bad or different will happen, just their login template will look different

      const useMinimalLoginWindow = usesPopupLogin && Boolean(window.opener) && Knack.isOnNonKnackDomain()

      const version = Knack.getStyleEngine()
      const type = useMinimalLoginWindow ? `embed` : `regular`

      const template = loginTemplates[`${version}.${type}`]

      if (useMinimalLoginWindow) {

        const templateVariables = Object.assign({
          appName: Knack.app.get(`name`)
        }, this.model.view)

        $(this.el).html(Knack.render(`login-view`, template, templateVariables))

        document.title = Knack.trans(`Sign In`)

        $(`.kn-section`).removeClass(`kn-scenes kn-section`)
        $(`#knack-logo`).remove()
        $(`header`).remove()
      } else {

        $(this.el).html(Knack.render(`login-view`, template, this.model.view))
      }

      // check param for any error message
      if (Knack.hash_vars.klem) {
        $form = $('.kn-login');
        var message = decodeURIComponent(Knack.hash_vars.klem);
        message = '<p><strong>' + _.escape(message) + '</strong></p>';
        $.utility_forms.renderMessage($form, message, 'error');

        var query_string = Knack.getQueryString(null, ['klem']);
        var url = Knack.scene_hash;
        if (query_string) {
          url += '?' + query_string;
        }
      }

      if (Knack.app.attributes && Knack.app.attributes.settings && Knack.app.attributes.settings.inactivity_timeout_enabled) {

        this.$('label.remember').hide();
      }

      // trigger udpate
      this.triggerRendered();
      this.triggerReady();
      return this;
    },

    handleSubmitLogin: function(event) {

      event.preventDefault();

      const _this = this

      if (this.$(':input:submit').prop('disabled')) {

        return false;
      }

      // Disable form 
      this.$(':input, :input:submit').prop('disabled', true);

      this.$(':input').blur();

      if (this.$('input[name=remember]').is(':checked')) {

        this.remember_login = true;
      }

      $('#kn-loading-spinner').show();

      // remove any previous error messages
      this.$('.kn-message').remove();

      var $form = $(this.el);

      // clear this model so it always POSTs
      this.model.clear({
        silent: true
      });

      // we need to check to ensure a hash is available here
      // removing the hash or using an embed which may not have the hash
      // will break routing to the generated password reset url
      const url = window.location.href.includes(`#`)
        ? window.location.href
        : `${window.location.href}${Knack.scene_hash}`

      this.model.save({
        'email': this.$('input[name=email]').val(),
        'password': this.$('input[name=password]').val(),
        'totp_token': this.$('input[name=totp-token]').val(),
        'remember': (this.$('input[name=remember]').is(':checked')) ? true : false,
        'view_key': this.model.view.key,
        url
      }, {
        success: function() {

          if (Knack.isOnNonKnackDomain() && window.opener && Knack.app && Knack.app.get && Knack.app.get(`settings`).embed_login_method === `cookies`) {

            window.opener.postMessage('login-success', '*')
          }

          return $.utility_forms.successCallback(arguments)
        },
        error: function(model, result, options) {

          // modify the error for rate limit exceeded errors
          if (result.status === 429) {

            result.errors = [{
              message: 'Email or password incorrect.'
            }];

            result.statusText = 'unauthorized';
            result.status = 401;
          }

          // error for brute force
          if (result.status === 423) {

            var lockout_options = Knack.app.get('settings').lockout_options;

            if (!lockout_options) {

              lockout_options = {
                lockout_message: 'Still there? If so, click &quot;Remain Logged In&quot; below.'
              };
            }

            var error_message = Knack.app.get('settings').lockout_options.lockout_message;

            if (Knack.app.get('settings').lockout_options.lockout_password_reset) {

              error_message = `${error_message} ${Knack.app.get('settings').lockout_options.password_reset_message}`
            }

            result.errors = [{
              message: error_message
            }];
            result.statusText = 'unauthorized';
            result.status = 401;
          }

          $.utility_forms.errorCallback(model, result, $form);

          // Support Access Tool: after failed submission retain disabled password input
          if (Knack.isSupportAccessible()) {

            _this.$('input[type=password]').prop('disabled', true)
          }
        }
      });
    },

    handleLoginSubmitted: function(model, result) {

      this.model.set('password', '*******');

      // We need to reset Knack.session.user here as well as in Knack.handleLogin, because handleLogin
      // will have already run at this point, before we were able to clear out the password on the above line.
      Knack.session.user = this.model.toJSON()

      if (model.get(`refreshToken`)) {

        localStorage.setItem(`refreshToken-${Knack.app.id}`, model.get(`refreshToken`))
        localStorage.setItem(`refreshToken-user-${Knack.app.id}`, model.get(`id`))
      }

      if (model.get('remember')) {

        if (Knack.mode == 'renderer') {
          var key = Knack.app.id + '-remember-me';
          var cookie = {
            application: {
              id: Knack.app.id
            },
            user: {
              id: model.id,
              utility_key: model.get('utility_key'),
              token: model.get('token')
            }
          };
        } else {
          var key = 'knack-builder';
          var cookie = {
            user: {
              id: model.id,
              utility_key: model.get('utility_key')
            }
          };
        }

        $.cookie(key, JSON.stringify(cookie), {
          expires: 14
        });
      }
    },

    handle2faLogin: function(event) {

      $('#kn-loading-spinner').hide();

      $(this.el).html(Knack.render('login_2fa', login_2fa));
      $('#email').val(event.email)
      $('#password').val(event.password)

      return $('#login-register em').html('If you\'ve misplaced your backup codes and cannot login, please contact <a href=\'https://support.knack.com/hc/en-us/requests/new\'>Knack Support.</a>')
    },

    handleAppRefresh: function (event, result) {
      $('#kn-loading-spinner').hide();
      $(document).unbind('ang.application_refreshed', $.proxy(this.handleAppRefresh, this));
      Knack.user = result.session;
      Knack.router.navigate(Knack.scene_hash + '?login=1', true);
    }
  });
});
