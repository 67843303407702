var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        "is-modal-active": _vm.isModalActive,
        "save-label": _vm.saveLabel,
        "save-disabled": _vm.saveDisabled,
      },
      on: {
        save: _vm.submit,
        cancel: _vm.clickCancel,
        "click:background": _vm.closeRescheduleModal,
      },
    },
    [
      _vm.isModalActive
        ? _c(
            "template",
            { slot: "content" },
            [
              _c(
                "Banner",
                [
                  _c("template", { slot: "content" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.rescheduleWindow) },
                    }),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "kn-migration-modal_content-heading" }, [
                _vm._v("\n      Selected Apps\n    "),
              ]),
              _vm._v(" "),
              _c(
                "ol",
                { staticClass: "kn-migration-modal_app-list fancy-scrollbar" },
                _vm._l(_vm.selectedApplicationIds, function (id) {
                  return _c("li", { key: id }, [
                    _c("span", [_vm._v(_vm._s(_vm.getAppName(id)))]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "kn-migration-modal_content-heading" }, [
                _vm._v("\n      Reschedule Date\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "kn-migration-modal_date-time-container" },
                [
                  _c("DatePicker", {
                    attrs: {
                      "min-date": _vm.minDate.format(_vm.dateFormat),
                      date: _vm.dateInputValue,
                      "max-date": _vm.sqlMigrationsAppealEndDate,
                    },
                    on: { "update:date": _vm.updateDate },
                  }),
                  _vm._v(" "),
                  _c("TimePicker", {
                    attrs: {
                      "min-date": _vm.minTime,
                      date: _vm.timeInputValue,
                      "hour-increment": 1,
                      "minute-increment": 20,
                    },
                    on: { "update:time": _vm.updateTime },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.timezone))]),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "kn-migration-modal_error" }, [
                _vm._v(_vm._s(_vm.saveError)),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }