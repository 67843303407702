import moment from "moment-timezone";

export const getBrowserTimezone = () => {
  const timezoneAbbreviated = getBrowserTimezoneAbbr();

  const timezoneFull = new Date()
    .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
    .substring(4);

  return `${timezoneFull} (${timezoneAbbreviated})`;
};

export const getBrowserTimezoneAbbr = () => {
  const browserTimezone = moment.tz.guess();
  const timezoneAbbreviated = moment()
    .tz(browserTimezone)
    .zoneAbbr();

  // Evaluate zoneAbbr for only letters. eg Sao Paulo returns '-03' for zoneAbbr
  const timezoneAbbrDisplayed = Number.isInteger(Number(timezoneAbbreviated))
    ? `(${browserTimezone})`
    : timezoneAbbreviated;

  return timezoneAbbrDisplayed;
};
