var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isModalActive
    ? _c("div", [
        _c("div", {
          staticClass: "kn-migration-modal-bg",
          on: { click: _vm.clickBackground },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "kn-migration-modal" }, [
          _c("div", { staticClass: "kn-migration-modal-header" }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("span", {
              staticClass: "kn-migration-modal-close icon-cancel",
              on: { click: _vm.clickCancel },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "kn-migration-modal-body" },
            [_vm._t("content")],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "kn-migration-modal-footer" }, [
            _c("div", { staticClass: "kn-migration-modal-button-container" }, [
              _c(
                "button",
                {
                  staticClass: "kn-migration-modal-save",
                  attrs: { disabled: _vm.saveDisabled },
                  on: { click: _vm.clickSave },
                },
                [_vm._v("\n          " + _vm._s(_vm.saveLabel) + "\n        ")]
              ),
              _vm._v(" "),
              _vm.displayCancelButton
                ? _c(
                    "button",
                    {
                      staticClass: "kn-migration-modal-cancel",
                      on: { click: _vm.clickCancel },
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }