 define([
  'core/lib/jquery', 
  'core/lib/backbone'
], function($, Backbone) {  
  return Backbone.Model.extend({
    
    id: null,
    
    initialize: function() {
      
    },
    
    poll: function() {
      //log('I am polling!');
    },
    
    emit: function() {
      //log('I am emiting!');
    }
  });
 });