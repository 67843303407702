define(['jquery', 'underscore', 'core/backbone/views/BaseView'], function($, _, BaseView) {

  return BaseView.extend({

    initialize: function() {

      this.auto_complete_services = {
        fields: {},
        views: {}
      };
    },

    renderAddressAutoComplete: function(el, fields, views, element) {

      if (fields) {

        return this.renderAddressAutoCompleteFields(el, fields, element);
      }

      if (views) {

        return this.renderAddressAutoCompleteViews(el, views, element);
      }
    },

    renderAddressAutoCompleteFields: function(el, fields, element) {

      if (!element) {

        element = 'kn-input';
      }

      if (!fields) {

        return;
      }

      if (fields.toJSON) {

        fields = fields.toJSON();
      }

      fields = _.map(fields, function(field) {

        if (field.toJSON) {

          field = field.toJSON();
        }

        return field;
      });

      var address_fields = _.filter(fields, function(field) {

        if (field.type !== 'address') {

          return false;
        }

        return field.format && field.format.enable_address_autocomplete === true
      });

      if (_.isEmpty(address_fields)) {

        log('no address fields exist, returning');

        return;
      }

      this.renderAddressAutoCompleteEntity(el, this.auto_complete_services.fields, address_fields, element);
    },

    renderAddressAutoCompleteViews: function(el, views, element) {

      if (!element) {

        element = 'kn-map-input';
      }

      if (!views) {

        return;
      }

      views = _.map(views, function(view) {

        if (view.toJSON) {

          view = view.toJSON();
        }

        return view;
      });

      var address_views = _.filter(views, function(view) {

        return view.type === 'map' || view.type === 'search';
      });

      if (_.isEmpty(address_views)) {

        log('no address views exist, returning');

        return;
      }

      this.renderAddressAutoCompleteEntity(el, this.auto_complete_services.views, address_views, element);
    },

    renderAddressAutoCompleteEntity: function(el, auto_complete_services, values, element) {

      var _this = this;

      _this.loadGoogleMapsLibrary(() => {

        // values here is sort of ambiguous, could be a view or a field potentially depending on usage
        for (let value of values) {

          let input = _this.findKnackInputElement(el, element, value);

          if (!input) {

            continue
          }

          switch (input.type) {

            case 'form':

              // this is the case if there is a lat/lon input for this address
              if (!el.find(input.element + ' #street').length) {

                continue
              }

              _this.hideThenShowAddressStreet1InputDisableAutoBrowserAutocomplete(el, input.element + ' #street')

              const focus_handler = () => {

                if (this.gettingFocusFromTableInlineEdit) {

                  this.gettingFocusFromTableInlineEdit = false

                  return
                }

                // Chrome doesn't respect autocomplete='off', it needs to be a unique string to prevent native dropdown
                // Create a mutation observer, wait for autocomplete='off' to be set and then override it
                const autocompleteInput = el.find(input.element + ' #street')[0]

                const observerHack = new MutationObserver(() => {

                    observerHack.disconnect()
                    el.find(input.element + ' #street').attr('autocomplete', input.element + '#street')
                });

                observerHack.observe(autocompleteInput, {
                    attributes: true,
                    attributeFilter: ['autocomplete']
                })

                auto_complete_services[value.key] = new google.maps.places.Autocomplete((el.find(input.element + ' #street')[0]), {
                  types: ['geocode'],
                  fields: ['address_components'],
                });

                let have_pressed_down_arrow = false;

                el.find(input.element + ' #street').keydown((event) => {

                  if (event.which === 40) {

                    _this.selecting_address = true;
                    have_pressed_down_arrow = true;
                  }

                  if (event.which === 13 && have_pressed_down_arrow) {

                    return setTimeout(() => {

                      _this.selecting_address = false;
                    }, 250);
                  }

                  return;
                });

                auto_complete_services[value.key].addListener('place_changed', function() {

                  _this.handleSelectedAddress(el, input.element, auto_complete_services[value.key]);
                });
              }

              el.find(input.element + ' #street').off().one('keydown', focus_handler);

              el.find(input.element + ' #street').focus(focus_handler);

              el.find(input.element + ' #street').blur(() => {

                _this.selecting_address = false;
              });

              break;

            case 'map':

              $(input.element).hide().show();

              auto_complete_services[$(input.element).length] = new google.maps.places.Autocomplete(_.last($(input.element)), {
                types: ['geocode'],
                fields: ['address_components'],
              });

              break;

            case 'search':

              _this.hideThenShowAddressStreet1InputDisableAutoBrowserAutocomplete(el, input.element);

              auto_complete_services[el.find(input.element).length] = new google.maps.places.Autocomplete(_.first(el.find(input.element)), {
                types: ['geocode'],
                fields: ['address_components'],
              });

              auto_complete_services[el.find(input.element).length].addListener('place_changed', function() {

                _this.checkRemoveCountry(el, input.element, value);
              });

              break;
          }
        }
      });
    },

    hideThenShowAddressStreet1InputDisableAutoBrowserAutocomplete: function(el, element) {

      el.find(element).hide().show();
    },

    checkRemoveCountry: function(el, element, value) {

      if (value && value.format && value.format.format !== 'international_country') {

        var address = $(element).val().split(',');
        address.pop();
        el.find(element).val(address.join());
      }
    },

    findKnackInputElement: function(el, element, field) {

      if (element === `account-billing`) {

        return {
          type: `form`,
          element: `#payment-form`
        }
      }

      var address_field_element_1 = '#' + element + '-' + field.key;
      var address_field_element_2 = '.' + element + ' .value input';
      var address_field_popup = '.drop-content #' + element + '-' + field.key;
      var address_field_modal = '.kn-page-modal #' + element + '-' + field.key;
      var address_view_map = '#' + field.key + ' .kn-map-input';
      var address_view_search = '.kn-search-filter input';

      if (el.find(address_field_modal).length) {

        return {
          type: 'form',
          element: address_field_modal
        };
      }

      if (el.find(address_field_popup).length) {

        return {
          type: 'form',
          element: address_field_popup
        };
      }

      if (el.find(address_field_element_1).length) {

        return {
          type: 'form',
          element: address_field_element_1
        };
      }

      if (el.find(address_field_element_2).length) {

        return {
          type: 'filter',
          element: address_field_element_2
        };
      }

      if ($(address_view_map).length) {

        return {
          type: 'map',
          element: address_view_map
        };
      }

      if (el.find(address_view_search).length) {

        return {
          type: 'search',
          element: address_view_search
        };
      }

      return false
    },

    handleSelectedAddress: function(el, address_field_element, autocomplete) {

      var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        sublocality_level_1: 'short_name',
        country: address_field_element === `#payment-form` ? `short_name` : `long_name`,
        postal_code: 'short_name',
        postal_town: 'short_name',
        neighborhood: 'short_name',
        sublocality_level_3: 'short_name'
      };

      var place = autocomplete.getPlace();

      var address_data = {};

      if (!place || !place.address_components) {

        return;
      }

      for (var i = 0; i < place.address_components.length; i++) {

        var addressType = place.address_components[i].types[0];

        if (componentForm[addressType]) {

          address_data[addressType] = place.address_components[i][componentForm[addressType]];
        }
      }

      this.clearAddress(el, address_field_element);

      return this.fillInAddress(el, address_field_element, address_data);
    },

    clearAddress: function(el, address_field_element) {

      el.find(address_field_element + ' #street').val('');
      el.find(address_field_element + ' #city').val('');
      el.find(address_field_element + ' #state').val('');
      el.find(address_field_element + ' #zip').val('');
      el.find(address_field_element + ' #country').val('');
    },

    fillInAddress: function(el, address_field_element, address_data) {

      if (address_data && address_data.street_number && address_data.route) {

        el.find(address_field_element + ' #street').val(address_data.street_number + ' ' + address_data.route);
      } else if (address_data && address_data.route) {

        el.find(address_field_element + ' #street').val(address_data.route);
      } else if (address_data && address_data.sublocality_level_3) {

        el.find(address_field_element + ' #street').val(address_data.sublocality_level_3);
      }

      if (address_data && address_data.locality) {

        el.find(address_field_element + ' #city').val(address_data.locality);
      } else if (address_data && address_data.sublocality_level_1) {

        el.find(address_field_element + ' #city').val(address_data.sublocality_level_1);
      } else if (address_data && address_data.postal_town) {

        el.find(address_field_element + ' #city').val(address_data.postal_town);
      } else if (address_data && address_data.neighborhood) {

        el.find(address_field_element + ' #city').val(address_data.neighborhood);
      }

      if (address_data && address_data.administrative_area_level_1) {

        el.find(address_field_element + ' #state').val(address_data.administrative_area_level_1);
      }

      if (address_data && address_data.postal_code) {

        el.find(address_field_element + ' #zip').val(address_data.postal_code);
      }

      if (address_data && address_data.country) {

        el.find(`${address_field_element} #country`).val(address_data.country)
      }
    }
  });
});
