<template>
  <div class="kn-migration_date-picker-container">
    <VueFlatPicker
      :value="date"
      :config="config"
    />
  </div>
</template>

<script>
import VueFlatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    VueFlatPicker,
  },

  props: {
    date: {
      type: String,
      required: true,
    },
    dateFormat: {
      type: String,
      default: 'm/d/Y',
    },
    minDate: {
      type: String,
      default: undefined,
    },
    maxDate: {
      type: String,
      default: undefined,
    },
  },

  emits: ['update:date'],

  computed: {
    config() {
      return {
        allowInput: true,
        dateFormat: this.dateFormat,
        minDate: this.minDate,
        maxDate: this.maxDate, // Format needs to match `this.dateFormat`
        onClose: this.onClose,
      };
    },
  },

  methods: {
    onClose(selectedDates, dateStr, instance) {
      this.$emit('update:date', { selectedDates, dateStr, instance });
    },
  },
};
</script>

<style lang="scss">
.kn-migration_date-picker-container {
  .flatpickr-input:not(.flatpickr-mobile) {
    max-width: 96px;
  }
}

.flatpickr-day {
  border-radius: 2px;

  &.selected,
  &.prevMonthDay.selected,
  &.nextMonthDay.selected {
    border: transparent;
    background: #91267f;
    color: #fff;

    &:hover {
      opacity: 0.7;
      background: #91267f;
    }
  }
}
</style>
