define([
  'jquery',
  'core/lib/backbone',
  'vue',
  'moment',
  'underscore',
  'core/backbone/views/BaseView',
  'core/backbone/models/DataRow',
  'core/templates/form.html',
  'core/templates/v2/form-v2.html',
  'core/templates/inputs/text_input.html',
  'core/templates/v2/inputs/text_input-v2.html',
  'core/templates/inputs/email.html',
  'core/templates/v2/inputs/email-v2.html',
  'core/templates/inputs/address.html',
  'core/templates/v2/inputs/address-v2.html',
  'core/templates/inputs/name.html',
  'core/templates/v2/inputs/name-v2.html',
  'core/templates/inputs/phone.html',
  'core/templates/v2/inputs/phone-v2.html',
  'core/templates/inputs/text_area.html',
  'core/templates/v2/inputs/text_area-v2.html',
  'core/templates/inputs/password.html',
  'core/templates/v2/inputs/password-v2.html',
  'core/templates/inputs/multiple_choice.html',
  'core/templates/v2/inputs/multiple_choice-v2.html',
  'core/templates/inputs/date_time.html',
  'core/templates/v2/inputs/date_time-v2.html',
  'core/templates/inputs/timer.html',
  'core/templates/v2/inputs/timer-v2.html',
  'core/templates/inputs/rating.html',
  'core/templates/inputs/signature.html',
  'core/templates/inputs/connection.html',
  'core/templates/v2/inputs/connection-v2.html',
  'core/templates/inputs/image.html',
  'core/templates/v2/inputs/image-v2.html',
  'core/templates/inputs/file.html',
  'core/templates/v2/inputs/file-v2.html',
  'core/templates/inputs/boolean.html',
  'core/templates/v2/inputs/boolean-v2.html',
  'core/templates/inputs/radio_button.html',
  'core/templates/inputs/check_box.html',
  'core/templates/inputs/link.html',
  'core/templates/v2/inputs/link-v2.html',
  'core/templates/inputs/rich_text.html',
  'core/templates/inputs/section_break.html',
  'core/templates/v2/inputs/section_break-v2.html',
  'core/templates/inputs/equation.html',
  'core/templates/v2/inputs/equation-v2.html',
  'core/templates/calendar_repeat.html',
  'core/templates/v2/calendar_repeat-v2.html',
  'core/templates/calendar_repeat_edit.html',
  `core/templates/v3/divider.html`,
  '@knack/equation-helper',
  '@knack/rules-helper',
  '@knack/validation-helper',
  'core/ui/chosen',
  'core/ui/rateit',
  'core/ui/signature',
  'jquery-mask-plugin',
  'core/ui/timepicker',
  'core/ui/inputs/connection-picker',
  'core/ui/inputs/asset-picker',
  'core/ui/helpers/address-helper',
  'core/lib/redactor/redactor/redactor.min',
  'core/utility/utility-cookies'
], function($, Backbone, Vue, moment, _, BaseView, DataRow, form_view, form_view_v2, TextField, TextField_v2, Email, Email_v2, Address, Address_v2, Name, Name_v2, Phone, Phone_v2, TextArea, TextArea_v2, Password, Password_v2, MultipleChoice, MultipleChoice_v2, DateTime, DateTime_v2, Timer, Timer_v2, Rating, Signature, Connection, Connection_v2, Image, Image_v2, File, File_v2, Boolean, Boolean_v2, RadioButton, CheckBox, Link, Link_v2, RichText, SectionBreak, SectionBreak_v2, Equation, Equation_v2, CalendarRepeat, CalendarRepeat_v2, CalendarRepeatEdit, Divider, { EquationHelper }, commonRuleHelper, Validation_Helper, chosen, rateit, signature, mask, timepicker, ConnectionPicker, AssetPicker, AddressHelper, redactor, CookieUtil) {
  return BaseView.extend({

    is_new: true,
    ignore_trigger_rendered: false,
    ignore_hidden_inputs: true,
    returned_record: null, // the record sent back from the server after submission; the id is used for redirects
    savedRecordData: null,
    connection_inputs: 0,
    action: null, // the original action is set once in case it needs to be reset when reloading the form
    isValidForm: true,
    inputsWithErrors: [],
    errorMessage: '',

    events: _.extend({
      'submit form': 'handleSubmitForm',
      'click #check_submit': 'handleSubmitForm',
      'click .kn-form-reload': 'handleReloadForm'
    }, BaseView.prototype.events),

    // events: {
    //   'submit form': 'handleSubmitForm',
    //   'click #check_submit': 'handleSubmitForm',
    //   'click .kn-form-reload': 'handleReloadForm'
    // },

    // options.listener - send the form submission to a listener object

    input_map: null,

    initialize: function(options) {

      this.options = options || {
      };

      var _this = this;
      this.model.on('sync', function(model, response, event) {
        if (!event.fetch) {
          _this.handleFormSubmitted(model, response, event);
        }
      }); // edit forms trigger this on load.

      this.model.view.mode = this.options.mode || 'form';

      // store original action
      if (!this.action) {
        this.action = this.model.view.action;
      }

      if (this.model.view.action == 'update') {
        this.model.bind('change', this.render, this);
        this.model.bind('recast-insert', this.recastInsert, this);
      }

      this.address_helper = new AddressHelper();

      if (Knack.getStyleEngine() === 'v2') {
        this.input_map = {
          'text_input': TextField_v2,
          'text_field': TextField_v2,
          'auto_increment': TextField,
          'email': Email_v2,
          'address': Address_v2,
          'name': Name_v2,
          'text_area': TextArea_v2,
          'password': Password_v2,
          'multiple_choice': MultipleChoice_v2,
          'date_time': DateTime_v2,
          'timer': Timer_v2,
          'rating': Rating,
          'signature': Signature,
          'connection': Connection_v2,
          'image': Image_v2,
          'file': File_v2,
          'boolean': Boolean_v2,
          'radio_buttons': RadioButton,
          'check_box': CheckBox,
          'link': Link_v2,
          'rich_text': RichText,
          'section_break': SectionBreak_v2,
          'equation': Equation_v2,
          'phone': Phone_v2,
          'user_roles': Connection,
          divider: Divider
        };
      } else {
        this.input_map = {
          'text_input': TextField,
          'text_field': TextField,
          'auto_increment': TextField,
          'email': Email,
          'address': Address,
          'name': Name,
          'text_area': TextArea,
          'password': Password,
          'multiple_choice': MultipleChoice,
          'date_time': DateTime,
          'timer': Timer,
          'rating': Rating,
          'signature': Signature,
          'connection': Connection,
          'image': Image,
          'file': File,
          'boolean': Boolean,
          'radio_buttons': RadioButton,
          'check_box': CheckBox,
          'link': Link,
          'rich_text': RichText,
          'section_break': SectionBreak,
          'equation': Equation,
          'phone': Phone,
          'user_roles': Connection,
          divider: Divider
        };
      }
    },

    // used when "to-one" connections haven't been created yet to the parent form
    recastInsert: function() {
      var current_scene = Knack.scenes.getBySlug(Knack.router.current_scene);
      if (current_scene && this.model.view.source.object != current_scene.get('object')) {
        delete this.model.id;
        this.model.view.action = 'create';

        // set any defaults
        this.model.initInputs();
      }
      this.render();
    },

    // called by scene
    renderView: function() {
      var _this = this;

      // if update then fetch the record to populate the form
      if (this.model.view.action == 'update') {
        this.model.fetch({
          fetch: true,
          success: function() {
            // renderEquations actually gets called during the render event, but we need to make sure it is done again once the DOM is loaded and the update record info is populated
            setTimeout(function() {
              _this.renderEquations();
            }, 1500);
          },
          error: (model, result) => {
            const matchedText = result.responseText.match(/\{.+\}/g);

            let errors = [];
            if (matchedText && matchedText.length > 0) {
              errors = JSON.parse(decodeURIComponent(matchedText[0])).errors;
            }

            $.utility_forms.errorCallback(null, {
              status: 400,
              errors: errors
            }, this.$el);

            this.$el.parent().show()
          }
        });
      } else {
        this.render();
      }

    },

    getInputs: function() {

      var inputs = [];
      if (this.model.view.groups) {
        var groups = this.model.view.groups;
        _.each(groups, function(group) {
          _.each(group.columns, function(col) {
            inputs = inputs.concat(col.inputs);
          });
        });
      } else if (this.model.view.inputs) {
        inputs = this.model.view.inputs;
      }

      return inputs;
    },

    render: function() {

      // In v2 this was done on the schema, but I don't think there's
      // a good reason to continue saving an extra property, search
      // for matching rules instead.
      const hasUserLocation = this.model.view.rules && this.model.view.rules.records && !!this.model.view.rules.records.find(rule => !!rule.values.find(value => value.type === `current_location`))

      // part 1, check for geolocation
      // Keeping the old property as a precaution.
      if ((this.model.view.user_location || hasUserLocation) && Knack.account.product_plan.level > 1) {

        if (navigator.geolocation) {
          var _this = this;
          navigator.geolocation.getCurrentPosition(

            // success
            function(position) {

              // this.$(':input[name=map_origin]').val(this.model.view.starting_address);
              _this.coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };

              _this.render2();

            },

            // error
            function() {
              _this.render2();
            }
          );
        } else {
          this.render2();
        }
      } else {
        this.render2();
      }
    },

    render2: function() {
      this.is_new = this.model.isNew();

      this.form_object = (this.model.view.source && this.model.view.source.object) ? Knack.objects.get(this.model.view.source.object) : {
      };

      var render_data = this.input_map;
      render_data.view = this.model.view;
      render_data.data = this.model.toJSON();
      render_data.fields = (this.form_object.fields || {
      });

      if (Knack.getStyleEngine() === 'v2') {
        $(this.el).html(Knack.render('form-view', form_view_v2, render_data));
      } else {
        $(this.el).html(Knack.render('form-view', form_view, render_data));
      }

      this.address_helper.renderAddressAutoComplete($(this.el), render_data.fields, null, null);

      this.renderForm();

      $('form').attr('id', this.options.form_id);

      // unbind
      // any future renders should now be on 'save' or 'sync' events
      this.model.unbind('change', this.render, this);

      if (!this.options.disabled && this.model.view.key) {
        this.connection_inputs = this.$('.kn-input-connection:not(.kn-read-only)').length;

        if (this.connection_inputs) {
          $(document).bind('knack-connections-load.' + this.model.view.key, $.proxy(this.handleLoadConnections, this));
        }
      }

      // trigger
      if (this.connection_inputs == 0 && !this.ignore_trigger_rendered) {
        this.renderRules();
        this.triggerReady();
        this.triggerRendered();
        // equations first run
        this.renderEquations();
      }

      return this;
    },

    handleLoadConnections: function() {
      this.connection_inputs --;

      if (this.connection_inputs == 0 && !this.ignore_trigger_rendered) {
        this.renderRules();
        this.triggerReady();
        this.triggerRendered();
        // equations first run
        this.renderEquations();
      }
    },

    getFormErrorHandler: function(response) {

      this.number_of_uploads_in_progress--;

      console.log('response from FormView:', response)

      let parsedErrorResponse = ``

      try {
        parsedErrorResponse = JSON.parse(response.responseText);
      } catch (e) {
        let errorMessage = 'Something went wrong. Please try again';
        if (response.responseText) {
          errorMessage = response.responseText;
        }
        parsedErrorResponse = { errors: [{ message: errorMessage }] };
      }

      const { errors } = parsedErrorResponse

      $.utility_forms.errorCallback(null, {
        status: 400,
        errors
      }, this.$('form'));
    },

    renderForm: function() {
      var _this = this;

      // don't show these in builder forms (disabled = true)
      if (!this.options.disabled) {

        // multiple choice - add options

        // dates
        this.$('.kn-input-multiple_choice .kn-add-option').bind('click', $.proxy(this.handleClickAddOption, this));

        // dates
        this.$('.kn-input-date_time:not(.kn-read-only)').each(function() {

          var date_format;
          var time_format;
          var $date_input = $(this);
          var field = $(this).find('input[name=key]').val();
          var input = _.find(_this.getInputs(), function(input) {
            return input.field && input.field.key == field;
          });
          if (input) {
            if (input.format && input.format.date_format != 'Ignore Date') {
              var date_format = (input.format.date_format == 'dd/mm/yyyy') ? 'dd/mm/yy' : 'mm/dd/yy';
              $(this).find('.knack-date').datepicker({
                dateFormat: date_format,
                beforeShow: () => {

                  $('#ui-datepicker-div').addClass(`prevent-close`);
                }
              });
            }

            //log('> > > ADDING TIME PICKER, _this.model.view.key: ' + _this.model.view.key + '; format: ' + input.format.time_format + '; length: ' +$('#' + _this.model.view.key + '-' + field + '-time').length );
            if (input.format && input.format.time_format != 'Ignore Time') {
              time_format = (input.format.time_format == 'HH:MM am') ? 'g:ia' : 'H:i';
              if ($('#' + _this.model.view.key + '-' + field + '-time').length) {
                $('#' + _this.model.view.key + '-' + field + '-time').timepicker({
                  timeFormat: time_format,
                  step: 15
                });
              }
              if ($('#' + _this.model.view.key + '-' + field + '-time-to').length) {
                $('#' + _this.model.view.key + '-' + field + '-time-to').timepicker({
                  timeFormat: time_format,
                  step: 15
                });
              }
            }

            // from-to handling
            if (input.format && input.format.calendar) {
              $('#' + _this.model.view.key + '-' + field).change(function(event) {
                $date_input.find('input[name=to_date]').val($(this).val());
              });
            }

            // DATE REPEATING
            if (input.format && input.format.date_format != 'Ignore Date') {

              var $repeat = $(this).find('input[name=repeat]');

              if (input.value && input.value.repeat) {
                _this.renderRepeatChecked($repeat, input.value.repeat, date_format);
              }

              // repeat click
              $repeat.click(function(event) {

                // disable
                if ($(this).attr('checked') != 'checked') {

                  $repeat.data('repeat', null);
                  $repeat.parent().find('span.repeat-label').text('Repeat...');
                  $repeat.parent().parent().find('span.repeat-edit').empty();
                  $repeat.removeAttr('checked');

                // enable
                } else if ($(this).attr('checked') == 'checked') {

                  event.preventDefault();
                  event.stopImmediatePropagation();

                  _this.renderRepeat($repeat, date_format);
                }

              });
            }
          }

          // Conditional rule values aren't in the input value but check model attributes as they may exist there
          if (
            (_.isUndefined(_this.model.attributes.id) || _.isNull(_this.model.attributes.id)) &&
            !_.isUndefined(_this.model.attributes[field]) &&
            !_.isNull(_this.model.attributes[field]) &&
            !_.isUndefined(_this.model.attributes[field].repeat) &&
            !_.isNull(_this.model.attributes[field].repeat)
          ) {

            const $repeat = $(this).find('input[name=repeat]')

            _this.renderRepeatChecked($repeat, _this.model.attributes[field].repeat, date_format)
          }
        }); // end iterate date_times

        // timers
        this.$('.kn-input-timer:not(.kn-read-only)').each(function(index) {

          var field = $(this).find('input[name=key]').val();
          var input = _.find(_this.getInputs(), function(input) {
            return input.field && input.field.key == field;
          });
          if (input) {
            if (input.format.date_format != 'Ignore Date') {
              var date_format = (input.format.date_format == 'dd/mm/yyyy') ? 'dd/mm/yy' : 'mm/dd/yy';
              $(this).find('.knack-date').datepicker({
                dateFormat: date_format,
                beforeShow: () => {

                  $('#ui-datepicker-div').addClass(`prevent-close`);
                }
              });
            }
            var time_format = (input.format.time_format == 'HH:MM am') ? 'g:ia' : 'G:i';
            var step = input.format.minutes || 15;
            if ($('#' + _this.model.view.key + '-' + field + '-time-from').length) {
              $('#' + _this.model.view.key + '-' + field + '-time-from').timepicker({
                timeFormat: time_format,
                step: step
              });
            }
            if ($('#' + _this.model.view.key + '-' + field + '-time-to').length) {
              $('#' + _this.model.view.key + '-' + field + '-time-to').timepicker({
                timeFormat: time_format,
                step: step
              });
            }
          }
        });

        // connections
        this.$('.kn-input-connection:not(.kn-read-only)').each(function() {
          var field = $(this).find(':input[type=hidden]:first').attr('name');
          var input = _.find(_this.getInputs(), function(input) {
            return input.field && input.field.key == field;
          });

          var options = {
            source: _this.model.view.source,
            input: input,
            form_action: _this.model.view.action,
            view_key: _this.model.view.key,
            disabled: input && input.field && input.field.read_only,
            fake_view_key: _this.model.view.fake_view_key
          };

          if (_this.model.view.action == 'update') {
            // send in ID in case cp source limits based on the current record
            //log(_this.model);
            options.record_id = _this.model.id;
          } else {
            options.form_source = _this.model.view.source;
          }
          $(this).connectionPicker(options);
        });

        _this.number_of_uploads_in_progress = 0;

        // assets
        _this.$('.kn-input-image:not(.kn-read-only)').each(function() {

          $(this).assetPicker({
            type: 'image',
            origin: {
              view_key: Knack.getCurrentViewKey(_this.model),
              field_key: $(this).find(':input[type=hidden]:first').attr('name')
            },
            upload_in_progress_handler: function(fileData) {
              _this.options.disabled = true;
              _this.number_of_uploads_in_progress++;

              $('input[type=submit]').addClass('disabled'); //renderer forms
              $('.save').addClass('disabled'); // builder forms

              $('.kn-message.error, .kn-message.is-error').remove();
              $('.input-error').removeClass('input-error');

              return _this.handleAssetValidation(fileData, this.origin.field_key)
            },
            getAssetType: function() { return 'image' },
            success_handler: function() {

              _this.number_of_uploads_in_progress--;
              if (_this.number_of_uploads_in_progress === 0) {

                _this.options.disabled = false;

                if (Knack.mode === 'renderer') {
                  $('input[type=submit]').removeClass('disabled');
                } else {
                  $('.save').removeClass('disabled'); // builder forms
                }
              }
            },
            error_handler: _this.getFormErrorHandler.bind(_this)
          });
        });

        _this.$('.kn-input-file:not(.kn-read-only)').each(function() {

          $(this).assetPicker({
            type: 'file',
            origin: {
              view_key: Knack.getCurrentViewKey(_this.model),
              field_key: $(this).find(':input[type=hidden]:first').attr('name')
            },
            upload_in_progress_handler: function(fileData) {
              _this.options.disabled = true;
              _this.number_of_uploads_in_progress++;

              $('input[type=submit]').addClass('disabled'); // renderer forms
              $('.save').addClass('disabled'); // builder forms

              $('.kn-message.error, .kn-message.is-error').remove();
              $('.input-error').removeClass('input-error');

              return _this.handleAssetValidation(fileData, this.origin.field_key)
            },
            getAssetType: function() { return 'file' },
            success_handler: function() {

              _this.number_of_uploads_in_progress--;
              if (_this.number_of_uploads_in_progress === 0) {

                _this.options.disabled = false;

                if (Knack.mode === 'renderer') {
                  $('input[type=submit]').removeClass('disabled');
                } else {
                  $('.save').removeClass('disabled'); // builder forms
                }
              }
            },
            error_handler: _this.getFormErrorHandler.bind(_this)
          });
        });

        // add chosen to multi-choice (non-checkboxes);
        this.$('.kn-input-multiple_choice .chzn-select').chosen();

        // redactor: HTML Views
        this.$('.kn-input-rich_text textarea').redactor({
          autoresize: true,
          removeComments: true,
          buttons: ['html', 'formatting', 'bold', 'italic', 'deleted', 'link', 'unorderedlist', 'orderedlist', 'outdent', 'indent', 'alignment', 'horizontalrule'],
          plugins: ['fontcolor', 'image_web_link', 'video', 'table']
        });
      }

      // phone
      $.jMaskGlobals.maskElements = `.kn-input-phone:not(.kn-read-only) :input`
      $.jMaskGlobals.watchInputs = false
      $.jMaskGlobals.translation = {
        '9': {
          pattern: /\d/,
          optional: true
        },
        '?': {
          pattern: /\?/,
          optional: true
        }
      }

      this.$('.kn-input-phone:not(.kn-read-only)').each(function() {
        var field = _this.form_object.fields.get($(this).find(':input').attr('id')).toJSON();
        var format = (field.format && field.format.format) ? field.format.format : '(999) 999-9999';
        if (format != 'any') {
          if (field.format && field.format.extension) {
            if (format.indexOf('?') == -1) {
              format += '?';
            }
            format += ' x99999';
          }
          $(this).find(':input').mask(format, {
            placeholder: format.replace(/9|\?/g, `_`)
          });
        }
      });

      // ratings
      this.$('.kn-input-rating:not(.kn-read-only)').each(function() {
        var key = $(this).find(':input[name=key]').val();
        var field = _this.form_object.fields.get(key);
        var step = (field.get('format').allow_half) ? 0.5 : 1;
        var val_id = _this.model.view.key + '-' + key + '-value';
        var rate_vals = {
          max: 5,
          step: step,
          backingfld: '#' + val_id,
          resetable: true,
          max: field.get('format').max,
          starwidth: 19,
          starheight: 20
        };

        if (_this.options.disabled) {
          rate_vals.value = 2;
          rate_vals.ispreset = true;
          rate_vals.readonly = true;
        }

        $('#' + _this.model.view.key + '-' + key).rateit(rate_vals);
      });
      //$('.rating').bind('over', function (event,value) { $(this).attr('title', value); });

      // signatures
      //this.renderSignatures();

      // date events
      this.$('input[name=all_day]').change(function(event) {
        $(event.currentTarget).closest('.kn-input').find('.kn-time').toggle();
      });

      var baseline_security = {};

      if (Knack.mode !== 'render') {

        baseline_security = {
          password_minimum_character: true,
          password_require_no_common: true,
          password_must_match: true
        };
      }

      var hipaa_security_options = {
        password_minimum_character: true,
        password_require_no_common: true,
        password_must_match: true
      };

      var app_attributes = Knack.app.attributes;

      var security_settings = (Knack.isHIPAA()) ? hipaa_security_options : (app_attributes) ? app_attributes.settings.password_options : baseline_security;
      var security_settings_array = _.keys(security_settings);

      if (window.location.href.indexOf('?hipaa=true') > -1) {

        $('.view-header').append('<p>Your security account settings require a stronger password. Please create a new password.</p>');
      }

      if (security_settings_array.length > 0) {

        // initialize disabling submit button
        _this.isFormSubmitable(false);

        var validation_message = {
          props: ['message', 'validated'],
          template: '<div :class="{ \'input-suggestion__message\': true, \'input-suggestion__message--active\': validated }">{{ message }}</div>'
        };

        var input_templates = {
          password_minimum_character: '\
            <validation-message \
              :validated="password_minimum_character" \
              message="Minimum of 8 characters"> \
            </validation-message> \
            ',
          password_require_number: '\
            <validation-message \
              :validated="password_require_number" \
              message="Number"> \
            </validation-message> \
            ',
          password_special_character: '\
            <validation-message \
              :validated="password_special_character" \
              message="Special character"> \
            </validation-message> \
            ',
          password_require_lowercase: '\
            <validation-message \
              :validated="password_require_lowercase" \
              message="Lowercase letter"> \
            </validation-message> \
            ',
          password_require_uppercase: '\
            <validation-message \
              :validated="password_require_uppercase" \
              message="Uppercase letter"> \
            </validation-message> \
            ',
          password_require_no_common: '\
            <validation-message \
              :validated="password_require_no_common" \
              message="Must not be common password"> \
            </validation-message> \
            ',
          password_must_match: '\
            <validation-message \
              :validated="password_must_match" \
              message="Passwords must match"> \
            </validation-message> \
            '
        };

        var validation_template_inputs = security_settings_array.reduce(function(template, option) {

          return template += input_templates[option];
        }, '');

        var header_text = Knack.isHIPAA() ? 'High Security Passwords Need:' : 'Passwords Need:';

        var validation_messages_container = {
          props: security_settings_array,
          template: '\
            <div class="input-suggestion__container"> \
              <header class="input-suggestion__header">' + header_text + '</header> \
              ' + validation_template_inputs + '\
            </div>\
          ',
          components: {
            'validation-message': validation_message
          }
        };

        if (document.getElementById('kn-input-password')) {

          new Vue({
            el: '#kn-input-password',
            data: {
              password: '',
              password_confirmation: '',
              password_minimum_character: false,
              password_require_number: false,
              password_special_character: false,
              password_require_lowercase: false,
              password_require_uppercase: false,
              password_require_no_common: false,
              password_must_match: false,
              rules: security_settings_array
            },
            watch: {
              password: function(password) {

                var password_values = this;
                var password_confirmation = this.password_confirmation;
                var validation_errors = Validation_Helper.getPasswordValidationFailures(password, security_settings, Knack.isHIPAA());

                this.password_minimum_character = validation_errors.indexOf('password_minimum_character') === -1;
                this.password_minimum_character = validation_errors.indexOf('password_minimum_character') === -1;
                this.password_require_number = validation_errors.indexOf('password_require_number') === -1;
                this.password_special_character = validation_errors.indexOf('password_special_character') === -1;
                this.password_require_lowercase = validation_errors.indexOf('password_require_lowercase') === -1;
                this.password_require_uppercase = validation_errors.indexOf('password_require_uppercase') === -1;
                this.password_require_no_common = validation_errors.indexOf('password_require_no_common') === -1;
                this.password_must_match = password === password_confirmation;

                // form submit active/deactive if all rules are passing
                var can_submit = this.rules.every(function(rule) {

                  return password_values[rule];
                });

                _this.isFormSubmitable(can_submit);
              },
              password_confirmation: function(password_confirmation) {

                var password_values = this;
                var password = this.password;
                var validation_errors = Validation_Helper.getPasswordValidationFailures(password, security_settings, Knack.isHIPAA());

                this.password_minimum_character = validation_errors.indexOf('password_minimum_character') === -1;
                this.password_minimum_character = validation_errors.indexOf('password_minimum_character') === -1;
                this.password_require_number = validation_errors.indexOf('password_require_number') === -1;
                this.password_special_character = validation_errors.indexOf('password_special_character') === -1;
                this.password_require_lowercase = validation_errors.indexOf('password_require_lowercase') === -1;
                this.password_require_uppercase = validation_errors.indexOf('password_require_uppercase') === -1;
                this.password_require_no_common = validation_errors.indexOf('password_require_no_common') === -1;
                this.password_must_match = password === password_confirmation;

                // form submit active/deactive if all rules are passing
                var can_submit = this.rules.every(function(rule) {

                  return password_values[rule];
                });

                _this.isFormSubmitable(can_submit);
              }
            },
            components: {
              'validation-messages-container': validation_messages_container
            }
          });
        }
      }

      // image events
      this.$('[data-click="img-remove-v2"]').click(function(event) {

        event.preventDefault();
        event.stopImmediatePropagation(); // for inline editing popovers

        var asset_id = $(event.currentTarget).data('asset-id');
        // Set form value to blank
        $(this).closest('.kn-input-image').find('.image').val('');
        // Clear the image in the view for this asset ID
        $('.kn-asset-current[data-asset-id=' + asset_id + '] a').empty();
      });

      this.$('[data-click="img-remove"]').click(function(event) {
        event.preventDefault(); event.stopImmediatePropagation(); // for inline editing popovers
        var asset_id = $(event.currentTarget).data('asset-id');
        // Set form value to blank
        $(this).closest('.kn-input-image').find('.image').val('');
        // Clear the image in the view for this asset ID
        $('.kn-asset-current[data-asset-id=' + asset_id + ']').empty();
      });

      this.$('.kn-file-remove').click(function(event) {
        event.preventDefault(); event.stopImmediatePropagation();
        $(this).closest('.kn-input-file').find('.file').val('');
        $(this).closest('.kn-asset-current').empty();
      });
    },

    // Repeat Modal
    renderRepeat: function($repeat, date_format) {
      // render repeat popover
      var _this = this;

      if (Knack.getStyleEngine() === 'v2') {
        var $html = Knack.render('CalendarRepeat', CalendarRepeat_v2);
      } else {
        var $html = Knack.render('CalendarRepeat', CalendarRepeat);
      }

      this.handleAddPopoverClass();

      Knack.renderModal($html, {
        'class': 'small',
      });

      var repeat = $repeat.data('repeat');

      $('#kn-calendar-repeat select[name="frequency"]').change(function(event) {

        var freq = $(this).val();

        // interval
        var show_interval = (freq == 'daily' || freq == 'weekly' || freq == 'monthly' || freq == 'yearly');
        $('.kn-interval').toggle((show_interval));
        if (show_interval) {
          var interval_type = Knack.trans('days');
          if (freq == 'weekly') {
            interval_type = Knack.trans('weeks');
          } else if (freq == 'monthly') {
            interval_type = Knack.trans('months');
          } else if (freq == 'yearly') {
            interval_type = Knack.trans('years');
          }
          $('.kn-interval-type').text(interval_type);
        }

        // dow
        $('.kn-dow').toggle(freq == 'weekly');

        // repeat type (day of week/day of month)
        $('.kn-repeat-type').toggle(freq == 'monthly');

        // endson
        $('#kn-calendar-repeat input[name=endson]').change(function(event) {
          //$('#kn-calendar-repeat label>input[type=text]').attr('disabled', 'disabled')
          $(this).parent().find('input[type=text]').focus();//.removeAttr('disabled').focus();
          if ($(this).val() == 'limit' && !$(this).parent().find('input[type=text]').val()) {
            $(this).parent().find('input[type=text]').val(5);
          }
        });
        $('input[name=end_date]').datepicker({
          dateFormat: date_format,
          beforeShow: () => {

            $('#ui-datepicker-div').addClass(`prevent-close`);
          }
        });

        // save
        $('#kn-calendar-repeat .save').unbind().click(function(event) {
          event.preventDefault();
          event.stopImmediatePropagation();

          var repeat = {
          };
          $('#kn-calendar-repeat :input').each(function() {
            var val = $(this).val();
            if ($(this).is(':checkbox')) {
              val = $(this).prop('checked');
            }
            repeat[$(this).attr('name')] = val;
          });
          repeat.endson = $('#kn-calendar-repeat input[name=endson]:checked').val();
          repeat.repeatby = $('#kn-calendar-repeat input[name=repeatby]:checked').val();

          _this.renderRepeatChecked($repeat, repeat, date_format);

          _this.handleRemovePopoverClass();

          Knack.closeModal();
        });

      });

      // populate
      if (repeat) {
        $('#kn-calendar-repeat :input').not(':radio').each(function() {
          var val = repeat[$(this).attr('name')];
          if ($(this).is(':checkbox') && (val === true || val === false)) {
            //val = (val === true) ? 'on' : 'off';
            if (val) {
              $(this).prop('checked', true);
            } else {
              $(this).prop('checked', false);
            }
          } else {
            // set
            if (val) {
              $(this).val(val);
            }

          }
        });

        $('#kn-calendar-repeat input[name=endson][value=' + repeat.endson + ']').attr('checked', 'checked');
        $('#kn-calendar-repeat input[name=endson][value=' + repeat.endson + ']').parent().find('input[type=text]').removeAttr('disabled');
        $('#kn-calendar-repeat input[name=repeatby][value=' + repeat.repeatby + ']').attr('checked', 'checked');
      }

      // start date
      var d = $repeat.closest('.kn-input').find('input[name=date]').val();
      if (date_format == 'dd/mm/yy') {
        d = Knack.convertDateEuroString(d);
      }
      var date = new Date(d);

      // convert euros
      if (repeat && repeat.start_date) {
        date = new Date(repeat.start_date);
      } else {
        // check weely
        var days = ['SU','MO','TU','WE','TH','FR','SA'];
        var day = date.getDay();
        $('input[name=' + days[day] + ']').attr('checked', 'checked');
      }

      if (date_format == 'mm/dd/yy') {
        date = Knack.getDate(date);
      } else {
        date = Knack.getDateEuro(date);
      }

      $('.repeat-start-date').text(date);

      // trigger
      $('#kn-calendar-repeat select[name="frequency"]').change();
    },

    // Repeat
    renderRepeatChecked: function($repeat, repeat, date_format) {

      var _this = this;

      $repeat.data('repeat', repeat);

      if (repeat && repeat.frequency) {
        var freq = repeat.frequency.charAt(0).toUpperCase() + repeat.frequency.slice(1);
        $repeat.parent().find('span.repeat-label').text(Knack.trans('Repeat: '));
        $repeat.parent().parent().find('span.repeat-edit').html('<strong>' + Knack.trans(freq) + '</strong> <a class="kn-repeat-edit" href="#">' + Knack.trans('edit') + '</a>');
        $repeat.attr('checked', 'checked');
      }

      // edit handler
      $repeat.parent().parent().find('.repeat-edit a').click(function(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        _this.renderRepeat($repeat, date_format);
      });
    },

    renderEquations: function() {
      var _this = this;

      // builder doesn't care about this
      if (this.options.disabled) {
        return;
      }

      const options = { local: true }
      var vals = this.getFormValues(options)
      this.equations_by_field = {
      };

      _.each(this.getInputs(), function(input) {

        if (input && input.type && (input.type == 'equation' || input.type == 'concatenation')) {

          // index equations by input and add events for change()
          if (typeof input.field.format.equation === 'string') {
            var equation = input.field.format.equation;

            Knack.objects.get(this.model.view.source.object).fields.each(function(field) {
              if (!field) {
                return;
              }
              var match = equation.match(new RegExp('{' + field.get('key').replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '}', 'g'));

              if (!_this.equations_by_field[field.get('key')] && match) {
                _this.equations_by_field[field.get('key')] = [];
                _this.$('#' + field.get('key') + ', #' + _this.model.view.key + '-' + field.get('key')).bind('change blur keyup', $.proxy(_this.handleChangeEquationInput, _this));
                _this.$('input[name=' + _this.model.view.key + '-' + field.get('key') + ']').bind('change blur keyup', $.proxy(_this.handleChangeEquationInput, _this));
              }

              if (match) {
                _this.equations_by_field[field.get('key')].push(input.field);
              }
            });

          } else { //legacy equations
            _.each(input.field.format.equation, function(piece) {

              if (piece.type && piece.type == 'field') {
                if (!this.equations_by_field[piece.field.key]) {
                  this.equations_by_field[piece.field.key] = [];
                  this.$('#' + piece.field.key + ', #' + this.model.view.key + '-' + piece.field.key).bind('change blur keyup', $.proxy(this.handleChangeEquationInput, this));
                }
                this.equations_by_field[piece.field.key].push(input.field);
              }

            }, this);
          }

          setTimeout(() => {
            this.calculateEquation(input.field, vals, input.field.type)
          }, 10);
        }

        //this.calculateEquation(input, vals);
      }, this);

      // start by running through it
      _.each(this.equations_by_field, function(rules, field) {
        //this.checkEquation(field, vals);
      }, this);
    },

    handleChangeEquationInput: function(event) {
      var id = $(event.currentTarget).prop('name') || $(event.currentTarget).prop('id');
      if (id === 'value') {
        id = $(event.currentTarget).prop('id');
      }
      this.checkEquation(id);
    },

    checkEquation: function(field, vals) {

      const options = { local: true }
      var vals = vals || this.getFormValues(options)
      var _this = this;
      _.each(this.equations_by_field[field], function(equation) {

        // calculate this equation!
        setTimeout(function() {
          _this.calculateEquation(equation, vals, equation.type);
        }, 10);

      });
    },

    calculateEquation: function(equation_field, vals, type) {

      var equation = equation_field.format.equation;

      //log('calculateEquation(), equation: ');
      //log(equation);
      //log(vals);
      //log(type);
      //log(equation_field);

      if (!equation_field.key) {
        equation_field.key = equation_field.id;
      }

      var calc = '';

      if (typeof equation === 'string') {
        calc = equation;
        _.each(Object.keys(vals), function(field) {

          var field_model = Knack.fields[field];

          if (field_model) {

            field_model = field_model.toJSON();
          }

          var val = (vals[field] && field_model) ? Knack.stripNumber(field_model, vals[field]) : 0;

          if (field_model && field_model.type === 'boolean') {

            var field_format = field_model.format.format || field_model.format;
            switch (field_format) {
              case 'true_false':
                val = (vals[field] === 'True') ? 1 : 0;
                break;
              case 'on_off':
                val = (vals[field] === 'On') ? 1 : 0;
                break;
              default:
                val = (vals[field] === 'Yes') ? 1 : 0;
                break;
            }
          } else {

            val = Number(String(val).replace(/[^0-9\.\-]+/g, '')).toFixed(4);
          }

          calc = calc.replace(new RegExp('{' + field.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '}', 'g'), val);
        });

        calc = calc.replace(/currentTime\(\)/g, new Date().valueOf());

        // replace unmatched fields:
        calc = calc.replace(new RegExp(/{field_\d+.field_\d+}/g), 0);
        calc = calc.replace(new RegExp(/{field_\d+}/g), 0);
      } else { // legacy equations
        _.each(equation, function(item) {

          if (item.type == 'text') {

            if (item.text) {
              calc += item.text;
            }

          } else {

            if (item.field && item.field.key) {

              var field = Knack.fields[item.field.key];
              if (field) {
                field = field.toJSON();
                var val = vals[item.field.key] || 0;
                val = Knack.stripNumber(field, val);

                val = Number(String(val).replace(/[^0-9\.\-]+/g, '')).toFixed(4);

                calc += val; //entries[0].values[item.field.key]
              } else {
                calc += 0;
              }

            }

          }
        });
      }

      //log('calc is: ' + calc);

      try {
        if (type === 'equation') {
          var is_date_equation = (equation_field && equation_field.format && equation_field.format.equation_type && equation_field.format.equation_type === 'date');
          val = EquationHelper.evalNumericEquation(calc, Knack.getTimeZone(), is_date_equation);
          val = Number(val).toFixed(4);
        } else {
          val = eval(calc);
        }
      } catch (err) {
        log(err);
        val = 0;
      }

      // set val
      if (type === 'equation') {

        var formatted_number = Knack.formatNumber(val, equation_field.format);

        if (equation_field.format.equation_type === 'date' && equation_field.format.date_result === 'date') {

          var date_format = '';

          if (equation_field.format.date_format !== 'Ignore Date') {

            date_format += equation_field.format.date_format.toUpperCase();
          }

          if (equation_field.format.time_format !== 'Ignore Time') {

            date_format += ' ' + (equation_field.format.time_format === 'HH:MM am') ? 'h:m a' : 'H:m';
          }

          formatted_number = moment(new Date(Number(formatted_number))).format(date_format);
        }

        this.$('#kn-input-' + equation_field.key + ' p.kn-equation').html(formatted_number);
        this.$('#kn-input-' + equation_field.key + ' input[name=value]').val(val);
        this.$('#' + equation_field.key).trigger('change');
      } else {
        this.$('#kn-input-' + equation_field.key + ' p.kn-equation').html(val);
        this.$('#kn-input-' + equation_field.key + ' input[name=value]').val(val);
      }
    },

    handleClickAddOption: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      var _this = this;
      var $input = $(event.currentTarget).closest('.kn-input');

      var field_key = $input.prop('id').split('-').pop();

      var field = Knack.fields[field_key].toJSON();

      if (Knack.getStyleEngine() === 'v2') {
        var html = '<header class="modal-card-head">'
                 + '<h1 class="modal-card-title">' + Knack.trans('Add a new option') + '</h1>'
                 + '<button class="delete close-modal"></button>'
                 + '</header>'
                 + '<section class="modal-card-body">'
                 + '<div id="kn-add-option" class="kn-modal-wrapper kn-view kn-form">'
                 + '<form>'
                 + '<div class="kn-input kn-view"><input type="text" class="input"></div>'
                 + '<input class="kn-button is-primary" type="submit" value="' + Knack.trans('Submit') + '">'
                 + '</form>'
                 + '</div>'
                 + '</section>';
      } else {
        var html = '<h1>' + Knack.trans('Add a new option') + '<span class="close-modal">' + Knack.trans('close') + '</span></h1><div id="kn-add-option" class="kn-modal-wrapper kn-view kn-form">' +
        '<form><div class="kn-input"><input type="text"></div><div class="kn-submit"><input type="submit" value="' + Knack.trans('Submit') + '"></div></form></div>';
      }

      this.handleAddPopoverClass();

      Knack.renderModal(html, {
        'class': 'small',
      });

      $('#kn-add-option input[type=text]').focus();

      $('#kn-add-option form').submit(function(event) {

        event.preventDefault();

        var val = $('#kn-add-option input[type=text]').val();

        if (val) {

          // send to api
          var model = new Backbone.Model();
          var parts = String(_this.model.url).split('/records');
          model.url = parts[0] + '/fields/' + field_key + '/options';

          Knack.showSpinner();

          model.save({
            option: val
          }, {
            success: function(model, response) {

              Knack.hideSpinner();

                // handle changes
              if (response.changes) {
                Knack.handleChanges(response.changes);
              }

                // add to form and auto select
              switch (field.format.type) {
                case 'checkboxes':
                  $input.find('.kn-add-option').before('<label class="option"><input type="checkbox" name="' + val + '" value="' + val + '" checked="checked">' + val + '</label>');
                  break;

                case 'radios':
                  $input.find('.kn-add-option').before('<label class="option"><input type="radio" name="' + _this.model.view.key + '-' + field_key + '" value="' + val + '">' + val + '</label>');
                  break;

                default:
                  $input.find('select').append('<option value="' + val + '">' + val + '</option>');
                  $input.find('option[value="' + val + '"]').prop('selected', true);

                  if (field.format.type == 'multi') {
                    $input.find('.chzn-select').trigger('liszt:updated');
                  }
                  $input.find('select').trigger('change');
                  break;
              }
            },
            error: function() {
              Knack.hideSpinner();
            }
          });

        }

        _this.handleRemovePopoverClass();

        // close popover
        //$('body').click();
        Knack.closeModal();

      });
    },

    renderRules: function() {

      var rules = this.model.view.rules;
      this.rules_by_field = {
      };
      var _this = this;

      if (rules && rules.fields && rules.fields.length) {

        // index rules by input and add events for change()
        _.each(rules.fields, function(rule) {

          _.each(rule.criteria, function(criteria) {
            if (!this.rules_by_field[criteria.field]) {
              this.rules_by_field[criteria.field] = [];

              //log('adding listeners for field: ' + criteria.field + '; length of #kn-input-' + criteria.field + ' input: ' + this.$('#kn-input-' + criteria.field + ' :input').length );

              this.$('#' + criteria.field + ', #kn-input-' + criteria.field + ' :input, input[name=' + criteria.field + ']').bind('change blur keyup', $.proxy(this.handleChangeRuleInput, this));

              var field = Knack.fields[criteria.field];
              if (field.get('type') == 'connection') {
                $('#' + this.model.view.key + '-' + criteria.field).chosen().change(function(event) {
                  _this.handleChangeRuleInput(event);
                });
              }
            }
            this.rules_by_field[criteria.field].push(rule);
          }, this);
        }, this);

        // start by running through it
        _.each(this.rules_by_field, function(rules, field) {
          this.checkRule(field);
        }, this);
      }
    },

    handleChangeRuleInput: function(event) {
      var id = $(event.currentTarget).attr('name') || $(event.currentTarget).attr('id');

      // complex input
      if (!id || id.indexOf('field') == -1) {
        id = $(event.currentTarget).closest('.kn-input').find(':input[name=key]').val();
      } else {

        // check if name is preceded with a view key (used for radios so they don't overwrite each other)
        if (id.indexOf('-') > -1) {
          id = id.split('-')[1];
        }
      }
      this.checkRule(id);
    },

    // Check if `display: none` instead of using the `:visible` jQuery selector as the element may not have been shown yet
    hasNoVisibleChildren: function(elem) {

      return elem.children().filter(function() {

        return $(this).css(`display`) !== `none`
      }).length === 0
    },

    updateFormGroupVisibility: function(elem, show) {

      const parentLi = elem.closest(`li.kn-form-col`)

      const parentUl = parentLi.closest(`ul.kn-form-group`)

      if (show) {

        parentLi.show()

        parentUl.show()
      } else {

        if (this.hasNoVisibleChildren(parentLi)) {

          parentLi.hide()
        }

        if (this.hasNoVisibleChildren(parentUl)) {

          parentUl.hide()
        }
      }
    },

    checkRule: function(field) {

      var _this = this;

      // log('checkRule, visible of: ' + '#' + field + ':visible ' + this.$('#kn-input-' + field ).css('display'))

      // ignore hidden inputs
      if (this.$('#kn-input-' + field).css('display') == 'none') {
        return;
      }

      _.each(this.rules_by_field[field], function(rule) {

        // log('Rule: Passed? checking criteria...:');
        var passed = this.checkRuleCriteria(rule.criteria);
        // log('passed: ' + passed);

        if (passed) {

          _.each(rule.actions, function(action) {

            if (action.action == 'label') {
              this.$('#kn-input-' + action.field + '>label>span:first-child').html(action.value);
            } else {
              if (action.action == 'show' || action.action == 'show-hide') {

                const elem = this.$(`#kn-input-${action.field}`)

                elem.show()

                _this.updateFormGroupVisibility(elem, true)
              } else {

                const elem = this.$(`#kn-input-${action.field}`)

                elem.hide()

                _this.updateFormGroupVisibility(elem, false)
              }
            }
          }, this);

        } else {

          _.each(rule.actions, function(action) {
            //log('field: ' + action.field + '; length: ' + this.$('#kn-input-' + action.field).length  + '; action: ' + action.action );
            if (action.action == 'show-hide') {

              const elem = this.$(`#kn-input-${action.field}`)

              elem.hide()

              _this.updateFormGroupVisibility(elem, false)
            } else if (action.action == 'hide-show') {

              const elem = this.$(`#kn-input-${action.field}`)

              elem.show()

              _this.updateFormGroupVisibility(elem, true)
            }
          }, this);

        }
      }, this);

      this.renderSignatures();
    },

    checkRuleCriteria: function(criteria) {

      var pass = true;

      _.each(criteria, function(rule) {

        // read-only input
        if (this.$('#kn-input-' + rule.field).hasClass('kn-read-only')) {

          var val = this.model.get(rule.field + '_raw');

        // regular input
        } else {

          var val = this.$('#' + rule.field).val();

          if (val == null) {

            val = this.$('#' + this.model.view.key + '-' + rule.field).val();
          }

          if (!val || val == null) {

            var f = Knack.fields[rule.field].toJSON();//get('type')
            //log('undefined, check type: ' + f.type);

            switch (f.type) {
              case 'connection':
              case 'multiple_choice':
                if (f.format && f.format.type == 'radios') {
                  val = this.$('#kn-input-' + rule.field).find(':input[type=radio]:checked').val();
                } else {
                  val = this.$('#kn-input-' + rule.field + ' select').val();
                }
                break;

              case Knack.config.BOOLEAN:
                if (this.$('#kn-input-' + rule.field).find(':input[type=radio]').length) {
                  var true_values = 'yes,true,on';
                  val = (true_values.indexOf(this.$('#kn-input-' + rule.field).find(':input[type=radio]:checked').val().toLowerCase()) > -1) ? true : false;
                }
                break;

              case Knack.config.ADDRESS:
              case Knack.config.NAME:
              case Knack.config.LINK:
              case Knack.config.EMAIL:
                val = {
                };
                $('#kn-input-' + rule.field).find(':input:not([name=key])').each(function() {
                  val[$(this).attr('name')] = $(this).val();
                });
                break;

              case Knack.config.SIGNATURE:

                val = this.$('#kn-input-' + rule.field).find('.sig_value_' + rule.field).val();
                break;

              case Knack.config.RATING:

                val = this.$('#' + this.model.view.key + '-' + rule.field).rateit('value');
                break;

              case Knack.config.FILE:
                val = this.$(`#kn-input-${rule.field}`).find(`input.file[name=${rule.field}]`).val();
                break;

              case Knack.config.IMAGE:
                val = this.$(`#kn-input-${rule.field}`).find(`input.image[name=${rule.field}]`).val();
                break;
            }
          }

          if (val == undefined || typeof (val) == 'undefined') {

            const inputElement = $(f.type === `connection` ? `:input[name=${this.model.view.key}-${rule.field}], :input[name=${rule.field}]` : `:input[name=${rule.field}]`)

            // use the rules value if array (checkboxes)
            if (inputElement.length > 1) {

              inputElement.each(function() {

                // Handles non-option-specific cases ("is blank", "is not blank", etc.)
                if (!rule.value && $(this).is(':checked')) {

                  val = $(this).val()

                  // Handles specific cases (selected is "A")
                } else if (rule.value && $(this).val() == rule.value) {

                  val = ($(this).is(':checked')) ? rule.value : '';
                }
              });

            } else if (inputElement.is(':checkbox')) {

              val = '';
              if (inputElement.prop('checked')) {
                val = inputElement.val();
              }
            }
          }
        }
        //log('checking rule.field: ' + rule.field + '; val: ' + val);
        //log('checkRuleCriteria(); checkbox: ' + this.$(':input[name=' + rule.field + ']').is(':checkbox') + '; val: ' + val);

        var record = {
        };
        record[rule.field + '_raw'] = val;

        // if any rule is false than the pass is false
        var rule_pass = Knack.checkRule(rule, record);
        // log('rule_pass: ' + rule_pass);
        if (!rule_pass) {
          pass = rule_pass;
        }
      }, this);

      return pass;
    },

    addInput: function(input) {

      if (input.input_type == 'skip') {
        return;
      }

      var input_html = '<div class="kn-input kn-input-' + input.type + '" id="kn-input-' + input.id + '">';

      if (input.type != 'section_break') {
        input_html += '<label for="' + input.id + '"">' + input.label + '</label>';
      }

      //slog('addInput, type: ' + input.input_type);log(input);

      input_html += Knack.render(input.input_type, this.input_map[input.input_type], {
        'input': input,
        view: {
          key: this.model.view.key
        }
      });

      // instructions
      input_html += '<p class="kn-instructions"';
      if (!input.instructions) {
        input_html += 'style="display: none;"';
      }
      input_html += '>';
      if (input.instructions) {
        input_html += Knack.parseText(input.instructions);
      }
      input_html += '</p>';

      input_html += '</div>';
      this.$('form').append(input_html);

    },

    isFormSubmitable: function(canSubmit) {

      $('#user-password .kn-submit :input:submit').prop('disabled', !canSubmit);
      $('#user-password .kn-submit :input:submit').toggleClass('submit--disabled', !canSubmit);

    },

    handleSubmitForm: function(event) {

      if (event) {
        event.preventDefault();
      }

      if (this.address_helper.selecting_address) {

        return false;
      }

      if (this.options.disabled || this.$('.kn-submit :input:submit').prop('disabled')) {
        return false;
      }

      // we don't need to return read-only values in the payload
      const options = { ignore_read_only: true };

      this.validateNumericValues(options);

      const showErrorMessage = (errors) => {
        const errorsList = errors.map(error => `<li>${error}</li>`).join("");

        $.utility_forms.renderMessage(this.$('form'), this.errorMessage + `<ul>${errorsList}</ul>`, 'error');
      };

      // check for errors in numeric values
      if (!this.isValidForm) {
        // remove any previous error messages
        this.$('form .kn-message').remove();
        // Create input errors list
        showErrorMessage(this.inputsWithErrors);
        return;
      }

      // save disabled fields so we can re-disable them later if there is a form error
      this.disabled_form_fields = this.$(':input:disabled');

      // Disable form
      this.$(':input, :input:submit').prop('disabled', true);

      if (Knack.getStyleEngine() === 'v2') { // Add spinner in button

        if (event) {

          $(event.currentTarget).find('[type="submit"]').addClass('is-loading');
        }
      } else { // Old spinner next to button

        this.$('.kn-submit .kn-spinner').show();
      }

      // remove any previous error messages
      this.$('form .kn-message').remove();

      var values = this.getFormValues(options);

      // get values can get interrupted and will return false
      if (!values) {
        return false;
      }

      // get parent vars
      var crumbtrail = {
      };
      this.$('input.crumb').each(function() {
        crumbtrail[$(this).attr('name')] = $(this).val();
      });
      values.crumbtrail = crumbtrail;

      // get any connections
      var connection = null;
      if (this.$('input[name=parent_object]').length>0) {
        connection = {
          object: this.$('input[name=parent_object]').val(),
          field: this.$('input[name=parent_field]').val(),
          id: this.$('input[name=parent_id]').val()
        };
      }

      // coordinates
      if (this.coords) {
        values.coords = this.coords;
      }

      // add the url
      values.url = window.location.href;
      if (Knack.mode === 'renderer') {
        var parent_scene = Knack.getPreviousScene();
        if (parent_scene) {
          values.parent_url = window.location.href.split(Knack.hash_token)[0] + parent_scene.link;
        }
      }

      // submit form, check for listener
      if (this.options.submit_handler) {
        this.options.submit_handler(values, connection, crumbtrail);
      } else {
        this.addFormSubmission(values);
      }
    },

    addFormSubmission: function(values, connection, crumbtrail) {
      var _this = this;
      var submit_options = this.submit_options || {
      };
      submit_options.error = function(model, result, options) {

        // check status for authentication errors
        if (result.status == 401) {

          // check for cookie and try resubmitting?
          CookieUtil.checkCookie({
            cookie_key: Knack.app.id + '-' + logins.scene.get('slug'),
            success: function() {
              form.addFormSubmission(values);
            },
            failure: function() {
              window.location.reload(true);
            },
            no_cookie: function() {
              window.location.reload(true);
            }
          });

        } else {

          if ((result.status == 500 || result.status == 502) && !result.errors) {
            result.errors = 'Unfortunately there was a technical issue and this form did not submit successfully.<br />Please contact the owner of this form for more information.';
          }

          // regular error handling
          $.utility_forms.errorCallback(model, result, _this.$('form'));

          // scroll to top
          $(window).scrollTop(_this.$el.offset().top);

          // enable submit
          _this.$('.kn-submit :input:submit').attr('disabled', false);
          _this.disabled_form_fields.attr('disabled', true);
        }
      };
      submit_options.wait = true;

      this.model.save(values, submit_options);
    },

    setSubmitHandler: function(success) {
      this.options.success = success;
    },

    handleFormSubmitted: function(model, result) {
      var _this = this;

      // reset action
      this.model.view.action = this.action;
      delete Knack.calendar_date;

      if (result) {

        var is_new = this.is_new; // save for trigger events

        // add this for any customization where we want to edit this immediately after submitting
        /* - this was screwing up all the actions if this form was called again;  if this is still needed will have to move the fetch/render split into this view and remove from scene*/
        /*
        this.model.view.action = 'update';
        var url = this.model.url.split('?');
        this.model.url = url[0] + result.id;
        this.model.id = result.id;
        if (url[1]) this.model.url += '?' + url[1];
        */

        this.returned_record = result.record || result; // a registration doesn't return a result set
        this.savedRecordData = new DataRow(result.record);
        this.is_new = false;

        // trigger events
        //$(document).trigger('knack-form-submission.' + this.model.view.key, result);

        var args = [this.model.view, this.returned_record];

        if (this.model.view.source) {
          var which = (is_new) ? 'knack-record-create' : 'knack-record-update';
          $(document).trigger(which + '.any', args);
          $(document).trigger(which + '.' + this.model.view.key, args);
         // $(document).trigger('knack-form-submit', args ); // deprecate this in favor of .any so it doesn't trigger all the namespaces
          $(document).trigger('knack-form-submit.' + this.model.view.key, args);
          $(document).trigger('knack-form-submit.any', args);

          // trigger by object (used by table view to insert new record)
          if (is_new) {
            $(document).trigger('knack-record-create.' + this.model.view.source.object, args);
          }
        }

        // enable the form submit button in case this is redirecting to a modal page
        _this.$(':input, .kn-submit :input:submit').attr('disabled', false);
        _this.$('.kn-submit .kn-spinner').hide();

        // handle success
        if (this.options.success) {

          this.options.success(model, result);

        // old form confirmation - only required during transition?
        } else if (!result.submit_key) {

          this.handleConfirmation();

        // new form
        } else {

          var submit_rule = _.find(this.model.view.rules.submits, function(rule) {
            return rule.key == result.submit_key;
          });

          // process submit rule!
          switch (submit_rule.action) {

            case 'message':
              this.renderSubmitMessage(submit_rule);
              break;

            case 'existing_page':

              var url_parts = window.location.href.split(Knack.hash_token);

              // set link
              var link = url_parts[0] + Knack.hash_token;

              // get scene
              var scene = Knack.scenes.getBySlug(submit_rule.existing_page).toJSON();

              if (scene.parent) {
                var parent = Knack.scenes.getBySlug(scene.parent).toJSON();
                if (parent.parent) {
                  var parent_scene = Knack.scenes.getBySlug(parent.parent).toJSON();
                  if (!parent_scene.type || parent_scene.type != 'authentication') {
                    link += parent.parent + '/';
                  }
                }
                if (!parent.type || parent.type != 'authentication') {
                  link += scene.parent + '/';
                }
              }

              link += submit_rule.existing_page;

              // add id if parent
              if (scene.object && scene.object == this.model.view.source.object && this.savedRecordData) {
                const secureRecordId = this.savedRecordData.getSecureId(submit_rule.existing_page);

                link += '/' + secureRecordId;
              }

              var to = setTimeout(function() {
                window.location = link;
              }, 1000);

              break;

            case 'child_page':

              // set link
              var url_parts = window.location.href.split(Knack.hash_token);
              var link = url_parts[0];

              link += Knack.getSceneHash();

              // get scene
              if (link[link.length-1] != '/') {
                link += '/';
              }
              link += submit_rule.scene;

              // add id if parent
              if (this.savedRecordData) {
                const secureRecordId = this.savedRecordData.getSecureId(submit_rule.scene);
                link += '/' + secureRecordId;
              }

              var to = setTimeout(function() {
                window.location = link;
              }, 500);

              break;

            case 'parent_page':

              // check for modal
              var current_scene = Knack.scenes.getBySlug(Knack.router.current_scene);
              var is_modal = current_scene.get('modal');

              // modal: simply close
              if (is_modal && Knack.modals && Knack.modals.length) {

                Knack.closeModal();

                var to = setTimeout(function() {

                  Knack.showSpinner();
                  Knack.router.scene_view.render();

                }, 500);

              // non-modal, navigate back
              } else {

                // set link
                var link = Knack.getPreviousScene().link;

                if (!_.isNull(Knack.url_ref) && !_.isEmpty(Knack.url_ref)) {

                  link = `${link}?${decodeURIComponent(Knack.url_ref)}`
                }

                var to = setTimeout(function() {
                  window.location = link;
                }, 500);
              }

              break;

            case 'url':
              var to = setTimeout(function() {
                window.location = submit_rule.url;
              }, 500);
              break;

          }
        }
      }

      // trigger submit on the view
      this.trigger('submit', this.returned_record);

      // A redirect may be to a modal page, so clear the loading state on submit buttons
      this.$el.find(`[type=submit]`).removeClass(`is-loading`)
    },

    renderSubmitMessage: function(rule) {

      // kill rateits...these were conflicting with table views
      this.$('form .rateit').remove();

      // reload
      if (rule.reload_auto) {

        this.reloadForm();

      // roll up form
      } else {

        this.$('form').slideUp({
          complete: function() {
          }
        });
      }

      // move to top of form
      var $scroll = $(window);
      if (this.$el.closest('.kn-modal-bg').length) {
        $scroll = this.$el.closest('.kn-modal-bg');
      }
      $scroll.scrollTop(this.$el.offset().top);

      // render message
      this.$('.kn-message p').html(rule.message);
      this.$('.kn-form-confirmation').fadeIn('slow');

      if (this.model.view.ignore_reload || !rule.reload_show) {
        this.$('.kn-form-reload').hide();
      }
    },

    handleConfirmation: function() {

      var _this = this;

      // return if no confirmation, like a modal to add a connected record
      if (!this.model.view.confirmation || !this.model.view.confirmation.type) {
        return;
      }

      switch (this.model.view.confirmation.type) {
        case 'show_text':

          this.$('.kn-message p').html(this.model.view.confirmation.confirmation_text);

          // kill rateits...these were conflicting with table views
          this.$('form .rateit').remove();
          this.$('form').slideUp({
            complete: function() {
              $(window).scrollTop(_this.$el.offset().top);
            }
          });
          this.$('.kn-form-confirmation').fadeIn('slow');

          if (this.model.view.ignore_reload) {
            this.$('.kn-form-reload').hide();
          }
          break;

        case 'redirect_url':
          window.location = this.model.view.confirmation.confirmation_url;
          break;

        case 'redirect_scene':

          var url_parts = window.location.href.split(Knack.hash_token);
          //values.url = url_parts[0];
          var _this = this;

          // set link
          var link = url_parts[0] + Knack.hash_token;

          // get scene
          var scene = Knack.scenes.getBySlug(this.model.view.confirmation.confirmation_scene).toJSON();

          if (scene.parent) {
            var parent = Knack.scenes.getBySlug(scene.parent).toJSON();
            if (parent.parent) {
              var parent_scene = Knack.scenes.getBySlug(parent.parent).toJSON();
              if (!parent_scene.type || parent_scene.type != 'authentication') {
                link += parent.parent + '/';
              }
            }
            if (!parent.type || parent.type != 'authentication') {
              link += scene.parent + '/';
            }
          }

          link += _this.model.view.confirmation.confirmation_scene;

          // add id if parent
          if (scene.object && scene.object == this.model.view.source.object && this.savedRecordData) {
            const secureRecordId = this.savedRecordData.getSecureId(_this.model.view.confirmation.confirmation_scene);

            link += '/' + secureRecordId;
          }

          var to = setTimeout(function() {
            window.location = link;
          }, 1000);
          //Knack.router.navigate('/' + this.model.view.confirmation.confirmation_scene, true);
          break;
      }

    },

    handleReloadForm: function(event) {
      event.preventDefault();

      this.reloadForm();
    },

    reloadForm: function() {

      // reset model
      if (this.model.view.action == 'create' || this.model.view.action == 'insert') {
        this.model.clear();
        this.model.id = null;
        this.model.initInputs();
      }

      // render
      this.render();
      this.postRender();
    },

    getValues: function(options) {
      options || (options = {
      });
      var values = {
      };
      var rule_values = {
      };
      var proceed = true;
      var _this = this;

      // if we have the "include_vars" variable set, that means that we are on a registration form, and want to
      // ensure that a default "account_status" property is set when we create the new record to base the user on.
      if (this.model.view.include_vars) {

        values.account_status = this.model.view.include_vars.account_status;
      }

      // get inut values
      var input_selector = (this.ignore_hidden_inputs) ? '.kn-input:visible' : '.kn-input';

      this.$(input_selector).each(function() {
        const $input_div = $(this);

        // READ ONLY
        if ($input_div.hasClass('kn-read-only')) {

          var key = $input_div.attr('data-input-id');

            // convert connections to arrays of IDs
          if ($input_div.hasClass('kn-input-connection')) {
            if (!options.ignore_read_only) {
              values[key] = _.pluck(_this.model.get(key + '_raw'), 'id');
            } else {
              rule_values[key] = _.pluck(_this.model.get(key + '_raw'), 'id');
            }
          } else {
            if (!options.ignore_read_only) {
              values[key] = _this.model.get(key + '_raw');
            } else {
              rule_values[key] = _this.model.get(key + '_raw');
            }
          }

        // DATE TIME
        } else if ($input_div.hasClass('kn-input-date_time')) {

          var date_time = {
          };
          date_time.date = $input_div.find('input[name=date]').val();

          if ($input_div.find('input[name=time]').length && $input_div.find('input[name=time]').val()) {
            var time = $input_div.find('input[name=time]').timepicker('getTime');
            if (time && time.getHours) {
              var hours = time.getHours();
              if (hours > 12) {
                hours -= 12;
              }
              if (hours == 0) {
                hours = 12;
              }
              date_time.hours = hours;
              date_time.minutes = time.getMinutes();
              date_time.am_pm = (time.getHours() > 11) ? 'PM' : 'AM';
            } else {
              date_time.hours = 12;
              date_time.minutes = 0;
              date_time.am_pm = 'AM';
            }
          }

          if ($input_div.find('input[name=to_date]').length || $input_div.find('input[name=to_time]').length) {

            date_time.to = {
            };
            if ($input_div.find('input[name=to_date]').length) {
              date_time.to.date = $input_div.find('input[name=to_date]').val();
            }

            if ($input_div.find('input[name=to_time]').length && $input_div.find('input[name=to_time]').val()) {
              var time = $input_div.find('input[name=to_time]').timepicker('getTime');
              if (time && time.getHours) {
                var hours = time.getHours();
                if (hours > 12) {
                  hours -= 12;
                }
                if (hours == 0) {
                  hours = 12;
                }
                date_time.to.hours = hours;
                date_time.to.minutes = time.getMinutes();
                date_time.to.am_pm = (time.getHours() > 11) ? 'PM' : 'AM';
              } else {
                date_time.to.hours = 12;
                date_time.to.minutes = 0;
                date_time.to.am_pm = 'AM';
              }
            }
          }

          if ($input_div.find('input[name=all_day]').prop('checked')) {
            date_time.all_day = true;
          }

          if ($input_div.find('input[name=repeat]').prop('checked')) {
            date_time.repeat = $input_div.find('input[name=repeat]').data('repeat');
          }

          // set val
          var key = $(this).find(':input[name=key]').val();
          values[key] = date_time;

          // check repeat
          // confirm repeat if this is an update, the old date was a repeat, and this date was a repeat
          //if (!options.local && Knack.calendar_date && Knack.calendar_date.field == key) {

          if (_this.model.view.action == 'update') {

            var old_val = _this.model.get(key + '_raw');

            if (!options.local && Knack.calendar_date && Knack.calendar_date.field == key && old_val.repeat && Knack.getSceneInHash(Knack.calendar_date.scene)) {

              if (!_this.repeat_edit) {

                var first_event = (old_val.date == old_val.date_formatted);

                // render template
                var html = Knack.render('CalendarRepeatEdit', CalendarRepeatEdit, {
                  first_event: first_event
                });

                $.fancybox(
                  html,
                  {
                    'autoDimensions': true,
                    'height': 'auto',
                    'transitionIn': 'none',
                    'transitionOut': 'none'
                  }
                );

                $(`#fancybox-wrap`).css({
                  zIndex: $(`.kn-modal-bg`).css(`z-index`) + 1
                })

                // enable form and show loader
                _this.$(':input, .kn-submit :input:submit').attr('disabled', false);
                _this.$('.kn-submit .kn-spinner').hide();

                $('#calendar-repeat-edit a').click(function(event) {
                  event.preventDefault();
                  $.fancybox.close();
                  var id = $(this).attr('id').split('-');
                  _this.repeat_edit = id[2];
                  _this.handleSubmitForm();
                });

                proceed = false;

                return values;

              } else {
                values[key].repeat.edit = _this.repeat_edit;
                values[key].repeat.edit_date = Knack.calendar_date.edit_date;
              }

            }
          }

        // EQUATIONS
        } else if ($input_div.hasClass('kn-input-equation')) {

          if (options.local) {
            var key = $(this).find(':input[name=key]').val();
            values[key] = $(this).find(':input[name=value]').val();
          }

          // ignore?

        // CONNECTION values
        } else if ($input_div.hasClass('kn-input-connection')) {
          var key = $(this).find(':input[type=hidden]:first').attr('name');
          const connectionPickerValue = $(this).data('connectionPicker').getValueWithIdentifiers()

          values[key] = connectionPickerValue;
          values[`${key}_raw`] = connectionPickerValue;

        // RATING values
        } else if ($input_div.hasClass('kn-input-rating')) {

          var key = $(this).find(':input[name=key]').val();
          var val_id = _this.model.view.key + '-' + key + '-value';
          values[key] = $('#' + val_id).val();

        // SIGNATURE values
        } else if ($input_div.hasClass('kn-input-signature')) {

          var key = $(this).find(':input[name=key]').val();
          var svg = $(this).find('.kn-signature').jSignature('getData', 'svg');
          var base30 = $(this).find('.kn-signature').jSignature('getData', 'base30');
          if (base30 && base30[1]) {
            values[key] = {
              svg: svg[1],
              base30: base30[1]
            };
          } else {
            values[key] = null;
          }

        // TIMER values
        } else if ($input_div.hasClass('kn-input-timer')) {

          var key = $(this).find(':input[name=key]').val();

          values[key] = {
            times: [
              {
                from: {
                  date: $input_div.find(':input[name=date-from]').val()
                },
                to: {
                  date: $input_div.find(':input[name=date-to]').val()
                }
              }
            ]
          };

          var time_from = $input_div.find('input[name=time-from]').timepicker('getTime');
          if (time_from) {
            var hours = time_from.getHours();
            if (hours > 12) {
              hours -= 12;
            }
            if (hours == 0) {
              hours = 12;
            }
            values[key].times[0].from.hours = hours;
            values[key].times[0].from.minutes = time_from.getMinutes();
            values[key].times[0].from.am_pm = (time_from.getHours() > 11) ? 'PM' : 'AM';
          } else {
            values[key].times[0].from.hours = 12;
            values[key].times[0].from.minutes = 0;
            values[key].times[0].from.am_pm = 'AM';
          }

          var time_to = $input_div.find('input[name=time-to]').timepicker('getTime');
          if (time_to) {
            var hours = time_to.getHours();
            if (hours > 12) {
              hours -= 12;
            }
            if (hours == 0) {
              hours = 12;
            }
            values[key].times[0].to.hours = hours;
            values[key].times[0].to.minutes = time_to.getMinutes();
            values[key].times[0].to.am_pm = (time_to.getHours() > 11) ? 'PM' : 'AM';
          } else {
            values[key].times[0].to.hours = 12;
            values[key].times[0].to.minutes = 0;
            values[key].times[0].to.am_pm = 'AM';
          }

        // BOOLEAN values
        } else if ($input_div.hasClass('kn-input-boolean')) {

          var key = $input_div.find('input[name=key]').val();

          if ($input_div.find('input[type=checkbox]').length) {

            values[key] = ($input_div.find(':input[type=checkbox]:checked').length == 1);

          } else if ($input_div.find(':input[type=radio]').length) {

            values[key] = $input_div.find(':input[type=radio]:checked').val();

          } else {
            values[key] = $(this).find(':input:first').val();
          }

        // MULTIPLE CHOICE values
        } else if ($input_div.hasClass('kn-input-multiple_choice') && $input_div.find('input[type=checkbox]').length || $input_div.find('input[type=radio]').length || $input_div.hasClass('kn-input-combo_box') || $input_div.hasClass('kn-input-connection')) {

          $select = $input_div.find('select');

          if ($select.length > 0) {
            key = $select.attr('name');
            values[key] = [];
            if ($select.attr('multiple')) {
              $select.find(':selected').each(function() {
                values[key].push($(this).val());
              });
            } else {
              values[key] = $select.val();
            }
          } else {

            // radios/checkboxes
            var key = $input_div.find('input:first').attr('name') || $input_div.find('input[name=key]').val();

            // check if name is preceded with a view key (used for radios so they don't overwrite each other)
            if (key.indexOf('-') > -1) {
              key = key.split('-')[1];
            }

            if (key) {

              // radios
              if ($input_div.find(':input[type=radio]').length) {
                values[key] = $input_div.find(':input[type=radio]:checked').val();

              } else {

                // checkboxes
                values[key] = [];
                $input_div.find(':input:not([name=key]):checked').each(function() {
                  values[key].push($(this).val());
                });
              }

              if (!values[key]) {
                values[key] = ''; // set a default for triggering requireds
              }
            }
          }

        } else if ($input_div.find('input[name=key]').length > 0) {

          var key = $input_div.find('input[name=key]').val();
          values[key] = values[key] || {
          }; // passwords can combine inputs (authenticate with update)

          $input_div.find(':input:not([name=key])').each(function() {
            values[key][$(this).attr('name')] = $(this).val();
          });

        } else {

          $input = $(this).find(':input:first');
          values[$input.attr('name')] = $input.val();
          if ($input.val() === '' && _this.model.get($input.attr('name') + '_raw')) {
            values[$input.attr('name') + '_raw'] = '';
          }
        }
      });

      // get submit vars
      this.$('.kn-submit .kn-input').each(function() {
        $input = $(this).find(':input:first');
        values[$input.attr('name')] = $input.val();
      });

      if (this.$('input[name=id]').length) {
        values._id = this.$('input[name=id]').val();
      }

      // ensure that we are using read only fields when executing rules. rule_values is set in the case of when the ignore_readonly option is passed for regular form submits
      return {
        old_values: _.clone(values),
        values: _.extend(values, rule_values),
        proceed
      }
    },

    validateValues: function({options, old_values, values}) {

      options = options || {}
      let proceed = true
      let pass = true
      const validation_errors = {}
      const _this = this

      // iterate field values
      _.each(values, function(field_val, field_key) {

        if (Knack.objects && Knack.objects.getField) {

          // get the field so its validation can be retrieved
          var field = Knack.objects.getField(field_key);

          if (field && field.get('validation') && !options.ignore_validation) {
            _.each(field.get('validation'), function(validation_rule) {
              var rule_pass = true;

              _.each(validation_rule.criteria, function(criteria) {
                if (!values.hasOwnProperty(criteria.field)) {
                  rule_pass = false;
                  return;
                }

                if (!values[criteria.field] && options && options.existing_values && options.existing_values[criteria.field]) {
                  values[criteria.field] = options.existing_values[criteria.field];
                }

                var val_to_use = field.get('type') === 'password' ? values[criteria.field].password : values[criteria.field];
                if (rule_pass && !Knack.checkRule(criteria, values, val_to_use)) {

                  rule_pass = false;
                }
              });

              if (rule_pass) {
                pass = false;

                if (!validation_errors[field_key]) {
                  validation_errors[field_key] = [];
                }

                validation_errors[field_key].push(validation_rule.message);
              }
            });
          }
        }

      });

      if (!pass && !options.local) {
        var errors = [];
        _.each(validation_errors, function(validation_message, field_key) {
          _this.$el.find('#' + field_key).addClass('view-input-validation-error');
          errors.push({
            message: validation_message.join('<br>'),
            field: field_key
          });
        });

        _this.$('.kn-submit :input:submit').attr('disabled', false);
        if (_this.disabled_form_fields) {
          _this.disabled_form_fields.attr('disabled', true);
        }

        _this.$('.kn-message.error').remove();
        _this.$('.input-error').removeClass('input-error');
        $.utility_forms.errorCallback(null, {
          status: 400,
          errors: errors
        }, _this.$('form'));

        proceed = false;
      }

      //log('values:');log(values);
      // reset original values to pass to server so that it can look up relevant record values when executing the validation checks on its end.
      // this prevents the accidental overwriting of special fields used as criteria (like user roles or connections) with formatted values
      values = old_values;

      if (proceed) {
        //return false;
        return values;
      }

      return false;
    },

    validateNumericValues: function(options) {

      options = options || {}
      const { values } = this.getValues(options);
      const _this = this;

      // reset validation
      _this.isValidForm = true;
      _this.inputsWithErrors = [];
      _this.errorMessage = '';

      // iterate field values
      _.each(values, function(field_val, field_key) {

        if (Knack.objects && Knack.objects.getField) {

          // get the field so its validation can be retrieved
          var field = Knack.objects.getField(field_key);

          const fieldValue = values[field_key];
          const fieldType = field ? field.attributes.type : '';

          if (fieldType === 'number' || fieldType === 'currency') {
            const regex = /^[0-9. +,-:]+$/;
            if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && !regex.test(fieldValue)) {
              _this.inputsWithErrors.push(field.attributes.name);
              _this.isValidForm = false;
              _this.errorMessage = 'Values entered in number and currency fields must be numeric';
            }
          }
        }
      });
    },

    getFormValues: function(options) {

      const { old_values, values, proceed } = this.getValues(options)

      if (proceed) {

        return this.validateValues({ options, old_values, values })
      }

      return

    },

    handleAssetValidation: function(fileFormData, field_key) {

      const { values } = this.getValues()

      const fieldValidationDefinitions = this.getFieldValidationDefinitions(field_key)

      if (!fieldValidationDefinitions || _.isEmpty(fieldValidationDefinitions)) {

        return true
      }

      const getFailedCriteria = ({ criteria }) => {

        // I don't think this is possible, but adding for safety.
        if (_.isEmpty(criteria)) {

          return false
        }

        // For a single field, it's an AND condition - all must fail for it to be invalid.
        return criteria.every((rule) => {

          const fieldFromSchema = Knack.objects.getField(rule.field)

          if (rule.field !== field_key) {

            return Knack.checkRule(rule, values, values[rule.field])
          }

          return commonRuleHelper.checkRule(rule, fileFormData, fieldFromSchema, Knack.app.toJSON())
        })
      }

      // For multiple fields, it's an OR condition
      const validationFailures = fieldValidationDefinitions.find(getFailedCriteria)

      if (!_.isEmpty(validationFailures)) {

        this.number_of_uploads_in_progress--

        this.handleValidationError({ message: validationFailures.message, field: field_key })

        return false
      }

      return true
    },

    handleValidationError: function({ message, field }) {

      $.utility_forms.errorCallback(null, {
        status: 400,
        errors: [{
          message,
          field
        }]
      }, this.$('form'))
    },

    getFieldValidationDefinitions: function(field_key) {

      const fieldFromSchema = Knack.objects.getField(field_key).attributes

      const fieldValidationDefinitions = fieldFromSchema.validation

      if (fieldValidationDefinitions && fieldValidationDefinitions.length) {

        return fieldValidationDefinitions.map(({ criteria, message }) => ({ criteria, message }))
      }
    },

    renderSignatures: function() {

      setTimeout(() => {
        // signatures
        this.$('.kn-input-signature:visible').each(function() {

          var data = $(this).data('sig');

          if (!data) {
            var $sig = $(this).find('.kn-signature');
            $sig.jSignature({
              UndoButton: true,
              signatureLine: true
            });

            // set val
            var val = decodeURIComponent($(this).find('input[name=value]').val());

            if (val) {
              $sig.jSignature('setData', 'data:base30,' + val);
            }

            // reset handler
            $(this).find('.kn-sig-clear').die().click(function(event) {
              event.preventDefault();
              $sig.jSignature('reset');
            });

            $(this).data('sig', true);
          }
        });
      }, 1000);
    },

    postRender: function() {

      this.renderSignatures();
      $('.kn-input-multiple_choice').has($('ul.chzn-choices')).addClass('no-dropdown-arrow');

      // handling closing timer when clicking off
      var hideTimepicker = function() {

        $('.ui-timepicker-wrapper').hide();
      }

      $('.ui-timepicker-input').off().on('click', function(e) {

        $(this).timepicker('show');

        e.stopPropagation();
      });

      const timePickerBlurHandler = (event) => {

        if ($(event.relatedTarget).hasClass(`ui-timepicker-wrapper`)) {

          return $(event.currentTarget).focus()
        }

        return hideTimepicker()
      }

      $('.ui-timepicker-input').on('blur', timePickerBlurHandler);
    },

    handleAddPopoverClass: function() {
      // Account for z-index conflicts between form popover and modal
      // Add class to form popover element that modifies its z-index to be lower
      // than that of a modal
      $('.kn-popover').addClass('kn-popover-lower');
    },

    handleRemovePopoverClass: function() {
      // Remove class from form popover element that modifies its z-index to be lower
      // than that of a modal
      $('.kn-popover').removeClass('kn-popover-lower');
    }
  });
});
