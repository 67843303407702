var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kn-migration-dashboard-list_container" }, [
    _vm.filteredApplications.length > 0
      ? _c(
          "ul",
          { staticClass: "kn-migration-dashboard-list fancy-scrollbar" },
          _vm._l(_vm.filteredApplications, function (app) {
            return _c("ListItem", {
              key: app.app_id,
              attrs: {
                app: app,
                "selected-application-ids": _vm.selectedApplicationIds,
                now: _vm.now,
              },
            })
          }),
          1
        )
      : _c("div", [_c("p", [_vm._v("Your filter returned no results.")])]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }