define(function() {
  var content = {
    features: [
      {
        title: 'Search Upgrades: Find More Needles',
        copy: 'Search Views just got way more powerful!',
        link: 'http://www.knack.com/links/blogs/find-more-needles.html',
        timestamp: '11/30/2015'
      },
      {
        title: 'Connection Upgrades',
        copy: 'We recently released some new features that continue to improve on our Connections\' flexibility and automation.',
        link: 'http://www.knack.com/links/blogs/connection-upgrades.html',
        timestamp: '11/19/2015'
      },
      {
        title: 'Logging Upgrades',
        copy: 'We are starting to log activity in more places!',
        link: 'http://www.knack.com/links/blogs/logging.html',
        timestamp: '11/10/2015'
      }
    ],
    posts: [
      {
        title: 'Copy a Record with Form Rules',
        copy: 'Here\'s a quick way on how to duplicate a record using a Form...',
        link: 'http://helpdesk.knackhq.com/discussions/topics/5000008480',
        timestamp: '11/14/2014'
      },
      {
        title: 'Require a connected record during User Registration',
        copy: 'Let\'s say you connect a "Company Admin" user role to a "Company" record. How can you require that admin to create the company record during the registration?',
        link: 'http://helpdesk.knackhq.com/discussions/topics/5000008126',
        timestamp: '11/07/2014'
      },
      {
        title: 'Generate QR Codes',
        copy: 'Create and display QR Codes that link directly to your Knack records',
        link: 'http://helpdesk.knackhq.com/discussions/topics/5000039305',
        timestamp: '3/11/2015'
      }
    ]
  };
  return content;
});
