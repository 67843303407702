var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", { staticClass: "kn-migration-loading-spinner-bg" }, [
        _c("div", { attrs: { id: "kn-loading-spinner" } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }