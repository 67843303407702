 define([
   'jquery',
   'backbone',
   'underscore'
 ], function($, Backbone, _) {
   return Backbone.Model.extend({

     view: {
       'title': '',
       'description': ''
     },
     dirty: null, // just save these attributes

     setView: function(view) {
       this.view = $.extend({
       }, this.view, view);
     },

     save: function(attrs, options) {
       if (attrs) {
         this.dirty = attrs;
       }

       var success = options.success;
       var model = this;
       options.success = function(resp, status, xhr) {
         if (success) {
           success(model, resp, xhr);
         }
        // trigger saves
         model.trigger('save', model, attrs);

         _.each(attrs, function(val, attr) {
           model.trigger('save:' + attr, model, val, options);
         }, this);
       };

       Backbone.Model.prototype.save.call(this, attrs, options);
     },

     toJSON: function() {
       if (this.dirty) {
         var json = _.clone(this.dirty);
         this.dirty = null;
         return json;
       }
       return _.clone(this.attributes);

     }
   });
 });
