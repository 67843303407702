define([
  'jquery',
  'underscore',
  'core/lib/backbone',
  'vue',
  'core/backbone/views/BaseView',
  'dashboard/templates/layout-register.html',
  'dashboard/utility/form-vars',
  'dashboard/utility/landing-pages',
  '@knack/validation-helper',
  'core/lib/cookie'
], function($, _, Backbone, Vue, BaseView, template_register, FormVars, LandingPages, Validation_Helper, cookie) {
  return BaseView.extend({

    data: {
    },

    initialize: function() {
      log('register.init()');
      this.hubspotAppID = '23287346';
      this.hubspotFormID = '24d6e745-507d-4092-9a0a-a1d74d7473ad';
    },

    render: function(app_id) {

      var _this = this;

      if (app_id) {
        this.app_id = app_id;
      }

      log('register.render() app_id: ' + this.app_id);

      // set any referrals
      var ref;

      // first we should check if a referral is in the URL
      var URL_parts = location.href.split('?');
      if (URL_parts[1]) {
        URL_parts = URL_parts[1].split('&');
      }

      _.each(URL_parts, function(part) {

        let param = part.split(`=`)

        if (param[0] === `ref` || param[0] === `referral`) {

          ref = param[1]
        }

        // set Google Analytics tracking cookies
        const gaCookieKeys = [`utm_source`, `utm_medium`, `utm_campaign`]

        if (gaCookieKeys.includes(param[0])) {

          // remove routing hash fragment identifier
          param[1] = param[1].split(`#`)[0]

          $.cookie(param[0], param[1], {
            domain: `.knack.com`,
            expires: 30
          })
        }
      })

      // if in params, set the cookie, otherwise try to read from the cookie
      if (ref) {
        $.cookie('referral', ref);
      } else {
        ref = $.cookie('referral');
      }

      log('current ref is: ' + ref);

      // set registration form variables based on referrer
      this.setRegistrationFormVars()

      // render
      $('#dashboard-wrapper').html(Knack.render('template_register', template_register, this.registrationFormVars))
      $('#step-1').show();
      $('#register-login').show();
      $('input[name=first_name]').focus();

      // add app id to login view
      if (this.app_id) {
        $('#register-login-link').attr('href', '/?redirect=template/' + this.app_id);
      }

      if ($.cookie('registration')) {
        _this.data = JSON.parse($.cookie('registration'));
        log('data:'); log(_this.data);
      }

      $('input[name=first_name]').val(_this.data.first_name);
      $('input[name=last_name]').val(_this.data.last_name);
      $('input[name=email]').val(_this.data.email);
      $('input[name=password]').val(_this.data.password);

      var validation_message = {
        props: ['message', 'validated'],
        template: '<div :class="{ \'input-suggestion__message\': true, \'input-suggestion__message--active\': validated }">{{ message }}</div>'
      };

      var validation_messages_container = {
        props: ['password_minimum_character','password_require_no_common'],
        template: '<div class="input-suggestion__container"> \
          <header class="input-suggestion__header">Passwords Need:</header> \
          <validation-message \
            :validated="password_minimum_character" \
            message="Minimum of 8 characters"> \
          </validation-message> \
          <validation-message \
            :validated="password_require_no_common" \
            message="Must not be common password"> \
          </validation-message> \
        </div> \
        ',
        components: {
          'validation-message': validation_message
        }
      };

      new Vue({
        el: '#kn-input-password',
        data: {
          password: '',
          password_minimum_character: false,
          password_require_no_common: false
        },
        watch: {
          password: function(password) {

            var security_settings = {
              password_minimum_character: true,
              password_require_no_common: true
            };

            var validation_errors = Validation_Helper.getPasswordValidationFailures(password, security_settings, false);

            this.password_minimum_character = validation_errors.indexOf('password_minimum_character') === -1;
            this.password_require_no_common = validation_errors.indexOf('password_require_no_common') === -1;
          }
        },
        components: {
          'validation-messages-container': validation_messages_container
        }
      });

      // events
      $('#register-1').off().on('click', function(event) {
        event.preventDefault();

        _this.data.first_name = $('input[name=first_name]').val();
        _this.data.last_name = $('input[name=last_name]').val();
        _this.data.email = $('input[name=email]').val();
        _this.data.password = $('input[name=password]').val();

        $.cookie('registration', JSON.stringify(_this.data));

        var submit = true;
        var is_valid = _this.validate1(submit);

        if (is_valid) {

          Knack.showSpinner();

          // confirm email
          var model = new Backbone.Model();
          model.url = Knack.api_url + '/v1/accounts/register/user';

          var data = {
            name: {
              first: _this.data.first_name,
              last: _this.data.last_name
            },
            email: {
              email: _this.data.email
            },
            password: {
              password: _this.data.password
            }
          };

          log('saving data: ');
          log(data);

          model.save(data, {
            success: function(model, response) {
              Knack.router.navigate('register2', true);
            },
            error: function(model, response) {
              Knack.hideSpinner();
              log('ERROR! index: ' + response.responseText.indexOf('is not available'));
              // log(response);

              var err_msg = 'invalid email address';

              // password validation error
              if (response.responseText.indexOf('Password must') > -1) {
                var response_text = response.responseText;
                var error_response_message = response_text.substring(response_text.lastIndexOf('"message":"') + 11, response_text.lastIndexOf('"}]}'));

                log('error response text ');
                log(error_response_message);

                err_msg = 'something wrong with password';

                return _this.invalidateInput('password', error_response_message);
              }

              // email or unknown error
              _this.invalidateInput('email', err_msg);

              if (response.responseText.indexOf('is not available') > -1) {
                err_msg = 'this email is not available';
              }
              _this.invalidateInput('email', err_msg);
            }
          });
        }
      });

      // On enter, submit form
      $('.input').keypress(function(event) {
        if (event.which == 13) {
          $('#register-1').click();
          return false;
        }
      });

      this.renderInputs();

      if (typeof analytics !== 'undefined') {
        analytics.page('Signup', 'Step 1');
      }
    },

    renderInputs: function() {

      $('input').off().on('focus', function(event) {
        log('focus!');
        var $wrapper = $(event.currentTarget).closest('.input-wrapper');
        $wrapper.find('input').removeClass('invalid');
        $wrapper.find('.invalid-msg').remove();
        $wrapper.find('.hint').addClass('visible');
      });
      $('input').on('blur', function() {
        $(event.currentTarget).closest('.input-wrapper').find('.hint').removeClass('visible');
      });
    },

    invalidateInput: function(name, msg) {
      var $input = $('input[name=' + name + ']');
      var $wrapper = $input.closest('.input-wrapper');
      log($wrapper);
      $input.addClass('invalid');
      $wrapper.find('.invalid-msg').remove();
      $wrapper.find('label').append('<em class="invalid-msg">' + msg + '</em>');

      return false;
    },

    validate1: function(submit) {

      submit || (submit = false);
      var is_valid = true;

      if (!this.data.first_name) {
        is_valid = false;
        if (submit) {
          this.invalidateInput('first_name', 'enter your first name');
        }
      }
      if (!this.data.last_name) {
        is_valid = false;
        var msg = (!this.data.first_name) ? 'enter your first and last name' : 'enter your last name';
        if (submit) {
          this.invalidateInput('last_name', msg);
        }
      }

      if (!this.data.email) {
        is_valid = false;
        if (submit) {
          this.invalidateInput('email', 'enter your email');
        }
      }

      if (!this.data.password) {
        is_valid = false;
        if (submit) {
          this.invalidateInput('password', 'enter your password');
        }
      } else if (this.data.password.length < 5) {
        is_valid = false;
        if (submit) {
          this.invalidateInput('password', 'at least 5 characters');
        }
      }

      return is_valid;
    },

    renderStep2: function() {

      var _this = this;
      if (typeof analytics !== 'undefined') {
        analytics.page('Signup', 'Step 2');
      }

      if (this.app_id && !this.template) {

        var model = new Backbone.Model();
        model.url = Knack.api + '/applications/examples';

        Knack.showSpinner();

        model.fetch({
          success: function(model, data) {
            log('got apps!');
            log(arguments);

            _this.template = _.find(data.apps, function(app) {
              return (app.id == _this.app_id);
            });

            log('template:');
            log(_this.template);

            _this.renderStep2();
          }
        });

        return false;
      }

      $('#knack-register').scrollTop(0);
      Knack.hideSpinner();

      // set registration form variables based on referrer
      this.setRegistrationFormVars()

      // render
      $('#dashboard-wrapper').html(Knack.render('template_register', template_register, this.registrationFormVars))

      $('#step-1').hide();
      $('#step-2').show();
      $('#register-login').hide();

      log('render step2');
      log($.cookie('registration'));
      this.data = JSON.parse($.cookie('registration'));

      // hints
      this.renderInputs();

      // populate account name based on email. if the domain includes one of the "nots", e.g. "someuser@gmail.com", we
      // default the account name to the part before the `@`. Otherwise we default to the domain
      var emails = this.data.email.split('@');
      var domain = emails[1].split('.')[0];
      var nots = 'google,gmail,yahoo,aol,internet,hotmail,microsoft,warner,msn,cox,live.com,me.com,outlook,mac.com,skynet,knack'.split(',');
      function checkArray(str, arr) {
        for (var i=0; i < arr.length; i++) {
          if (str.indexOf(arr[i]) > -1) {
            return true;
          }
        }
        return false;
      }
      var account_name = (checkArray(domain, nots)) ? emails[0] : domain;
      log('account_name: ' + account_name + '; in Array? ' + checkArray(domain, nots));
      $('#url_overlay').text(account_name);
      $('input[name=account]').val(account_name);

      // check for template app
      log('app_id?????? ' + this.app_id);
      if (this.app_id) {
        $('#app-name-wrapper input').val(this.template.title);
        $('#app-name-wrapper label').css({
          float: 'left'
        }).after('<em class="gray" style="vertical-align: middle; padding-left: 10px; font-size: .95em;">we\'ll install the <b>' + this.template.title + '</b> app.</em>');
      }

      // events
      $('input[name=account]').off().on('input change', function(event) {
        $('#url-overlay').html($(this).val());
      }).change().focus();

      // Registration form (Step 2) - Knack URL, App Name, etc.
      $('#register-form-2').on('submit', function(event) {
        event.preventDefault();
        $(event.currentTarget).addClass('is-submitting');

        _this.data.account = $('input[name=account]').val();
        _this.data.app_name = $('input[name=app_name]').val();

        const fromGoogleAd = $.cookie('kn_ad')

        // if this registration came from a Google Ad add tracking properties to the Account
        if (fromGoogleAd) {

          const knAdCookieParts = $.cookie('kn_ad').split('~')

          _this.data.gclid = knAdCookieParts[0]
          _this.data.clientid = knAdCookieParts[1]
        }

        var ref = $.cookie('referral');
        log('ref: ' + ref);
        _this.data.referral = (ref) ? ref : 'direct';

        var submit = true;
        var is_valid = _this.validate2(submit);

        if (is_valid) {

          Knack.showSpinner();

          // confirm email
          var model = new Backbone.Model();
          var url = Knack.api_url + '/v1/accounts/register';

          // add template
          if (_this.app_id) {
            url += '?app_id=' + _this.app_id + '&origin=template&template_slug=' + _this.template.slug;
          }
          model.url = url;

          $.cookie('registration', JSON.stringify(_this.data));

          model.save(_this.data, {
            success: function(model, response) {

              log('success:');
              log(arguments);

              _this.data.account_id = response.account_id;
              _this.data.user_id = response.user.id;
              _this.data.account = response.account;
              _this.data.app_slug = response.app;

              $.cookie('registration', JSON.stringify(_this.data));

              _this.confirmRegistration();
            },

            error: function(model, response) {

              $('#knack-register').scrollTop(0);
              $(event.currentTarget).removeClass('is-submitting');
              Knack.hideSpinner();
              log('ERROR! index: ' + response.responseText.indexOf('is not available'));
              log(response);

              var err_msg = 'this account is not available';
              if (response.responseText.indexOf('is not available') > -1) {
                err_msg = 'this account is not available';
              }
              _this.invalidateInput('account', err_msg);
            }
          });
        } else {
          $(event.currentTarget).removeClass('is-submitting');
        }
      });
    },

    validate2: function(submit) {
      submit || (submit = false);
      var is_valid = true;

      log('validate2');
      log(this.data);

      if (!this.data.account) {
        is_valid = false;
        if (submit) {
          this.invalidateInput('account', 'enter your Knack URL');
        }
      }
      if (!this.data.app_name) {
        is_valid = false;
        if (submit) {
          this.invalidateInput('app_name', 'enter your app name');
        }
      }

      return is_valid;
    },

    confirmRegistration: function() {
      const _this = this;
      this.data = JSON.parse($.cookie('registration'));

      log(this.data);
      log('confirmRegistration, this.data.app_slug: '+ this.data.app_slug);

      if (!_this.data.account) {
        _this.data.account = $('input[name=account]').val();
      }

      if (typeof analytics !== 'undefined') {
        const props = {
          name: (this.data.first_name + ' ' + this.data.last_name),
          email: this.data.email,
          account_slug: this.data.account,
          app_template: this.data.template,
          internal_referrer: this.data.referral,
          signup_type: 'organic',
          createdAt: Date.now(),
          signup_source: 'site',
          company: {
            id: this.data.account.id,
            name: (this.data.first_name + ' ' + this.data.last_name),
            referral: this.data.referral
          },
          systemUser: this.data.user_id
        };

        analytics.alias(this.data.user_id);

        analytics.identify(this.data.user_id, props);

        analytics.page('Signup', 'Complete', {
          integrations: {
            'All': true
          },
          clientid: this.data.clientid,
          gclid: this.data.gclid
        });
      }

      if ($.cookie('hubspotutk')) {
        this.addNewUserToHubspot({ email: this.data.email })
          .finally(() => {
            _this.redirectToBuilderApp(_this.data);
          });
      } else {
        log('No hubspot tracking cookie found');
        _this.redirectToBuilderApp(_this.data);
      }
    },

    redirectToBuilderApp: function (data) {
      let url = Knack.getBuilderUrl(data.account, data.app_slug);

      log('URL IS: ' + url);
      log(data);

      if (!data.template) {
        url += '#welcome';
      }

      window.location.href = url;
    },

    setRegistrationFormVars: function () {

      this.registrationFormVars = FormVars.registrationForm.source

      const hasWorkflowTestReferrer = LandingPages.test.workflow.includes(document.referrer)

      if (hasWorkflowTestReferrer) {

        this.registrationFormVars = FormVars.registrationForm.test.workflow
      }
    },

    addNewUserToHubspot: async function (userVars) {
      const email = userVars.email;
      const hubspotTrackingCookie = $.cookie('hubspotutk');

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${this.hubspotAppID}/${this.hubspotFormID}`;
      const data = {
        fields: [
          {
            name: 'email',
            value: email,
          }
        ],
        context: {
          hutk: hubspotTrackingCookie,
        },
      };

      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip,deflate,compress',
        },
        body: JSON.stringify(data),
      })
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => {throw new Error(text)})}
        else {
         return res.json();
       }
      })
      .catch(err => {
         console.log('Error submitting to hubspot: ', err);
      });
    },
  });
});
