 define([
   'jquery', 'backbone', 'underscore'
 ], function($, Backbone, _) {
   return Backbone.Model.extend({
     idAttribute: 'key',
     dirty: null, // just save these attributes

     initialize: function() {
       if (!Knack.fields[this.id]) {
         Knack.fields[this.id] = this;
       }
     },

     parse: function(response) {
       var field = response.field || response;
       if (!Knack.fields[field.key]) {
         Knack.fields[field.key] = this;
       }
       return field;
     },

     save: function(attrs, options) {
       options || (options = {
       });
       if (attrs) {
         this.dirty = attrs;
       }

       Backbone.Model.prototype.save.call(this, attrs, options);
     },

     url: function() {

      // this was added as a safeguard
       if (Knack.router.current_object && this.id) {
         return Knack.api_dev + '/objects/' + Knack.router.current_object + '/fields/' + this.id;
       }

       var url = Backbone.Model.prototype.url.call(this);

       if (url.indexOf('undefined') > -1 && Knack.mode == 'builder' && Knack.router.current_object) {

         this.collection = Knack.objects.get(Knack.router.current_object);
         this.urlRoot = Knack.api_dev + '/objects/' + Knack.router.current_object + '/fields';

         url = Backbone.Model.prototype.url.call(this);

        // just kill for now?
         if (Builder) {
           Builder.renderErrorMessage();
         }
         return false;
       }

       return url;
     },

     toJSON: function() {
       if (this.dirty) {
         var json = _.clone(this.dirty);
         this.dirty = null;
         return json;
       }
       return _.clone(this.attributes);

     }
   });
 });
