define([
  'jquery',
  'core/templates/inactivity-logout.html',
  'core/templates/v2/inactivity-logout-v2.html',
  'core/utility/utility-cookies',
  `moment`
], function($, template_InactivityLogoutV1, template_InactivityLogoutV2, CookieUtil, moment) {

  return function InactivityTimeout(app, isNonHIPAABuilder) {

    // Support Access Tool: if no timeout is set enforce a default 15min timeout
    if (Knack.isSupportAccessible() && Knack.getProductionMode() !== 'development' && !app.settings.inactivity_timeout_enabled) {

      app.settings.inactivity_timeout_enabled = true
      app.settings.inactivity_timeout = 15
    }

    // safe navigation
    if (!app || !app.settings || !app.settings.inactivity_timeout) {

      log('Inactivity timer not set.');

      return;
    }

    if (!app.settings.inactivity_timeout_enabled) {

      log('inactivity timeout not enabled');

      return;
    }

    let timeout = app.settings.inactivity_timeout

    log('Will log out after ' + timeout + ' minutes of inactivity.');

    var last_event_key = Knack.mode === 'renderer' ? app.id + '-app-last_event' : Knack.user ? Knack.user.id + '-user-last_event' : '-dashboard-last_event';
    var last_event = parseInt(localStorage.getItem(last_event_key))

    let renderedAlert = false

    // If this is a non-HIPAA builder then we don't want to log them out for spending time idling in the dashboard because we currently aren't
    // incrementing their localStorage value in that context
    if (isNonHIPAABuilder === true) {

      refreshLastEvent()
    }

    if (last_event && new Date().getTime() >= logoutTime()) {
      // There is a race condition where we hit this point, but we haven't loaded a user's permissions.
      // In that case we want to wait a small period of time before attempting to log the user out.
      // The difference in time to load is very subtle, so 250ms should be sufficient.
      setTimeout(() => {
        Knack.user.destroy({
          wait: true,
        });
      }, 250);
    }

    // on page refresh we should refresh their localStorage without input/mouse movement
    refreshLastEvent()

    // called on every mousemove, scroll, and keydown
    function refreshLastEvent() {

      last_event = new Date().getTime();

      return localStorage.setItem(last_event_key, last_event);
    }

    // returns when the user should be logged out
    function logoutTime() {

      // This value represents how many milliseconds are in a user's timeout setting
      // 6000ms for a 1 min timeout, 900000ms for 15 min timeout, etc
      const timeoutInMilliseconds = (timeout) ? timeout * 60 * 1000 : 900 * 1000;

      // We store the last_event time in local storage so it persists even after a user closes their tab.
      if (last_event + timeoutInMilliseconds < new Date().getTime()) {

        const previousTime = last_event + timeoutInMilliseconds;
        last_event = new Date().getTime();

        return previousTime;
      }

      return last_event + timeoutInMilliseconds;
    }

    function beginCountdown() {

      var seconds_remaining = 30;
      var $modal = $('#inactivity-alert');
      var $timer = $('#logout-timer');

      var countdown = setInterval(function() {

        if (!$modal.is(':visible')) {

          clearInterval(countdown);
        }

        seconds_remaining--;

        $timer.text(seconds_remaining);

        if (seconds_remaining <= 0) {

          clearInterval(countdown);

          refreshLastEvent();

          if (Knack.mode === 'builder' || Knack.mode === 'dashboard') {

            CookieUtil.destroyCookie({
              key: 'knack-builder'
            });

            Knack.user.off('destroy')
            Knack.user.destroy({
              success: (model, response, options) => {

                Knack.user.clear()
                Knack.user.id = null
              },
              wait: true
            })

            Knack.closeModal()

            Knack.showLoginModal(true)

            $('#knack-content').hide(1500).remove()

            return
          }

          Knack.user.destroy({
            wait: true
          });
        }
      }, 1000);
    }

    function displayAlert(mode) {

      renderedAlert = true

      log('displayAlert: ' + mode);

      var options = {
        inactivity_message: 'Still there? If so, click &quot;Remain Logged In&quot; below.'
      };

      switch (mode) {

        case 'builder':
        case 'dashboard':

          if (!app.settings.inactivity_timeout_enabled) {

            break
          }

          if (app.settings.inactivity_message) {

            options.inactivity_message = app.settings.inactivity_message;
          }

          Knack.renderModalTemplate('template_InactivityLogout', template_InactivityLogoutV1, {
            class: 'small'
          }, options);

          beginCountdown();
          break;
        case 'renderer':

          if (!app.settings.inactivity_timeout_enabled) {

            break
          }

          if (app.settings.inactivity_message) {

            options.inactivity_message = app.settings.inactivity_message;
          }

          var template = (Knack.getStyleEngine() === 'v2') ? template_InactivityLogoutV2 : template_InactivityLogoutV1;

          Knack.renderModalTemplate('template_InactivityLogout', template, {
            class: 'small'
          }, options);

          beginCountdown();
          break;
        default:
          break;
      }

      $('#remain-logged-in-link').off().on('click', function(event) {

        event.preventDefault();

        if ($('#inactivity-alert').length) {

          Knack.closeModal();
          handleTimeout();
        }

        renderedAlert = false;

        return refreshLastEvent();
      });

      $('.kn-modal-bg').off().on('click', function(event) {

        event.preventDefault();

        if ($('#inactivity-alert').length) {

          Knack.closeModal();
          handleTimeout();
        }

        renderedAlert = false;

        return refreshLastEvent();
      });

      $('#log-out-now-link').off().on('click', function(event) {

        event.preventDefault();

        refreshLastEvent();

        return Knack.user.destroy({
          wait: true
        });
      });
    }

    function handleTimeout() {

      if (!Knack.user || !Knack.user.id) {

        return;
      }

      const logout_time = moment(logoutTime())
      const current_time = moment()
      const alert_time = moment(logout_time - (30 * 1000))

      if (current_time.isSameOrAfter(alert_time) && !renderedAlert) {

        return displayAlert(Knack.mode);
      } else if (current_time.isSameOrAfter(logout_time)) {

        refreshLastEvent();

        return Knack.user.destroy({
          wait: true
        });
      }

      return setTimeout(handleTimeout, 1000)
    }

    $(document).on('mousemove scroll keydown', function(event) {

      if (!$('#inactivity-alert').length && Knack.user && Knack.user.id) {

        refreshLastEvent();
      }
    });

    $(document).on('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        if (last_event && new Date().getTime() >= logoutTime()) {
          return Knack.user.destroy({
            wait: true
          });
        }
      }
    });

    handleTimeout();

    return this;
  };
});
