// eslint-disable-next-line no-undef
define([
  'vue',
  'vuex',
  './modules/supportAccessTool',
], (Vue, Vuex, supportAccessTool) => {
  Vue.use(Vuex);

  const state = {
    homeSlug: '',
    isEmbedded: false,
    currentScene: null,
    topScene: null,
    entryPages: [],
    user: null,

    // account actions pages the current authenticated user has access to
    userAccountPages: [],

    // array that contains the hash (slug) of the active scene, plus the
    // hashes of any other parent scenes in the hierarchy
    hashScenes: [],

    // distribution data if app is embedded
    distribution: null,
  };

  const mutations = {
    setHomeSlug: (state, homeSlug) => { state.homeSlug = homeSlug; },
    setIsEmbedded: (state, isEmbedded) => { state.isEmbedded = isEmbedded; },
    setCurrentScene: (state, scene) => { state.currentScene = scene; },
    setTopScene: (state, scene) => { state.topScene = scene; },
    setUser: (state, user) => { state.user = user; },
    setUserAccountPages: (state, userAccountPages) => { state.userAccountPages = userAccountPages; },
    setEntryPages: (state, entryPages) => { state.entryPages = entryPages; },
    setHashScenes: (state, hashScenes) => { state.hashScenes = hashScenes; },
    setDistribution: (state, distribution) => { state.distribution = distribution; },
  };

  const getters = {
    homeSlug: (state) => state.homeSlug,
    isEmbedded: (state) => state.isEmbedded,
    currentScene: (state) => state.currentScene,
    topScene: (state) => state.topScene,
    user: (state) => state.user,
    userAccountPages: (state) => state.userAccountPages,
    entryPages: (state) => state.entryPages,
    hashScenes: (state) => state.hashScenes,
    distributionHeaderDesign: (state) => state.distribution.design.header.display,
  };

  return new Vuex.Store({
    state,
    mutations,
    actions: {},
    getters,
    modules: {
      supportAccessTool,
    },
    plugins: [],
  });
});
