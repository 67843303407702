define([
  'backbone',
  'underscore',
  'core/backbone/models/BaseModel'
], function(Backbone, _, BaseModel) {
  return Backbone.Model.extend({

    dirty: null, // just save these attributes

    initialize: function() {
      this.url = Knack.api_dev + '/settings';
    },

    save: function(attrs, options) {
      if (attrs) {
        this.dirty = attrs;
      }
      Backbone.Model.prototype.save.call(this, attrs, options);
    },

    toJSON: function() {
      if (this.dirty) {
        var json = _.clone(this.dirty);
        this.dirty = null;
      } else {
        var json = _.clone(this.attributes);
      }
      return json;
    },

    parse: function(response) {
      var application = response.application || response;
      return application;
    }

    //
  });
});
