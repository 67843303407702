module.exports = {
  registrationForm: {
    source: {
      step1: {},
      step2: {
        header: `Your first app`,
        nameLabel: `App Name`,
        nameHint: `the name of the first app you want to build`,
      }
    },
    test: {
      workflow: {
        step1: {},
        step2: {
          header: `Your first workflow`,
          nameLabel: `Name Your Workflow`,
          nameHint: `the name of the first workflow you want to build`,
          questionLabel: `What's the biggest workflow challenge you're hoping to solve with Knack?`,
          questionHint: `briefly describe the solution you'd like to build`
        }
      }
    }
  }
}
